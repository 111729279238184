//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const netOpenInterestDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Net open interest delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "net-open-interest-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Net open interest delta",
      shortDescription: "[SF] Net OI delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaColorer",
          type: StudyPlotType.Colorer,
          target: "oiDeltaPlot",
          palette: "oiDeltaPalette",
        },
        {
          id: "negOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posOiDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oiDeltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        oiDeltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          oiDeltaPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          oiDeltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          oiDeltaColorer: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          negOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posOiDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          oiDeltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          displayModeInput: "Taker net long",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        oiDeltaPlot: {
          title: "Net OI delta",
          histogramBase: 0,
        },
        negOiDeltaMaPlot: {
          title: "Negative net OI delta MA",
          histogramBase: 0,
        },
        posOiDeltaMaPlot: {
          title: "Positive net OI delta MA",
          histogramBase: 0,
        },
        oiDeltaStDevPlot: {
          title: "Net OI delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "displayModeInput",
          name: "Display mode",
          defval: "Taker net long",
          options: [
            "Taker net long",
            "Taker net short",
            "Maker net long",
            "Maker net short",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#NETOIDELTA`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User inputs
        const displayModeInput: string = this._input(0);
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const metricsBoolInput: boolean = this._input(4);

        //Market data parsing
        this._context.select_sym(1);
        const oiTime: any = this._context.new_var(this._context.symbol.time);
        const takerLongDeltaArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const takerShortDeltaArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const takerLongDelta: number = takerLongDeltaArray.adopt(
          oiTime,
          mainSymbolTimeSeries,
          1,
        );
        const takerShortDelta: number = takerShortDeltaArray.adopt(
          oiTime,
          mainSymbolTimeSeries,
          1,
        );

        //Calculations
        let oiDelta: number =
          displayModeInput === "Taker net long" ||
          displayModeInput === "Maker net short"
            ? takerLongDelta
            : takerShortDelta;
        if (measureInput === "USD") {
          const close: number = PineJS.Std.close(this._context);
          oiDelta *= close;
        }

        //Derivative metrics
        let netOiDeltaStdev: number = NaN;
        let posNetOiDeltaMa: number = NaN;
        let negNetOiDeltaMa: number = NaN;

        if (metricsBoolInput) {
          const posOiDelta: number = oiDelta > 0 ? oiDelta : 0;
          const negOiDelta: number = oiDelta < 0 ? oiDelta : 0;
          const oiDeltaSeries: any = this._context.new_var(oiDelta);
          const posOiDeltaSeries: any = this._context.new_var(posOiDelta);
          const negOiDeltaSeries: any = this._context.new_var(negOiDelta);
          switch (maTypeInput) {
            case "SMA":
              posNetOiDeltaMa = PineJS.Std.sma(
                posOiDeltaSeries,
                lengthInput,
                this._context,
              );
              negNetOiDeltaMa = PineJS.Std.sma(
                negOiDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              posNetOiDeltaMa = PineJS.Std.ema(
                posOiDeltaSeries,
                lengthInput,
                this._context,
              );
              negNetOiDeltaMa = PineJS.Std.ema(
                negOiDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              posNetOiDeltaMa = PineJS.Std.wma(
                posOiDeltaSeries,
                lengthInput,
                this._context,
              );
              negNetOiDeltaMa = PineJS.Std.wma(
                negOiDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              posNetOiDeltaMa = PineJS.Std.vwma(
                posOiDeltaSeries,
                lengthInput,
                this._context,
              );
              negNetOiDeltaMa = PineJS.Std.vwma(
                negOiDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              posNetOiDeltaMa = PineJS.Std.linreg(
                posOiDeltaSeries,
                lengthInput,
                0,
              );
              negNetOiDeltaMa = PineJS.Std.linreg(
                negOiDeltaSeries,
                lengthInput,
                0,
              );
              break;
            default:
              posNetOiDeltaMa = PineJS.Std.sma(
                posOiDeltaSeries,
                lengthInput,
                this._context,
              );
              negNetOiDeltaMa = PineJS.Std.sma(
                negOiDeltaSeries,
                lengthInput,
                this._context,
              );
          }
          netOiDeltaStdev = PineJS.Std.stdev(
            oiDeltaSeries,
            lengthInput,
            this._context,
          );
        }

        return [
          0,
          oiDelta,
          oiDelta >= 0 ? 1 : 0,
          negNetOiDeltaMa,
          posNetOiDeltaMa,
          netOiDeltaStdev,
        ];
      };
    },
  };
};
