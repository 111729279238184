//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const liquidationsHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Liquidations highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "liquidations-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Liquidations highlighter",
      shortDescription: "[SF] Liquidations highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 0,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Long liquidations" },
            { name: "Short liquidations" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(60, 166, 75, 0.25)" },
            ],
          },
        },
        styles: {
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 3,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0)",
          },
        },
        precision: 0,
        inputs: {
          filterSizeInput: 0,
        },
      },
      styles: {
        backgroundPlot: {
          title: "Liquidations",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "filterSizeInput",
          type: StudyInputType.Integer,
          name: "Size filter",
          defval: 0,
          min: 0,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_LIQUIDATIONS`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const filterSizeInput: number = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const liquidationsTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const quoteBuyArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const quoteSellArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_var(this._context.symbol.time);
        const buyliquidations: number =
          quoteBuyArray.adopt(liquidationsTime, mainSymbolTime, 1) || 0;
        const sellliquidations: number =
          quoteSellArray.adopt(liquidationsTime, mainSymbolTime, 1) || 0;

        //Calculations
        let color: number = NaN;
        if (buyliquidations + sellliquidations > filterSizeInput) {
          switch (true) {
            case sellliquidations > filterSizeInput &&
              buyliquidations > filterSizeInput:
              color = NaN;
              break;
            case sellliquidations > filterSizeInput:
              color = 0;
              break;
            case buyliquidations > filterSizeInput:
              color = 1;
              break;
          }
        }

        //Returns
        return [color];
      };
    },
  };
};
