import {
  IdeasKline,
  IManipulation,
  IPowerDelta,
  ISpotFuturesSpread,
  IStopKiller,
  IStrategy,
  MarketPowerKline,
  PumpTrendKline,
  RetailPowerKline,
  IActivityDetectorBucketResponse,
  ICryptoDriftBucketResponse,
} from "@/domain/interfaces/general.interface.ts";
import { apolloClient } from "@/lib/apolloClient.ts";
import {
  bybitKlineApiV2Domain,
  environment,
  klineApiV2Domain,
} from "@/lib/constants.ts";
import { IBinanceKlineArray } from "@/lib/datafeed/binanceTypes.ts";
import { BINANCE_RESOLUTION_MAP, EXCHANGES } from "@/lib/datafeed/config.ts";
import { gql } from "@apollo/client";
import {
  Bar,
  PeriodParams,
  ResolutionString,
} from "@tradingView/charting_library";
import axios from "axios";

export enum LineStudyPlotStyle {
  Line = 0,
  Histogram = 1,
  Cross = 3,
  Area = 4,
  Columns = 5,
  Circles = 6,
  LineWithBreaks = 7,
  AreaWithBreaks = 8,
  StepLine = 9,
  StepLineWithDiamonds = 10,
  StepLineWithBreaks = 11,
}

export interface ISymbol {
  symbol: string;
  pair: string;
  contractType: string;
  deliveryDate: number;
  onboardDate: number;
  status: string;
  maintMarginPercent: string;
  requiredMarginPercent: string;
  baseAsset: string;
  quoteAsset: string;
  marginAsset: string;
  pricePrecision: number;
  quantityPrecision: number;
  baseAssetPrecision: number;
  quotePrecision: number;
  underlyingType: string;
  underlyingSubType: string[];
  settlePlan: number;
  triggerProtect: string;
  liquidationFee: string;
  marketTakeBound: string;
  filters: IFilter[];
  orderTypes: string[];
  timeInForce: string[];
}

export interface IByBitSymbol {
  name: string;
  base_currency: string;
  quote_currency: string;
  price_scale: number;
  taker_fee: string;
  maker_fee: string;
  leverage_filter: {
    min_leverage: number;
    max_leverage: number;
    leverage_step: string;
  };
  price_filter: {
    min_price: string;
    max_price: string;
    tick_size: string;
  };
  lot_size_filter: {
    max_trading_qty: number;
    min_trading_qty: number;
    qty_step: number;
  };
}

export interface IFilter {
  minPrice?: string;
  maxPrice?: string;
  filterType: string;
  tickSize?: string;
  stepSize?: string;
  maxQty?: string;
  minQty?: string;
  limit?: number;
  notional?: string;
  multiplierDown?: string;
  multiplierUp?: string;
  multiplierDecimal?: string;
}

export async function getBinanceSymbols(): Promise<ISymbol[]> {
  try {
    if (!sessionStorage.getItem("binanceSymbols")) {
      const response = await fetch(
        `https://${klineApiV2Domain}/fapi/v1/exchangeInfo`,
      );
      const { symbols } = await response.json();
      sessionStorage.setItem(
        "binanceSymbols",
        JSON.stringify(
          symbols.filter(
            (symbol: ISymbol) =>
              symbol.status === "TRADING" &&
              symbol.symbol.includes("USDT") &&
              !symbol.symbol.includes("_"),
          ),
        ),
      );
      return symbols;
    } else {
      return JSON.parse(sessionStorage.getItem("binanceSymbols") || "");
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getByBitSymbols(): Promise<IByBitSymbol[]> {
  try {
    if (!sessionStorage.getItem("bybitSymbols")) {
      const response = await fetch(
        `https://${bybitKlineApiV2Domain}/v5/market/instruments-info`,
      );
      const { result } = await response.json();
      sessionStorage.setItem("bybitSymbols", JSON.stringify(result));
      return result;
    }
    return JSON.parse(sessionStorage.getItem("bybitSymbols") || "");
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getTrendAssessmentIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#TREND_ASSESSMENT";
    return ss;
  });
}

export async function getNetOIDeltaSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#NETOIDELTA";
    return ss;
  });
}

export async function getAltTimersSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_ALT_TIMERS";
    return ss;
  });
}

export async function getDogSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_DOG";
    return ss;
  });
}

export async function getTickIndexSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_TICK_INDEX";
    return ss;
  });
}

export async function getLayeringSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#LAYERING";
    return ss;
  });
}

export async function getFundingRateSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#FUNDINGRATE";
    return ss;
  });
}

export async function getOISpikeSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#OISPIKE";
    return ss;
  });
}

export async function getOINetTakerLongSpikeSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#NETTAKERLONGSPIKE";
    return ss;
  });
}

export async function getOINetTakerShortSpikeSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#NETTAKERSHORTSPIKE";
    return ss;
  });
}

export async function getSentimentsSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol =
      ss.symbol.split("USDT")[0] + "#SANTIMENTS_SOCIAL_DOMINANCE_TOTAL";
    return ss;
  });
}

export async function getPowerDeltaIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#POWERDELTA";
    return ss;
  });
}

export async function getPowerTradesSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#POWERTRADES";
    return ss;
  });
}

export async function getManipulationIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_MANIPULATION_MONITOR";
    return ss;
  });
}

export async function getRetailPowerIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_RETAIL_POWER";
    return ss;
  });
}

export async function getPumpTrendIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_PUMP_TREND";
    return ss;
  });
}

export async function getStrategyIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#STRATEGY";
    return ss;
  });
}

export async function getActivityDetectorIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_ACTIVITY_DETECTOR";
    return ss;
  });
}

export async function getCryptoDriftIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_CRYPTO_DRIFT";
    return ss;
  });
}

export async function getStopKillerIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#STOPKILLER";
    return ss;
  });
}

export async function getOpenInterestIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_OPEN_INTEREST";
    return ss;
  });
}

export async function getLiquidationsIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol.split("USDT")[0] + "#SF_LIQUIDATIONS";
    return ss;
  });
}

export async function getVolumeIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_VOLUME";
    return ss;
  });
}

export async function getSpreadIndicatorSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_SPREAD";
    return ss;
  });
}

export async function getDepthSpreadSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#BIDASKSPREAD";
    return ss;
  });
}

export async function getAsk_1_5_Symbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#ASKORDERBOOKSUM1_5";
    return ss;
  });
}

export async function getAsk_6_10_Symbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#ASKORDERBOOKSUM6_10";
    return ss;
  });
}

export async function getBid_1_5_Symbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#BIDORDERBOOKSUM1_5";
    return ss;
  });
}

export async function getBid_6_10_Symbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#BIDORDERBOOKSUM6_10";
    return ss;
  });
}

export async function getBidAskQuotedSpreadSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#BIDASKQUOTEDSPREAD";
    return ss;
  });
}

export async function getRealizedBidAskSpreadSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#REALIZEDBIDASKSPREAD";
    return ss;
  });
}

export async function getMatrixSpreadSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#MATRIXSPREAD";
    return ss;
  });
}

export async function getHFTActivitySymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#HFTACTIVITY";
    return ss;
  });
}

export async function getSpotFutureSpreadSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SPOTFUTURESPREAD";
    return ss;
  });
}

export async function getIdeasSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SF_IDEAS";
    return ss;
  });
}

export async function getSpoofLayerUpSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SPOOF_LAYER_UP";
    return ss;
  });
}

export async function getSpoofLayerDownSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#SPOOF_LAYER_DOWN";
    return ss;
  });
}

export async function getHighestSizeOrderSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#HIGHESTSIZEORDER";
    return ss;
  });
}

export async function getOrderBookChangesSymbols(
  binanceSymbols: ISymbol[],
): Promise<ISymbol[]> {
  return binanceSymbols.map((bs) => {
    const ss = { ...bs };
    ss.symbol = ss.symbol + "#ORDERBOOKCHANGES";
    return ss;
  });
}

const TAG_METRIC_MAP: { [key: string]: string } = {
  SANTIMENTS_MARKETCAP_USD: "marketcap_usd", //
  SANTIMENTS_ACTIVE_ADDRESSES_24H: "active_addresses_24h", //
  SANTIMENTS_TRANSACTION_VOLUME: "transaction_volume", //
  SANTIMENTS_MVRV_USD_INTRADAY: "mvrv_usd_intraday", //
  SANTIMENTS_WHALE_TRANSACTION_COUNT_1M_USD_TO_INF:
    "whale_transaction_count_1m_usd_to_inf", //
  SANTIMENTS_WHALE_TRANSACTION_COUNT_100K_USD_TO_INF:
    "whale_transaction_count_100k_usd_to_inf", //
  SANTIMENTS_NVT_5MIN: "nvt_5min", //
  SANTIMENTS_TOTAL_TRADE_VOLUME_BY_DEX: "total_trade_volume_by_dex", //
  SANTIMENTS_NETWORK_PROFIT_LOSS: "network_profit_loss", //
  SANTIMENTS_WHALE_BALANCE: "whale_balance", //
  SANTIMENTS_EXCHANGE_OUTFLOW_USD: "exchange_outflow_usd", //
  SANTIMENTS_EXCHANGE_INFLOW_USD: "exchange_inflow_usd", //
  SANTIMENTS_EXCHANGE_INFLOW: "exchange_inflow", //
  SANTIMENTS_EXCHANGE_OUTFLOW: "exchange_outflow", //
  SANTIMENTS_EXCHANGE_BALANCE: "exchange_balance", //
  SANTIMENTS_CEX_BALANCE: "cex_balance", //
  SANTIMENTS_WITHDRAWAL_BALANCE: "withdrawal_balance", //
  SANTIMENTS_WHALE_CEX_BALANCE: "whale_cex_balance", //
  SANTIMENTS_EXCHANGES_TO_WHALES_FLOW: "exchanges_to_whales_flow", //
  SANTIMENTS_WHALES_TO_EXCHANGES_FLOW: "whales_to_exchanges_flow", //
  SANTIMENTS_WHALE_TO_CEXES_FLOW: "whale_to_cexes_flow", //
  SANTIMENTS_CEXES_TO_WHALE_FLOW: "cexes_to_whale_flow", //
  SANTIMENTS_POSITIVE_TOTAL: "sentiment_positive_total", //
  SANTIMENTS_BALANCE_TOTAL: "sentiment_balance_total",
  SANTIMENTS_NEGATIVE_TOTAL: "sentiment_negative_total", //
  SANTIMENTS_DEV_ACTIVITY: "dev_activity",
  SANTIMENTS_TWITTER_FOLLOWERS: "twitter_followers",
  SANTIMENTS_GITHUB_ACTIVITY: "github_activity",
  SANTIMENTS_SOCIAL_ACTIVE_USERS: "social_active_users",
  SANTIMENTS_SOCIAL_DOMINANCE_AI_TOTAL: "social_dominance_ai_total",
  SANTIMENTS_SOCIAL_DOMINANCE_TOTAL: "social_dominance_total",
  SANTIMENTS_SOCIAL_VOLUME_TOTAL: "social_volume_total",
};

interface ISantimentBar {
  timestamp: string;
  value: string;
}

interface ISantimentBarResponse {
  getMetric: {
    timeseriesData: ISantimentBar[];
  };
}

export async function fetchSantimentBars(
  name: string,
  periodParams: PeriodParams,
  resolution: string,
): Promise<Bar[]> {
  const { to, from, countBack } = periodParams;
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const tag = name.split("#")[1];
  const metric = TAG_METRIC_MAP[tag] || "";
  const asset = name.split("#")[0].toLowerCase();

  async function getSlugByAsset(asset: string) {
    const response = await fetch(
      `${environment.santimentsSlugApiURL}/${asset}`,
    );
    const data = await response.json();
    return data.slug;
  }

  const slug = await getSlugByAsset(asset);
  if (slug == "") {
    console.log("no slug for", asset);
    return [];
  }

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  // const data = await getSantimentDataFromAPI(slug, metric, interval, from, to);
  const data = await recursiveApiCall<ISantimentBar>(
    environment.santimentsApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1400,
    countBack,
    "santiments",
    metric,
    slug,
  );

  const bars: Bar[] = [];
  data.forEach((d: ISantimentBar) => {
    bars.push({
      time: new Date(d.timestamp).getTime(),
      open: Number(d.value),
      high: 0,
      low: 0,
      close: Number(d.value),
    });
  });
  return bars;
}

function getSantimentDataFromGraphQL(
  slug: string,
  metric: string,
  interval: string,
  from: number,
  to: number,
) {
  const isoFrom = new Date(from * 1000).toISOString();
  const isoTo = new Date(to * 1000).toISOString();
  return apolloClient.query<ISantimentBarResponse>({
    query: gql`
      query {
        getMetric(metric: "${metric}") {
          timeseriesData(
            slug: "${slug}"
            from: "${isoFrom}"
            to: "${isoTo}"
            includeIncompleteData: true
            interval: "${interval}"
          ) {
            datetime
            value
          }
        }
      }`,
  });
}

export async function fetchManipulationBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IManipulation>(
    environment.manipulationApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IManipulation) => {
      return {
        time: d.timestamp,
        close: ((1 - d.corrV) * 100) / 2,
        open: ((1 - d.corr) * 100) / 2,
        high: Number(0),
        low: Number(0),
        volume: Number(0),
      };
    });
  }

  return [];
}

interface IHFTActivityResponse {
  timestamp: number;
  price: number;
  weight: string;
  side: string;
}

export async function fetchHFTActivity(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IHFTActivityResponse>(
    environment.hftActivityURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IHFTActivityResponse) => {
      let weightNum = 0;
      // tiny -
      // small - 1
      // normal/medium - 2
      // large - 3
      // huge - 4
      switch (d.weight) {
        case "TINY":
          weightNum = 1;
          break;
        case "SMALL":
          weightNum = 2;
          break;
        case "MEDIUM":
          weightNum = 3;
          break;
        case "LARGE":
          weightNum = 4;
          break;
        case "HUGE":
          weightNum = 5;
          break;
      }

      return {
        time: Number(d.timestamp),
        open: Number(d.price) == 0 ? NaN : Number(d.price),
        high: weightNum,
        low: d.side === "UP" ? 1 : 0,
        close: Number(0),
      } as Bar;
    });
  }
  return [];
}

export async function fetchRetailPowerBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<RetailPowerKline>(
    environment.retailPowerApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: RetailPowerKline) => {
      return {
        time: d.timestamp,
        close: d.close,
        open: d.open,
        high: d.high,
        low: d.low,
        volume: Number(0),
      };
    });
  }

  return [];
}

export async function fetchMarketPowerBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<MarketPowerKline>(
    environment.marketPowerApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: MarketPowerKline) => {
      return {
        time: d.timestamp,
        close: d.close,
        open: d.open,
        high: d.high,
        low: d.low,
        volume: Number(0),
      };
    });
  }

  return [];
}

export interface IPowerTradeResponse {
  timestamp: number;
  symbol: string;
  price: number;
  weight: string;
  side: string;
}

export async function fetchPowerTradesBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IPowerTradeResponse>(
    environment.powerTradesURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1400,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IPowerTradeResponse) => {
      let weightNum = 0;
      // tiny - 0
      // small - 1
      // normal/medium - 2
      // large - 3
      // huge - 4
      switch (d.weight) {
        case "TINY":
          weightNum = 1;
          break;
        case "SMALL":
          weightNum = 2;
          break;
        case "MEDIUM":
          weightNum = 3;
          break;
        case "LARGE":
          weightNum = 4;
          break;
        case "HUGE":
          weightNum = 5;
          break;
      }

      return {
        time: Number(d.timestamp),
        open: Number(d.price) == 0 ? NaN : Number(d.price),
        high: Number(weightNum),
        low: d.side === "UP" ? 1 : 0,
        close: Number(0),
      };
    });
  }

  return [];
}

export async function fetchPumpTrendBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<PumpTrendKline>(
    environment.pumpTrendApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: PumpTrendKline) => {
      return {
        time: d.timestamp,
        close: d.close,
        open: d.open,
        high: d.high,
        low: d.low,
        volume: Number(0),
      };
    });
  }

  return [];
}

export async function fetchPowerDelta(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IPowerDelta>(
    environment.powerDeltaApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IPowerDelta) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.value),
        high: Number(d.isShort),
        low: Number(d.isLong),
        close: 0,
      } as Bar;
    });
  } else {
    return [];
  }
}

export async function fetchIdeasBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IdeasKline>(
    environment.ideasApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IdeasKline) => {
      return {
        time: d.timestamp,
        close: Number(d.close),
        open: Number(d.open),
        high: Number(d.high),
        low: Number(d.low),
        volume: Number(0),
      };
    });
  }

  return [];
}

export async function fetchStrategyBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IStrategy>(
    environment.strategyApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: IStrategy) => {
      return {
        time: d.timestamp,
        close: Number(0),
        open: d.probability,
        high: d.direction == "BUY" ? 1 : 0,
        low: Number(d.price),
        volume: Number(0),
      };
    });
  }

  return [];
}

export interface IFundingRate {
  timestamp: number;
  open: number;
  high: number;
  low: number;
  close: number;
}

export async function fetchFundingRate(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IFundingRate>(
    environment.fundingRateApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: IFundingRate) => {
      return {
        time: d.timestamp,
        close: d.close,
        open: d.open,
        high: d.high,
        low: d.low,
        volume: Number(0),
      };
    });
  }

  return [];
}

export interface IAskBidSum {
  lastUpdateId: number;
  timestamp: number;
  T: number;
  bids: string[];
  asks: string[];
}

export async function fetchBidAsk(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
  bidOrAskParams: string,
): Promise<Bar[]> {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IAskBidSum>(
    environment.depthsSumApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IAskBidSum) => {
      switch (bidOrAskParams) {
        case EXCHANGES.ASKORDERBOOKSUM1_5:
          return {
            time: d.timestamp,
            open: d.asks.length > 0 ? Number(d.asks[0]) : Number(0),
            high: d.asks.length > 0 ? Number(d.asks[1]) : Number(0),
            low: d.asks.length > 0 ? Number(d.asks[2]) : Number(0),
            close: d.asks.length > 0 ? Number(d.asks[3]) : Number(0),
            volume: d.asks.length > 0 ? Number(d.asks[4]) : Number(0),
          };
        case EXCHANGES.BIDORDERBOOKSUM1_5:
          return {
            time: d.timestamp,
            open: d.bids.length > 0 ? Number(d.bids[0]) : Number(0),
            high: d.bids.length > 0 ? Number(d.bids[1]) : Number(0),
            low: d.bids.length > 0 ? Number(d.bids[2]) : Number(0),
            close: d.bids.length > 0 ? Number(d.bids[3]) : Number(0),
            volume: d.bids.length > 0 ? Number(d.bids[4]) : Number(0),
          };
        case EXCHANGES.ASKORDERBOOKSUM6_10:
          return {
            time: d.timestamp,
            open: d.asks.length > 5 ? Number(d.asks[5]) : Number(0),
            high: d.asks.length > 5 ? Number(d.asks[6]) : Number(0),
            low: d.asks.length > 5 ? Number(d.asks[7]) : Number(0),
            close: d.asks.length > 5 ? Number(d.asks[8]) : Number(0),
            volume: d.asks.length > 5 ? Number(d.asks[9]) : Number(0),
          };
        case EXCHANGES.BIDORDERBOOKSUM6_10:
          return {
            time: d.timestamp,
            open: d.bids.length > 5 ? Number(d.bids[5]) : Number(0),
            high: d.bids.length > 5 ? Number(d.bids[6]) : Number(0),
            low: d.bids.length > 5 ? Number(d.bids[7]) : Number(0),
            close: d.bids.length > 5 ? Number(d.bids[8]) : Number(0),
            volume: d.bids.length > 5 ? Number(d.bids[9]) : Number(0),
          };
      }
      return {} as Bar;
    });
  }

  return [];
}

export async function fetchSpreadBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ISpotFuturesSpread>(
    environment.spotFutureSpreadsApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: ISpotFuturesSpread) => {
      return {
        time: d.timestamp,
        close: Number(d.sma),
        open: d.value,
        high: Number(0),
        low: Number(0),
        volume: Number(0),
      };
    });
  }

  return [];
}

export async function fetchActivityDetector(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IActivityDetectorBucketResponse>(
    environment.activityDetectorApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: IActivityDetectorBucketResponse) => {
      return {
        time: d.timestamp,
        close: Number(d.close),
        open: Number(d.open),
        high: Number(d.high),
        low: Number(d.low),
        volume: Number(d.volume),
      };
    });
  }

  return [];
}

export async function fetchCryptoDrift(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ICryptoDriftBucketResponse>(
    environment.cryptoDriftApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: ICryptoDriftBucketResponse) => {
      return {
        time: d.timestamp,
        close: Number(d.close),
        open: Number(d.open),
        high: Number(d.high),
        low: Number(d.low),
        volume: Number(d.volume),
      };
    });
  }

  return [];
}

export async function fetchStopKillerBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IStopKiller>(
    environment.stopKillerApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: IStopKiller) => {
      return {
        time: d.timestamp,
        open: d.side === "UP" && d.params.bbMult === 4 ? d.delta : NaN,
        high: d.side === "DOWN" && d.params.bbMult === 4 ? d.delta : NaN,
        low: d.side === "UP" && d.params.bbMult === 3 ? d.delta : NaN,
        close: d.side === "DOWN" && d.params.bbMult === 3 ? d.delta : NaN,
        volume: Number(d.params.bbMult),
      };
    });
  }

  return [];
}

interface IOpenInterest {
  timestamp: string;
  open: string;
  high: string;
  low: string;
  close: string;
}

export interface ILiquidationWSResponse {
  symbol: string;
  side: string;
  orderType: string;
  timeInForce: string;
  origQuantity: string;
  price: string;
  avgPrice: string;
  orderStatus: string;
  lastFilledQty: string;
  accumulatedFilledQty: string;
  tradeTime: number;
}

export interface ILiquidationBucket {
  timestamp: number;
  quantityBuy: number;
  quantitySell: number;
  quoteBuy: number;
  quoteSell: number;
}

export async function fetchOpenInterestBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IOpenInterest>(
    environment.openInterestApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    3000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IOpenInterest) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.open),
        high: Number(d.high),
        low: Number(d.low),
        close: Number(d.close),
      } as unknown as Bar;
    });
  } else {
    return [];
  }
}

export interface IBidAskSpread {
  timestamp: number;
  asks: number[];
  bids: number[];
}

export async function fetchBidAskSpread(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IBidAskSpread>(
    environment.depthsSpreadApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IBidAskSpread) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.asks[0]),
        high: Number(d.asks[1]),
        low: Number(d.bids[0]),
        close: Number(d.bids[1]),
      } as unknown as Bar;
    });
  } else {
    return [];
  }
}

export interface IHighSize {
  timestamp: number;
  asks: number[];
  bids: number[];
}

export async function fetchHighestSizeOrder(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IHighSize>(
    environment.depthsHighSizeApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IHighSize) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.asks[0]),
        high: Number(d.asks[1]),
        low: Number(d.bids[0]),
        close: Number(d.bids[1]),
      } as unknown as Bar;
    });
  } else {
    return [];
  }
}

export interface ISpoofLayer {
  prc: number;
  price: number;
  side: string;
  symbol: string;
  timestamp: number;
}

export async function fetchSpoofLayer(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
  side: string,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ISpoofLayer>(
    environment.spoofLayersAPI,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
    side,
  );
  if (data && data.length) {
    return data.map((d: ISpoofLayer) => {
      return {
        time: Number(d.timestamp),
        open: d.price ? Number(d.price) : NaN,
        high: NaN,
        low: NaN,
        close: NaN,
      } as Bar;
    });
  } else {
    return [];
  }
}

export interface IBidAskChange {
  timestamp: number;
  asks: number;
  bids: number;
}

export async function fetchOrderBookChanges(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IBidAskChange>(
    environment.depthsChangeApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IBidAskChange) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.asks),
        high: Number(d.bids),
        low: Number(0),
        close: Number(0),
      } as unknown as Bar;
    });
  } else {
    return [];
  }
}

interface IRealizedBidAsk {
  timestamp: number;
  asks: number[];
  bids: number[];
}

export async function fetchRealizedBidAskSpread(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IRealizedBidAsk>(
    environment.depthsSpreadApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IRealizedBidAsk) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.asks[0]),
        high: Number(d.asks[1]),
        low: Number(d.bids[0]),
        close: Number(d.bids[1]),
      } as unknown as Bar;
    });
  } else {
    return [];
  }
}

interface IGenericSpreadResponse {
  futureRtCum: boolean;
  spotRtCum: boolean;
  timestamp: number;
}

export async function fetchMatrixSpread(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IGenericSpreadResponse>(
    environment.matrixSpreadCumApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IGenericSpreadResponse) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.futureRtCum),
        high: Number(d.spotRtCum),
        low: Number(0),
        close: Number(0),
      } as Bar;
    });
  } else {
    return [];
  }
}

export async function fetchSpotFutureSpread(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";
  const data = await recursiveApiCall<IGenericSpreadResponse>(
    environment.spotFutureSpreadsCumApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IGenericSpreadResponse) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.futureRtCum),
        high: Number(d.spotRtCum),
        low: Number(0),
        close: Number(0),
      } as Bar;
    });
  } else {
    return [];
  }
}

export async function fetchLiquidationsBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];

  const { from, to, countBack } = periodParams;

  if (Number.isNaN(from)) {
    console.log("from is NaN", from);
  }

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ILiquidationBucket>(
    environment.liquidationsURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: ILiquidationBucket) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.quantityBuy),
        high: Number(d.quantitySell),
        low: Number(d.quoteBuy),
        close: Number(d.quoteSell),
      };
    });
  } else {
    return [];
  }
}

export async function fetchVolumeBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { from, to, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IBinanceKlineArray>(
    environment.binanceFuturesKlineURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1500,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IBinanceKlineArray) => {
      return {
        time: d[0],
        open: Number(d[8]), // Num trades
        high: Number(d[7]), // Quote asset volume
        low: Number(d[9]), // Taker buy base asset volume
        close: Number(d[10]), // Taker buy quote asset volume
        volume: Number(d[5]), // Volume
      };
    });
  } else {
    return [];
  }
}

export async function recursiveApiCall<T>(
  url: string,
  symbol: string,
  timeFrame: string,
  startTime: number,
  endTime: number,
  limit: number,
  count: number,
  side?: string,
  metric?: string,
  slug?: string,
): Promise<T[]> {
  const response = await axios.get(url, {
    params: {
      symbol,
      timeFrame,
      interval: timeFrame,
      startTime: startTime,
      endTime: endTime,
      limit: count > limit ? limit : count + 1,
      side,
      metric,
      slug,
    },
  });

  let data = response.data;

  if (data?.length === limit) {
    const remainingLimit = count - data.length;
    const newStartTime = data[data.length - 1].timestamp
      ? data[data.length - 1].timestamp
      : data[data.length - 1][0];
    const remainingData = await recursiveApiCall(
      url,
      symbol,
      timeFrame,
      newStartTime,
      endTime,
      limit,
      remainingLimit,
      side,
      metric,
      slug,
    );
    data = data.concat(remainingData);
  }

  return data;
}

export interface ITrendAssessmentData {
  timestamp: number;
  symbol: string;
  threadCum: number;
  smallTrendCum: number;
  mediumTrendCum: number;
  HugeTrendCum: number;
  hugeBackgroundColor?: string;
  mediumBackgroundColor?: string;
  smallBackgroundColor?: string;
  threadBackgroundColor?: string;
}

export interface ITrendAssessmentResponse {
  timestamp: number;
  timeFrame: string;
  data: ITrendAssessmentData[];
}

export async function fetchTrendAssessment(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ITrendAssessmentData>(
    environment.assessmentTrendsCumApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1400,
    countBack,
  );
  if (data && data.length) {
    return data.map((d: ITrendAssessmentData) => {
      return {
        time: d.timestamp,
        open: Number(d.threadCum),
        high: Number(d.smallTrendCum),
        low: Number(d.mediumTrendCum),
        close: Number(d.HugeTrendCum),
        volume: Number(0),
      };
    });
  }

  return [];
}

interface IOINetSpikeResponse {
  timestamp: number;
  price: number;
  weight: string;
  side: string;
}

export async function fetchOiNetSpike(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
  side: string,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IOINetSpikeResponse>(
    environment.openInterestNetSpikeURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
    side,
  );

  if (data && data.length) {
    return data.map((d: IOINetSpikeResponse) => {
      let weightNum = 0;
      // tiny - 0
      // small - 1
      // normal/medium - 2
      // large - 3
      // huge - 4
      switch (d.weight) {
        case "TINY":
          weightNum = 0;
          break;
        case "SMALL":
          weightNum = 1;
          break;
        case "MEDIUM":
          weightNum = 2;
          break;
        case "LARGE":
          weightNum = 3;
          break;
        case "HUGE":
          weightNum = 4;
          break;
      }
      return {
        time: Number(d.timestamp),
        open: Number(d.price) == 0 ? NaN : Number(d.price),
        high: Number(weightNum),
        low: d.side === "UP" ? 1 : 0,
        close: Number(0),
      } as Bar;
    });
  } else {
    return [];
  }
}

interface IOISpikeResponse {
  timestamp: number;
  price: number;
  weight: string;
  side: string;
}

export async function fetchOiSpike(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IOISpikeResponse>(
    environment.openInterestSpikeURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IOISpikeResponse) => {
      let weightNum = 0;
      // tiny - 0
      // small - 1
      // normal/medium - 2
      // large - 3
      // huge - 4
      switch (d.weight) {
        case "TINY":
          weightNum = 0;
          break;
        case "SMALL":
          weightNum = 1;
          break;
        case "MEDIUM":
          weightNum = 2;
          break;
        case "LARGE":
          weightNum = 3;
          break;
        case "HUGE":
          weightNum = 4;
          break;
      }
      return {
        time: Number(d.timestamp),
        open: Number(d.price) == 0 ? NaN : Number(d.price),
        high: Number(weightNum),
        low: d.side === "UP" ? 1 : 0,
        close: Number(0),
      } as Bar;
    });
  } else {
    return [];
  }
}

export interface INetOIDeltaResponse {
  timestamp: number;
  symbol: string;
  takerLongDelta: number;
  takerShortDelta: number;
  takerLong: number;
  takerShort: number;
  oiDelta: number;
  volumeDelta: number;
}

export async function fetchNetOiDelta(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<INetOIDeltaResponse>(
    environment.netOpenInterestDeltaURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: INetOIDeltaResponse) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.takerLongDelta),
        high: Number(d.takerShortDelta),
        low: Number(d.oiDelta),
        close: Number(d.volumeDelta),
      } as Bar;
    });
  } else {
    return [];
  }
}

export interface IAltMeterResponse {
  timestamp: number;
  value: number;
}

export async function fetchAltTimersBars(
  name: string,
  periodParams: PeriodParams,
  // resolution: ResolutionString,
) {
  // const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IAltMeterResponse>(
    environment.altTimersURL,
    symbol,
    "15m",
    from * 1000,
    to * 1000,
    1400,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IAltMeterResponse) => {
      return {
        time: d.timestamp,
        open: 100 - Math.round(((d.value + 5) / 10) * 100),
        close: NaN,
        high: NaN,
        low: NaN,
        volume: NaN,
      };
    });
  }

  return [];
}

export interface ITickIndexResponse {
  sma: number;
  tickIndex: number;
  timestamp: number;
}

export async function fetchTickIndexBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ITickIndexResponse>(
    environment.tickIndexesURL + "?window=60",
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1400,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: ITickIndexResponse) => {
      return {
        time: d.timestamp,
        open: d.tickIndex,
        close: NaN,
        high: NaN,
        low: NaN,
        volume: NaN,
      };
    });
  }

  return [];
}

export interface IDog {
  timestamp: number;
  value: number;
  values: number[];
}

export async function fetchDogBars(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<IDog>(
    environment.dogsURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1400,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: IDog) => {
      return {
        time: d.timestamp,
        open: d.value,
        close: NaN,
        high: NaN,
        low: NaN,
        volume: NaN,
      };
    });
  }

  return [];
}

export interface ILayeringItem {
  timestamp: number;
  value: number;
  symbol: string;
}

export async function fetchLayering(
  name: string,
  periodParams: PeriodParams,
  resolution: ResolutionString,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const { to, from, countBack } = periodParams;

  const symbol = name.includes("BUSD")
    ? name.split("#")[0]
    : name.split("#")[0] + "USDT";

  const data = await recursiveApiCall<ILayeringItem>(
    environment.layeringApiURL,
    symbol,
    interval,
    from * 1000,
    to * 1000,
    1000,
    countBack,
  );

  if (data && data.length) {
    return data.map((d: ILayeringItem) => {
      return {
        time: Number(d.timestamp),
        open: Number(d.value),
        high: Number(0),
        low: Number(0),
        close: 0,
      } as Bar;
    });
  } else {
    return [];
  }
}
