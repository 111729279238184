import { useDashboard } from "@/lib/store.ts";
import React, { useCallback } from "react";
import { Button } from "@/components/ui/button.tsx";

export function RemoveFavorite({
  folderId,
  favoriteName,
}: {
  folderId: string;
  favoriteName: string;
}) {
  const { removeSymbolFromFavorites } = useDashboard((state) => ({
    removeSymbolFromFavorites: state.removeSymbolFromFavorites,
  }));
  const handleRemoveFavorite = useCallback(() => {
    removeSymbolFromFavorites(folderId, favoriteName);
  }, [removeSymbolFromFavorites, folderId, favoriteName]);
  return (
    <Button size="xs" variant="ghost" className="w-4 h-4">
      <i className="fa-regular fa-trash" onClick={handleRemoveFavorite} />
    </Button>
  );
}
