import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ILiquidation, useLiquidations } from "@/hooks/useLiquidations.ts";
import dayjs from "dayjs";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<ILiquidation>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("volume", {
    header: "V $",
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn(rowClassCb(info.row.original), "font-bold block")}
          shorten={true}
        />
      );
    },
    size: 50,
    minSize: 50,
  }),

  columnHelper.accessor("price", {
    header: "P",
    cell: (row) => {
      const value = row.getValue();
      return <div>{value}</div>;
    },
    size: 50,
    minSize: 20,
  }),

  columnHelper.accessor("timestamp", {
    header: "Time",
    cell: (row) => {
      const value = row.getValue();
      return dayjs(value).format("HH:mm:ss");
    },
    size: 120,
    minSize: 20,
  }),
];

function rowClassCb(row: IRow<ILiquidation>) {
  return row.o.S === "BUY" ? "bg-green-800" : "bg-red-800";
}

export function LiquidationsScannerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();

  const { data: _raw, isLoading } = useLiquidations();
  const { blacklist } = useDashboard();

  const preparedData = useMemo(() => {
    if (!_raw) {
      return [];
    }

    let data = _raw;

    if (blacklist.length > 0) {
      data = data.filter((item) => !blacklist.includes(item.symbol));
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ILiquidation>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, _raw, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
