import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface IActivityDetectorResponse {
  data: IActivityDetector[];
  timestamp: number;
}

export interface IActivityDetector {
  symbol: string;
  value: number;
}

export function useActivityDetector() {
  const handler = useCallback(
    (_: IActivityDetectorResponse, newData: IActivityDetectorResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<IActivityDetectorResponse>(
    SocketTopics.activityDetector,
    handler,
  );
}
