import { SfChartFullFunctional } from "@/components/core/charts/sfChartFullFunctional.tsx";
import { ControlPanel } from "@/components/widgets/controlPanel/controlPanel.tsx";
import { ManipulationMonitorPaneBody } from "@/components/widgets/manipulationMonitorPaneBody.tsx";
import { SpotFuturesSpreadPaneBody } from "@/components/widgets/spotFuturesSpreadPaneBody.tsx";
import { StopKillerPaneBody } from "@/components/widgets/stopKillerPaneBody.tsx";
import { StrategiesPaneBody } from "@/components/widgets/strategiesPaneBody.tsx";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { memo } from "react";
import { PaneContext } from "./paneContext";
import { VolumeScreenerPaneBody } from "@/components/widgets/volumeScreenerPaneBody.tsx";
import { OiScreenerPaneBody } from "@/components/widgets/oiScreenerPaneBody.tsx";
import { PriceScreenerPaneBody } from "@/components/widgets/priceScreenerPaneBody.tsx";
import { BuySellDeltaScreenerPaneBody } from "@/components/widgets/buySellDeltaScreenerPaneBody.tsx";
import { LiquidationsScannerPaneBody } from "@/components/widgets/liquidationsScannerPaneBody.tsx";
import { PairsScannerPaneBody } from "@/components/widgets/pairsScannerPaneBody.tsx";
import { CascadeLevelsPaneBody } from "@/components/widgets/cascadeLevelsPaneBody.tsx";
import { PredictLiquidationPaneBody } from "@/components/widgets/predictLiquidationPaneBody.tsx";
import { AdtvScreenerPaneBody } from "@/components/widgets/adtvScreenerPaneBody.tsx";
import { ActivityDetectorScreenerPaneBody } from "@/components/widgets/activityDetectorScreenerPaneBody.tsx";
import { useDashboard } from "@/lib/store.ts";
import { CryptoDriftScreenerPaneBody } from "@/components/widgets/cryptoDriftScreenerPaneBody.tsx";
import { MarketRatioScreenerPaneBody } from "@/components/widgets/marketRatioScreenerPaneBody.tsx";
import { StrategiesV2PaneBody } from "../widgets/strategiesV2PaneBody";
import { SpreadDominationPaneBody } from "../widgets/spreadDominationPaneBody";

function getInner(type: EWidgetType) {
  // TODO move to widgets config constant, to keep everything in one place
  switch (type) {
    case EWidgetType.manipulationMonitor:
      return <ManipulationMonitorPaneBody />;
    case EWidgetType.strategies:
      return <StrategiesPaneBody />;
    case EWidgetType.strategiesV2:
      return <StrategiesV2PaneBody />;
    case EWidgetType.stopKiller:
      return <StopKillerPaneBody />;
    case EWidgetType.spotFuturesSpread:
      return <SpotFuturesSpreadPaneBody />;
    case EWidgetType.chart:
      return <SfChartFullFunctional />;
    case EWidgetType.controlPanel:
      return <ControlPanel />;
    case EWidgetType.deltaScreener:
      return <BuySellDeltaScreenerPaneBody />;
    case EWidgetType.priceScreener:
      return <PriceScreenerPaneBody />;
    case EWidgetType.oiScreener:
      return <OiScreenerPaneBody />;
    case EWidgetType.volumeScreener:
      return <VolumeScreenerPaneBody />;
    case EWidgetType.liquidationsScanner:
      return <LiquidationsScannerPaneBody />;
    case EWidgetType.pairsScanner:
      return <PairsScannerPaneBody />;
    case EWidgetType.predictLiquidation:
      return <PredictLiquidationPaneBody />;
    case EWidgetType.cascadeLevels:
      return <CascadeLevelsPaneBody />;
    case EWidgetType.adtvScreener:
      return <AdtvScreenerPaneBody />;
    case EWidgetType.activityDetectorScreener:
      return <ActivityDetectorScreenerPaneBody />;
    case EWidgetType.cryptoDriftScreener:
      return <CryptoDriftScreenerPaneBody />;
    case EWidgetType.marketRatioScreener:
      return <MarketRatioScreenerPaneBody />;
    case EWidgetType.spreadDominationScreener:
      return <SpreadDominationPaneBody />;
    default:
      return <div>default component</div>;
  }
}

const PaneComponent = ({ nodeId }: { nodeId: string }) => {
  const { node } = useGrid(nodeId);

  return (
    <PaneContext.Provider value={nodeId}>
      {node ? getInner(node.getComponent() as EWidgetType) : "loading"}
    </PaneContext.Provider>
  );
};
PaneComponent.displayName = "Pane";
export const Pane = memo(PaneComponent);
