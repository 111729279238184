//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const bidAskImbalance = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Bid & ask imbalance",
    metainfo: {
      _metainfoVersion: 51,
      id: "bid-ask-imbalance@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Bid & ask imbalance",
      shortDescription: "[SF] Bid & ask imbalance",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "percent",
        precision: 2,
      },

      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "imbalancePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "imbalanceColorer",
          type: StudyPlotType.Colorer,
          target: "imbalancePlot",
          palette: "imbalancePalette",
        },
        {
          id: "negImbalanceMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posImbalanceMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "imbalanceStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        imbalancePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          imbalancePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          imbalancePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          negImbalanceMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posImbalanceMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          imbalanceStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "DOM by 1%",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        imbalancePlot: {
          title: "Imbalance",
          histogramBase: 0,
        },
        negImbalanceMaPlot: {
          title: "Negative imbalance MA",
          histogramBase: 0,
        },
        posImbalanceMaPlot: {
          title: "Positive imbalance MA",
          histogramBase: 0,
        },
        imbalanceStDevPlot: {
          title: "Imbalance sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "DOM by 1%",
          options: [
            "Filled orders",
            "Best bid & ask size",
            "DOM by 1%",
            "DOM by 2%",
            "DOM by 3%",
            "DOM by 4%",
            "DOM by 5%",
            "DOM by 6%",
            "DOM by 7%",
            "DOM by 8%",
            "DOM by 9%",
            "DOM by 10%",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const buyVolumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );

        const bestBidAskSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#BIDASKQUOTEDSPREAD`;
        this._context.new_sym(
          bestBidAskSymbol,
          PineJS.Std.period(this._context),
        );

        const askSymbol1_5 = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM1_5`;
        this._context.new_sym(askSymbol1_5, PineJS.Std.period(this._context));

        const bidSymbol1_5 = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM1_5`;
        this._context.new_sym(bidSymbol1_5, PineJS.Std.period(this._context));

        const askSymbol6_10 = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM6_10`;
        this._context.new_sym(askSymbol6_10, PineJS.Std.period(this._context));

        const bidSymbol6_10 = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM6_10`;
        this._context.new_sym(bidSymbol6_10, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const maTypeInput: string = this._input(1);
        const lengthInput: number = this._input(2);
        const metricsBoolInput: boolean = this._input(3);

        //Market data parsing
        let ask: number = 0.0;
        let askArray: any = this._context.new_var(0.0);
        let askTimeArray: any = this._context.new_var(0.0);
        let bid: number = 0.0;
        let bidArray: any = this._context.new_var(0.0);
        let bidTimeArray: any = this._context.new_var(0.0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        if (sourceInput === "Filled orders") {
          const volume: number = PineJS.Std.volume(this._context);
          this._context.select_sym(1);
          ask = PineJS.Std.low(this._context);
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(0);
          ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
          bid = volume - ask;
        } else if (sourceInput === "Best bid & ask size") {
          this._context.select_sym(2);
          askTimeArray = this._context.new_var(this._context.symbol.time);
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(PineJS.Std.high(this._context));
          bidArray = this._context.new_var(PineJS.Std.close(this._context));
          this._context.select_sym(0);
          ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
          bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
        } else if (
          sourceInput === "DOM by 1%" ||
          sourceInput === "DOM by 2%" ||
          sourceInput === "DOM by 3%" ||
          sourceInput === "DOM by 4%" ||
          sourceInput === "DOM by 5%"
        ) {
          this._context.select_sym(3);
          switch (sourceInput) {
            case `DOM by 1%`:
              ask = PineJS.Std.open(this._context);
              break;
            case `DOM by 2%`:
              ask = PineJS.Std.high(this._context);
              break;
            case `DOM by 3%`:
              ask = PineJS.Std.low(this._context);
              break;
            case `DOM by 4%`:
              ask = PineJS.Std.close(this._context);
              break;
            case `DOM by 5%`:
              ask = PineJS.Std.volume(this._context);
              break;
          }
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(4);
          switch (sourceInput) {
            case `DOM by 1%`:
              bid = PineJS.Std.open(this._context);
              break;
            case `DOM by 2%`:
              bid = PineJS.Std.high(this._context);
              break;
            case `DOM by 3%`:
              bid = PineJS.Std.low(this._context);
              break;
            case `DOM by 4%`:
              bid = PineJS.Std.close(this._context);
              break;
            case `DOM by 5%`:
              bid = PineJS.Std.volume(this._context);
              break;
          }
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          bidArray = this._context.new_var(bid);
          this._context.select_sym(0);
          ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
          bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
        } else if (
          sourceInput === "DOM by 6%" ||
          sourceInput === "DOM by 7%" ||
          sourceInput === "DOM by 8%" ||
          sourceInput === "DOM by 9%" ||
          sourceInput === "DOM by 10%"
        ) {
          this._context.select_sym(5);
          switch (sourceInput) {
            case `DOM by 6%`:
              ask = PineJS.Std.open(this._context);
              break;
            case `DOM by 7%`:
              ask = PineJS.Std.high(this._context);
              break;
            case `DOM by 8%`:
              ask = PineJS.Std.low(this._context);
              break;
            case `DOM by 9%`:
              ask = PineJS.Std.close(this._context);
              break;
            case `DOM by 10%`:
              ask = PineJS.Std.volume(this._context);
              break;
          }
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(6);
          switch (sourceInput) {
            case `DOM by 6%`:
              bid = PineJS.Std.open(this._context);
              break;
            case `DOM by 7%`:
              bid = PineJS.Std.high(this._context);
              break;
            case `DOM by 8%`:
              bid = PineJS.Std.low(this._context);
              break;
            case `DOM by 9%`:
              bid = PineJS.Std.close(this._context);
              break;
            case `DOM by 10%`:
              bid = PineJS.Std.volume(this._context);
              break;
          }
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          bidArray = this._context.new_var(bid);
          this._context.select_sym(0);
          ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
          bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
        }

        //Calculation
        const depth: number = bid + ask;
        const bidAskDelta: number = bid - ask;
        const imbalance: number = (bidAskDelta / depth) * 100;

        let posImbalanceMa: number = NaN;
        let negImbalanceMa: number = NaN;
        let imbalanceStDev: number = NaN;
        if (metricsBoolInput) {
          const imbalanceSeries = this._context.new_var(imbalance);
          const posImbalance: number = bidAskDelta > 0 ? imbalance : 0;
          const posImbalanceSeries = this._context.new_var(posImbalance);
          const negImbalance: number = bidAskDelta < 0 ? imbalance : 0;
          const negImbalanceSeries = this._context.new_var(negImbalance);
          switch (maTypeInput) {
            case "SMA":
              posImbalanceMa = PineJS.Std.sma(
                posImbalanceSeries,
                lengthInput,
                this._context,
              );
              negImbalanceMa = PineJS.Std.sma(
                negImbalanceSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              posImbalanceMa = PineJS.Std.ema(
                posImbalanceSeries,
                lengthInput,
                this._context,
              );
              negImbalanceMa = PineJS.Std.ema(
                negImbalanceSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              posImbalanceMa = PineJS.Std.wma(
                posImbalanceSeries,
                lengthInput,
                this._context,
              );
              negImbalanceMa = PineJS.Std.wma(
                negImbalanceSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              posImbalanceMa = PineJS.Std.vwma(
                posImbalanceSeries,
                lengthInput,
                this._context,
              );
              negImbalanceMa = PineJS.Std.vwma(
                negImbalanceSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              posImbalanceMa = PineJS.Std.linreg(
                posImbalanceSeries,
                lengthInput,
                0,
              );
              negImbalanceMa = PineJS.Std.linreg(
                negImbalanceSeries,
                lengthInput,
                0,
              );
              break;
            default:
              posImbalanceMa = PineJS.Std.sma(
                posImbalanceSeries,
                lengthInput,
                this._context,
              );
              negImbalanceMa = PineJS.Std.sma(
                negImbalanceSeries,
                lengthInput,
                this._context,
              );
              break;
          }
          imbalanceStDev = PineJS.Std.stdev(
            imbalanceSeries,
            lengthInput,
            this._context,
          );
        }

        //Returns
        return [
          0,
          imbalance,
          imbalance < 0 ? 0 : 1,
          negImbalanceMa,
          posImbalanceMa,
          imbalanceStDev,
        ];
      };
    },
  };
};
