//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const altMeter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Alt meter",
    metainfo: {
      _metainfoVersion: 51,
      id: "alt-meter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Alt meter",
      shortDescription: "[SF] Alt meter",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "percent",
        precision: 0,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "altmeterPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "altmeterColorer",
          type: StudyPlotType.Colorer,
          target: "altmeterPlot",
          palette: "altmeterPalette",
        },
      ],
      palettes: {
        altmeterPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          altmeterPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          altmeterPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
        },
        precision: 0,
        inputs: {},
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        altmeterPlot: {
          title: "Alt meter",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_ALT_TIMERS`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Markte data parsing
        this._context.select_sym(1);
        const altmeterTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const altmeterArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const altmeter: number = altmeterArray.adopt(
          altmeterTime,
          mainSymbolTime,
          0,
        );

        // Calculations
        const altmeterColor: number = altmeter >= 50 ? 1 : 0;

        // return
        return [50, altmeter, altmeterColor];
      };
    },
  };
};
