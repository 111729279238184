//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const marketPower = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market power",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-power@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Market power",
      shortDescription: "[SF] Market power",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlotColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPlotPalette",
        },
      ],
      palettes: {
        metricPlotPalette: {
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          },
          colors: {
            0: { name: "Green" },
            1: { name: "Yellow" },
            2: { name: "Red" },
            3: { name: "White" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPlotPalette: {
            colors: {
              0: { color: "rgb(38,164,82)", width: 1, style: 0 },
              1: { color: "rgb(243,198,48)", width: 1, style: 0 },
              2: { color: "rgb(166,40,38)", width: 1, style: 0 },
              3: { color: "rgb(255,255,255)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Histogram,
            trackPrice: false,
            color: "#FF5B5B",
            transparency: 0,
          },
        },
        inputs: {
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Market power",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "metricsBoolInput",
          name: "Filter noise?",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_MARKET_POWER`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const metricsBoolInput: boolean = this._input(0);

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let val: number = firstSeriesCloseSeries.adopt(
          firstSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        if (metricsBoolInput) {
          val = Math.abs(val) < 1 ? NaN : val;
        }
        let color: number = 0;
        switch (true) {
          case Math.abs(val) > 10:
            color = 2;
            break;
          case Math.abs(val) > 3:
            color = 1;
            break;
          case Math.abs(val) > 1:
            color = 0;
            break;
          default:
            color = 3;
        }

        return [0, val, color];
      };
    },
  };
};
