import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { ICryptoDrift, useCryptoDrift } from "@/hooks/useCryptoDrift.ts";
import { useFilterStream } from "@/hooks/useFilterStream.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<ICryptoDrift>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("value", {
    header: "D AC",
    cell: (info) => {
      const value = info.getValue();
      return <div className="font-semibold">{value}</div>;
    },
    size: 70,
    minSize: 30,
  }),
];

interface ICryptoDriftWithFilters extends ICryptoDrift {
  adtv15m: number;
  adtv30m: number;
  adtv1h: number;
  adtv4h: number;
  adtv1d: number;
  adtv1w: number;
  adtv1M: number;
  vol15m: number;
  vol30m: number;
  vol1h: number;
  vol4h: number;
  vol1d: number;
  vol1w: number;
  vol1M: number;
}

export function CryptoDriftScreenerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");
  const { data: cryptoDrift, isLoading } = useCryptoDrift();
  const { blacklist } = useDashboard();

  const preparedData = useMemo(() => {
    if (!cryptoDrift?.data) {
      return [];
    }

    if (
      !adtv15m ||
      !adtv30m ||
      !adtv1h ||
      !adtv4h ||
      !adtv1d ||
      !adtv1w ||
      !adtv1M ||
      !volume15m ||
      !volume30m ||
      !volume1h ||
      !volume4h ||
      !volume1d ||
      !volume1w ||
      !volume1M
    ) {
      return [];
    }

    let cryptoDriftWithFilters: ICryptoDriftWithFilters[] = [];

    cryptoDrift.data.forEach((item) => {
      const symbol = item.symbol;
      const adtv15mValue = adtv15m[symbol];
      const adtv30mValue = adtv30m[symbol];
      const adtv1hValue = adtv1h[symbol];
      const adtv4hValue = adtv4h[symbol];
      const adtv1dValue = adtv1d[symbol];
      const adtv1wValue = adtv1w[symbol];
      const adtv1MValue = adtv1M[symbol];
      const volume15mValue = volume15m[symbol];
      const volume30mValue = volume30m[symbol];
      const volume1hValue = volume1h[symbol];
      const volume4hValue = volume4h[symbol];
      const volume1dValue = volume1d[symbol];
      const volume1wValue = volume1w[symbol];
      const volume1MValue = volume1M[symbol];

      cryptoDriftWithFilters.push({
        ...item,
        adtv15m: adtv15mValue,
        adtv30m: adtv30mValue,
        adtv1h: adtv1hValue,
        adtv4h: adtv4hValue,
        adtv1d: adtv1dValue,
        adtv1w: adtv1wValue,
        adtv1M: adtv1MValue,
        vol15m: volume15mValue,
        vol30m: volume30mValue,
        vol1h: volume1hValue,
        vol4h: volume4hValue,
        vol1d: volume1dValue,
        vol1w: volume1wValue,
        vol1M: volume1MValue,
      });
    });

    if (blacklist.length > 0) {
      cryptoDriftWithFilters = cryptoDriftWithFilters.filter(
        (item) => !blacklist.includes(item.symbol),
      );
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ICryptoDriftWithFilters>(
        cryptoDriftWithFilters,
        config.filters,
        config.filterValues,
      );
    }

    return cryptoDriftWithFilters;
  }, [
    config,
    cryptoDrift,
    blacklist,
    adtv15m,
    adtv30m,
    adtv1h,
    adtv4h,
    adtv1d,
    adtv1w,
    adtv1M,
    volume15m,
    volume30m,
    volume1h,
    volume4h,
    volume1d,
    volume1w,
    volume1M,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
