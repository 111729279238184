//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const totalTradeVolumeByDex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Total trade volume by DEX",
    metainfo: {
      _metainfoVersion: 51,
      id: "total-trade-volume-by-dex@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Total trade volume by DEX",
      shortDescription: "[SF] DEX volume",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: true,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "DEX volume",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SANTIMENTS_TOTAL_TRADE_VOLUME_BY_DEX`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const metric: number = firstSeriesCloseSeries.adopt(
          firstSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );

        //Return
        return [0, metric];
      };
    },
  };
};
