import { cn } from "@/lib/utils.ts";
import { memo, useMemo, useState } from "react";
import { isEqual } from "lodash";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { FaRegStar, FaStar } from "react-icons/fa6";
import { useFavoriteFolders } from "@/hooks/useFavoriteFolders";
import { useToast } from "@/components/ui/use-toast.ts"; // Import the new hook

function FavoriteStarRaw({
  symbolName,
  size,
}: {
  symbolName: string;
  size?: string;
}) {
  const { folders } = useFavoriteFolders(); // Use the hook here

  const inFavorites = useMemo(
    () => folders.some((f) => f.symbols.includes(symbolName)),
    [folders, symbolName],
  );

  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <div>
          {inFavorites ? (
            <FaStar size={size} />
          ) : (
            <FaRegStar size={size} className="text-gray-400" />
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" side="right" align="start">
        <FavoriteStarFoldersList symbolName={symbolName} setOpen={setOpen} />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export const FavoriteStar = memo(FavoriteStarRaw, isEqual);

const FavoriteStarFoldersList = memo(
  function FavoriteStarFolders({
    symbolName,
    setOpen,
  }: {
    symbolName: string;
    setOpen: (open: boolean) => void;
  }) {
    const {
      folders,
      addFavoriteFolder,
      addSymbolsToFavorites,
      removeSymbolFromFavorites,
      availableColors,
      canAddMoreFolders,
    } = useFavoriteFolders();
    const { toast } = useToast();

    const toggleFavorite = (e: any, folderId: string) => {
      e.stopPropagation();
      const folder = folders.find((f) => f.id === folderId);
      if (!folder) {
        return;
      }
      if (folder.symbols.includes(symbolName)) {
        removeSymbolFromFavorites(folderId, symbolName);
      } else {
        addSymbolsToFavorites(folderId, [symbolName]);
      }
      setOpen(false);
    };

    function addToNewFolder(e: any) {
      e.stopPropagation();
      if (canAddMoreFolders) {
        addFavoriteFolder({
          name: "New Folder #" + Number(folders.length + 1),
          color: availableColors[0],
          expanded: true,
          symbols: [symbolName],
        });
        setOpen(false);
      } else {
        toast({
          variant: "destructive",
          title: "You have reached the maximum number of folders",
        });
      }
    }

    return (
      <>
        <DropdownMenuLabel className="text-xs">
          Add or remove {symbolName} from favorites
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <ul>
          {folders.map((folder, i) => (
            <DropdownMenuItem
              className="flex mb-0.5 cursor-pointer text-xs h-8"
              key={folder.name + i}
              onClick={(e) => toggleFavorite(e, folder.id)}
              style={{ backgroundColor: folder.color }}
            >
              <i
                className={cn(
                  "fa-solid",
                  folder.symbols.includes(symbolName)
                    ? "fa-square-check"
                    : "fa-square",
                )}
              />
              {folder.name}
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={addToNewFolder}
            disabled={!canAddMoreFolders}
            className="flex cursor-pointer text-xs h-8"
          >
            <i className="fa-solid fa-plus" />
            Add to new folder
          </DropdownMenuItem>
        </ul>
      </>
    );
  },
  () => true,
);
