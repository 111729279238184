//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const negativeMarketdataIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Negative market data index",
    metainfo: {
      _metainfoVersion: 51,
      id: "negative-market-data-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Negative market data index",
      shortDescription: "[SF] NMDI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "nmdiPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "nmdiColorer",
          type: StudyPlotType.Colorer,
          target: "nmdiPlot",
          palette: "linePalette",
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maColorer",
          type: StudyPlotType.Colorer,
          target: "maPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "nmdiPlot",
          objBId: "maPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling NMDI" },
            1: { name: "Growing NMDI" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling NMDI" },
            1: { name: "Growing NMDI" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 20,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.1)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.1)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          nmdiPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          nmdiColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          maColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "Volume",
          initialValueInput: 100,
          maTypeInput: "SMA",
          lengthInput: 240,
          depthInput: 0,
        },
      },
      styles: {
        nmdiPlot: {
          title: "NMDI",
          histogramBase: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: [
            "Volume",
            "Volume delta",
            "Open interest",
            "Tape",
            "Average trade size",
            "Socail sentiment",
          ],
          type: StudyInputType.Text,
        },
        {
          id: "initialValueInput",
          name: "Initial value",
          defval: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "depthInput",
          name: "Depth",
          defval: 0,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const buyVolumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
        const socailVolumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SANTIMENTS_SOCIAL_VOLUME_TOTAL`;
        this._context.new_sym(
          socailVolumeSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const initialValueInput: number = this._input(1);
        const maTypeInput: string = this._input(2);
        const maLengthInput: number = this._input(3);
        const depthInput: number = this._input(4);

        //Market data parsing
        const close: number = PineJS.Std.close(this._context);
        const closeSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const closePrevious: number = closeSeries.get(1);

        let dataSourceArray: any,
          dataSourceTimeArray: any,
          dataSource: any,
          dataSourceSeries: any;
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        switch (sourceInput) {
          case "Volume":
            dataSourceSeries = this._context.new_var(
              PineJS.Std.volume(this._context),
            );
            break;
          case "Volume delta":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource =
              dataSourceArray.adopt(
                dataSourceTimeArray,
                mainSymbolTimeSeries,
                0,
              ) - PineJS.Std.volume(this._context);
            dataSourceSeries = this._context.new_var(0);
            dataSourceSeries.set(dataSource);
            break;
          case "Open interest":
            this._context.select_sym(2);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            dataSourceSeries = this._context.new_var(0);
            dataSourceSeries.set(dataSource);
            break;
          case "Tape":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            dataSourceSeries = this._context.new_var(0);
            dataSourceSeries.set(dataSource);
            break;
          case "Average trade size":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            this._context.select_sym(0);
            dataSource =
              PineJS.Std.volume(this._context) /
              dataSourceArray.adopt(
                dataSourceTimeArray,
                mainSymbolTimeSeries,
                0,
              );
            dataSourceSeries = this._context.new_var(0);
            dataSourceSeries.set(dataSource);
            break;
          case "Socail sentiment":
            this._context.select_sym(2);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            dataSourceSeries = this._context.new_var(dataSource);
            dataSourceSeries.set(dataSource);
            break;
          default:
            dataSourceSeries = this._context.new_var(
              PineJS.Std.volume(this._context),
            );
            break;
        }

        //Calculations
        const nmdiSeries: any = this._context.new_var(0);
        const nmdiPrevious: number = nmdiSeries.get(1);
        const change: number = PineJS.Std.change(dataSourceSeries);
        const nmdi: number = PineJS.Std.na(nmdiPrevious)
          ? initialValueInput
          : change < 0
            ? (nmdiPrevious * close) / closePrevious
            : nmdiPrevious;
        nmdiSeries.set(nmdi);

        //Derivative metrics
        let movingAverage: number;
        switch (maTypeInput) {
          case "SMA":
            movingAverage = PineJS.Std.sma(
              nmdiSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "EMA":
            movingAverage = PineJS.Std.ema(
              nmdiSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "WMA":
            movingAverage = PineJS.Std.wma(
              nmdiSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "VWMA":
            movingAverage = PineJS.Std.vwma(
              nmdiSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "LSMA":
            movingAverage = PineJS.Std.linreg(nmdiSeries, maLengthInput, 0);
            break;
          default:
            movingAverage = PineJS.Std.sma(
              nmdiSeries,
              maLengthInput,
              this._context,
            );
            break;
        }

        if (depthInput != 0) {
          if (
            Date.now() - PineJS.Std.time(this._context) >
            depthInput * 1000 * 60
          ) {
            return [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN];
          }
        }

        //Returns
        const color: number = nmdi > movingAverage ? 1 : 0;
        return [nmdi, color, movingAverage, color, color];
      };
    },
  };
};
