//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const orderFlowIntesity = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Order flow intensity",
    metainfo: {
      _metainfoVersion: 51,
      id: "order-flow-intesity@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Order flow intensity",
      shortDescription: "[SF] Order flow intensity",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "intensityPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "intensityPlotColorer",
          type: StudyPlotType.Colorer,
          target: "intensityPlot",
          palette: "intensityPalette",
        },
        {
          id: "dayHighPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        intensityPalette: {
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
          },
          colors: {
            0: { name: "Color 1" },
            1: { name: "Color 2" },
            2: { name: "Color 3" },
            3: { name: "Color 4" },
            4: { name: "Color 5" },
            5: { name: "Color 6" },
            6: { name: "Color 7" },
            7: { name: "Color 8" },
            8: { name: "Color 9" },
            9: { name: "Color 10" },
          },
        },
      },
      defaults: {
        palettes: {
          intensityPalette: {
            colors: {
              0: { color: "#211CFF", width: 1, style: 0 },
              1: { color: "#1871FF", width: 1, style: 0 },
              2: { color: "#18BAFF", width: 1, style: 0 },
              3: { color: "#18FFB5", width: 1, style: 0 },
              4: { color: "#18FF42", width: 1, style: 0 },
              5: { color: "#63FF18", width: 1, style: 0 },
              6: { color: "#D6FF18", width: 1, style: 0 },
              7: { color: "#FFCF18", width: 1, style: 0 },
              8: { color: "#FF7118", width: 1, style: 0 },
              9: { color: "#FF2821", width: 1, style: 0 },
            },
          },
        },
        styles: {
          intensityPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
          intensityPlotColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
          dayHighPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            display: 3,
            transparency: 1,
            color: "rgb(255, 255, 255)",
          },
        },
        inputs: {
          sourceInput: "Tape",
          lengthInput: 240,
        },
      },
      styles: {
        intensityPlot: {
          title: "Order flow intensity",
          histogramBase: 0,
        },
        dayHighPlot: {
          title: "Day high",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Tape",
          options: ["Tape", "Average trade size", "Volume"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const lengthInput: number = this._input(1);

        //Market data parsing
        let source: number = 0.0;
        let sourceArray: any, sourceTimeArray: any;
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        switch (sourceInput) {
          case "Tape":
            this._context.select_sym(1);
            sourceTimeArray = this._context.new_var(this._context.symbol.time);
            sourceArray = this._context.new_var(PineJS.Std.open(this._context));
            this._context.select_sym(0);
            source = sourceArray.adopt(
              sourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            break;
          case "Average trade size":
            this._context.select_sym(1);
            sourceTimeArray = this._context.new_var(this._context.symbol.time);
            sourceArray = this._context.new_var(PineJS.Std.open(this._context));
            this._context.select_sym(0);
            source =
              PineJS.Std.volume(this._context) /
              sourceArray.adopt(sourceTimeArray, mainSymbolTimeSeries, 0);
            break;
          case "Volume":
            source = PineJS.Std.volume(this._context);
            break;
          default:
            source = PineJS.Std.volume(this._context);
            break;
        }
        const sourceSeries: any = this._context.new_var(source);

        //Calculations
        const avg: number =
          PineJS.Std.sum(sourceSeries, lengthInput, this._context) /
          lengthInput;
        const stdev: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );
        const zScore: number = (source - avg) / stdev;
        let dayHigh: number = NaN;
        if (PineJS.Std.isintraday(this._context)) {
          const length: number =
            1440 / parseInt(PineJS.Std.period(this._context));
          dayHigh = PineJS.Std.highest(sourceSeries, length, this._context);
        }

        //Color
        let color: number = 0;
        switch (true) {
          case zScore >= 5.00095:
            color = 9;
            break;
          case zScore >= 4:
            color = 8;
            break;
          case zScore >= 3.03323:
            color = 7;
            break;
          case zScore >= 2.5:
            color = 6;
            break;
          case zScore >= 1.83975:
            color = 5;
            break;
          case zScore >= 1.11586:
            color = 4;
            break;
          case zScore >= 0.67681:
            color = 3;
            break;
          case zScore >= 0.4105:
            color = 2;
            break;
          case zScore >= 0:
            color = 1;
            break;
          case zScore < 0:
            color = 0;
            break;
          default:
            color = 0;
            break;
        }

        //Returns
        return [source, color, dayHigh];
      };
    },
  };
};
