import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";
// {"timestamp":1727446260000,"side":"UP","price":2968.87,"weight":"LOW","quoteQuantity":2522637.67}
export interface IMarginLevelDetails {
  timestamp: number;
  side: string;
  price: number;
  weight: string;
  quoteQuantity: number;
}

export interface IMarginLevelsResponse {
  timestamp: number;
  timeFrame: string;
  levels: IMarginLevelDetails[];
}

export function useMarginLevelsBySymbol(
  timeFrameFilter: string,
  symbol: string,
) {
  const handler = useCallback(
    (_: IMarginLevelDetails[], newData: IMarginLevelsResponse) => {
      if (!newData.levels) {
        return [];
      }
      return newData.levels;
    },
    [],
  );

  const topic =
    `sf.core.scripts.screener.marginLevel.${symbol.toLowerCase()}.1m.all` as SocketTopics;

  return useGenericSocket<IMarginLevelDetails[], IMarginLevelsResponse>(
    topic,
    handler,
  );
}
