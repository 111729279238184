import { getBars } from "@/lib/datafeed/getBars.ts";
import { resolveSymbol } from "@/lib/datafeed/resolveSymbol.ts";
import { searchSymbols } from "@/lib/datafeed/searchSymbols.ts";
import {
  subscribeOnStream,
  unsubscribeFromStream,
} from "@/lib/datafeed/streaming.ts";
import {
  IDatafeedChartApi,
  IExternalDatafeed,
} from "@tradingView/charting_library";
import { CHART_CONFIG } from "@/lib/datafeed/config.ts";

export const datafeed: IDatafeedChartApi & IExternalDatafeed = {
  onReady: (callback) => {
    setTimeout(() => callback(CHART_CONFIG));
  },
  searchSymbols,
  resolveSymbol,
  getBars,

  subscribeBars: subscribeOnStream,
  unsubscribeBars: unsubscribeFromStream,
};
