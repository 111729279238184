import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import useWebSocket from "react-use-websocket";
import Big from "big.js";

const baseDomain = "fra1.prod.do.spreadfighter.cloud";
const liquidationDomain = `binf-liquidation.${baseDomain}`;
const liq = `https://${liquidationDomain}/api/liquidations`;
const wsUrl = "wss://fstream.binance.com/ws/!forceOrder@arr";

export interface ILiquidation {
  e: string;
  E: number;
  symbol: string;
  timestamp: number;
  volume: number;
  price: number;
  o: {
    s: string;
    S: string;
    o: string;
    f: string;
    q: string;
    p: string;
    ap: string;
    X: string;
    l: string;
    z: string;
    T: number;
  };
}

interface ILiquidationAPIResponse {
  symbol: string;
  side: string;
  orderType: string;
  timeInForce: string;
  origQuantity: string;
  price: string;
  avgPrice: string;
  orderStatus: string;
  lastFilledQty: string;
  accumulatedFilledQty: string;
  tradeTime: number;
}

export function useLiquidations(
  volumeFilter: number = 50000,
  instrumentFilter: string[] = [],
) {
  const [data, setData] = useState<ILiquidation[]>([]);
  const queryClient = useQueryClient();

  const {
    data: initialData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["liquidations", volumeFilter],
    queryFn: async () => {
      const response = await axios.get<ILiquidationAPIResponse[]>(liq, {
        params: { limit: 50, active: true, volume: volumeFilter },
      });
      console.log(1);
      return response.data.map(convertToILiquidation);
    },
  });

  const { lastJsonMessage } = useWebSocket<ILiquidation>(wsUrl, {
    onOpen: () => console.log("WebSocket connected"),
    onMessage: () => {
      if (lastJsonMessage) {
        setData((prevData) => {
          const newData = [lastJsonMessage, ...prevData];
          queryClient.setQueryData(["liquidations", volumeFilter], newData);
          return newData;
        });
      }
    },
    onError: (event: any) => console.error("WebSocket error", event),
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  const filteredData = useCallback(() => {
    return data.filter(
      (l) =>
        (!instrumentFilter.length || instrumentFilter.includes(l.o.s)) &&
        (!volumeFilter || l.volume > volumeFilter),
    );
  }, [data, instrumentFilter, volumeFilter]);

  return {
    data: filteredData(),
    isLoading,
    error,
    socketConnected: !!lastJsonMessage,
  };
}

function convertToILiquidation(item: ILiquidationAPIResponse): ILiquidation {
  return {
    e: "forceOrder",
    E: item.tradeTime,
    symbol: item.symbol,
    timestamp: item.tradeTime,
    volume: Big(item.avgPrice).times(item.origQuantity).toNumber(),
    price: parseFloat(item.price),
    o: {
      s: item.symbol,
      S: item.side,
      o: item.orderType,
      f: item.timeInForce,
      q: item.origQuantity,
      p: item.price,
      ap: item.avgPrice,
      X: item.orderStatus,
      l: item.lastFilledQty,
      z: item.accumulatedFilledQty,
      T: item.tradeTime,
    },
  };
}
