import { type ClassValue, clsx } from "clsx";
import type { KeyboardEvent, MouseEvent } from "react";
import {
  KeyboardSensor as LibKeyboardSensor,
  MouseSensor as LibMouseSensor,
} from "@dnd-kit/core";
import {
  IFilterOption,
  IFilterValues,
} from "@/domain/interfaces/dashboard.interface.ts";
import {
  EFilterType,
  ENumberComparisonType,
} from "@/domain/enums/dashboard.enum.ts";
import { SFTwMerge } from "@/lib/twMerge.ts";

export function cn(...inputs: ClassValue[]) {
  return SFTwMerge(clsx(inputs));
}

export class SmartMouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown" as const,
      handler: ({ nativeEvent: event }: MouseEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

export class SmartKeyboardSensor extends LibKeyboardSensor {
  static activators = [
    {
      eventName: "onKeyDown" as const,
      handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}

function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }

  return true;
}

export const applyFilters = <T>(
  data: T[],
  filters: IFilterOption[],
  filterValues: IFilterValues,
) => {
  return data.filter((item) => {
    return filters.every((filter) => {
      const filterValue = filterValues[filter.filterByField];
      const value = (item as any)[filter.filterByField];

      if (!filterValue) {
        return true;
      }

      if (filter.type === EFilterType.range) {
        const min = filterValue?.min ?? filter.min;
        const max = filterValue?.max ?? filter.max;
        return value >= min && value <= max;
      }

      if (filter.type === EFilterType.multiSelect) {
        return filterValue?.includes(value);
      }

      if (filter.type === EFilterType.text) {
        return value?.toLowerCase().includes(filterValue?.toLowerCase());
      }

      if (filter.type === EFilterType.object) {
        return Object.entries(filterValue || {}).every(
          ([key, isChecked]) => !isChecked || value?.[key],
        );
      }

      if (filter.type === EFilterType.select) {
        return value.toString() === filterValue.toString();
      }

      if (filter.type === EFilterType.number) {
        switch (filter.numberComparisonType) {
          case ENumberComparisonType.greater:
            return Number(value) > Number(filterValue);
          case ENumberComparisonType.less:
            return Number(value) < Number(filterValue);
          case ENumberComparisonType.equal:
            return Number(value) === Number(filterValue);
          case ENumberComparisonType.mixed:
            if (filterValue >= 0) {
              return Number(value) >= Number(filterValue);
            } else {
              return Number(value) <= Number(filterValue);
            }
          default:
            throw new Error("Unknown number comparison type");
        }
      }

      return true;
    });
  });
};
