import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { useGrid } from "@/hooks/useGrid";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useDashboard } from "@/lib/store";

export function GlobalFilters() {
  const nodeId = usePaneContext();
  const { node } = useGrid(nodeId);
  const { globalFilters, updateGlobalFilter } = useDashboard();

  const height = node?.getRect()?.height ?? 0;

  const handleFilterChange = (index: number, value: string) => {
    updateGlobalFilter(index, value);
  };

  const clearFilter = (index: number) => {
    updateGlobalFilter(index, "");
  };

  return (
    <Card className="bg-background pt-6" style={{ height: height - 50 }}>
      <CardContent>
        <div className="grid grid-cols-2 gap-2">
          {globalFilters.map((filter, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div className="w-full relative">
                <Input
                  id={`filter-${index}`}
                  type="number"
                  placeholder={`Enter ${filter.title?.toLowerCase()}`}
                  value={filter.value}
                  onChange={(e) => handleFilterChange(index, e.target.value)}
                  className="pr-8 w-full"
                />
                <Button
                  type="button"
                  variant="ghost"
                  className="absolute right-1 top-1/2 -translate-y-1/2"
                  onClick={() => clearFilter(index)}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
