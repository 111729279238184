import { Button } from "@/components/ui/button.tsx";
import { useAuth } from "@/hooks/useAuth.ts";
import { Dashboard } from "@/components/pages/dashboard.tsx";
import { ThemeProvider } from "@/components/theme-provider.tsx";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/lib/queryClient.ts";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "@/components/ui/toaster.tsx";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import "./lib/dayjsExt.ts";

// import { useStoreMiddlewares } from "@/lib/store.ts";

function Layout() {
  const { user, login, isLoading } = useAuth();

  if (isLoading) {
    return <div className="animate-pulse">Loading user...</div>;
  }

  return (
    <div className="flex flex-col justify-between h-screen">
      {user ? (
        <Dashboard />
      ) : (
        <Button onClick={login}>Login with Google</Button>
      )}
      <Toaster />
    </div>
  );
}

const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const isPersistent = window.localStorage.getItem("isPersistent") === "true";
  // useServiceWorkerUpdater();
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      {isPersistent ? (
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: localStoragePersister,
          }}
        >
          <Layout />
        </PersistQueryClientProvider>
      ) : (
        <QueryClientProvider client={queryClient}>
          <Layout />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      )}
    </ThemeProvider>
  );
}
export default App;
