//eslint-disable-next-line
//@ts-nocheck
import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

export const accumulationDistribution = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Accumulation & distribution",
    metainfo: {
      _metainfoVersion: 51,
      id: "accumulation-distribution@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Accumulation & distribution",
      shortDescription: "[SF] Accum & dist",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "accumDistPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "accumDistColorer",
          type: StudyPlotType.Colorer,
          target: "accumDistPlot",
          palette: "linePalette",
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maColorer",
          type: StudyPlotType.Colorer,
          target: "maPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "accumDistPlot",
          objBId: "maPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling accum & dist" },
            1: { name: "Growing accum & dist" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling accum & dist" },
            1: { name: "Growing accum & dist" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 20,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          accumDistPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          accumDistColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          maColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "Volume",
          maTypeInput: "SMA",
          lengthInput: 240,
        },
      },
      styles: {
        accumDistPlot: {
          title: "Accum & dist",
          histogramBase: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: ["Volume", "CVD", "Open interest"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        const oiSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const maTypeInput: string = this._input(1);
        const maLengthInput: number = this._input(2);

        //Market data parsing
        const high: number = PineJS.Std.high(this._context);
        const low: number = PineJS.Std.low(this._context);
        const close: number = PineJS.Std.close(this._context);

        let priceSource;
        if ((close == high && close == low) || high == low) {
          priceSource = 0;
        } else {
          priceSource = (2 * close - low - high) / (high - low);
        }

        let dataSourceArray;
        let dataSourceTimeArray;
        let dataSource;
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        switch (sourceInput) {
          case "Volume":
            dataSource = PineJS.Std.volume(this._context);
            break;
          case "CVD":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = PineJS.Std.cum(
              dataSourceArray.adopt(
                dataSourceTimeArray,
                mainSymbolTimeSeries,
                0,
              ) - PineJS.Std.volume(this._context),
              this._context,
            );
            break;
          case "Open interest":
            this._context.select_sym(2);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            break;
        }

        //Calculations
        const accumDist: number = PineJS.Std.cum(
          priceSource * dataSource,
          this._context,
        );
        const accumDistSeries = this._context.new_var(accumDist);

        let movingAverage;
        switch (maTypeInput) {
          case "SMA":
            movingAverage = PineJS.Std.sma(
              accumDistSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "EMA":
            movingAverage = PineJS.Std.ema(
              accumDistSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "WMA":
            movingAverage = PineJS.Std.wma(
              accumDistSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "VWMA":
            movingAverage = PineJS.Std.vwma(
              accumDistSeries,
              maLengthInput,
              this._context,
            );
            break;
          case "LSMA":
            movingAverage = PineJS.Std.linreg(
              accumDistSeries,
              maLengthInput,
              0,
            );
            break;
        }

        //Returns
        const color: number = accumDist < movingAverage ? 0 : 1;
        return [accumDist, color, movingAverage, color, color];
      };
    },
  };
};
