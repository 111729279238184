//eslint-disable-next-line
//@ts-nocheck
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

export const marketFacilitationIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market facilitation index",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-facilitation-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Market facilitation index",
      shortDescription: "[SF] MkFI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "price",
        precision: 12,
      },
      plots: [
        {
          id: "mkfiPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "mkfiPlotColorer",
          type: StudyPlotType.Colorer,
          target: "mkfiPlot",
          palette: "mkfiPalette",
        },
      ],
      palettes: {
        mkfiPalette: {
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          },
          colors: {
            0: { name: "Green state" },
            1: { name: "Fade state" },
            2: { name: "Squat state" },
            3: { name: "Fake state" },
          },
        },
      },
      defaults: {
        palettes: {
          mkfiPalette: {
            colors: {
              0: { color: "#3CA64B", width: 1, style: 0 },
              1: { color: "#9598a1", width: 1, style: 0 },
              2: { color: "#0d9ddb", width: 1, style: 0 },
              3: { color: "#FFA028", width: 1, style: 0 },
            },
          },
        },
        styles: {
          mkfiPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
          mkfiPlotColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            transparency: 1,
            color: "rgb(149, 152, 161)",
          },
        },
        inputs: {},
      },
      styles: {
        mkfiPlot: {
          title: "MkFI",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Market data parsing
        const high: number = PineJS.Std.high(this._context);
        const low: number = PineJS.Std.low(this._context);
        const volumeSeries: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );
        const volume: number = volumeSeries.get(0);
        const volumePrevious: number = volumeSeries.get(1);

        //Calculations
        const mkfi: number = (high - low) / volume;
        const mkfiSeries: any = this._context.new_var(mkfi);
        const mkfiPrevious: number = mkfiSeries.get(1);

        //Color
        let color: number = 0;
        switch (true) {
          case volume > volumePrevious && mkfi > mkfiPrevious:
            color = 0;
            break;
          case volume < volumePrevious && mkfi < mkfiPrevious:
            color = 1;
            break;
          case volume < volumePrevious && mkfi > mkfiPrevious:
            color = 2;
            break;
          case volume > volumePrevious && mkfi < mkfiPrevious:
            color = 3;
            break;
        }

        //Returns
        return [mkfi, color];
      };
    },
  };
};
