//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const spotFuturesDominance = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Spot futures dominance",
    metainfo: {
      _metainfoVersion: 51,
      id: "spot-futures-dominance@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot futures dominance",
      shortDescription: "[SF] Spot fut dominance",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "dominancePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "dominanceColorer",
          type: StudyPlotType.Colorer,
          target: "dominancePlot",
          palette: "dominancePalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        dominancePalette: {
          valToIndex: {
            100: 0,
            200: 1,
          },
          colors: {
            0: { name: "Futures dominance" },
            1: { name: "Spot dominance" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            100: 0,
            200: 1,
          },
          colors: {
            0: { name: "Futures dominance" },
            1: { name: "Spot dominance" },
          },
        },
      },
      defaults: {
        palettes: {
          dominancePalette: {
            colors: {
              0: { color: "rgb(13, 157, 219)", width: 2, style: 0 },
              1: { color: "rgb(255, 160, 40)", width: 2, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(13, 157, 219, 0.25)" },
              1: { color: "rgba(255, 160, 40, 0.25)" },
            },
          },
        },
        styles: {
          dominancePlot: {
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 1,
          },
          dominanceColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
          },
        },
        precision: 2,
        inputs: {
          scaleInput: "Inverse",
        },
      },
      styles: {
        dominancePlot: {
          title: "Dominance",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "scaleInput",
          name: "Scale",
          defval: "Inverse",
          options: ["Inverse", "Classical"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const spotFuturesDominanceSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_SPREAD`;
        this._context.new_sym(
          spotFuturesDominanceSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Input
        const scaleInput: string = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const spotFuturesDominanceTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotFuturesDominanceArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const spotFuturesDominanceSmaArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);

        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        let dominance: number = spotFuturesDominanceArray.adopt(
          spotFuturesDominanceTime,
          mainSymbolTime,
          0,
        );
        const dominanceSma: number = spotFuturesDominanceSmaArray.adopt(
          spotFuturesDominanceTime,
          mainSymbolTime,
          0,
        );

        //Calculations
        let color: number = NaN;
        if (dominance <= dominanceSma) {
          color = 100;
        } else {
          color = 200;
        }

        if (scaleInput === "Classical") {
          dominance *= -1;
        }

        //Return
        return [dominance, color, color];
      };
    },
  };
};
