//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const commonActiveReturnMeasure = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Common active return measure",
    metainfo: {
      _metainfoVersion: 51,
      id: "common-active-return-measure@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Common active return measure",
      shortDescription: "[SF] CARM",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTickerInput: "BINANCE:BTCUSDT",
          riskFreeRateInput: 10.95,
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "CARM",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "benchmarkTickerInput",
          name: "Benchmark tiker",
          defval: "BINANCE:BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "riskFreeRateInput",
          name: "Risk free rate",
          defval: 10.95,
          min: 0,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const benchmarkSymbolInput: symbol = this._input(0);
        this._context.new_sym(
          benchmarkSymbolInput,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const riskFreeRateInput: number = this._input(1);
        const lengthInput: number = this._input(2);

        //Market data parsing
        const hlc3: number = PineJS.Std.hlc3(this._context);
        const hlc3Series: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(1);
        const benchmarkTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmarkArray: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmark: number = benchmarkArray.adopt(
          benchmarkTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        const benchmarkSeries = this._context.new_var(benchmark);

        //Risk-free rate calculations
        const time: number = PineJS.Std.time(this._context);
        const timeSeries: any = this._context.new_var(time);
        const timePeriod: number =
          (time - timeSeries.get(lengthInput)) / 31536000000;
        const riskFreeRate: number = (timePeriod * riskFreeRateInput) / 100;

        //CARM calculation
        let sum: number = 0.0;
        let sum2Pow: number = 0.0;
        const hlc3Avg: number =
          PineJS.Std.sum(hlc3Series, lengthInput, this._context) / lengthInput;
        const deviation: number = (hlc3 - hlc3Avg) / hlc3;
        const deviationSeries: any = this._context.new_var(deviation);
        const portfolioReturn: number =
          deviation - deviationSeries.get(lengthInput);
        const becnhmarkAvg: number =
          PineJS.Std.sum(benchmarkSeries, lengthInput, this._context) /
          lengthInput;
        const deviationBenchmark: number =
          (benchmark - becnhmarkAvg) / benchmark;
        const deviationBenchmarkSeries: any =
          this._context.new_var(deviationBenchmark);
        const benchmarkReturn: number =
          deviationBenchmark - deviationBenchmarkSeries.get(lengthInput);
        for (let i = 0; i < lengthInput; i++) {
          const value1: number = hlc3Series.get(i);
          const value2: number = benchmarkSeries.get(i);
          if (!isNaN(value1) && !isNaN(value2)) {
            sum += (value1 - hlc3Avg) * (value2 - becnhmarkAvg);
            sum2Pow += PineJS.Std.pow(value2 - becnhmarkAvg, 2);
          }
        }
        const beta: number = sum / sum2Pow;
        const activePortfolioReturn: number = portfolioReturn - riskFreeRate;
        const activePbenchmarkReturn: number = benchmarkReturn - riskFreeRate;
        const carm: number =
          activePortfolioReturn - activePbenchmarkReturn * beta;

        //Returns
        return [0, carm];
      };
    },
  };
};
