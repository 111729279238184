import React from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";
import { Favorites } from "@/components/widgets/controlPanel/favorites/favorites";
import { Blacklist } from "@/components/widgets/controlPanel/blacklist/blacklist";
import { GlobalFilters } from "@/components/widgets/controlPanel/globalFilters/globalFilters";

export function ControlPanel() {
  return (
    <Tabs defaultValue="favorites" className="">
      <TabsList className="grid grid-cols-3">
        <TabsTrigger className="text-xs text-white uppercase" value="favorites">
          Favorites
        </TabsTrigger>
        <TabsTrigger className="text-xs text-white uppercase" value="blacklist">
          BlackList
        </TabsTrigger>
        <TabsTrigger
          className="text-xs text-white uppercase"
          value="globalFilters"
        >
          Global Filters
        </TabsTrigger>
      </TabsList>
      <TabsContent value="favorites">
        <Favorites />
      </TabsContent>
      <TabsContent value="blacklist">
        <Blacklist />
      </TabsContent>
      <TabsContent value="globalFilters">
        <GlobalFilters />
      </TabsContent>
    </Tabs>
  );
}
