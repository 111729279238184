//eslint-disable-next-line
//@ts-nocheck
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";
import { group } from "console";

export const averageTradesSize = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average trade size",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-trade-size@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Average trade size",
      shortDescription: "[SF] Average trade size",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [
        {
          id: "averageTradesSizePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "stDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          averageTradesSizePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          maPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Line,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.66)",
          },
          stDevPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.33)",
          },
        },
        precision: 0,
        inputs: {
          displayModeInput: "Total",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        averageTradesSizePlot: {
          title: "Average trade size",
          histogramBase: 0,
        },
        maPlot: {
          title: "Average trade size MA",
          histogramBase: 0,
        },
        stDevPlot: {
          title: "Average trade size sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "displayModeInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const displayModeInput: string = this._input(0);
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const metricsBoolInput: boolean = this._input(4);

        //Market data parsing
        const volume: number = PineJS.Std.volume(this._context);

        this._context.select_sym(1);
        const tapeTimeArray = this._context.new_var(this._context.symbol.time);
        const tapeArray = this._context.new_var(PineJS.Std.open(this._context));
        const buyVolumeSeriesArray = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolume: number = buyVolumeSeriesArray.adopt(
          tapeTimeArray,
          mainSymbolTimeSeries,
          0,
        );

        //Calculations
        const sellVolume: number = volume - buyVolume;
        const measureCoefficient: number =
          measureInput === "USD" ? PineJS.Std.hlc3(this._context) : 1;
        const tradeNumber: number = tapeArray.adopt(
          tapeTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        let averageTradesSize;
        switch (displayModeInput) {
          case "Total":
            averageTradesSize = (volume / tradeNumber) * measureCoefficient;
            break;
          case "Buy":
            averageTradesSize =
              (((buyVolume / tradeNumber) * buyVolume) / volume) *
              measureCoefficient;
            break;
          case "Sell":
            averageTradesSize =
              (((sellVolume / tradeNumber) * sellVolume) / volume) *
              measureCoefficient;
            break;
          case "Delta":
            averageTradesSize =
              ((((buyVolume - sellVolume) / tradeNumber) * sellVolume) /
                volume) *
              measureCoefficient;
            break;
          default:
            averageTradesSize = (volume / tradeNumber) * measureCoefficient;
            break;
        }

        //Derivative metrics
        let averageTradesSizeMa: number = NaN;
        let averageTradesSizeStdev: number = NaN;
        if (metricsBoolInput) {
          const averageTradesSizeSeries =
            this._context.new_var(averageTradesSize);
          switch (maTypeInput) {
            case "SMA":
              averageTradesSizeMa = PineJS.Std.sma(
                averageTradesSizeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              averageTradesSizeMa = PineJS.Std.ema(
                averageTradesSizeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              averageTradesSizeMa = PineJS.Std.wma(
                averageTradesSizeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              averageTradesSizeMa = PineJS.Std.vwma(
                averageTradesSizeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              averageTradesSizeMa = PineJS.Std.linreg(
                averageTradesSizeSeries,
                lengthInput,
                0,
              );
              break;
          }
          averageTradesSizeStdev = PineJS.Std.stdev(
            averageTradesSizeSeries,
            lengthInput,
            this._context,
          );
        }

        //Returns
        return [averageTradesSize, averageTradesSizeMa, averageTradesSizeStdev];
      };
    },
  };
};
