import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface ICascadeLevelDetails {
  timestamp: number;
  side: string;
  price: number;
  fcSum: number;
  logicFc: boolean;
}

export interface ICascadeLevelsResponse {
  timestamp: number;
  timeFrame: string;
  levels: ICascadeLevelDetails[];
}

export function useCascadeLevelsBySymbol(
  timeFrameFilter: string,
  symbol: string,
) {
  const handler = useCallback(
    (_: ICascadeLevelDetails[], newData: ICascadeLevelsResponse) => {
      if (!newData.levels) {
        return [];
      }
      return newData.levels;
    },
    [],
  );

  const topic =
    `sf.core.scripts.screener.cascadeLevel.${symbol.toLowerCase()}.${timeFrameFilter.toLowerCase()}` as SocketTopics;

  return useGenericSocket<ICascadeLevelDetails[], ICascadeLevelsResponse>(
    topic,
    handler,
  );
}
