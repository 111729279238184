//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const manipulationMonitor = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Manipulation monitor",
    metainfo: {
      _metainfoVersion: 51,
      id: "manipulation-monitor@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Manipulation monitor",
      shortDescription: "[SFM] Manipulation monitor",

      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "priceManipulationPlotBorder",
          type: StudyPlotType.Line,
        },
        {
          id: "priceManipulationPlotBackground",
          target: "filledAreaId1",
          type: StudyPlotType.Data,
          targetField: "topValue",
        },
        {
          id: "volumeManipulationPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
        {
          id: "topline",
          type: StudyPlotType.Line,
        },
        {
          id: "filterPricePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "filterVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "avgPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "low activity" },
            { name: "medium activity" },
            { name: "high activity" },
            { name: "culmination activity" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
            300: 2,
            400: 3,
          },
        },
      },
      filledAreas: [
        {
          id: "filledAreaId1",
          objAId: "zeroline",
          objBId: "priceManipulationPlotBorder",
          title: "Histogram gradient",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(13, 157, 219, 0.2)" },
              { color: "rgba(60, 166, 75, 0.2)" },
              { color: "rgba(255, 160, 40, 0.2)" },
              { color: "rgba(178, 24, 44, 0.2)" },
            ],
          },
        },
        filledAreasStyle: {
          filledAreaId1: {
            fillType: "gradient",
            topValue: 100,
            bottomValue: 0,
            topColor: "rgb(255, 160, 40)",
            bottomColor: "rgba(255, 160, 40, 0.4)",
            visible: true,
            transparency: 100,
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          priceManipulationPlotBorder: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 160, 40, 0.2)",
            transparency: 0,
          },
          volumeManipulationPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255,0.5)",
            transparency: 0,
          },
          topline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          filterPricePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(13, 157, 219)",
            transparency: 0,
          },
          filterVolumePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          avgPlot: {
            linestyle: 0,
            visible: false,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          highlightInput: "Price activity",
          avgSourceInput: "Price activity",
          filterInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        priceManipulationPlotBorder: {
          title: "Price manipulation",
          histogramBase: 0,
        },
        priceManipulationPlotBackground: {
          title: "Price manipulation",
          histogramBase: 0,
        },
        volumeManipulationPlot: {
          title: "Manipulation volume",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
        topline: {
          title: "Topline",
          histogramBase: 0,
          joinPoints: false,
          isHidden: true,
          zorder: 0,
        },
        filterPricePlot: {
          title: "Price filter",
          histogramBase: 0,
        },
        filterVolumePlot: {
          title: "Volume filter",
          histogramBase: 0,
        },
        avgPlot: {
          title: "Average day manipulations",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
        },
        {
          id: "highlightInput",
          name: "Highlight",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "avgSourceInput",
          name: "Avg source",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "filterInput",
          name: "Filter",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string =
          this._input(0) === ``
            ? this._context.symbol.info.name
            : this._input(0);
        const substringToRemove: string = '={"session":"regular","symbol":"';
        const manipulationMonitorSymbol: string = `${symbol.replace(substringToRemove, "").split("USDT")[0]}#SF_MANIPULATION_MONITOR`;
        const filterSymbol: string = `DEFI#SF_MANIPULATION_MONITOR`;
        this._context.new_sym(
          manipulationMonitorSymbol,
          PineJS.Std.period(this._context),
        );
        this._context.new_sym(filterSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const highlightInput: string = this._input(1);
        const avgSourceInput: string = this._input(2);
        const filter: boolean = this._input(3);

        //Market data parsing
        this._context.select_sym(1);
        const marketDataTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let volumeActivitySeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        let priceActivitySeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(2);
        const indexTime = this._context.new_var(this._context.symbol.time);
        const indexPriceActivitySeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const indexVolumeActivitySeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let volumeActivity: number = volumeActivitySeries.adopt(
          marketDataTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        let priceActivity: number = priceActivitySeries.adopt(
          marketDataTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        const indexPriceActivity: number = indexPriceActivitySeries.adopt(
          indexTime,
          mainSymbolTimeSeries,
          0,
        );
        const indexVolumeActivity: number = indexVolumeActivitySeries.adopt(
          indexTime,
          mainSymbolTimeSeries,
          0,
        );

        //Filter noize
        if (filter === true) {
          priceActivity =
            priceActivity - indexPriceActivity < 6.66 ? 0 : priceActivity;

          volumeActivity =
            volumeActivity - indexVolumeActivity < 6.66 ? 0 : volumeActivity;
        }

        //Avg value calculations
        let avgMm: number = 0.0;
        const length: number = 1440 / Number(PineJS.Std.period(this._context));

        switch (avgSourceInput) {
          case "Price activity":
            priceActivitySeries = this._context.new_var(priceActivity);
            avgMm =
              PineJS.Std.sum(priceActivitySeries, length, this._context) /
              length;
            break;
          case "Volume activity":
            volumeActivitySeries = this._context.new_var(volumeActivity);
            avgMm =
              PineJS.Std.sum(volumeActivitySeries, length, this._context) /
              length;
            break;
        }

        let color: number = NaN;
        switch (highlightInput) {
          case "Price activity":
            switch (true) {
              case priceActivity < 0.5:
                color = NaN;
                break;
              case priceActivity > 0.5 && priceActivity <= 5:
                color = 100;
                break;
              case priceActivity > 5 && priceActivity <= 50:
                color = 200;
                break;
              case priceActivity > 50 && priceActivity <= 95:
                color = 300;
                break;
              case priceActivity > 95:
                color = 400;
                break;
            }
            break;
          case "Volume activity":
            switch (true) {
              case volumeActivity < 0.5:
                color = NaN;
                break;
              case volumeActivity > 0.5 && volumeActivity <= 5:
                color = 100;
                break;
              case volumeActivity > 5 && volumeActivity <= 50:
                color = 200;
                break;
              case volumeActivity > 50 && volumeActivity <= 95:
                color = 300;
                break;
              case volumeActivity > 95:
                color = 400;
                break;
            }
            break;
        }

        //Return
        return [
          0,
          priceActivity,
          priceActivity,
          volumeActivity,
          color,
          100,
          indexPriceActivity,
          indexVolumeActivity,
          avgMm,
        ];
      };
    },
  };
};
