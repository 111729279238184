import { Button } from "@/components/ui/button.tsx";
import React from "react";
import { useDashboard } from "@/lib/store.ts";
import { cn } from "@/lib/utils.ts";

function useToggleExpand({ folderId }: { folderId: string }) {
  const { updateFavorites, favorites } = useDashboard((state) => ({
    updateFavorites: state.updateFavorites,
    favorites: state.favorites,
  }));

  const folder = favorites.find((f) => f.id === folderId);

  const handleToggle = () => {
    updateFavorites((state) => {
      const folder = state.find((f) => f.id === folderId);
      if (folder) {
        folder.expanded = !folder.expanded;
      }
    });
  };

  return {
    expanded: folder?.expanded,
    handleToggle,
  };
}

export function ToggleExpand({ folderId }: { folderId: string }) {
  const { updateFavorites, favorites } = useDashboard((state) => ({
    updateFavorites: state.updateFavorites,
    favorites: state.favorites,
  }));
  const { expanded, handleToggle } = useToggleExpand({ folderId });
  const folder = favorites.find((f) => f.id === folderId);
  return (
    <Button
      onClick={handleToggle}
      size="xs"
      variant="ghost"
      className="h-4 w-4"
    >
      <i
        className={cn("fa-regular", expanded ? "fa-folder-open" : "fa-folder")}
        style={{
          color: folder?.color,
        }}
      />
    </Button>
  );
}
