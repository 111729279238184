import { cn } from "@/lib/utils.ts";

interface SortingArrowProps {
  className?: string;
  isPlaceholder?: boolean;
  direction: "asc" | "desc" | undefined;
}

export function SortingArrow({
  className,
  isPlaceholder,
  direction,
}: SortingArrowProps) {
  return (
    <span
      className={cn(isPlaceholder ? "text-gray-600" : "text-white", className)}
    >
      {direction === "desc" ? (
        <i className="fa-regular fa-arrow-down fa-sm" />
      ) : (
        <i className="fa-regular fa-arrow-up fa-sm" />
      )}
    </span>
  );
}
