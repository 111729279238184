//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const powerVolumeDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Power volume delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "power-delta-volume@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Power volume delta",
      shortDescription: "[SF] PVD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      //priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
        {
          id: "upperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "lowerBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
          },
          colors: {
            0: { name: "Neutral" },
            1: { name: "Negative" },
            2: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {},
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgba(255, 255, 255, 0.15)", width: 1, style: 0 },
              1: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              2: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
          },
          metricColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
          },
          upperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255,255,255,0.5)",
          },
          lowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255,255,255,0.5)",
          },
        },
        inputs: {
          upperBandInput: 2,
          lowerBandInput: -2,
        },
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Power delta volume",
          histogramBase: 0,
        },
        metricColorer: {
          title: "Power delta volume",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "upperBandInput",
          name: "Upper band",
          defval: 2,
          type: StudyInputType.Float,
        },
        {
          id: "lowerBandInput",
          name: "Lower band",
          defval: -2,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#POWERDELTA`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const upperBandInput: number = this._input(0);
        const lowerBandInput: number = this._input(1);

        //Data parsing
        this._context.select_sym(1);
        const cvdPowerTime = this._context.new_var(this._context.symbol.time);
        const valueArray = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const isShortArray = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const isLongArray = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_var(this._context.symbol.time);
        const value: number = valueArray.adopt(cvdPowerTime, mainSymbolTime, 0);
        const isShort: boolean = isShortArray.adopt(
          cvdPowerTime,
          mainSymbolTime,
          0,
        );
        const isLong: boolean = isLongArray.adopt(
          cvdPowerTime,
          mainSymbolTime,
          0,
        );

        // Calcualtions
        let color;
        switch (true) {
          case value >= upperBandInput:
            color = 2;
            break;
          case value <= lowerBandInput:
            color = 1;
            break;
          default:
            color = 0;
        }

        // Не работает что-то совсем
        console.log(
          "upper: ",
          upperBandInput,
          " lower: ",
          lowerBandInput,
          " value: ",
          value,
        );
        let bgcolor;

        switch (true) {
          case isShort:
            bgcolor = 0;
            break;
          case isLong:
            bgcolor = 1;
            break;
          default:
            bgcolor = NaN;
        }

        //Return
        return [0, value, color, upperBandInput, lowerBandInput, bgcolor];
      };
    },
  };
};
