import { LayoutSwitcher } from "@/components/core/layoutSwitcher.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { useDashboard } from "@/lib/store.ts";
import { LayoutSettingsModal } from "@/components/core/layoutSettingsModal.tsx";
import { toast } from "@/components/ui/use-toast.ts";

export function DashboardSubHeader() {
  return (
    <div className="flex justify-between items-center">
      <LayoutSwitcher />
      {/*<DropdownMenuDemo />*/}
    </div>
  );
}
function DropdownMenuDemo() {
  const { activeLayout } = useDashboard((state) => ({
    activeLayout: state.layouts.find((l) => l.id === state.activeLayoutId),
  }));

  const exportLayout = () => {
    if (activeLayout?.json) {
      navigator.clipboard
        .writeText(JSON.stringify(activeLayout.json))
        .then(() => {
          toast({
            title: "Layout copied to clipboard",
            duration: 3000,
          });
        })
        .catch((err) => {
          toast({
            variant: "destructive",
            title: "Failed to copy layout!",
            duration: 5000,
          });
        });
    } else {
      toast({
        variant: "destructive",
        title: "Failed to copy layout!",
        duration: 5000,
      });
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="xs" className="flex gap-1 items-center">
          <div>layout</div>
          <i className="fa-regular fa-bars" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem asChild>
          <LayoutSettingsModal />
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Copy Layout</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Import layout</DropdownMenuItem>
        <DropdownMenuItem onClick={exportLayout}>
          Export layout
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <span>Add default layout</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuItem>
                <span>Scalping</span>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <span>Scalping2</span>
              </DropdownMenuItem>
              <DropdownMenuItem>
                <span>Scalping3</span>
              </DropdownMenuItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
