//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const tape = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Tape",
    metainfo: {
      _metainfoVersion: 51,
      id: "tape@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Tape",
      shortDescription: "[SF] Tape",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "tapePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "maPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          tapePlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          maPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {
          displayModeInput: "Total",
          measureInput: "Trade count",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        tapePlot: {
          title: "Tape",
          histogramBase: 0,
        },
        maPlot: {
          title: "Tape MA",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "displayModeInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Total",
          options: ["Trade count", "Speed (tpm)"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const displayModeInput: string = this._input(0);
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const metricsBoolInput: boolean = this._input(4);

        //Market data parsing
        this._context.select_sym(1);
        const tapeTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const tapeArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const buyVolumeArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolume: number = buyVolumeArray.adopt(
          tapeTimeArray,
          mainSymbolTimeSeries,
          0,
        );

        //Calculations
        const volume: number = PineJS.Std.volume(this._context);
        let volumeDelta: number = 0.0;
        let tape: number = tapeArray.adopt(
          tapeTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        if (measureInput === "Speed (tpm)") {
          let timeframeMultiplier: number = 1;
          switch (PineJS.Std.period(this._context)) {
            case "1":
              timeframeMultiplier = 1;
              break;
            case "3":
              timeframeMultiplier = 3;
              break;
            case "5":
              timeframeMultiplier = 5;
              break;
            case "15":
              timeframeMultiplier = 15;
              break;
            case "30":
              timeframeMultiplier = 30;
              break;
            case "60":
              timeframeMultiplier = 60;
              break;
            case "120":
              timeframeMultiplier = 120;
              break;
            case "240":
              timeframeMultiplier = 240;
              break;
            case "360":
              timeframeMultiplier = 360;
              break;
            case "480":
              timeframeMultiplier = 480;
              break;
            case "720":
              timeframeMultiplier = 720;
              break;
            case "1D":
              timeframeMultiplier = 1440;
              break;
            case "1W":
              timeframeMultiplier = 10080;
              break;
            case "1M":
              timeframeMultiplier = 43200;
              break;
            default:
              timeframeMultiplier = 1;
              break;
          }
          tape /= timeframeMultiplier;
        }
        switch (displayModeInput) {
          case "Buy":
            volumeDelta = buyVolume / PineJS.Std.volume(this._context);
            tape *= volumeDelta;
            break;
          case "Sell":
            volumeDelta = (volume - buyVolume) / volume;
            tape *= volumeDelta;
            break;
          case "Delta":
            volumeDelta = 2 * buyVolume - volume;
            tape *= volumeDelta / volume;
            break;
        }
        const tapeSeries = this._context.new_var(tape);

        //Derivative metrics
        let tapeMa: number = NaN;
        if (metricsBoolInput) {
          switch (maTypeInput) {
            case "SMA":
              tapeMa = PineJS.Std.sma(tapeSeries, lengthInput, this._context);
              break;
            case "EMA":
              tapeMa = PineJS.Std.ema(tapeSeries, lengthInput, this._context);
              break;
            case "WMA":
              tapeMa = PineJS.Std.wma(tapeSeries, lengthInput, this._context);
              break;
            case "VWMA":
              tapeMa = PineJS.Std.vwma(tapeSeries, lengthInput, this._context);
              break;
            case "LSMA":
              tapeMa = PineJS.Std.linreg(tapeSeries, lengthInput, 0);
              break;
            default:
              tapeMa = PineJS.Std.sma(tapeSeries, lengthInput, this._context);
              break;
          }
        }

        //Returns
        return [tape, tapeMa];
      };
    },
  };
};
