//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const stopKillerHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Stop killer highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "stop-killer-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Stop killer highlighter",
      shortDescription: "[SF] Stop killer highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 0,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Potentially significant long stoploss" },
            { name: "Potentially significant short stoploss" },
            { name: "Potentially critical long stoploss" },
            { name: "Potentially critical short stoploss" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
            300: 2,
            400: 3,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(60, 166, 75, 0.25)" },
              { color: "rgba(178, 24, 44, 0.5)" },
              { color: "rgba(60, 166, 75, 0.5)" },
            ],
          },
        },
        styles: {
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 3,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0)",
          },
        },
        precision: 0,
        inputs: {
          filterSizeInput: 0,
        },
      },
      styles: {
        backgroundPlot: {
          title: "Stop killer",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "filterSizeInput",
          type: StudyInputType.Integer,
          name: "Size filter",
          defval: 0,
          min: 0,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const stopkillerSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#STOPKILLER`;
        this._context.new_sym(
          stopkillerSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const filterSizeInput: number = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const stopkillerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShortArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const significantLongArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const criticalShortArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const criticalLongArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShort: number =
          significantShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const significantLong: number =
          significantLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalShort: number =
          criticalShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalLong: number =
          criticalLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;

        //Calculations
        let color: number = NaN;
        const stoplossVolume: number =
          -significantLong + significantShort - criticalLong + criticalShort;
        if (stoplossVolume > filterSizeInput) {
          switch (true) {
            case significantLong < 0:
              color = 100; // bg-red-600
              break;
            case significantShort > 0:
              color = 200; // bg-green-600
              break;
            case criticalLong < 0:
              color = 300; // bg-red-800
              break;
            case criticalShort > 0:
              color = 400; // bg-green-800
              break;
            default:
              color = NaN;
              break;
          }
        }

        //Returns
        return [color];
      };
    },
  };
};
