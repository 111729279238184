//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const pumpTrend = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Pump trend",
    metainfo: {
      _metainfoVersion: 51,
      id: "pump-trend@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Pump trend",
      shortDescription: "[SF] Pump trend",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "linePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
        {
          id: "medianPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [{ name: "Red" }, { name: "Green" }, { name: "Transparent" }],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
              2: { color: "rgba(0, 0, 0, 0)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          linePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "#ffffff",
            transparency: 0,
          },
          medianPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "#FFA028",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        linePlot: {
          title: "Pump trend pressure",
          histogramBase: 0,
        },
        medianPlot: {
          title: "Pump trend pressure",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Pump trend pressure",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_PUMP_TREND`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const val: number = firstSeriesCloseSeries.adopt(
          firstSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        let color: number = 2;
        switch (true) {
          case Math.abs(val) > 70:
            color = 1;
            break;
          case Math.abs(val) < 30:
            color = 0;
            break;
          default:
            color = 2;
            break;
        }
        return [val, color, 50];
      };
    },
  };
};
