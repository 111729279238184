import { getAllSymbols } from "@/lib/datafeed/resolveSymbol.ts";
import { IDatafeedChartApi } from "@tradingView/charting_library";

export const searchSymbols: IDatafeedChartApi["searchSymbols"] = async (
  userInput,
  _,
  _symbolType,
  onResultReadyCallback,
) => {
  const symbols = await getAllSymbols();

  const newSymbols = symbols.filter((symbol) => {
    const isExchangeValid =
      symbol.exchange === "Binance" || symbol.exchange === "Bybit";
    const isFullSymbolContainsInput =
      symbol.full_name.toLowerCase().indexOf(userInput.toLowerCase()) !== -1;
    return isExchangeValid && isFullSymbolContainsInput;
  });
  onResultReadyCallback(newSymbols);
};
