import { TabNode } from "flexlayout-react";
import { useGrid } from "@/hooks/useGrid.ts";
import { useDashboard } from "@/lib/store.ts";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { FavoriteStar } from "@/components/shared/favoriteStar.tsx";

export function FavoriteStarInTabHeader({ node }: { node: TabNode }) {
  const { getNodeConfig } = useGrid(node.getId());
  const { colorsToSymbolMap } = useDashboard((state) => ({
    colorsToSymbolMap: state.colorsToSymbolMap,
  }));
  const config = getNodeConfig();
  const symbol = colorsToSymbolMap?.[config.color];
  if (node.getComponent() === EWidgetType.chart) {
    return <FavoriteStar symbolName={symbol} size="0.7rem" />;
  }
  return null;
}
