//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const hullWhiteVolatilityModel = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Hull-White volatility model",
    metainfo: {
      _metainfoVersion: 51,
      id: "hull-white-volatility-model@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Hull-White volatility model",
      shortDescription: "[SF] Hull-White vol",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          lengthInput: 10,
        },
      },
      styles: {
        metricPlot: {
          title: "Hull-White vol",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const lengthInput: number = this._input(0);

        //Market data parsing
        const high: number = PineJS.Std.high(this._context);
        const low: number = PineJS.Std.low(this._context);

        //Calculation
        const logH1: number = Math.pow(Math.log(high / low) * 100, 2);
        const logH1Series: any = this._context.new_var(logH1);
        const hwVol: number = Math.sqrt(
          PineJS.Std.sum(logH1Series, lengthInput, this._context) /
            (4 * Math.log(2)),
        );

        //Returns
        return [hwVol];
      };
    },
  };
};
