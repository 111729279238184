import { PaneBody } from "@/components/pane/paneBody.tsx";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { IStopKiller } from "@/domain/interfaces/general.interface.ts";
import {
  IColumn,
  IRow,
  ISfTable,
} from "@/domain/interfaces/sfTable.interface.ts";
import { PriceData, useBinancePrices } from "@/hooks/useBinancePrices.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useStopKiller } from "@/hooks/useStopKiller.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { Loader } from "@/components/shared/loader.tsx";
import { useDashboard } from "@/lib/store";

interface IStopKillerExtended extends IStopKiller {
  kill: number;
  price: number;
  priceChangePrc: number;
  priceChange: number;
}

const columnHelper = createColumnHelper<IStopKillerExtended>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("kill", {
    header: "Kill $",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bbMult = info.row.original.params.bbMult;
      return (
        <div
          className={cn("font-bold px-0.5", {
            "bg-red-600": side === "DOWN" && bbMult == 4,
            "bg-green-600": side === "UP" && bbMult == 4,
            "bg-red-800": side === "DOWN" && bbMult == 3,
            "bg-green-800": side === "UP" && bbMult == 3,
          })}
        >
          {value}
        </div>
      );
    },
    size: 50,
    minSize: 50,
  }),

  columnHelper.accessor("price", {
    header: "P",
    cell: (row) => {
      const value = row.getValue();
      return <div>{value}</div>;
    },
    size: 50,
    minSize: 20,
  }),

  columnHelper.accessor("timestamp", {
    header: "Time",
    cell: (row) => {
      const value = row.getValue();
      return <div>{dayjs.utc(value).format("HH:mm:ss")}</div>;
    },
    size: 60,
    minSize: 20,
  }),
];

const rowClass: ISfTable<IRow<IStopKillerExtended>>["rowClassCb"] = (row) =>
  cn({
    "animate-pulse": row.timestamp > Date.now() - 10000,
  });

const prepareData = (
  stopKiller: IStopKiller[],
  prices: Record<string, PriceData>,
) => {
  return stopKiller.map((item) => {
    return {
      ...item,
      kill: Math.abs(item.delta),
      price: prices[item.symbol]?.price ?? 0,
      priceChangePrc: prices[item.symbol]?.priceChangePrc ?? 0,
      priceChange: prices[item.symbol]?.priceChange ?? 0,
    };
  });
};

export function StopKillerPaneBody() {
  const { data: stopKiller } = useStopKiller();
  const { data: prices, isLoading } = useBinancePrices();

  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();
  const preparedData = useMemo(() => {
    if (!stopKiller || !prices) {
      return [];
    }

    let data = prepareData(stopKiller, prices);

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IStopKillerExtended>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [stopKiller, prices, config, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={rowClass}
      />
    </>
  );
}
