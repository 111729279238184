import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn } from "@/domain/interfaces/sfTable.interface.ts";
import { isNil } from "lodash";

import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import React, { useMemo } from "react";
import { applyFilters, cn } from "@/lib/utils.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import {
  IPredictLiquidation,
  usePredictLiquidation,
} from "@/hooks/usePredictLiquidation.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<IPredictLiquidation>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),
  columnHelper.accessor("prc", {
    header: "%",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bgClass = getBgClass(side);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn(bgClass, "font-bold block px-0.5")}
          decimal={2}
        />
      );
    },
    size: 50,
    minSize: 20,
  }),
  columnHelper.accessor("quoteQuantity", {
    header: "Pr. V",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bgClass = getBgClass(side);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn(bgClass, "font-bold block px-0.5")}
          shorten={true}
        />
      );
    },
    size: 60,
    minSize: 60,
  }),
  columnHelper.accessor("quoteQuantityCum", {
    header: "Pr. V Cum",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bgClass = getBgClass(side);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn(bgClass, "font-bold block px-0.5")}
          shorten={true}
        />
      );
    },
    size: 90,
    minSize: 90,
  }),
  columnHelper.accessor("index", {
    header: "Lvls",
    cell: (info) => {
      const value = info.getValue();
      const side = info.row.original.side;
      const bgClass = getBgClass(side);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn(bgClass, "font-bold block px-0.5")}
          shorten={false}
        />
      );
    },
    size: 40,
    minSize: 40,
  }),
];

const getBgClass = (side: string) => {
  return side === "UP" ? "bg-green-800" : "bg-red-800";
};

export function PredictLiquidationPaneBody() {
  const { data: _raw, isLoading } = usePredictLiquidation();
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist, globalFilters } = useDashboard();

  const preparedData = useMemo(() => {
    if (!_raw) {
      return [];
    }

    let data = _raw;

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    const globalFilterValues = globalFilters.reduce(
      (acc, filter) => {
        acc[filter.filterByField] = filter.value;
        return acc;
      },
      {} as Record<string, string>,
    );

    data = applyFilters(data, globalFilters, globalFilterValues);

    if (config && config.filters && config.filterValues) {
      return applyFilters<IPredictLiquidation>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, _raw, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData ?? []}
      />
    </>
  );
}
