import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { merge } from "lodash";
import { useCallback } from "react";

export interface PriceData {
  priceChange: number;
  priceChangePrc: number;
  price: number;
  closeQty: number;
  baseVolume: number;
  quoteVolume: number;
}
interface IPriceDataResponse {
  data: Record<string, PriceData>;
}

export function useBinancePrices() {
  const handler = useCallback(
    (oldData: Record<string, PriceData>, newData: IPriceDataResponse) =>
      merge(oldData, newData.data),
    [],
  );
  return useGenericSocket<Record<string, PriceData>, IPriceDataResponse>(
    SocketTopics.dict24Prices,
    handler,
  );
}
