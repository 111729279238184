import {
  DatafeedConfiguration,
  ResolutionString,
} from "@tradingView/charting_library";

export enum EXCHANGES {
  BINANCE = "Binance",
  BYBIT = "Bybit",
  SANTIMENT = "Santiment",
  MANIPULATIONS = "Manipulations",
  RETAIL_POWER = "Retail Power",
  MARKET_POWER = "Market Power",
  IDEAS = "Ideas",
  PUMP_TREND = "Pump Trend",
  STRATEGY = "Strategy",
  STOP_KILLER = "Stop Killer",
  OPEN_INTEREST = "Open Interest",
  VOLUME = "Volume",
  LIQUIDATIONS = "Liquidations",
  SPREAD = "Spread",
  ASKORDERBOOKSUM1_5 = "AskOrderBookSum1_5",
  ASKORDERBOOKSUM6_10 = "AskOrderBookSum6_10",
  BIDORDERBOOKSUM1_5 = "BidOrderBookSum1_5",
  BIDORDERBOOKSUM6_10 = "BidOrderBookSum6_10",
  BIDASKQUOTEDSPREAD = "BidAskQuotedSpread",
  HIGHESTSIZEORDER = "HighestSizeOrder",
  ORDERBOOKCHANGES = "OrderBookChanges",
  REALIZEDBIDASKSPREAD = "RealizedBidAskSpread",
  MATRIXSPREAD = "MatrixSpread",
  SPOTFUTURESPREAD = "SpotFutureSpread",
  SPOOF_LAYER_UP = "SpoofLayerUp",
  SPOOF_LAYER_DOWN = "SpoofLayerDown",
  POWER_DELTA = "PowerDelta",
  ACTIVITY_DETECTOR = "ActivityDetector",
  CRYPTO_DRIFT = "CryptoDrift",
  POWER_TRADES = "PowerTrades",
  HFT_ACTIVITY = "HFTActivity",
  OISPIKE = "OISpike",
  OINETLONGSPIKE = "OINetLongSpike",
  OINETSHORTSPIKE = "OINetShortSpike",
  OINETDELTA = "OINetDelta",
  ALT_TIMERS = "AltTimers",
  TICK_INDEX = "TickIndex",
  DOG = "Dog",
  LAYERING = "Layering",
  FUNDING_RATE = "FundingRate",
  TREND_ASSESSMENT = "TrendAssessment",
}

export const SANTIMENT_CACHE_DURATION = 60 * 60; // in seconds

export const BINANCE_RESOLUTION_MAP: Record<string, string> = {
  "1": "1m",
  "3": "3m",
  "5": "5m",
  "15": "15m",
  "30": "30m",
  "60": "1h",
  "120": "2h",
  "240": "4h",
  "360": "6h",
  "480": "8h",
  "720": "12h",
  "1D": "1d",
  "3D": "3d",
  "1W": "1w",
  "1M": "1M",
};

// reverse BINANCE_RESOLUTION_MAP
export const BINANCE_RESOLUTION_MAP_REVERSE: Record<string, string> = {
  "1m": "1",
  "3m": "3",
  "5m": "5",
  "15m": "15",
  "30m": "30",
  "1h": "60",
  "2h": "120",
  "4h": "240",
  "6h": "360",
  "8h": "480",
  "12h": "720",
  "1d": "1D",
  "3d": "3D",
  "1w": "1W",
  "1M": "1M",
};

export const BINANCE_RESOLUTION_TO_MINUTES: Record<string, number> = {
  "1": 1,
  "3": 3,
  "5": 5,
  "15": 15,
  "30": 30,
  "60": 60,
  "120": 120,
  "240": 240,
  "360": 360,
  "480": 480,
  "720": 720,
  "1D": 1440,
  "3D": 4320,
  "1W": 10080,
  "1M": 43200,
};

const supportedResolution: ResolutionString[] = [
  "1",
  "3",
  "5",
  "15",
  "30",
  "60",
  "240",
  "1D",
].map((e) => e as ResolutionString);

export const CHART_CONFIG: DatafeedConfiguration = {
  supported_resolutions: supportedResolution,
  supports_marks: true,
  supports_timescale_marks: true,
  exchanges: [
    { value: "", name: "All Exchanges", desc: "" },
    {
      value: EXCHANGES.BINANCE,
      name: EXCHANGES.BINANCE,
      desc: "Binance Exchange",
    },
    {
      value: EXCHANGES.BYBIT,
      name: EXCHANGES.BYBIT,
      desc: "Bybit Exchange",
    },
  ],
  symbols_types: [
    { name: "All types", value: "" },
    {
      name: "crypto",
      value: "crypto",
    },
    {
      name: "spot",
      value: "spot",
    },
    {
      name: "futures",
      value: "futures",
    },
  ],
};
