//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const layeringHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Layering",
    metainfo: {
      _metainfoVersion: 51,
      id: "layering-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Layering highlighter",
      shortDescription: "[SF] Layering highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Bid layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
            { name: "Ask layering" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
            11: 11,
            12: 12,
            13: 13,
            14: 14,
            15: 15,
            16: 16,
            17: 17,
            18: 18,
            19: 19,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(60, 166, 75, 0.50)" },
              { color: "rgba(60, 166, 75, 0.45)" },
              { color: "rgba(60, 166, 75, 0.40)" },
              { color: "rgba(60, 166, 75, 0.35)" },
              { color: "rgba(60, 166, 75, 0.30)" },
              { color: "rgba(60, 166, 75, 0.25)" },
              { color: "rgba(60, 166, 75, 0.20)" },
              { color: "rgba(60, 166, 75, 0.15)" },
              { color: "rgba(60, 166, 75, 0.10)" },
              { color: "rgba(60, 166, 75, 0.05)" },
              { color: "rgba(178, 24, 44, 0.05)" },
              { color: "rgba(178, 24, 44, 0.10)" },
              { color: "rgba(178, 24, 44, 0.15)" },
              { color: "rgba(178, 24, 44, 0.20)" },
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(178, 24, 44, 0.30)" },
              { color: "rgba(178, 24, 44, 0.35)" },
              { color: "rgba(178, 24, 44, 0.40)" },
              { color: "rgba(178, 24, 44, 0.45)" },
              { color: "rgba(178, 24, 44, 0.50)" },
            ],
          },
        },
        styles: {},
        inputs: {},
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const askDepthSymbol1_5: string = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM1_5`;
        this._context.new_sym(
          askDepthSymbol1_5,
          PineJS.Std.period(this._context),
        );

        const askDepthSymbol6_10: string = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM6_10`;
        this._context.new_sym(
          askDepthSymbol6_10,
          PineJS.Std.period(this._context),
        );

        const bidDepthSymbol1_5: string = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM1_5`;
        this._context.new_sym(
          bidDepthSymbol1_5,
          PineJS.Std.period(this._context),
        );

        const bidDepthSymbol6_10: string = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM6_10`;
        this._context.new_sym(
          bidDepthSymbol6_10,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Function import
        function layering(bid: number, ask: number): number {
          const length: number = 1440 / Number(PineJS.Std.period(context));

          //Ratio Z-score calculations
          const bidRatio: number = bid / ask;
          const bidRatioSeries: any = context.new_var(bidRatio);
          const bidRatioAvg: number =
            PineJS.Std.sum(bidRatioSeries, length, context) / length;
          const bidRatioSigma: number = PineJS.Std.stdev(
            bidRatioSeries,
            length,
            context,
          );
          const bidRatioZscore: number =
            (bidRatio - bidRatioAvg) / bidRatioSigma;

          const askRatio: number = ask / bid;
          const askRatioSeries: any = context.new_var(askRatio);
          const askRatioAvg: number =
            PineJS.Std.sum(askRatioSeries, length, context) / length;
          const askRatioSigma: number = PineJS.Std.stdev(
            askRatioSeries,
            length,
            context,
          );
          const askRatioZscore: number =
            (askRatio - askRatioAvg) / askRatioSigma;

          // Abs Z-score calculations
          const bidSeries: any = context.new_var(bid);
          const bidAvg: number =
            PineJS.Std.sum(bidSeries, length, context) / length;
          const bidSigma: number = PineJS.Std.stdev(bidSeries, length, context);
          const bidZscore: number = (bid - bidAvg) / bidSigma;

          const askSeries: any = context.new_var(ask);
          const askAvg: number =
            PineJS.Std.sum(askSeries, length, context) / length;
          const askSigma: number = PineJS.Std.stdev(askSeries, length, context);
          const askZscore: number = (ask - askAvg) / askSigma;

          // Layering matrix
          let layering: number = 0;
          switch (true) {
            case bidRatioZscore > 3 && bidZscore > 3:
              layering = 1;
              break;
            case askRatioZscore > 3 && askZscore > 3:
              layering = -1;
              break;
            default:
              layering = 0;
          }

          // Return
          return layering;
        }

        //Market data parsing
        this._context.select_sym(1);
        const ask_1_5_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const ask_1_5_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const ask_1_5_High_SeriesArray = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const ask_1_5_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const ask_1_5_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const ask_1_5_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(2);
        const ask_6_10_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const ask_6_10_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const ask_6_10_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const ask_6_10_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const ask_6_10_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const ask_6_10_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(3);
        const bid_1_5_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const bid_1_5_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const bid_1_5_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const bid_1_5_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const bid_1_5_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const bid_1_5_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(4);
        const bid_6_10_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const bid_6_10_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const bid_6_10_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const bid_6_10_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const bid_6_10_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const bid_6_10_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );
        this._context.select_sym(0);

        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const ask1Depth: number = ask_1_5_Open_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask2Depth: number = ask_1_5_High_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask3Depth: number = ask_1_5_Low_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask4Depth: number = ask_1_5_Close_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask5Depth: number = ask_1_5_Volume_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        const ask6Depth: number = ask_6_10_Open_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask7Depth: number = ask_6_10_High_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask8Depth: number = ask_6_10_Low_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask9Depth: number = ask_6_10_Close_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const ask10Depth: number = ask_6_10_Volume_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        const bid1Depth: number = bid_1_5_Open_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid2Depth: number = bid_1_5_High_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid3Depth: number = bid_1_5_Low_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid4Depth: number = bid_1_5_Close_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid5Depth: number = bid_1_5_Volume_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        const bid6Depth: number = bid_6_10_Open_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid7Depth: number = bid_6_10_High_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid8Depth: number = bid_6_10_Low_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid9Depth: number = bid_6_10_Close_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        const bid10Depth: number = bid_6_10_Volume_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        //Calculation
        const layering_01: number = layering(bid1Depth, ask1Depth);
        const layering_02: number = layering(bid2Depth, ask2Depth);
        const layering_03: number = layering(bid3Depth, ask3Depth);
        const layering_04: number = layering(bid4Depth, ask4Depth);
        const layering_05: number = layering(bid5Depth, ask5Depth);
        const layering_06: number = layering(bid6Depth, ask6Depth);
        const layering_07: number = layering(bid7Depth, ask7Depth);
        const layering_08: number = layering(bid8Depth, ask8Depth);
        const layering_09: number = layering(bid9Depth, ask9Depth);
        const layering_10: number = layering(bid10Depth, ask10Depth);
        const layeringSum: number =
          layering_01 +
          layering_02 +
          layering_03 +
          layering_04 +
          layering_05 +
          layering_06 +
          layering_07 +
          layering_08 +
          layering_09 +
          layering_10;

        //Colorer
        let backgroundColor: number = NaN;
        switch (true) {
          case layeringSum === 10:
            backgroundColor = 0;
            break;
          case layeringSum === 9:
            backgroundColor = 1;
            break;
          case layeringSum === 8:
            backgroundColor = 2;
            break;
          case layeringSum === 7:
            backgroundColor = 3;
            break;
          case layeringSum === 6:
            backgroundColor = 4;
            break;
          case layeringSum === 5:
            backgroundColor = 5;
            break;
          case layeringSum === 4:
            backgroundColor = 6;
            break;
          case layeringSum === 3:
            backgroundColor = 7;
            break;
          case layeringSum === 2:
            backgroundColor = 8;
            break;
          case layeringSum === 1:
            backgroundColor = 9;
            break;
          case layeringSum === 0:
            backgroundColor = NaN;
            break;
          case layeringSum === -1:
            backgroundColor = 10;
            break;
          case layeringSum === -2:
            backgroundColor = 11;
            break;
          case layeringSum === -3:
            backgroundColor = 12;
            break;
          case layeringSum === -4:
            backgroundColor = 13;
            break;
          case layeringSum === -5:
            backgroundColor = 14;
            break;
          case layeringSum === -6:
            backgroundColor = 15;
            break;
          case layeringSum === -7:
            backgroundColor = 16;
            break;
          case layeringSum === -8:
            backgroundColor = 17;
            break;
          case layeringSum === -9:
            backgroundColor = 18;
            break;
          case layeringSum === -10:
            backgroundColor = 19;
            break;
          default:
            backgroundColor = NaN;
        }

        //Returns
        return [backgroundColor];
      };
    },
  };
};
