//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const activityDetectorHighlighter = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Activity detector highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "activity-detector-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Activity detector highlighter",
      shortDescription: "[SF] Activity detector highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
            { name: "Activity" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(13, 157, 219, 0.05)" },
              { color: "rgba(13, 157, 219, 0.10)" },
              { color: "rgba(13, 157, 219, 0.15)" },
              { color: "rgba(13, 157, 219, 0.20)" },
              { color: "rgba(13, 157, 219, 0.25)" },
              { color: "rgba(13, 157, 219, 0.30)" },
              { color: "rgba(13, 157, 219, 0.35)" },
              { color: "rgba(13, 157, 219, 0.40)" },
              { color: "rgba(13, 157, 219, 0.45)" },
              { color: "rgba(13, 157, 219, 0.50)" },
            ],
          },
        },
        styles: {},
        precision: 0,
        inputs: {},
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_ACTIVITY_DETECTOR`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Markte data parsing
        this._context.select_sym(1);
        const activityDetectorTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const activityDetectorArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const activityDetector: number = activityDetectorArray.adopt(
          activityDetectorTime,
          mainSymbolTime,
          0,
        );

        //Colorer
        let backgroundColor: number = NaN;
        switch (true) {
          case activityDetector >= 0.9:
            backgroundColor = 9;
            break;
          case activityDetector >= 0.8:
            backgroundColor = 8;
            break;
          case activityDetector >= 0.7:
            backgroundColor = 7;
            break;
          case activityDetector >= 0.6:
            backgroundColor = 6;
            break;
          case activityDetector >= 0.5:
            backgroundColor = 5;
            break;
          case activityDetector >= 0.4:
            backgroundColor = 4;
            break;
          case activityDetector >= 0.3:
            backgroundColor = 3;
            break;
          case activityDetector >= 0.2:
            backgroundColor = 2;
            break;
          case activityDetector >= 0.1:
            backgroundColor = 1;
            break;
          case activityDetector >= 0:
            backgroundColor = 0;
            break;
          default:
            backgroundColor = NaN;
        }

        // Return
        return [backgroundColor];
      };
    },
  };
};
