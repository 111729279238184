//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const marketRatio = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market ratio",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-ratio@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Market ratio",
      shortDescription: "[SF] Market ratio",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "mainPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "btcPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ethPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bnbPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "solPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "xrpPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "adaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "avaxPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "linkPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "dogePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "trxPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          mainPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: false,
            color: "#ffffff",
            transparency: 0,
          },
          btcPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ffeb3b",
            transparency: 50,
          },
          ethPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#311b92",
            transparency: 50,
          },
          bnbPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#808000",
            transparency: 50,
          },
          solPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#9c27b0",
            transparency: 50,
          },
          xrpPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#4caf50",
            transparency: 50,
          },
          adaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#00bcd4",
            transparency: 50,
          },
          avaxPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ff9800",
            transparency: 50,
          },
          linkPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#2196f3",
            transparency: 50,
          },
          dogePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#ff5252",
            transparency: 50,
          },
          trxPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "#880e4f",
            transparency: 50,
          },
        },
        palettes: {},
      },
      palettes: {},
      styles: {
        mainPlot: {
          isHidden: false,
          title: "Chart symbol",
          histogramBase: 0,
        },
        btcPlot: {
          isHidden: false,
          title: "Bitcoin",
          histogramBase: 0,
        },
        ethPlot: {
          isHidden: false,
          title: "Ethereum",
          histogramBase: 0,
        },
        bnbPlot: {
          isHidden: false,
          title: "Binance Coin",
          histogramBase: 0,
        },
        solPlot: {
          isHidden: false,
          title: "Solana",
          histogramBase: 0,
        },
        xrpPlot: {
          isHidden: false,
          title: "Ripple",
          histogramBase: 0,
        },
        adaPlot: {
          isHidden: false,
          title: "Cardano",
          histogramBase: 0,
        },
        avaxPlot: {
          isHidden: false,
          title: "Avalanche",
          histogramBase: 0,
        },
        linkPlot: {
          isHidden: false,
          title: "Chainlink",
          histogramBase: 0,
        },
        dogePlot: {
          isHidden: false,
          title: "Dogecoin",
          histogramBase: 0,
        },
        trxPlot: {
          isHidden: false,
          title: "Tron",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const btcSymbol: string = `BTCUSDT`;
        this._context.new_sym(btcSymbol, PineJS.Std.period(this._context));
        const ethSymbol: string = `ETHUSDT`;
        this._context.new_sym(ethSymbol, PineJS.Std.period(this._context));
        const bnbSymbol: string = `BNBUSDT`;
        this._context.new_sym(bnbSymbol, PineJS.Std.period(this._context));
        const solSymbol: string = `SOLUSDT`;
        this._context.new_sym(solSymbol, PineJS.Std.period(this._context));
        const xrpSymbol: string = `XRPUSDT`;
        this._context.new_sym(xrpSymbol, PineJS.Std.period(this._context));
        const adaSymbol: string = `ADAUSDT`;
        this._context.new_sym(adaSymbol, PineJS.Std.period(this._context));
        const avaxSymbol: string = `AVAXUSDT`;
        this._context.new_sym(avaxSymbol, PineJS.Std.period(this._context));
        const linkSymbol: string = `LINKUSDT`;
        this._context.new_sym(linkSymbol, PineJS.Std.period(this._context));
        const dogeSymbol: string = `DOGEUSDT`;
        this._context.new_sym(dogeSymbol, PineJS.Std.period(this._context));
        const trxSymbol: string = `TRXUSDT`;
        this._context.new_sym(trxSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Market data parsing
        const close: number = PineJS.Std.close(this._context);
        const olhc4Series: any = this._context.new_var(
          PineJS.Std.ohlc4(this._context),
        );

        this._context.select_sym(1);
        const btcTime: any = this._context.new_var(this._context.symbol.time);
        const btcCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(2);
        const ethTime: any = this._context.new_var(this._context.symbol.time);
        const ethCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(3);
        const bnbTime: any = this._context.new_var(this._context.symbol.time);
        const bnbCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(4);
        const solTime: any = this._context.new_var(this._context.symbol.time);
        const solCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(5);
        const xrpTime: any = this._context.new_var(this._context.symbol.time);
        const xrpCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(6);
        const adaTime: any = this._context.new_var(this._context.symbol.time);
        const adaCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(7);
        const avaxTime: any = this._context.new_var(this._context.symbol.time);
        const avaxCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(8);
        const linkTime: any = this._context.new_var(this._context.symbol.time);
        const linkCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(9);
        const dogeTime: any = this._context.new_var(this._context.symbol.time);
        const dogeCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(10);
        const trxTime: any = this._context.new_var(this._context.symbol.time);
        const trxCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);

        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const btcClose: number = btcCloseArray.adopt(
          btcTime,
          mainSymbolTime,
          0,
        );
        const btcCloseSeries: any = this._context.new_var(btcClose);
        const ethClose: number = ethCloseArray.adopt(
          ethTime,
          mainSymbolTime,
          0,
        );
        const ethCloseSeries: any = this._context.new_var(ethClose);
        const bnbClose: number = bnbCloseArray.adopt(
          bnbTime,
          mainSymbolTime,
          0,
        );
        const bnbCloseSeries: any = this._context.new_var(bnbClose);
        const solClose: number = solCloseArray.adopt(
          solTime,
          mainSymbolTime,
          0,
        );
        const solCloseSeries: any = this._context.new_var(solClose);
        const xrpClose: number = xrpCloseArray.adopt(
          xrpTime,
          mainSymbolTime,
          0,
        );
        const xrpCloseSeries = this._context.new_var(xrpClose);
        const adaClose: number = adaCloseArray.adopt(
          adaTime,
          mainSymbolTime,
          0,
        );
        const adaCloseSeries: any = this._context.new_var(adaClose);
        const avaxClose: number = avaxCloseArray.adopt(
          avaxTime,
          mainSymbolTime,
          0,
        );
        const avaxCloseSeries: any = this._context.new_var(avaxClose);
        const linkClose: number = linkCloseArray.adopt(
          linkTime,
          mainSymbolTime,
          0,
        );
        const linkCloseSeries: any = this._context.new_var(linkClose);
        const dogeClose: number = dogeCloseArray.adopt(
          dogeTime,
          mainSymbolTime,
          0,
        );
        const dogeCloseSeries: any = this._context.new_var(dogeClose);
        const trxClose: number = trxCloseArray.adopt(
          trxTime,
          mainSymbolTime,
          0,
        );
        const trxCloseSeries: any = this._context.new_var(trxClose);

        const length: number = 1440 / Number(PineJS.Std.period(this._context));
        const avg: number =
          PineJS.Std.sum(olhc4Series, length, this._context) / length;
        const mainPercent: number = ((close - avg) / avg) * 100;
        const btcAvg: number =
          PineJS.Std.sum(btcCloseSeries, length, this._context) / length;
        const btcPercent: number = ((btcClose - btcAvg) / btcAvg) * 100;
        const ethAvg: number =
          PineJS.Std.sum(ethCloseSeries, length, this._context) / length;
        const ethPercent: number = ((ethClose - ethAvg) / ethAvg) * 100;
        const bnbAvg: number =
          PineJS.Std.sum(bnbCloseSeries, length, this._context) / length;
        const bnbPercent: number = ((bnbClose - bnbAvg) / bnbAvg) * 100;
        const solAvg: number =
          PineJS.Std.sum(solCloseSeries, length, this._context) / length;
        const solPercent: number = ((solClose - solAvg) / solAvg) * 100;
        const xrpAvg: number =
          PineJS.Std.sum(xrpCloseSeries, length, this._context) / length;
        const xrpPercent: number = ((xrpClose - xrpAvg) / xrpAvg) * 100;
        const adaAvg: number =
          PineJS.Std.sum(adaCloseSeries, length, this._context) / length;
        const adaPercent: number = ((adaClose - adaAvg) / adaAvg) * 100;
        const avaxAvg: number =
          PineJS.Std.sum(avaxCloseSeries, length, this._context) / length;
        const avaxPercent: number = ((avaxClose - avaxAvg) / avaxAvg) * 100;
        const linkAvg: number =
          PineJS.Std.sum(linkCloseSeries, length, this._context) / length;
        const linkPercent: number = ((linkClose - linkAvg) / linkAvg) * 100;
        const dogeAvg: number =
          PineJS.Std.sum(dogeCloseSeries, length, this._context) / length;
        const dogePercent: number = ((dogeClose - dogeAvg) / dogeAvg) * 100;
        const trxAvg: number =
          PineJS.Std.sum(trxCloseSeries, length, this._context) / length;
        const trxPercent: number = ((trxClose - trxAvg) / trxAvg) * 100;

        return [
          mainPercent,
          btcPercent,
          ethPercent,
          bnbPercent,
          solPercent,
          xrpPercent,
          adaPercent,
          avaxPercent,
          linkPercent,
          dogePercent,
          trxPercent,
        ];
      };
    },
  };
};
