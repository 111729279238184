//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const sentimentBalanceTotal = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Sentiment balance total",
    metainfo: {
      _metainfoVersion: 51,
      id: "sentiment-balance-total@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Sentiment balance total",
      shortDescription: "[SF] Sentiment balance total",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: true,
            color: "#FF5B5B",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Sentiment balance total",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SANTIMENTS_BALANCE_TOTAL`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const metric: number = firstSeriesCloseSeries.adopt(
          firstSymbolTime,
          mainSymbolTimeSeries,
          1,
        );
        let color: number = NaN;
        switch (true) {
          case metric < 0:
            color = 0;
            break;
          case metric > 0:
            color = 1;
            break;
          default:
            color = NaN;
        }

        //Return
        return [0, metric, color, color];
      };
    },
  };
};
