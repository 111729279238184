// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator, IPineStudyResult, LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyTargetPriceScale,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const activeAddresses = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Active addresses",
    metainfo: {
      _metainfoVersion: 51,
      id: "active-addresses@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Active addresses",
      shortDescription: "[SF] Active addresses",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "#FFCB47",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        metricPlot: {
          title: "Active addresses",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol = `${this._context.symbol.info.name.split("USDT")[0]}#SANTIMENTS_ACTIVE_ADDRESSES_24H`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );

        //Return
        return [
          firstSeriesCloseSeries.adopt(
            firstSymbolTimeSeries,
            mainSymbolTimeSeries,
            0,
          ),
        ];
      };
    },
  };
};
