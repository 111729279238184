export enum EAvailableWidgetOptions {
  timeFrame,
}

export enum EWidgetType { //  TODO move to widgets config constant, to keep everything in one place
  chart = "chart",
  manipulationMonitor = "manipulationMonitor",
  spotFuturesSpread = "spotFuturesSpread",
  controlPanel = "controlPanel",
  strategies = "strategies",
  strategiesV2 = "strategiesV2",
  stopKiller = "stopKiller",
  priceScreener = "priceScreener",
  oiScreener = "oiScreener",
  volumeScreener = "volumeScreener",
  deltaScreener = "deltaScreener",
  liquidationsScanner = "liquidationsScanner",
  pairsScanner = "pairsScanner",
  predictLiquidation = "predictLiquidation",
  cascadeLevels = "cascadeLevels",
  adtvScreener = "adtvScreener",
  activityDetectorScreener = "activityDetectorScreener",
  cryptoDriftScreener = "cryptoDriftScreener",
  marketRatioScreener = "marketRatioScreener",
  spreadDominationScreener = "spreadDominationScreener",
  // prePumpDetectorScreener = "prePumpDetectorScreener",
}

export enum EFilterType {
  text = "text",
  select = "select",
  number = "number",
  range = "range",
  multiSelect = "multiSelect",
  object = "object",
}

export enum ENumberComparisonType {
  equal = "equal",
  greater = "greater",
  less = "less",
  mixed = "mixed",
}

export enum ESFTableType {
  chart = "chart",
  pinned = "pinned",
  unpinned = "unpinned",
}
