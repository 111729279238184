//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const moneyFlowIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Money flow index",
    metainfo: {
      _metainfoVersion: 51,
      id: "money-flow-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Money flow index",
      shortDescription: "[SF] MFI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "middleBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "mfiPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "overboughtPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "oversoldPlot",
          type: StudyPlotType.Line,
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "overboughtPlot",
          objBId: "oversoldPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {},
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(60, 166, 75, 0.1)",
            visible: true,
            transparency: 10,
          },
        },
        styles: {
          middleBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          mfiPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          overboughtPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(60, 166, 75)",
          },
          oversoldPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(60, 166, 75)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "Volume",
          lengthInput: 100,
          overboughtInput: 80,
          middleBandInput: 50,
          oversoldInput: 20,
        },
      },
      styles: {
        middleBandPlot: {
          title: "Middle band",
          histogramBase: 0,
        },
        mfiPlot: {
          title: "MFI",
          histogramBase: 0,
        },
        overboughtPlot: {
          title: "Overbought",
          histogramBase: 0,
        },
        oversoldPlot: {
          title: "Oversold",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: ["Volume", "CVD", "Open interest"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "overboughtInput",
          name: "Overbought",
          defval: 80,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "middleBandInput",
          name: "Middle band",
          defval: 50,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
        {
          id: "oversoldInput",
          name: "Oversold",
          defval: 20,
          min: 0,
          max: 100,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const lengthInput: number = this._input(1);
        const overboughtInput: number = this._input(2);
        const middleBandInput: number = this._input(3);
        const oversoldInput: number = this._input(4);

        //Market data parsing
        const priceSource: number = PineJS.Std.hlc3(this._context);
        const priceSourceSeries: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );

        let dataSourceArray: any;
        let dataSourceTimeArray: any;
        let dataSource: any;
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        switch (sourceInput) {
          case "Volume":
            dataSource = PineJS.Std.volume(this._context);
            break;
          case "CVD":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = PineJS.Std.cum(
              dataSourceArray.adopt(
                dataSourceTimeArray,
                mainSymbolTimeSeries,
                0,
              ) - PineJS.Std.volume(this._context),
              this._context,
            );
            break;
          case "Open interest":
            this._context.select_sym(2);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            break;
        }

        //Calculations
        const change: number =
          PineJS.Std.change(priceSourceSeries) * dataSource;
        const posChange: number = change <= 0 ? 0 : priceSource;
        const posChangeSeries: any = this._context.new_var(posChange);
        const upper: number = PineJS.Std.sum(
          posChangeSeries,
          lengthInput,
          this._context,
        );
        const negChange: number = change >= 0 ? 0 : priceSource;
        const negChangeSeries: any = this._context.new_var(negChange);
        const lower: number = PineJS.Std.sum(
          negChangeSeries,
          lengthInput,
          this._context,
        );
        const mfi: number = 100 - 100 / (1 + upper / lower);

        //Returns
        return [middleBandInput, mfi, overboughtInput, oversoldInput];
      };
    },
  };
};
