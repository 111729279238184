import { DashboardHeader } from "@/components/core/dashboardHeader.tsx";
import { DashboardSubHeader } from "@/components/core/dashboardSubHeader.tsx";
import { Grid } from "@/components/core/grid/grid.tsx";

export function Dashboard() {
  return (
    <>
      <DashboardHeader />
      <DashboardSubHeader />
      <Grid />
    </>
  );
}
