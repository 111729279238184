import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";
import { ISpotFuturesSpread } from "@/domain/interfaces/general.interface.ts";

export interface ISpotFuturesSpreadResponse {
  timeFrame: string;
  timestamp: number;
  data: ISpotFuturesSpread[];
}

export function useSpotFuturesSpread() {
  const handler = useCallback(
    (_: ISpotFuturesSpread[], newData: ISpotFuturesSpreadResponse) => {
      return newData.data;
    },
    [],
  );
  return useGenericSocket<ISpotFuturesSpread[], ISpotFuturesSpreadResponse>(
    SocketTopics.spotFuturesSpread,
    handler,
  );
}
