//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const bidAskDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Bid & ask delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "bid-ask-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Bid & ask delta",
      shortDescription: "[SF] Bid & ask delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaColorer",
          type: StudyPlotType.Colorer,
          target: "deltaPlot",
          palette: "deltaPalette",
        },
        {
          id: "negDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "posDeltaMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        deltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          deltaPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          deltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          negDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          posDeltaMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          deltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "DOM by 1%",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        deltaPlot: {
          title: "Delta",
          histogramBase: 0,
        },
        negDeltaMaPlot: {
          title: "Negative delta MA",
          histogramBase: 0,
        },
        posDeltaMaPlot: {
          title: "Positive delta MA",
          histogramBase: 0,
        },
        deltaStDevPlot: {
          title: "Delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "DOM by 1%",
          options: [
            "Filled orders",
            "Best bid & ask size",
            "DOM by 1%",
            "DOM by 2%",
            "DOM by 3%",
            "DOM by 4%",
            "DOM by 5%",
            "DOM by 6%",
            "DOM by 7%",
            "DOM by 8%",
            "DOM by 9%",
            "DOM by 10%",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const buyVolumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );

        const bestBidAskSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#BIDASKQUOTEDSPREAD`;
        this._context.new_sym(
          bestBidAskSymbol,
          PineJS.Std.period(this._context),
        );

        const askSymbol1_5 = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM1_5`;
        this._context.new_sym(askSymbol1_5, PineJS.Std.period(this._context));

        const bidSymbol1_5 = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM1_5`;
        this._context.new_sym(bidSymbol1_5, PineJS.Std.period(this._context));

        const askSymbol6_10 = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM6_10`;
        this._context.new_sym(askSymbol6_10, PineJS.Std.period(this._context));

        const bidSymbol6_10 = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM6_10`;
        this._context.new_sym(bidSymbol6_10, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const metricsBoolInput: boolean = this._input(4);

        //Market data parsing
        let ask;
        let askArray;
        let askTimeArray;
        let bid;
        let bidArray;
        let bidTimeArray;
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        if (sourceInput === "Filled orders") {
          const volume: number =
            measureInput === "Coin"
              ? PineJS.Std.volume(this._context)
              : PineJS.Std.volume(this._context) *
                PineJS.Std.close(this._context);
          this._context.select_sym(1);
          switch (measureInput) {
            case "Coin":
              ask = PineJS.Std.low(this._context);
              break;
            case "USD":
              ask = PineJS.Std.close(this._context);
              break;
          }
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(0);
          ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
          bid = volume - ask;
        } else if (sourceInput === "Best bid & ask size") {
          this._context.select_sym(2);
          askTimeArray = this._context.new_var(this._context.symbol.time);
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(PineJS.Std.high(this._context));
          bidArray = this._context.new_var(PineJS.Std.close(this._context));
          this._context.select_sym(0);
          if (measureInput === "USD") {
            const close: number = PineJS.Std.close(this._context);
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0) * close;
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0) * close;
          } else {
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
          }
        } else if (
          sourceInput === "DOM by 1%" ||
          sourceInput === "DOM by 2%" ||
          sourceInput === "DOM by 3%" ||
          sourceInput === "DOM by 4%" ||
          sourceInput === "DOM by 5%"
        ) {
          this._context.select_sym(3);
          switch (sourceInput) {
            case `DOM by 1%`:
              ask = PineJS.Std.open(this._context);
              break;
            case `DOM by 2%`:
              ask = PineJS.Std.high(this._context);
              break;
            case `DOM by 3%`:
              ask = PineJS.Std.low(this._context);
              break;
            case `DOM by 4%`:
              ask = PineJS.Std.close(this._context);
              break;
            case `DOM by 5%`:
              ask = PineJS.Std.volume(this._context);
              break;
          }
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(4);
          switch (sourceInput) {
            case `DOM by 1%`:
              bid = PineJS.Std.open(this._context);
              break;
            case `DOM by 2%`:
              bid = PineJS.Std.high(this._context);
              break;
            case `DOM by 3%`:
              bid = PineJS.Std.low(this._context);
              break;
            case `DOM by 4%`:
              bid = PineJS.Std.close(this._context);
              break;
            case `DOM by 5%`:
              bid = PineJS.Std.volume(this._context);
              break;
          }
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          bidArray = this._context.new_var(bid);
          this._context.select_sym(0);
          if (measureInput === "USD") {
            const close: number = PineJS.Std.close(this._context);
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0) * close;
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0) * close;
          } else {
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
          }
        } else if (
          sourceInput === "DOM by 6%" ||
          sourceInput === "DOM by 7%" ||
          sourceInput === "DOM by 8%" ||
          sourceInput === "DOM by 9%" ||
          sourceInput === "DOM by 10%"
        ) {
          this._context.select_sym(5);
          switch (sourceInput) {
            case `DOM by 6%`:
              ask = PineJS.Std.open(this._context);
              break;
            case `DOM by 7%`:
              ask = PineJS.Std.high(this._context);
              break;
            case `DOM by 8%`:
              ask = PineJS.Std.low(this._context);
              break;
            case `DOM by 9%`:
              ask = PineJS.Std.close(this._context);
              break;
            case `DOM by 10%`:
              ask = PineJS.Std.volume(this._context);
              break;
          }
          askTimeArray = this._context.new_var(this._context.symbol.time);
          askArray = this._context.new_var(ask);
          this._context.select_sym(6);
          switch (sourceInput) {
            case `DOM by 6%`:
              bid = PineJS.Std.open(this._context);
              break;
            case `DOM by 7%`:
              bid = PineJS.Std.high(this._context);
              break;
            case `DOM by 8%`:
              bid = PineJS.Std.low(this._context);
              break;
            case `DOM by 9%`:
              bid = PineJS.Std.close(this._context);
              break;
            case `DOM by 10%`:
              bid = PineJS.Std.volume(this._context);
              break;
          }
          bidTimeArray = this._context.new_var(this._context.symbol.time);
          bidArray = this._context.new_var(bid);
          this._context.select_sym(0);
          if (measureInput === "USD") {
            const close: number = PineJS.Std.close(this._context);
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0) * close;
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0) * close;
          } else {
            ask = askArray.adopt(askTimeArray, mainSymbolTimeSeries, 0);
            bid = bidArray.adopt(bidTimeArray, mainSymbolTimeSeries, 0);
          }
        }

        //Calculation
        const bidAskDelta: number = bid - ask;

        let posDeltaMa: number = NaN;
        let negDeltaMa: number = NaN;
        let deltaStdev: number = NaN;
        if (metricsBoolInput) {
          const posDelta: number = bidAskDelta > 0 ? bidAskDelta : 0;
          const negDelta: number = bidAskDelta < 0 ? bidAskDelta : 0;
          const bidAskDeltaSeries = this._context.new_var(bidAskDelta);
          const posDeltaSeries = this._context.new_var(posDelta);
          const negDeltaSeries = this._context.new_var(negDelta);
          switch (maTypeInput) {
            case "SMA":
              posDeltaMa = PineJS.Std.sma(
                posDeltaSeries,
                lengthInput,
                this._context,
              );
              negDeltaMa = PineJS.Std.sma(
                negDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              posDeltaMa = PineJS.Std.ema(
                posDeltaSeries,
                lengthInput,
                this._context,
              );
              negDeltaMa = PineJS.Std.ema(
                negDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              posDeltaMa = PineJS.Std.wma(
                posDeltaSeries,
                lengthInput,
                this._context,
              );
              negDeltaMa = PineJS.Std.wma(
                negDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              posDeltaMa = PineJS.Std.vwma(
                posDeltaSeries,
                lengthInput,
                this._context,
              );
              negDeltaMa = PineJS.Std.vwma(
                negDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              posDeltaMa = PineJS.Std.linreg(posDeltaSeries, lengthInput, 0);
              negDeltaMa = PineJS.Std.linreg(negDeltaSeries, lengthInput, 0);
              break;
            default:
              posDeltaMa = PineJS.Std.sma(
                posDeltaSeries,
                lengthInput,
                this._context,
              );
              negDeltaMa = PineJS.Std.sma(
                negDeltaSeries,
                lengthInput,
                this._context,
              );
              break;
          }
          deltaStdev = PineJS.Std.stdev(
            bidAskDeltaSeries,
            lengthInput,
            this._context,
          );
        }

        //Returns
        return [
          0,
          bidAskDelta,
          bidAskDelta > 0 ? 1 : 0,
          negDeltaMa,
          posDeltaMa,
          deltaStdev,
        ];
      };
    },
  };
};
