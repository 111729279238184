//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const volalility = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Volatility",
    metainfo: {
      _metainfoVersion: 51,
      id: "volatility@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Volatility",
      shortDescription: "[SF] Volatility",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        { id: "dailyVolPlot", type: StudyPlotType.Line },
        { id: "weeklyVolPlot", type: StudyPlotType.Line },
        { id: "monthlyVolPlot", type: StudyPlotType.Line },
      ],
      defaults: {
        styles: {
          dailyVolPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          weeklyVolPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 160, 40)",
            transparency: 0,
          },
          monthlyVolPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(13, 157, 219)",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        dailyVolPlot: {
          title: "Daily volatility",
          histogramBase: 0,
        },
        weeklyVolPlot: {
          title: "Weekly volatility",
          histogramBase: 0,
        },
        monthlyVolPlot: {
          title: "Monthly volatility",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const dailySymbol: string = this._context.symbol.info.name;
        this._context.new_sym(dailySymbol, "D");

        const weekSymbol: string = this._context.symbol.info.name;
        this._context.new_sym(weekSymbol, "W");

        const monthSymbol: string = this._context.symbol.info.name;
        this._context.new_sym(monthSymbol, "M");
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Функция расчета волатильности
        const volatility = () => {
          const high: number = PineJS.Std.high(this._context);
          const low: number = PineJS.Std.low(this._context);
          const volatility: number = ((high - low) / low) * 100;
          return volatility;
        };

        // Расчет дневной волатильности
        this._context.select_sym(1);
        const dailyVolArray: any = this._context.new_var(volatility());
        const dailyTime: any = this._context.new_var(this._context.symbol.time);

        // Расчет недельной волатильности
        this._context.select_sym(2);
        const weeklyVolArray: any = this._context.new_var(volatility());
        const weeklyTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Расчет месячной волатильности
        this._context.select_sym(3);
        const monthlyVolArray: any = this._context.new_var(volatility());
        const monthlyTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Возвращаемся к основному таймфрейму
        this._context.select_sym(0);

        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        // Адаптируем значения к основному таймфрейму
        const dailyVol: number = dailyVolArray.adopt(
          dailyTime,
          mainSymbolTimeSeries,
          0,
        );
        const weeklyVol: number = weeklyVolArray.adopt(
          weeklyTime,
          mainSymbolTimeSeries,
          0,
        );
        const monthlyVol: number = monthlyVolArray.adopt(
          monthlyTime,
          mainSymbolTimeSeries,
          0,
        );

        return [dailyVol, weeklyVol, monthlyVol];
      };
    },
  };
};
