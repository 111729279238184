import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";
import {
  IDominationData,
  ISpotFuturesSpread,
} from "@/domain/interfaces/general.interface.ts";

export interface ITimeDominationResponse {
  timeFrame: string;
  timestamp: number;
  data: IDominationData[];
}

export function useTimeDomination() {
  const handler = useCallback(
    (_: IDominationData[], newData: ITimeDominationResponse) => {
      return newData.data;
    },
    [],
  );
  return useGenericSocket<IDominationData[], ITimeDominationResponse>(
    SocketTopics.timeDomination,
    handler,
  );
}
