//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const bollingerBands = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Bollinger bands",
    metainfo: {
      _metainfoVersion: 51,
      id: "bollinger-bands@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Bollinger bands",
      shortDescription: "[SF] BB",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "maPlot", type: StudyPlotType.Line },
        { id: "upperBandPlot", type: StudyPlotType.Line },
        { id: "lowerBandPlot", type: StudyPlotType.Line },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(60, 166, 75, 0.1)",
            visible: true,
            transparency: 20,
          },
        },
        styles: {
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 40,
          },
          upperBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          lowerBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          lengthInput: 240,
          stdDevsInput: 3.0,
        },
      },
      styles: {
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "stdDevsInput",
          name: "Sigma multiplier",
          defval: 3.0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const sourceInput: string = this._input(0);
        const maTypeInput: string = this._input(1);
        const lengthInput: number = this._input(2);
        const stDevNumberInput: number = this._input(3);

        //Market data parsing
        let sourceSeries: any;
        switch (sourceInput) {
          case "open":
            sourceSeries = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            break;
          case "high":
            sourceSeries = this._context.new_var(
              PineJS.Std.high(this._context),
            );
            break;
          case "low":
            sourceSeries = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
          case "hl2":
            sourceSeries = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            sourceSeries = this._context.new_var(
              PineJS.Std.hlc3(this._context),
            );
            break;
          case "ohlc4":
            sourceSeries = this._context.new_var(
              PineJS.Std.ohlc4(this._context),
            );
            break;
          default:
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
        }

        //Calculaionsz
        let ma: number = NaN;
        let oiTime: any,
          oiArray: any,
          mainSymbolTime: any,
          oi: any,
          oiSeries: any,
          source_x_oi: any,
          source_x_oiSeries: any,
          source_x_oiAvg: any,
          oiAvg: any;

        switch (maTypeInput) {
          case "SMA":
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
            break;
          case "EMA":
            ma = PineJS.Std.ema(sourceSeries, lengthInput, this._context);
            break;
          case "WMA":
            ma = PineJS.Std.wma(sourceSeries, lengthInput, this._context);
            break;
          case "VWMA":
            ma = PineJS.Std.vwma(sourceSeries, lengthInput, this._context);
            break;
          case "OIWMA":
            this._context.select_sym(1);
            oiTime = this._context.new_var(this._context.symbol.time);
            oiArray = this._context.new_var(PineJS.Std.close(this._context));
            this._context.select_sym(0);
            mainSymbolTime = this._context.new_var(this._context.symbol.time);
            oi = oiArray.adopt(oiTime, mainSymbolTime, 0);
            oiSeries = this._context.new_var(oi);
            source_x_oi = sourceSeries.get(0) * oi;
            source_x_oiSeries = this._context.new_var(source_x_oi);
            source_x_oiAvg =
              PineJS.Std.sum(source_x_oiSeries, lengthInput, this._context) /
              lengthInput;
            oiAvg =
              PineJS.Std.sum(oiSeries, lengthInput, this._context) /
              lengthInput;
            ma = source_x_oiAvg / oiAvg;
            break;
          case "LSMA":
            ma = PineJS.Std.linreg(sourceSeries, lengthInput, 0);
            break;
          default:
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
            break;
        }
        const stDev: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );

        const upperBand: number = ma + stDevNumberInput * stDev;
        const lowerBand: number = ma - stDevNumberInput * stDev;

        //Returns
        return [ma, upperBand, lowerBand];
      };
    },
  };
};
