import { useEffect, useState } from "react";
import { cn } from "@/lib/utils.ts";
import { isNil } from "lodash";
import numeral from "numeral";

interface HighlightedValueChangeProps {
  value: string | number;
  className?: string;
  highlightPosNeg?: boolean;
  highlightNewTextColor?: boolean;
  highlightNewClass?: string;
  decimal?: number;
  prefix?: string;
  suffix?: string;
  shorten?: boolean;
}

export function HighlightedValueChange({
  value,
  className,
  highlightPosNeg = true,
  highlightNewTextColor = true,
  highlightNewClass,
  decimal,
  prefix = "",
  suffix = "",
  shorten = false,
}: HighlightedValueChangeProps) {
  const val = (typeof value === "string" ? parseFloat(value) : value) || 0;
  const [highlightClass, setHighlightClass] = useState("");
  const [shadowClass, setShadowClass] = useState("");

  useEffect(() => {
    let newClass = "";

    if (highlightPosNeg) {
      if (!isNil(decimal) && Number(val?.toFixed(decimal)) === 0) {
        return;
      }

      newClass = val >= 0 ? "text-green-600" : "text-red-600";
    }

    setHighlightClass(newClass);
  }, [val, highlightPosNeg, decimal]);

  useEffect(() => {
    if (highlightNewTextColor || highlightNewClass) {
      setShadowClass(highlightNewClass ?? "highlight-shadow");
      const timer = setTimeout(() => {
        setShadowClass("");
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [val, highlightNewTextColor]);

  if (isNil(value)) {
    return "-";
  }

  const formatedValue = shorten
    ? numeral(val).format("0,0.00a").toUpperCase()
    : !isNil(decimal)
      ? parseFloat(val?.toFixed(decimal)).toFixed(decimal)
      : val?.toString();

  return (
    <span className={cn(highlightClass, shadowClass, className)}>
      {prefix}
      {formatedValue}
      {suffix}
    </span>
  );
}
