//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const natrVolalility = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Normalized average true range volalility",
    metainfo: {
      _metainfoVersion: 51,
      id: "natr-volalility@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Normalized average true range volalility",
      shortDescription: "[SF] NATR",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          lengthInput: 10,
        },
      },
      styles: {
        metricPlot: {
          title: "NATR",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const lengthInput: number = this._input(0);

        //Market data parsing
        const high: number = PineJS.Std.high(this._context);
        const low: number = PineJS.Std.low(this._context);
        const ohlc4: number = PineJS.Std.ohlc4(this._context);

        //Calculations
        const barRange: number = high - low;
        const barRangeSeries: any = this._context.new_var(barRange);
        const natr: number =
          (PineJS.Std.sum(barRangeSeries, lengthInput, this._context) /
            lengthInput /
            ohlc4) *
          100;

        //Return
        return [natr];
      };
    },
  };
};
