//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const rateOfChange = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Rate of change",
    metainfo: {
      _metainfoVersion: 51,
      id: "rate-of-change@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Rate of change",
      shortDescription: "[SF] ROC",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "rocPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
          rocPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "Price",
          lengthInput: 60,
        },
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        rocPlot: {
          title: "ROC",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Volume",
          options: ["Price", "Volume", "CVD", "Open interest"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 100,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const lengthInput: number = this._input(1);

        //Market data parsing
        let dataSource: number = 0.0;
        let dataSourceArray: any, dataSourceTimeArray: any;
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        switch (sourceInput) {
          case "Price":
            dataSource = PineJS.Std.hlc3(this._context);
            break;
          case "Volume":
            dataSource = PineJS.Std.volume(this._context);
            break;
          case "CVD":
            this._context.select_sym(1);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = PineJS.Std.cum(
              dataSourceArray.adopt(
                dataSourceTimeArray,
                mainSymbolTimeSeries,
                0,
              ) - PineJS.Std.volume(this._context),
              this._context,
            );
            break;
          case "Open interest":
            this._context.select_sym(2);
            dataSourceTimeArray = this._context.new_var(
              this._context.symbol.time,
            );
            dataSourceArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            this._context.select_sym(0);
            dataSource = dataSourceArray.adopt(
              dataSourceTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            break;
          default:
            dataSource = PineJS.Std.hlc3(this._context);
            break;
        }
        const dataSourceSeries: any = this._context.new_var(dataSource);

        //Calculations
        const roc: number =
          (100 * (dataSource - dataSourceSeries.get(lengthInput))) /
          dataSourceSeries.get(lengthInput);

        //Returns
        return [0, roc];
      };
    },
  };
};
