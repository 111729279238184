import { useDashboard } from "@/lib/store.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Button } from "@/components/ui/button.tsx";
import { FaColumns } from "react-icons/fa";
import { cn } from "@/lib/utils.ts";
import { FaCheck } from "react-icons/fa6";
import React from "react";
import { dynamicColumns } from "@/domain/constants.ts";

export function SelectColumns() {
  const { favoritesSettings, updateFavoritesSettings } = useDashboard(
    (state) => ({
      favoritesSettings: state.favoritesSettings,
      updateFavoritesSettings: state.updateFavoritesSettings,
    }),
  );

  const handleColumnToggle = (column: string) => {
    updateFavoritesSettings((state) => {
      if (state.activeColumns.includes(column as any)) {
        state.activeColumns = state.activeColumns.filter((c) => c !== column);
      } else {
        state.activeColumns.push(column as any);
      }
    });
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="xs">
          <FaColumns />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Choose Columns</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {Object.keys(dynamicColumns).map((key) => {
          const col = dynamicColumns[key];
          return col.availableTimeFrames ? (
            <DropdownMenuSub key={key}>
              <DropdownMenuSubTrigger>
                <span className={cn("h-full w-4", key)}>
                  {favoritesSettings.activeColumns.find(
                    (e) => e.split(".")[0] === (key as any),
                  ) && <FaCheck />}
                </span>
                <span>{col.title}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {col.availableTimeFrames.map((tf, i) => (
                    <DropdownMenuItem
                      key={key + tf}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleColumnToggle(`${key}.${tf}`);
                      }}
                    >
                      <span className={cn("h-full w-4", `${key}.${tf}`)}>
                        {favoritesSettings.activeColumns.includes(
                          `${key}.${tf}` as any,
                        ) && <FaCheck />}
                      </span>
                      <span>{col.timeFrameTitles?.[i]}</span>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          ) : (
            <DropdownMenuItem
              key={key}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleColumnToggle(key);
              }}
            >
              <span className="h-full w-4">
                {favoritesSettings.activeColumns.includes(key as any) && (
                  <FaCheck />
                )}
              </span>
              <span>{col.title}</span>
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
