import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config.ts";
import { getPairScreenerTopic } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface IPairScreenerItem {
  id: number;
  symbol: string;
  signalTime: string;
  baseName: string;
  quoteName: string;
  basePrice: number;
  quotePrice: number;
  pairPrice: number;
  maDev1: number;
  maDev2: number;
  sigma: number;
  zScore: number;
  pictureUrl: string;
  description: string;
  categoryId: number;
  pairCorr: boolean;
  createdAt: string;
  updatedAt: string;
  isNew?: boolean;
}

interface IPairScreenerResponse {
  signals: IPairScreenerItem[];
}

export function usePairs(tf: string = "1m") {
  const timeFrame = BINANCE_RESOLUTION_MAP[tf];

  const handler = useCallback(
    (oldData: IPairScreenerItem[] = [], newData: IPairScreenerItem) => {
      newData.symbol = `${newData.baseName}/${newData.quoteName}`;
      return [newData, ...(oldData ?? [])];
    },
    [],
  );
  return useGenericSocket<IPairScreenerItem[], IPairScreenerItem>(
    getPairScreenerTopic(timeFrame),
    handler,
    {
      apiUrl: `https://api.spreadscreener.com/api/signals?limit=30&timeFrame=${timeFrame}`,
      processInitialDataFunction: (data: IPairScreenerResponse) => {
        return data.signals.map((e) => {
          e.symbol = `${e.baseName}/${e.quoteName}`;
          return e;
        });
      },
      requestHeaders: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im1tYXZzeXNAZ21haWwuY29tIiwiaWQiOjY3MSwidGVsZWdyYW0iOiIiLCJkaXNjb3JkIjoiIiwicmVmZXJyYWwiOiIiLCJpc0FjdGl2YXRlZCI6dHJ1ZSwiaXNQYWlkIjp0cnVlLCJlbmREYXRlIjoiMjAyOC0xMi0wOVQxNzo0NDowMC4wMDBaIiwicm9sZXMiOlt7InZhbHVlIjoiVVNFUiIsImRlc2NyaXB0aW9uIjoiVXNlciJ9XSwic3Vic2NyaXB0aW9ucyI6W3sidmFsdWUiOiI1bSIsImRlc2NyaXB0aW9uIjoiU2lnbmFsIDVtIn0seyJ2YWx1ZSI6IjFoIiwiZGVzY3JpcHRpb24iOiJTaWduYWwgMWgifSx7InZhbHVlIjoiNGgiLCJkZXNjcmlwdGlvbiI6IlNpZ25hbCA0aCJ9LHsidmFsdWUiOiIxNW0iLCJkZXNjcmlwdGlvbiI6IlNpZ25hbCAxNW0ifSx7InZhbHVlIjoiMzBtIiwiZGVzY3JpcHRpb24iOiJTaWduYWwgMzBtIn0seyJ2YWx1ZSI6IjFtIiwiZGVzY3JpcHRpb24iOiJTaWduYWwgMW0ifSx7InZhbHVlIjoiM20iLCJkZXNjcmlwdGlvbiI6IlNpZ25hbCAzbSJ9LHsidmFsdWUiOiIyaCIsImRlc2NyaXB0aW9uIjoiU2lnbmFsIDJoIn1dLCJpYXQiOjE3MjA1NDY1MDMsImV4cCI6MTkwOTg0NzY1MX0.IRpxzmF2Yi7NQJpwUiFRJl_73NvGFJzn334B-_E-uYE",
      },
    },
  );
}
