import { cn } from "@/lib/utils.ts";

export function Loader({
  className,
  white = false,
}: {
  className?: string;
  white?: boolean;
}) {
  return (
    <div
      className={cn(
        "flex items-center justify-center h-full w-full",
        className,
      )}
    >
      <svg
        className="animate-spin -mt-1 max-h-14 max-w-14 h-full w-full"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 44 44"
      >
        <circle
          cx="22"
          cy="22"
          r="20"
          stroke={white ? "transparent" : "#0a0a0a"}
          strokeWidth="4"
        ></circle>
        <path
          fill={white ? "white" : "#ffffff"}
          d="M4 22a18 18 0 0118-18V0C9.373 0 0 9.373 0 22h4zm2 9.291A17.962 17.962 0 014 22H0c0 6.042 2.135 11.824 6 15.938l3-2.647z"
        ></path>
      </svg>
    </div>
  );
}
