//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const averageDayTradeVolume = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average day trade volume",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-day-trade-volume@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Average day trade volume",
      shortDescription: "[SF] ADTV",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "adtvPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          adtvPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          sideInput: "Total",
          measureInput: "USD",
        },
      },
      styles: {
        adtvPlot: {
          title: "ADTV",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sideInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const buyVolumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sideInput: string = this._input(0);
        const measureInput: string = this._input(1);

        //Market data parsing
        const volume: number =
          measureInput === "Coins"
            ? PineJS.Std.volume(this._context)
            : PineJS.Std.volume(this._context) * PineJS.Std.hlc3(this._context);

        this._context.select_sym(1);
        const buyVolumeSymbolTimeArray = this._context.new_var(
          this._context.symbol.time,
        );
        let buyVolumeSeriesArray;
        if (sideInput != "Total") {
          switch (measureInput) {
            case "Coins":
              buyVolumeSeriesArray = this._context.new_var(
                PineJS.Std.low(this._context),
              );
              break;
            case "USD":
              buyVolumeSeriesArray = this._context.new_var(
                PineJS.Std.close(this._context),
              );
              break;
          }
        }
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );

        const length: number = 1440 / Number(PineJS.Std.period(this._context));
        let source;
        switch (sideInput) {
          case "Total":
            source = volume;
            break;
          case "Buy":
            source = buyVolumeSeriesArray.adopt(
              buyVolumeSymbolTimeArray,
              mainSymbolTimeSeries,
              0,
            );
            break;
          case "Sell":
            source =
              volume -
              buyVolumeSeriesArray.adopt(
                buyVolumeSymbolTimeArray,
                mainSymbolTimeSeries,
                0,
              );
            break;
          case "Delta":
            source =
              2 *
                buyVolumeSeriesArray.adopt(
                  buyVolumeSymbolTimeArray,
                  mainSymbolTimeSeries,
                  0,
                ) -
              volume;
            break;
        }
        const sourceSeries = context.new_var(source);
        const adtv: number =
          PineJS.Std.sum(sourceSeries, length, this._context) / length;

        //Returns
        return [adtv];
      };
    },
  };
};
