//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { LineStudyPlotStyle, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

export const timeDomination = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Time Domination",
    metainfo: {
      _metainfoVersion: 51,
      id: "time-domination@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot futures time domination",
      shortDescription: "[SF] Time domination",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "mxs_future",
          type: StudyPlotType.Line,
        },
        {
          id: "mxs_spot",
          type: StudyPlotType.Line,
        },
        {
          id: "sfs_future",
          type: StudyPlotType.Line,
        },
        {
          id: "sfs_spot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},

        styles: {
          mxs_future: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(13, 157, 219, 1)",
          },
          mxs_spot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(255, 160, 40, 1)",
          },
          sfs_future: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(13, 157, 219, .25)",
          },
          sfs_spot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Area,
            trackPrice: false,
            color: "rgba(255, 160, 40, .25)",
          },
        },
        precision: 4,
        inputs: {},
      },
      styles: {
        mxs_future: {
          title: "Matrix futures",
          histogramBase: 0,
        },
        mxs_spot: {
          title: "Matrix spot",
          histogramBase: 0,
        },
        sfs_future: {
          title: "Futures",
          histogramBase: 0,
        },
        sfs_spot: {
          title: "Spot",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const mxs: string = `${this._context.symbol.info.name.split("USDT")[0]}#MATRIXSPREAD`;
        this._context.new_sym(mxs, PineJS.Std.period(this._context));
        const sfs: string = `${this._context.symbol.info.name.split("USDT")[0]}#SPOTFUTURESPREAD`;
        this._context.new_sym(sfs, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        this._context.select_sym(1);
        const mxsSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const mxsFutureSeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const mxsSpotSeries: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );

        this._context.select_sym(2);
        const sfsSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const sfsFutureSeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const sfsSpotSeries: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );

        const mxs_future: number = mxsFutureSeries.adopt(
          mxsSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );
        const mxs_spot: number = mxsSpotSeries.adopt(
          mxsSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );
        const sfs_future: number = sfsFutureSeries.adopt(
          sfsSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );
        const sfs_spot: number = sfsSpotSeries.adopt(
          sfsSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );

        return [mxs_future, mxs_spot, sfs_future, sfs_spot];
      };
    },
  };
};
