import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  IColumn,
  IRow,
  ISfTable,
} from "@/domain/interfaces/sfTable.interface.ts";
import { IStrategy } from "@/domain/interfaces/general.interface.ts";
import { useStrategies } from "@/hooks/useStrategies.ts";
import { PriceData, useBinancePrices } from "@/hooks/useBinancePrices.ts";
import dayjs from "dayjs";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

interface IStrategyExtended extends IStrategy {
  price: number;
  priceChangePrc: number;
  priceChange: number;
}

const columnHelper = createColumnHelper<IStrategyExtended>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("probability", {
    header: "WinRate",
    cell: (row) => {
      const value = row.getValue();
      return <div>{value}</div>;
    },
    size: 50,
    minSize: 20,
  }),

  columnHelper.accessor("price", {
    header: "P",
    cell: (row) => {
      const value = row.getValue();
      return <div>{value}</div>;
    },
    size: 50,
    minSize: 20,
  }),

  columnHelper.accessor("timestamp", {
    header: "Time",
    cell: (row) => {
      const value = row.getValue();
      return <div>{dayjs.utc(value).format("HH:mm:ss")}</div>;
    },
    size: 60,
    minSize: 20,
  }),
];

const rowClass: ISfTable<IRow<IStrategyExtended>>["rowClassCb"] = (row) =>
  cn({
    "border-neutral-500": true,
    "animate-pulse": row.timestamp > Date.now() - 10000,
    "bg-green-800": row.direction === "BUY",
    "bg-red-800": row.direction === "SELL",
  });

const prepareData = (
  strategies: IStrategy[],
  prices: Record<string, PriceData>,
) => {
  return strategies.map((item) => {
    return {
      ...item,
      winRate: item.probability.toFixed(2),
      price: prices[item.symbol]?.price ?? 0,
      priceChangePrc: prices[item.symbol]?.priceChangePrc ?? 0,
      priceChange: prices[item.symbol]?.priceChange ?? 0,
    };
  });
};

export function StrategiesPaneBody() {
  const { data: strategies, isLoading: isStrategiesLoading } = useStrategies();
  const { data: prices, isLoading: isPricesLoading } = useBinancePrices();
  const { blacklist } = useDashboard();

  const isLoading = isStrategiesLoading || isPricesLoading;

  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();

  const preparedData = useMemo(() => {
    if (!strategies || !prices) {
      return [];
    }

    let data = prepareData(strategies, prices);

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IStrategyExtended>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [config, prices, strategies]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={rowClass}
      />
    </>
  );
}
