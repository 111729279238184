import React, { useEffect, useRef } from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";

interface IMarketRatioProps {
  data: {
    min: number;
    value: number;
    max: number;
  };
  width?: number;
  height?: number;
  withTooltip?: boolean;
  withValue?: boolean;
}

export const MarketRatio: React.FC<IMarketRatioProps> = ({
  data,
  width = 60,
  height = 50,
  withTooltip = true,
  withValue = true,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const { min, value, max } = data;

    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Define the horizontal scale (scaleX)
    const range = Math.max(Math.abs(min), Math.abs(max)) * 2;
    const scaleX = width / range;

    // Calculate positions
    const zeroPosition = width / 2;
    const valuePosition = zeroPosition + value * scaleX;
    const minPosition = zeroPosition + min * scaleX;
    const maxPosition = zeroPosition + max * scaleX;

    // Draw the candle wick (line from min to max)
    ctx.beginPath();
    ctx.moveTo(minPosition, height / 2);
    ctx.lineTo(maxPosition, height / 2);
    ctx.strokeStyle = value >= 0 ? "#16a34a" : "#dc2626";
    ctx.lineWidth = 2;
    ctx.stroke();

    // Draw the candle body (rectangle from 0 to value)
    ctx.fillStyle = value >= 0 ? "#16a34a" : "#dc2626";
    if (value >= 0) {
      ctx.fillRect(
        zeroPosition,
        height / 4,
        valuePosition - zeroPosition,
        height / 2,
      );
    } else {
      ctx.fillRect(
        valuePosition,
        height / 4,
        zeroPosition - valuePosition,
        height / 2,
      );
    }
  }, [data, width, height]);

  const content = (
    <div className={"flex items-center justify-center"}>
      {withValue && (
        <HighlightedValueChange
          className="text-xs text-left pr-2"
          value={data.value}
          highlightNewTextColor={false}
          decimal={2}
        />
      )}
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  );

  return withTooltip ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>{content}</TooltipTrigger>
        <TooltipContent>
          <div className="text-left">
            <p>
              <HighlightedValueChange
                value={data.min}
                prefix="min: "
                highlightNewTextColor={false}
                highlightPosNeg={false}
                decimal={2}
              />
            </p>
            <p>
              <HighlightedValueChange
                value={data.value}
                prefix="value: "
                highlightNewTextColor={false}
                highlightPosNeg={false}
                decimal={2}
              />
            </p>
            <p>
              <HighlightedValueChange
                value={data.max}
                prefix="max: "
                highlightNewTextColor={false}
                highlightPosNeg={false}
                decimal={2}
              />
            </p>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    content
  );
};
