//eslint-disable-next-line
//@ts-nocheck

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@/lib/datafeed/tvTypes.ts";

export const averageDayTradeSpeed = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Average day trade speed",
    metainfo: {
      _metainfoVersion: 51,
      id: "average-day-trade-speed@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Average day trade speed",
      shortDescription: "[SF] ADTS",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "adtsPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          adtsPlot: {
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          sideInput: "Total",
        },
      },
      styles: {
        adtsPlot: {
          title: "ADTS",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sideInput",
          name: "Side",
          defval: "Total",
          options: ["Total", "Buy", "Sell", "Delta"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const buyVolumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sideInput: string = this._input(0);

        //Market data parsing
        const volume: number = PineJS.Std.volume(this._context);
        const volumeVolumeSeries = this._context.new_var(0);
        volumeVolumeSeries.set(volume);

        this._context.select_sym(1);
        const volumeSymbolTimeArray = this._context.new_var(
          this._context.symbol.time,
        );
        const orderflowIntesitySeriesArray = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const buyVolumeSeriesArray = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );

        const length: number = 1440 / Number(PineJS.Std.period(this._context));
        let source = orderflowIntesitySeriesArray.adopt(
          volumeSymbolTimeArray,
          mainSymbolTimeSeries,
          0,
        );

        switch (sideInput) {
          case "Total":
            break;
          case "Buy":
            source =
              (buyVolumeSeriesArray.adopt(
                volumeSymbolTimeArray,
                mainSymbolTimeSeries,
                0,
              ) /
                volume) *
              source;
            break;
          case "Sell":
            source =
              ((volume -
                buyVolumeSeriesArray.adopt(
                  volumeSymbolTimeArray,
                  mainSymbolTimeSeries,
                  0,
                )) /
                volume) *
              source;
            break;
          case "Delta":
            source =
              ((2 *
                buyVolumeSeriesArray.adopt(
                  volumeSymbolTimeArray,
                  mainSymbolTimeSeries,
                  0,
                ) -
                volume) /
                volume) *
              source;
            break;
        }
        const sourceSeries = context.new_var(source);

        const adtv: number =
          PineJS.Std.sum(sourceSeries, length, this._context) / 1440;

        //Returns
        return [adtv];
      };
    },
  };
};
