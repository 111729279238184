//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const spotFuturesSpread = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Spot futures spread",
    metainfo: {
      _metainfoVersion: 51,
      id: "spot-futures-spread@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot futures spread",
      shortDescription: "[SF] Spot fut spread",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "basisPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "upperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "spreadPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "spreadColorer",
          type: StudyPlotType.Colorer,
          target: "spreadPlot",
          palette: "spreadPalette",
        },
        {
          id: "lowerBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Background of the default range",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        spreadPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(120, 123, 134, 0.05)",
            visible: true,
            transparency: 5,
          },
        },
        palettes: {
          spreadPalette: {
            colors: {
              0: { color: "rgba(255, 160, 40, 0.5)", width: 1, style: 0 },
              1: { color: "rgba(13, 157, 219, 0.5)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(255, 160, 40, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(13, 157, 219, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          basisPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          upperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          spreadPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          spreadColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          lowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
        },
        precision: 2,
        inputs: {
          defaultValueInput: 0.2,
        },
      },
      styles: {
        basisPlot: {
          title: "Basis",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        spreadPlot: {
          title: "Spot fut spread",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        backgroundColorer: {
          title: "Background highlighter",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "defaultValueInput",
          name: "Default spread value",
          defval: 0.2,
          min: 0,
          max: 100,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const spotFutureSpreadSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_SPREAD`;
        this._context.new_sym(
          spotFutureSpreadSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const defaultValueInput: number = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const spotFuturesSpreadTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotFuturesSpreadArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotFuturesSpread: number = -spotFuturesSpreadArray.adopt(
          spotFuturesSpreadTime,
          mainSymbolTime,
          0,
        );
        const spreadColor: number = spotFuturesSpread > 0 ? 1 : 0;
        let backgroundColor: number = NaN;
        if (spotFuturesSpread > defaultValueInput) {
          backgroundColor = 1;
        } else if (spotFuturesSpread < -defaultValueInput) {
          backgroundColor = 0;
        }

        //Returns
        return [
          0,
          defaultValueInput,
          spotFuturesSpread,
          spreadColor,
          -defaultValueInput,
          backgroundColor,
        ];
      };
    },
  };
};
