//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

export const bestAskAndBidSize = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Best ask & bid size",
    metainfo: {
      _metainfoVersion: 51,
      id: "best-ask-and-bid-size@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Best ask & bid size",
      shortDescription: "[SF] Best ask & bid size",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },

      plots: [
        {
          id: "bestAskPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bestBidPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {},
      defaults: {
        palettes: {},
        styles: {
          bestAskPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          bestBidPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
            transparency: 0,
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
        },
      },
      styles: {
        bestAskPlot: {
          title: "Ask",
          histogramBase: 0,
        },
        bestBidPlot: {
          title: "Bid",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const orderbookSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#BIDASKQUOTEDSPREAD`;
        this._context.new_sym(
          orderbookSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const measureInput: string = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const orderbookTime = this._context.new_var(this._context.symbol.time);
        const bestAskArray = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const bestBidArray = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        let ask;
        let bid;
        if (measureInput === "USD") {
          const close: number = PineJS.Std.close(this._context);
          ask =
            bestAskArray.adopt(orderbookTime, mainSymbolTimeSeries, 0) * close;
          bid =
            -bestBidArray.adopt(orderbookTime, mainSymbolTimeSeries, 0) * close;
        } else {
          ask = bestAskArray.adopt(orderbookTime, mainSymbolTimeSeries, 0);
          bid = -bestBidArray.adopt(orderbookTime, mainSymbolTimeSeries, 0);
        }

        //Returns
        return [ask, bid];
      };
    },
  };
};
