//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const cumulativeVolumeDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Cumulative volume delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "cumulative-volume-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Cumulative volume delta",
      shortDescription: "[SF] CVD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "cvdOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "cvdOhlcPlotcandle",
        },
        {
          id: "cvdMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cvdUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cvdLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CVD" },
            1: { name: "Growing CVD" },
          },
        },
      },
      ohlcPlots: {
        cvdOhlcPlotcandle: {
          title: "CVD plot candle",
        },
      },
      defaults: {
        styles: {
          cvdMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cvdUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cvdLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          cvdOhlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
          calculationMethodInput: "Cum",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        cvdMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        cvdUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        cvdLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "calculationMethodInput",
          name: "Calculation method",
          defval: "Cum",
          options: ["Cum", "Sum"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const volumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(volumeSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const measureInput: string = this._input(0);
        const calculationMethodInput: string = this._input(1);
        const maType: string = this._input(2);
        const length: number = this._input(3);
        const chanelType: string = this._input(4);
        const metricsBoolInput: boolean = this._input(5);

        //Market data parsing
        const volume: number =
          measureInput === "Coins"
            ? PineJS.Std.volume(this._context)
            : PineJS.Std.volume(this._context) *
              PineJS.Std.close(this._context);

        this._context.select_sym(1);
        const volumeTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        let volumeArray: any = this._context.new_var(0.0);
        switch (measureInput) {
          case "Coins":
            volumeArray = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "USD":
            volumeArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
          default:
            volumeArray = this._context.new_var(PineJS.Std.low(this._context));
        }
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolume: number = volumeArray.adopt(
          volumeTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        const volumeDelta: number = 2 * buyVolume - volume;
        const volumeDeltaSeries: any = this._context.new_var(volumeDelta);
        const volumeDeltaLength: number =
          1440 / Number(PineJS.Std.period(this._context));

        //Calculations
        let cvdClose: number = 0.0;
        switch (calculationMethodInput) {
          case "Cum":
            cvdClose = PineJS.Std.cum(volumeDelta, this._context);
            break;
          case "Sum":
            cvdClose = PineJS.Std.sum(
              volumeDeltaSeries,
              volumeDeltaLength,
              this._context,
            );
            break;
          default:
            cvdClose = PineJS.Std.cum(volumeDelta, this._context);
            break;
        }

        const cvdCloseSeries: any = this._context.new_var(cvdClose);
        const cvdOpen: number = cvdCloseSeries.get(1);
        const cvdHigh: number = PineJS.Std.max(cvdOpen, cvdClose);
        const cvdHighSeries = this._context.new_var(cvdHigh);
        const cvdLow: number = PineJS.Std.min(cvdOpen, cvdClose);
        const cvdLowSeries = this._context.new_var(cvdLow);
        const cvdColor: number = cvdClose > cvdOpen ? 1 : 0;

        //Derivative metrics
        let cvdStdev: number = NaN;
        let cvdMa: number = NaN;
        let cvdUpperBand: number = NaN;
        let cvdLowerBand: number = NaN;
        if (metricsBoolInput) {
          switch (maType) {
            case "SMA":
              cvdMa = PineJS.Std.sma(cvdCloseSeries, length, this._context);
              break;
            case "EMA":
              cvdMa = PineJS.Std.ema(cvdCloseSeries, length, this._context);
              break;
            case "WMA":
              cvdMa = PineJS.Std.wma(cvdCloseSeries, length, this._context);
              break;
            case "VWMA":
              cvdMa = PineJS.Std.vwma(cvdCloseSeries, length, this._context);
              break;
            case "LSMA":
              cvdMa = PineJS.Std.linreg(cvdCloseSeries, length, 0);
              break;
            default:
              cvdMa = PineJS.Std.sma(cvdCloseSeries, length, this._context);
              break;
          }
          switch (chanelType) {
            case "Extremum channel":
              cvdUpperBand = PineJS.Std.highest(
                cvdHighSeries,
                length,
                this._context,
              );
              cvdLowerBand = PineJS.Std.lowest(
                cvdLowSeries,
                length,
                this._context,
              );
              break;
            case "Bollinger channel":
              cvdStdev = PineJS.Std.stdev(
                cvdCloseSeries,
                length,
                this._context,
              );
              cvdUpperBand = cvdMa + cvdStdev;
              cvdLowerBand = cvdMa - cvdStdev;
              break;
            default:
              cvdUpperBand = PineJS.Std.highest(
                cvdHighSeries,
                length,
                this._context,
              );
              cvdLowerBand = PineJS.Std.lowest(
                cvdLowSeries,
                length,
                this._context,
              );
              break;
          }
        }

        //Returns
        return [
          cvdOpen,
          cvdHigh,
          cvdLow,
          cvdClose,
          cvdColor,
          cvdColor,
          cvdColor,
          cvdMa,
          cvdUpperBand,
          cvdLowerBand,
        ];
      };
    },
  };
};
