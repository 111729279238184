//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const augmentedDickeyFullerTest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Augmented Dickey–Fuller test",
    metainfo: {
      _metainfoVersion: 51,
      id: "augmented-dickey-fuller-test@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Augmented Dickey–Fuller test",
      shortDescription: "[SF] ADF test",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "criticalPlot", type: StudyPlotType.Line },
        { id: "tauadfPlot", type: StudyPlotType.Line },
        {
          id: "tauadfColorer",
          type: StudyPlotType.Colorer,
          target: "tauadfPlot",
          palette: "metricPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Not stationary" },
            1: { name: "Stationary" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          criticalPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
          tauadfColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: true,
            color: "rgb(149, 152, 161)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          lookbackInput: 240,
          nlagInput: 0,
          confidenceInput: "90%",
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        criticalPlot: {
          title: "Critical value",
          histogramBase: 0,
        },
        tauadfPlot: {
          title: "Test statistic",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "lookbackInput",
          name: "Lookback",
          defval: 240,
          min: 2,
          type: StudyInputType.Integer,
        },
        {
          id: "nlagInput",
          name: "Maximum lag",
          defval: 0,
          min: 0,
          type: StudyInputType.Integer,
        },
        {
          id: "confidenceInput",
          name: "Confidence level",
          defval: "90%",
          options: ["90%", "95%", "99%"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };
      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Function import
        function matrixGet(
          A: number[],
          i: number,
          j: number,
          nRows: number,
        ): number {
          return A[i + nRows * j];
        }

        function matrixSet(
          A: number[],
          value: number,
          i: number,
          j: number,
          nRows: number,
        ): number[] {
          A[i + nRows * j] = value;
          return A;
        }

        function transpose(
          A: number[],
          nRows: number,
          nColumns: number,
        ): number[] {
          const at: number[] = Array(nRows * nColumns).fill(0);
          for (let i: number = 0; i < nRows; i++) {
            for (let j: number = 0; j < nColumns; j++) {
              matrixSet(at, matrixGet(A, i, j, nRows), j, i, nColumns);
            }
          }
          return at;
        }

        function multiply(
          A: number[],
          B: number[],
          nRowsA: number,
          nColumnsA: number,
          nColumnsB: number,
        ): number[] {
          const C: number[] = Array(nRowsA * nColumnsB).fill(0);
          const nRowsB: number = nColumnsA;
          for (let i: number = 0; i < nRowsA; i++) {
            for (let j: number = 0; j < nColumnsB; j++) {
              let elementC: number = 0;
              for (let k: number = 0; k < nColumnsA; k++) {
                elementC +=
                  matrixGet(A, i, k, nRowsA) * matrixGet(B, k, j, nRowsB);
              }
              matrixSet(C, elementC, i, j, nRowsA);
            }
          }
          return C;
        }

        function vNorm(x: number[]): number {
          const norm: number = x.reduce(
            (sum, val) => sum + Math.pow(val, 2),
            0,
          );
          return Math.sqrt(norm);
        }

        function qrDiag(
          A: number[],
          nRows: number,
          nColumns: number,
        ): [number[], number[]] {
          const Q: number[] = Array(nRows * nColumns).fill(0);
          const R: number[] = Array(nColumns * nColumns).fill(0);
          const a: number[] = Array(nRows).fill(0);

          for (let i: number = 0; i < nRows; i++) {
            a[i] = matrixGet(A, i, 0, nRows);
          }
          let r: number = vNorm(a);
          matrixSet(R, r, 0, 0, nColumns);
          for (let i: number = 0; i < nRows; i++) {
            matrixSet(Q, a[i] / r, i, 0, nRows);
          }
          if (nColumns !== 1) {
            for (let k: number = 1; k < nColumns; k++) {
              for (let i: number = 0; i < nRows; i++) {
                a[i] = matrixGet(A, i, k, nRows);
              }
              for (let j: number = 0; j < k; j++) {
                r = 0;
                for (let i: number = 0; i < nRows; i++) {
                  r += matrixGet(Q, i, j, nRows) * a[i];
                }
                matrixSet(R, r, j, k, nColumns);
                for (let i: number = 0; i < nRows; i++) {
                  a[i] -= r * matrixGet(Q, i, j, nRows);
                }
              }
              r = vNorm(a);
              matrixSet(R, r, k, k, nColumns);
              for (let i: number = 0; i < nRows; i++) {
                matrixSet(Q, a[i] / r, i, k, nRows);
              }
            }
          }
          return [Q, R];
        }

        function pInv(A: number[], nRows: number, nColumns: number): number[] {
          const [Q, R] = qrDiag(A, nRows, nColumns);
          const QT: number[] = transpose(Q, nRows, nColumns);

          const rinv: number[] = Array(nColumns * nColumns).fill(0);
          matrixSet(rinv, 1 / matrixGet(R, 0, 0, nColumns), 0, 0, nColumns);

          if (nColumns !== 1) {
            for (let j: number = 1; j < nColumns; j++) {
              for (let i: number = 0; i < j; i++) {
                let r = 0;
                for (let k: number = i; k < j; k++) {
                  r +=
                    matrixGet(rinv, i, k, nColumns) *
                    matrixGet(R, k, j, nColumns);
                }
                matrixSet(rinv, r, i, j, nColumns);
              }
              for (let k: number = 0; k < j; k++) {
                matrixSet(
                  rinv,
                  -matrixGet(rinv, k, j, nColumns) /
                    matrixGet(R, j, j, nColumns),
                  k,
                  j,
                  nColumns,
                );
              }
              matrixSet(rinv, 1 / matrixGet(R, j, j, nColumns), j, j, nColumns);
            }
          }

          const aInv: number[] = multiply(rinv, QT, nColumns, nColumns, nRows);
          return aInv;
        }

        function adfTest(
          a: number[],
          nLag: number,
          confidence: string,
        ): [number, number] {
          if (nLag >= a.length / 2 - 2) {
            throw new Error(
              "ADF: Maximum lag must be less than (Length / 2 - 2)",
            );
          }

          const nobs: number = a.length - nLag - 1;
          const y: number[] = [];
          const x: number[] = [];
          const x0: number[] = [];
          for (let i: number = 0; i < nobs; i++) {
            y.push(a[i] - a[i + 1]);
            x.push(a[i + 1]);
            x0.push(1.0);
          }

          let X: number[] = [...x];
          let M: number = 2;
          X = X.concat(x0);

          if (nLag > 0) {
            for (let n: number = 1; n <= nLag; n++) {
              const xl: number[] = [];
              for (let i: number = 0; i < nobs; i++) {
                xl.push(a[i + n] - a[i + n + 1]);
              }
              X = X.concat(xl);
              M += 1;
            }
          }

          const c: number[] = pInv(X, nobs, M);
          const coeff: number[] = multiply(c, y, M, nobs, 1);
          const yHat: number[] = multiply(X, coeff, nobs, M, 1);
          const meanX: number = x.reduce((sum, val) => sum + val, 0) / x.length;

          let sum1: number = 0;
          let sum2: number = 0;
          for (let i: number = 0; i < nobs; i++) {
            sum1 += Math.pow(y[i] - yHat[i], 2) / (nobs - M);
            sum2 += Math.pow(x[i] - meanX, 2);
          }

          const se: number = Math.sqrt(sum1 / sum2);
          const adf: number = coeff[0] / se;

          let crit: number = 0;
          switch (confidence) {
            case "90%":
              crit = -2.56677 - 1.5384 / nobs - 2.809 / (nobs * nobs);
              break;
            case "95%":
              crit =
                -2.86154 -
                2.8903 / nobs -
                4.234 / (nobs * nobs) -
                40.04 / (nobs * nobs * nobs);
              break;
            case "99%":
              crit =
                -3.43035 -
                6.5393 / nobs -
                16.786 / (nobs * nobs) -
                79.433 / (nobs * nobs * nobs);
              break;
          }
          return [adf, crit];
        }

        //User input
        const sourceInput: string = this._input(0);
        const lookbackInput: number = this._input(1);
        const nlagInput: number = this._input(2);
        const confidenceInput: string = this._input(3);

        //Market data parsing
        let sourceSeries;
        switch (sourceInput) {
          case "open":
            sourceSeries = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            break;
          case "high":
            sourceSeries = this._context.new_var(
              PineJS.Std.high(this._context),
            );
            break;
          case "low":
            sourceSeries = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
          case "hl2":
            sourceSeries = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            sourceSeries = this._context.new_var(
              PineJS.Std.hlc3(this._context),
            );
            break;
          case "ohlc4":
            sourceSeries = this._context.new_var(
              PineJS.Std.ohlc4(this._context),
            );
            break;
          default:
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
        }

        //Calculations
        const a: number[] = [];
        for (let i: number = 0; i < lookbackInput; i++) {
          a.push(sourceSeries.get(i));
        }
        const [tauADF, crit] = adfTest(a, nlagInput, confidenceInput);
        const tauColor: number = tauADF > crit ? 0 : 1;

        //Returns
        return [0, crit, tauADF, tauColor, tauColor];
      };
    },
  };
};
