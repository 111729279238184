// eslint-disable-next-line
// @ts-nocheck

// import {
//   CustomIndicator,
//   PineJS,
//   RawStudyMetaInfoId,
//   StudyTargetPriceScale,
// } from "@tradingView/charting_library";

import {
  CustomIndicator,
  LineStudyPlotStyle,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const agarchVolatilityEstimation = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "AGARCH volatility estimation",
    metainfo: {
      _metainfoVersion: 51,
      id: "agarch-volatility-estimation@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] AGARCH volatility estimation",
      shortDescription: "[SF] AGARCH vol",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          sourceInput: "close",
          alphaInput: 0.1,
          betaInput: 0.8,
          lengthInput: 10,
          measureInput: "Percent",
        },
      },
      styles: {
        metricPlot: {
          title: "AGARCH vol",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "alphaInput",
          name: "Alpha",
          defval: 0.1,
          min: 0.1,
          max: 100,
          step: 0.1,
          type: StudyInputType.Float,
        },
        {
          id: "betaInput",
          name: "Beta",
          defval: 0.8,
          min: 0.1,
          max: 100,
          step: 0.1,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Percent",
          options: ["USD", "Percent"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const alphaInput: number = this._input(1);
        const betaInput: number = this._input(2);
        const lengthInput: number = this._input(3);
        const measureInput: string = this._input(4);

        //Market data parsing
        let source;
        switch (sourceInput) {
          case "open":
            source = PineJS.Std.open(this._context);
            break;
          case "high":
            source = PineJS.Std.high(this._context);
            break;
          case "low":
            source = PineJS.Std.low(this._context);
            break;
          case "close":
            source = PineJS.Std.close(this._context);
            break;
          case "hl2":
            source = PineJS.Std.hl2(this._context);
            break;
          case "hlc3":
            source = PineJS.Std.hlc3(this._context);
            break;
          case "ohlc4":
            source = PineJS.Std.ohlc4(this._context);
            break;
          default:
            source = PineJS.Std.close(this._context);
            break;
        }
        const sourceSeries = this._context.new_var(source);

        //Calculation
        const tolerance: number = 0.0001;
        const ema: number = PineJS.Std.ema(
          sourceSeries,
          lengthInput,
          this._context,
        );
        const returns: number = source - ema;
        const returnsSeries = this._context.new_var(returns);
        const returnsStdev: number = PineJS.Std.stdev(
          returnsSeries,
          lengthInput,
          this._context,
        );
        const negReturns: number = returns < 0 ? returns : 0;
        const negReturnsSeries = this._context.new_var(negReturns);
        const negReturnsStdev: number = PineJS.Std.stdev(
          negReturnsSeries,
          lengthInput,
          this._context,
        );
        const gamma: number = negReturnsStdev / returnsStdev;

        let variance: number = 0.0;
        const varianceSeries = this._context.new_var(variance);
        let alpha;
        let beta;
        for (let i = 1; i <= 100; i++) {
          const newsImpact: number = source < ema ? gamma : 0;
          variance =
            (alphaInput + newsImpact) * Math.pow(source - ema, 2) +
            betaInput * PineJS.Std.nz(varianceSeries.get(1));
          alpha = variance / Math.pow(source - ema, 2);
          const alphaSeries = this._context.new_var(alpha);
          beta = variance / PineJS.Std.nz(varianceSeries.get(1));
          const betaSeries = this._context.new_var(beta);
          if (
            Math.abs(alpha - PineJS.Std.nz(alphaSeries.get(1))) < tolerance &&
            Math.abs(beta - PineJS.Std.nz(betaSeries.get(1))) < tolerance
          ) {
            break;
          }
        }
        let volalility: number = PineJS.Std.sqrt(variance);
        if (measureInput === "Percent") {
          volalility /= ema / 100;
        }

        //Return
        return [volalility];
      };
    },
  };
};
