import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import { cn } from "@/lib/utils";

interface IAltSelectProps {
  onChange: (value: string) => void;
  triggerText?: string;
  trigger?: ReactNode;
  searchText: string;
  emptyText: string;
  defaultTriggerClassName?: string;
  options: { label: string; value: string; bgColor?: string }[];
  closeOnSelect?: boolean;
  allowCustomInput?: boolean;
}

export function AltSelect({
  onChange,
  triggerText,
  trigger,
  searchText,
  emptyText,
  options,
  closeOnSelect = true,
  defaultTriggerClassName,
  allowCustomInput = true,
}: IAltSelectProps) {
  const [open, setOpen] = useState(false);
  const defaultValue =
    options.find((e) => e.label === triggerText)?.value ?? null;
  const [selectedValue, setSelectedValue] = useState<string | null>(
    defaultValue,
  );
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const onChangeHandler = (value: string) => {
    setSelectedValue(value);
    onChange(value.toUpperCase());
    if (closeOnSelect) {
      setOpen(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && allowCustomInput) {
      e.preventDefault();
      setSelectedValue(inputValue);
      onChange(inputValue.toUpperCase());
      setOpen(false);
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {trigger ? (
          <div aria-expanded={open} role="combobox">
            {trigger}
          </div>
        ) : (
          <Button
            variant="outline"
            role="combobox"
            size="xs"
            aria-expanded={open}
            className={cn("justify-between w-full", defaultTriggerClassName)}
          >
            {selectedValue || triggerText}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput
            ref={inputRef}
            placeholder={searchText}
            className="text-xs p-0.5 h-8"
            value={inputValue}
            onValueChange={setInputValue}
            onKeyDown={handleKeyDown}
          />
          <CommandList>
            <CommandEmpty>{emptyText}</CommandEmpty>
            <CommandGroup>
              {options?.map((opt) => (
                <CommandItem
                  key={opt.value}
                  value={opt.value}
                  className="flex mb-0.5 cursor-pointer text-xs h-8"
                  style={{
                    backgroundColor: opt.bgColor,
                  }}
                  onSelect={() => onChangeHandler(opt.value)}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedValue === opt.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {opt.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
