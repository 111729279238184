//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const marketPowerHighlighter = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market power Highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-power-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Market power highlighter",
      shortDescription: "[SF] Market power highlighter",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "10%" },
            { name: "20%" },
            { name: "30%" },
            { name: "40%" },
            { name: "40%" },
            { name: "50%" },
            { name: "60%" },
            { name: "70%" },
            { name: "80%" },
            { name: "90%" },
            { name: "100%" },
            { name: "0%" },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
            8: 8,
            9: 9,
            10: 10,
            11: 11,
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(38,164,82,0.1)", width: 1, style: 0 },
              1: { color: "rgba(38,164,82,0.15)", width: 1, style: 0 },
              2: { color: "rgba(38,164,82,0.2)", width: 1, style: 0 },
              3: { color: "rgba(38,164,82,0.3)", width: 1, style: 0 },
              4: { color: "rgba(38,164,82,0.4)", width: 1, style: 0 },
              5: { color: "rgba(38,164,82,0.5)", width: 1, style: 0 },
              6: { color: "rgba(38,164,82,0.6)", width: 1, style: 0 },
              7: { color: "rgba(38,164,82,0.7)", width: 1, style: 0 },
              8: { color: "rgba(38,164,82,0.8)", width: 1, style: 0 },
              9: { color: "rgba(38,164,82,0.9)", width: 1, style: 0 },
              10: { color: "rgba(38,164,82,1)", width: 1, style: 0 },
              11: { color: "rgba(38,164,82,0)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 3,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0)",
          },
        },
        inputs: {},
      },
      styles: {
        backgroundPlot: {
          title: "Transaction volume",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_MARKET_POWER`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const val: number = firstSeriesCloseSeries.adopt(
          firstSymbolTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        let color: number = 0;
        switch (true) {
          case Math.abs(val) > 15:
            color = 10;
            break;
          case Math.abs(val) > 14:
            color = 9;
            break;
          case Math.abs(val) > 13:
            color = 8;
            break;
          case Math.abs(val) > 12:
            color = 7;
            break;
          case Math.abs(val) > 11:
            color = 6;
            break;
          case Math.abs(val) > 10:
            color = 5;
            break;
          case Math.abs(val) > 9:
            color = 4;
            break;
          case Math.abs(val) > 8:
            color = 3;
            break;
          case Math.abs(val) > 7:
            color = 2;
            break;
          case Math.abs(val) > 6:
            color = 1;
            break;
          case Math.abs(val) > 5:
            color = 0;
            break;
          default:
            color = 11;
        }
        return [color];
      };
    },
  };
};
