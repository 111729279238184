//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

enum PlotSymbolSize {
  Auto = "auto",
  Tiny = "tiny",
  Small = "small",
  Normal = "normal",
  Large = "large",
  Huge = "huge",
}

enum MarkLocation {
  AboveBar = "AboveBar",
  BelowBar = "BelowBar",
  Top = "Top",
  Bottom = "Bottom",
  Right = "Right",
  Left = "Left",
  Absolute = "Absolute",
  AbsoluteUp = "AbsoluteUp",
  AbsoluteDown = "AbsoluteDown",
}

export const liveStrategies = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Strategy",
    metainfo: {
      _metainfoVersion: 51,
      id: "live-strategies@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Live strategies",
      shortDescription: "[SM] Live strategies",
      is_hidden_study: false,
      is_price_study: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "sellShapePlot",
          type: StudyPlotType.Shapes,
        },
        {
          id: "sellPricePlot",
          type: StudyPlotType.Shapes,
        },
        {
          id: "buyShapePlot",
          type: StudyPlotType.Shapes,
        },
        {
          id: "buyPricePlot",
          type: StudyPlotType.Shapes,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [{ name: "Sell signal" }, { name: "Buy signal" }],
          valToIndex: {
            0: 0,
            1: 1,
          },
        },
      },
      filledAreas: [],
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(178, 24, 44, 0.25)" },
              { color: "rgba(60, 166, 75, 0.25)" },
            ],
          },
        },
        styles: {
          sellShapePlot: {
            location: MarkLocation.AboveBar,
            visible: true,
            plottype: "shape_label_down",
            textColor: "rgba(178, 24, 44, 0.75)",
            color: "rgba(178, 24, 44, 0.75)",
          },
          sellPricePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_circle",
            textColor: "rgba(178, 24, 44, 0.75)",
            color: "rgba(178, 24, 44, 0.75)",
          },
          buyShapePlot: {
            location: MarkLocation.BelowBar,
            visible: true,
            plottype: "shape_label_up",
            textColor: "rgba(60, 166, 75, 0.75)",
            color: "rgba(60, 166, 75, 0.75)",
          },
          buyPricePlot: {
            location: MarkLocation.Absolute,
            visible: true,
            plottype: "shape_circle",
            textColor: "rgba(60, 166, 75, 0.75)",
            color: "rgba(60, 166, 75, 0.75)",
          },
        },
        precision: 2,
        inputs: {
          probabilityInput: 60,
        },
      },
      styles: {
        sellShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Normal,
          title: "Sell signal",
        },
        sellPricePlot: {
          isHidden: false,
          size: PlotSymbolSize.Tiny,
          title: "Sell signal",
        },
        buyShapePlot: {
          isHidden: false,
          size: PlotSymbolSize.Normal,
          title: "Buy signal",
        },
        buyPricePlot: {
          isHidden: false,
          size: PlotSymbolSize.Tiny,
          title: "Buy signal",
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "probabilityInput",
          name: "Probability",
          defval: 50,
          min: 0,
          max: 100,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#STRATEGY`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Input
        const probabilityInput: number = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const strategyTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const probabilityArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const directionArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const priceArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const probability: number = probabilityArray.adopt(
          strategyTime,
          mainSymbolTime,
          1,
        );
        const direction: number = directionArray.adopt(
          strategyTime,
          mainSymbolTime,
          1,
        );
        const price: number = priceArray.adopt(strategyTime, mainSymbolTime, 1);

        //Calculations
        let sellSignal: number = NaN;
        let buySignal: number = NaN;
        let bgcolor: number = NaN;
        let sellSignalPrice: number = NaN;
        let buySignalPrice: number = NaN;
        if (probability > probabilityInput) {
          //Side calculations
          if (direction === 0) {
            const high: number = PineJS.Std.high(this._context);
            sellSignal = high;
            bgcolor = 0;
            sellSignalPrice = price === 0 ? high : price;
          } else if (direction === 1) {
            const low: number = PineJS.Std.low(this._context);
            buySignal = PineJS.Std.low(this._context);
            bgcolor = 1;
            buySignalPrice = price === 0 ? low : price;
          }
        }

        return [
          sellSignal,
          sellSignalPrice,
          buySignal,
          buySignalPrice,
          bgcolor,
        ];
      };
    },
  };
};
