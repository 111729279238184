import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface ICryptoDriftResponse {
  data: ICryptoDrift[];
  timestamp: number;
}

export interface ICryptoDrift {
  symbol: string;
  value: number;
}

export function useCryptoDrift() {
  const handler = useCallback(
    (_: ICryptoDriftResponse, newData: ICryptoDriftResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<ICryptoDriftResponse>(
    SocketTopics.cryptoDrift,
    handler,
  );
}
