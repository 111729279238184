import {
  IdeasWsResponse,
  IGenericSpreadResponse,
  IManipulationStream,
  IPowerDeltaStream,
  ISpoofLayerResponse,
  ISpotFuturesSpreadResponse,
  IStopKiller,
  IStrategy,
  MarketPower,
  PumpTrend,
  RetailPower,
} from "@/domain/interfaces/general.interface.ts";
import {
  IActivityDetector,
  IActivityDetectorResponse,
} from "@/hooks/useActivityDetector";
import { ICryptoDriftResponse } from "@/hooks/useCryptoDrift";
import {
  IBinanceKline,
  IBybitWsKlineEvent,
  INatsBinanceKline,
} from "@/lib/datafeed/binanceTypes.ts";
import {
  BINANCE_RESOLUTION_MAP,
  BINANCE_RESOLUTION_TO_MINUTES,
  EXCHANGES,
} from "@/lib/datafeed/config.ts";
import { prepareFormula } from "@/lib/datafeed/helpers.ts";
import { IExtendedLibrarySymbolInfo } from "@/lib/datafeed/resolveSymbol.ts";
import {
  IAskBidSum,
  IBidAskChange,
  IBidAskSpread,
  IHighSize,
  ILiquidationWSResponse,
  ITrendAssessmentData,
  ITrendAssessmentResponse,
} from "@/lib/datafeed/utils.ts";
import {
  getBybitKlineTopic,
  getDepthChangeTopic,
  getDepthHighSizeTopic,
  getDepthSpreadTopic,
  getDepthSummaryTopic,
  getKlineTopic,
  getLiquidationTopic,
  getNetOIDeltaTopic,
  getNetOISpikeTopic,
  getOITopic,
  getPowerTradesTopic,
  socketConnection,
  SocketTopics,
} from "@/lib/natsConnection.ts";

import {
  Bar,
  IDatafeedChartApi,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
} from "@tradingView/charting_library";
import Formula from "fparser";
import { now } from "lodash";
import { v4 as uuidv4 } from "uuid";

type OpenInterestSubscription = {
  openInterest: string;
  symbol: string;
  time: number;
};

const subscriptionsTopics: Record<string, string> = {};
const unSubscriptions: Record<string, () => void> = {};
const getTradingSymbol = (symbolInfo: LibrarySymbolInfo) => {
  let tradingSymbol = symbolInfo.full_name.split("#")[0];
  if (!tradingSymbol.endsWith("USDT") && !tradingSymbol.endsWith("BUSD")) {
    tradingSymbol += "USDT";
  }
  return tradingSymbol;
};

export function subscribeOnOpenInterest(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
): Bar {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  let tradingSymbol = symbolInfo.full_name.split("#")[0];
  if (!tradingSymbol.endsWith("USDT") && !tradingSymbol.endsWith("BUSD")) {
    tradingSymbol += "USDT";
  }
  const topic = getOITopic(tradingSymbol.toLowerCase());
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<OpenInterestSubscription>(
      topic,
      (data) => {
        if (data.symbol === tradingSymbol) {
          const roundedTime = new Date(
            Math.ceil(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: parseFloat(data.openInterest),
            high: parseFloat(data.openInterest),
            low: parseFloat(data.openInterest),
            close: parseFloat(data.openInterest),
            volume: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
  return {} as Bar;
}

export function subscribeOnLiquidations(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
): Bar {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  let tradingSymbol = symbolInfo.full_name.split("#")[0];
  if (!tradingSymbol.endsWith("USDT") && !tradingSymbol.endsWith("BUSD")) {
    tradingSymbol += "USDT";
  }
  const topic = getLiquidationTopic(tradingSymbol.toLowerCase());
  subscriptionsTopics[subscriberUID] = topic;

  const resultMap = new Map<
    number,
    {
      quantityBuy: number;
      quantitySell: number;
      quoteBuy: number;
      quoteSell: number;
    }
  >();

  socketConnection
    .subscribe<ILiquidationWSResponse>(
      topic,
      (data) => {
        let quantityBuy = 0;
        let quantitySell = 0;
        let quoteBuy = 0;
        let quoteSell = 0;
        if (data.side === "BUY") {
          quantityBuy = parseFloat(data.origQuantity);
          quoteBuy = parseFloat(data.avgPrice) * parseFloat(data.origQuantity);
        } else {
          quantitySell = parseFloat(data.origQuantity);
          quoteSell = parseFloat(data.avgPrice) * parseFloat(data.origQuantity);
        }

        const roundedTime = new Date(
          Math.floor(data.tradeTime / 60000) * 60000,
        ).getTime();

        resultMap.set(roundedTime, {
          quantityBuy:
            (resultMap.get(roundedTime)?.quantityBuy || 0) + quantityBuy,
          quantitySell:
            (resultMap.get(roundedTime)?.quantitySell || 0) + quantitySell,
          quoteBuy: (resultMap.get(roundedTime)?.quoteBuy || 0) + quoteBuy,
          quoteSell: (resultMap.get(roundedTime)?.quoteSell || 0) + quoteSell,
        });

        onRealtimeCallback({
          time: new Date(Math.floor(now() / 60000) * 60000).getTime(),
          open: resultMap.get(roundedTime)?.quantityBuy || 0,
          high: resultMap.get(roundedTime)?.quantitySell || 0,
          low: resultMap.get(roundedTime)?.quoteBuy || 0,
          close: resultMap.get(roundedTime)?.quoteSell || 0,
          volume: 0,
        });
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });

  return {} as Bar;
}

export interface IPowerTrade {
  timestamp: number;
  symbol: string;
  price: number;
  weight: string;
  side: string;
}

export function subscribeOnPowerTrades(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getPowerTradesTopic(tradingSymbol, resolution);

  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IPowerTrade>(
      topic,
      (d) => {
        if (d) {
          let weightNum = 0;
          // tiny - 0
          // small - 1
          // normal/medium - 2
          // large - 3
          // huge - 4
          switch (d.weight) {
            case "TINY":
              weightNum = 1;
              break;
            case "SMALL":
              weightNum = 2;
              break;
            case "MEDIUM":
              weightNum = 3;
              break;
            case "LARGE":
              weightNum = 4;
              break;
            case "HUGE":
              weightNum = 5;
              break;
          }
          onRealtimeCallback({
            time: d.timestamp,
            open: Number(d.price) == 0 ? NaN : Number(d.price),
            high: Number(weightNum),
            low: d.side === "UP" ? 1 : 0,
            close: Number(0),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export interface IHftActivitySymbolEvent {
  symbol: string;
  timestamp: number;
  price: number;
  weight: EWeight;
  side: string;
}

export enum EWeight {
  TINY = "TINY",
  SMALL = "SMALL",
  MEDIUM = "NORMAL",
  LARGE = "LARGE",
  HUGE = "HUGE",
}

export function subscribeOnHFTActivity(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.dictManipulationMonitors;

  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IHftActivitySymbolEvent>(
      topic,
      (d) => {
        if (d) {
          // const roundedTime = new Date(
          //   Math.floor(now() / (intervalMin * 60000)) *
          //   (intervalMin * 60000)
          // ).getTime();

          let weightNum = 0;
          // tiny - 0
          // small - 1
          // normal/medium - 2
          // large - 3
          // huge - 4
          switch (d.weight) {
            case "TINY":
              weightNum = 1;
              break;
            case "SMALL":
              weightNum = 2;
              break;
            case "NORMAL":
              weightNum = 3;
              break;
            case "LARGE":
              weightNum = 4;
              break;
            case "HUGE":
              weightNum = 5;
              break;
          }

          onRealtimeCallback({
            time: d.timestamp,
            open: Number(d.price) == 0 ? NaN : Number(d.price),
            high: weightNum,
            low: d.side === "UP" ? 1 : 0,
            close: Number(0),
          });
        } else {
          console.log("No hft activity data");
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnManipulationMonitor(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.dictManipulationMonitors;

  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IManipulationStream>(
      topic,
      (dataStream) => {
        const dataDict = dataStream.data;
        const manipulationData = Object.hasOwn(dataDict, tradingSymbol)
          ? dataDict[tradingSymbol]
          : undefined;

        if (manipulationData) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: ((1 - manipulationData.corrV) * 100) / 2,
            open: ((1 - manipulationData.corr) * 100) / 2,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnRetailPower(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.retailPower;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<RetailPower>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const retailPowerItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );

        if (retailPowerItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: retailPowerItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnPowerDelta(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.powerDelta;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IPowerDeltaStream>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const powerDeltaItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );

        if (powerDeltaItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: Number(powerDeltaItem.value),
            high: Number(powerDeltaItem.isShort),
            low: Number(powerDeltaItem.isLong),
            close: Number(0),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnMarketPower(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.marketPower;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<MarketPower>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const marketPowerItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );

        if (marketPowerItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: marketPowerItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnIdeas(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.ideas;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IdeasWsResponse>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const ideasItem = data.find((item) => item.symbol === tradingSymbol);

        if (ideasItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: ideasItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnSpoofLayer(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
  side: string,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.sizesSnapshot;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<ISpoofLayerResponse>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const spoofLayerItem = data.find(
          (item) => item.symbol === tradingSymbol && item.side == side,
        );

        if (spoofLayerItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: spoofLayerItem.price,
            close: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnMatrixSpread(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = symbolInfo.full_name.split("#")[0] + "USDT";
  const topic = SocketTopics.matrixSpreadCum;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IGenericSpreadResponse>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const genericSpreadItem = data[tradingSymbol];

        if (genericSpreadItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: Number(genericSpreadItem.futureRtCum),
            high: Number(genericSpreadItem.spotRtCum),
            low: 0,
            close: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnSpotFutureSpread(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = symbolInfo.full_name.split("#")[0] + "USDT";
  const topic = SocketTopics.spotFuturesSpreadCum;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IGenericSpreadResponse>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const genericSpreadItem = data[tradingSymbol];

        if (genericSpreadItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: Number(genericSpreadItem.futureRtCum),
            high: Number(genericSpreadItem.spotRtCum),
            low: 0,
            close: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnPumpTrend(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.pumpTrend;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<PumpTrend>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const pumpTrendItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );

        if (pumpTrendItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: pumpTrendItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnStrategy(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.strategies;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IStrategy>(
      topic,
      (data) => {
        if (data.symbol === tradingSymbol) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: 0,
            open: data.probability,
            high: data.direction == "BUY" ? 1 : 0,
            low: Number(data.price),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export interface IOISpike {
  timestamp: number;
  symbol: string;
  price: number;
  weight: string;
  side: string;
}

export interface IDog {
  timestamp: number;
  value: number;
  values: number[];
}

export function subscribeOnDog(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.dog;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IDog>(
      topic,
      (d) => {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        onRealtimeCallback({
          time: roundedTime,
          open: d.value,
          close: 0,
          high: 0,
          low: 0,
        });
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnTrendAssessment(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.trendAssessment;
  subscriptionsTopics[subscriberUID] = topic;
  socketConnection.subscribe<ITrendAssessmentResponse>(
    topic,
    (d) => {
      const data = d.data;
      const taItem = data.find((item) => item.symbol === tradingSymbol);
      if (taItem) {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        onRealtimeCallback({
          time: roundedTime,
          open: Number(taItem.threadCum),
          high: Number(taItem.smallTrendCum),
          low: Number(taItem.mediumTrendCum),
          close: Number(taItem.HugeTrendCum),
          volume: Number(0),
        });
      }
    },
    uuidv4(),
  );
}

export interface IAltTimerResponse {
  timestamp: number;
  symbol: string;

  "1m": number;
  "5m": number;
  "15m": number;
  "1h": number;
  "4h": number;
  "1d": number;
}

export function subscribeOnAltTimers(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.altTimer;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IAltTimerResponse>(
      topic,
      (d) => {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        onRealtimeCallback({
          time: roundedTime,
          open: 100 - Math.round(((d["15m"] + 5) / 10) * 100),
          close: 0,
          high: 0,
          low: 0,
        });
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnNetOiSpike(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
  side: string,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getNetOISpikeTopic(tradingSymbol, resolution, side);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IOISpike>(
      topic,
      (d) => {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        if (d) {
          let weightNum = 0;
          // tiny - 0
          // small - 1
          // normal/medium - 2
          // large - 3
          // huge - 4
          switch (d.weight) {
            case "TINY":
              weightNum = 0;
              break;
            case "SMALL":
              weightNum = 1;
              break;
            case "MEDIUM":
              weightNum = 2;
              break;
            case "LARGE":
              weightNum = 3;
              break;
            case "HUGE":
              weightNum = 4;
              break;
          }
          onRealtimeCallback({
            time: roundedTime,
            open: Number(d.price) == 0 ? NaN : Number(d.price),
            high: Number(weightNum),
            low: d.side === "UP" ? 1 : 0,
            close: Number(0),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnActivityDetector(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.activityDetector;
  subscriptionsTopics[subscriberUID] = topic;
  socketConnection
    .subscribe<IActivityDetectorResponse>(
      topic,
      (d) => {
        const data = d.data;
        const activityDetectorItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );
        if (activityDetectorItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: activityDetectorItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnCryptoDrift(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.cryptoDrift;
  subscriptionsTopics[subscriberUID] = topic;
  socketConnection
    .subscribe<ICryptoDriftResponse>(
      topic,
      (d) => {
        const data = d.data;
        const cryptoDriftItem = data.find(
          (item) => item.symbol === tradingSymbol,
        );
        if (cryptoDriftItem) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: cryptoDriftItem.value,
            open: 0,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export interface INetOIDeltaResponse {
  timestamp: number;
  symbol: string;
  takerLongDelta: number;
  takerShortDelta: number;
  takerLong: number;
  takerShort: number;
  oiDelta: number;
  volumeDelta: number;
}

export function subscribeOnNetOpenInterestDelta(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getNetOIDeltaTopic(tradingSymbol.toLowerCase());
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<INetOIDeltaResponse>(
      topic,
      (d) => {
        const roundedTime = new Date(
          Math.ceil(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        onRealtimeCallback({
          time: roundedTime,
          open: Number(d.takerLongDelta),
          high: Number(d.takerShortDelta),
          low: Number(d.oiDelta),
          close: Number(d.volumeDelta),
          volume: 0,
        });
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnDepthSum(
  symbolInfo: LibrarySymbolInfo,
  exchange: string,
  subscriberUID: string,
  intervalMin: number,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getDepthSummaryTopic(tradingSymbol);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IAskBidSum>(
      topic,
      (askBidSumData) => {
        if (askBidSumData) {
          const roundedTime = new Date(
            Math.floor(askBidSumData.timestamp / (intervalMin * 60000)) *
              (intervalMin * 60000),
          ).getTime();

          switch (exchange) {
            case EXCHANGES.ASKORDERBOOKSUM1_5:
              onRealtimeCallback({
                time: roundedTime,
                open: Number(askBidSumData.asks[0]),
                high: Number(askBidSumData.asks[1]),
                low: Number(askBidSumData.asks[2]),
                close: Number(askBidSumData.asks[3]),
                volume: Number(askBidSumData.asks[4]),
              });
              break;
            case EXCHANGES.BIDORDERBOOKSUM1_5:
              onRealtimeCallback({
                time: roundedTime,
                open: Number(askBidSumData.bids[0]),
                high: Number(askBidSumData.bids[1]),
                low: Number(askBidSumData.bids[2]),
                close: Number(askBidSumData.bids[3]),
                volume: Number(askBidSumData.bids[4]),
              });
              break;
            case EXCHANGES.ASKORDERBOOKSUM6_10:
              onRealtimeCallback({
                time: roundedTime,
                open: Number(askBidSumData.asks[5]),
                high: Number(askBidSumData.asks[6]),
                low: Number(askBidSumData.asks[7]),
                close: Number(askBidSumData.asks[8]),
                volume: Number(askBidSumData.asks[9]),
              });
              break;
            case EXCHANGES.BIDORDERBOOKSUM6_10:
              onRealtimeCallback({
                time: roundedTime,
                open: Number(askBidSumData.bids[5]),
                high: Number(askBidSumData.bids[6]),
                low: Number(askBidSumData.bids[7]),
                close: Number(askBidSumData.bids[8]),
                volume: Number(askBidSumData.bids[9]),
              });
              break;
          }
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnSpread(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.spotFuturesSpread;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<ISpotFuturesSpreadResponse>(
      topic,
      (dataStream) => {
        const data = dataStream.data;
        const spreadData = data.filter(
          (spread) => spread.symbol === tradingSymbol,
        );

        if (spreadData && spreadData.length) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: spreadData[0].sma,
            open: spreadData[0].value,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnBidAskChange(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getDepthChangeTopic(tradingSymbol);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IBidAskChange>(
      topic,
      (changeData) => {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        if (changeData) {
          onRealtimeCallback({
            time: roundedTime,
            close: changeData.bids,
            open: changeData.asks,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnBidAskSpread(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getDepthSpreadTopic(tradingSymbol);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IBidAskSpread>(
      topic,
      (changeData) => {
        if (changeData) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: changeData.asks[0],
            high: changeData.asks[1],
            low: changeData.bids[0],
            close: changeData.bids[1],
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnBidAskHighSize(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getDepthHighSizeTopic(tradingSymbol);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IHighSize>(
      topic,
      (changeData) => {
        const roundedTime = new Date(
          Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
        ).getTime();
        if (changeData) {
          onRealtimeCallback({
            time: roundedTime,
            open: changeData.asks[0],
            high: changeData.asks[1],
            low: changeData.bids[0],
            close: changeData.bids[1],
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnStopKiller(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = SocketTopics.stopKillers;
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IStopKiller>(
      topic,
      (stopKillerData) => {
        if (stopKillerData.symbol === tradingSymbol) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: stopKillerData.price,
            open: stopKillerData.price,
            high: 0,
            low: 0,
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnByBitKline(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getBybitKlineTopic(tradingSymbol, interval);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<IBybitWsKlineEvent>(
      topic,
      (bybitWsKlineEvent) => {
        if (bybitWsKlineEvent) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            close: Number(bybitWsKlineEvent.kline.Close),
            open: Number(bybitWsKlineEvent.kline.Open),
            high: Number(bybitWsKlineEvent.kline.High),
            low: Number(bybitWsKlineEvent.kline.Low),
            volume: Number(bybitWsKlineEvent.kline.Volume),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

export function subscribeOnVolume(
  symbolInfo: LibrarySymbolInfo,
  resolution: ResolutionString,
  subscriberUID: string,
  onRealtimeCallback: SubscribeBarsCallback,
) {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];
  const tradingSymbol = getTradingSymbol(symbolInfo);
  const topic = getKlineTopic(tradingSymbol, interval);
  subscriptionsTopics[subscriberUID] = topic;

  socketConnection
    .subscribe<INatsBinanceKline>(
      topic,
      (binanceKline) => {
        if (binanceKline.symbol === tradingSymbol) {
          const roundedTime = new Date(
            Math.floor(now() / (intervalMin * 60000)) * (intervalMin * 60000),
          ).getTime();
          onRealtimeCallback({
            time: roundedTime,
            open: Number(binanceKline.kline.tradeNum),
            high: Number(binanceKline.kline.quoteAssetVolume),
            low: Number(binanceKline.kline.takerBuyBaseAssetVolume),
            close: Number(binanceKline.kline.takerBuyQuoteAssetVolume),
            volume: Number(binanceKline.kline.volume),
          });
        }
      },
      uuidv4(),
    )
    .then((unsub) => {
      unSubscriptions[subscriberUID] = unsub;
    });
}

// ...
export const subscribeOnStream: IDatafeedChartApi["subscribeBars"] = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
) => {
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const intervalMin = BINANCE_RESOLUTION_TO_MINUTES[resolution];

  if (!symbolInfo.ticker) {
    return console.error("Could not subscribe to bars", symbolInfo.full_name);
  }

  switch (symbolInfo.exchange) {
    case EXCHANGES.SANTIMENT:
      return console.info(
        "Skip subscription to santiment symbol",
        symbolInfo.full_name,
      );

    case EXCHANGES.ASKORDERBOOKSUM1_5:
      subscribeOnDepthSum(
        symbolInfo,
        EXCHANGES.ASKORDERBOOKSUM1_5,
        subscriberUID,
        intervalMin,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.ASKORDERBOOKSUM6_10:
      subscribeOnDepthSum(
        symbolInfo,
        EXCHANGES.ASKORDERBOOKSUM6_10,
        subscriberUID,
        intervalMin,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.BIDORDERBOOKSUM1_5:
      subscribeOnDepthSum(
        symbolInfo,
        EXCHANGES.BIDORDERBOOKSUM1_5,
        subscriberUID,
        intervalMin,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.DOG:
      subscribeOnDog(symbolInfo, resolution, subscriberUID, onRealtimeCallback);
      return;
    case EXCHANGES.ALT_TIMERS:
      subscribeOnAltTimers(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.OINETLONGSPIKE:
      subscribeOnNetOiSpike(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
        "long",
      );
      return;
    case EXCHANGES.OINETSHORTSPIKE:
      subscribeOnNetOiSpike(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
        "short",
      );
      return;
    case EXCHANGES.ACTIVITY_DETECTOR:
      subscribeOnActivityDetector(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.CRYPTO_DRIFT:
      subscribeOnCryptoDrift(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.HFT_ACTIVITY:
      subscribeOnHFTActivity(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.POWER_TRADES:
      subscribeOnPowerTrades(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.OINETDELTA:
      subscribeOnNetOpenInterestDelta(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.BIDORDERBOOKSUM6_10:
      subscribeOnDepthSum(
        symbolInfo,
        EXCHANGES.BIDORDERBOOKSUM6_10,
        subscriberUID,
        intervalMin,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.TREND_ASSESSMENT:
      subscribeOnTrendAssessment(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.MATRIXSPREAD:
      subscribeOnMatrixSpread(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.SPOTFUTURESPREAD:
      subscribeOnSpotFutureSpread(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.ORDERBOOKCHANGES:
      subscribeOnBidAskChange(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;

    case EXCHANGES.BIDASKQUOTEDSPREAD:
      subscribeOnBidAskSpread(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.HIGHESTSIZEORDER:
      subscribeOnBidAskHighSize(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.LIQUIDATIONS:
      subscribeOnLiquidations(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.OPEN_INTEREST:
      subscribeOnOpenInterest(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.STRATEGY:
      subscribeOnStrategy(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.SPREAD:
      subscribeOnSpread(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.MANIPULATIONS:
      subscribeOnManipulationMonitor(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.RETAIL_POWER:
      subscribeOnRetailPower(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.POWER_DELTA:
      subscribeOnPowerDelta(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.MARKET_POWER:
      subscribeOnMarketPower(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.IDEAS:
      subscribeOnIdeas(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.SPOOF_LAYER_UP:
      subscribeOnSpoofLayer(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
        "UP",
      );
      return;
    case EXCHANGES.SPOOF_LAYER_DOWN:
      subscribeOnSpoofLayer(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
        "DOWN",
      );
      return;
    case EXCHANGES.PUMP_TREND:
      subscribeOnPumpTrend(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.STOP_KILLER:
      subscribeOnStopKiller(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.VOLUME:
      subscribeOnVolume(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.BYBIT:
      subscribeOnByBitKline(
        symbolInfo,
        resolution,
        subscriberUID,
        onRealtimeCallback,
      );
      return;
    case EXCHANGES.BINANCE: {
      const _symbolInfo = symbolInfo as IExtendedLibrarySymbolInfo;
      const preparedFormula = prepareFormula(symbolInfo.name);
      const lastLegKline = new Map<string, IBinanceKline>();
      const formula = new Formula(preparedFormula);

      Promise.all(
        _symbolInfo.legs.map((leg) =>
          socketConnection.subscribe<INatsBinanceKline>(
            getKlineTopic(leg, interval),
            ({ symbol, kline }) => {
              if (_symbolInfo.legs.length === 1) {
                onRealtimeCallback({
                  time: kline.openTime,
                  close: parseFloat(kline.close),
                  open: parseFloat(kline.open),
                  high: parseFloat(kline.high),
                  low: parseFloat(kline.low),
                  volume: parseFloat(kline.volume),
                });
              } else {
                lastLegKline.set(symbol, kline);
                if (lastLegKline.size !== _symbolInfo.legs.length) {
                  return;
                }
                const params: {
                  open: {
                    [leg: string]: number;
                  };
                  high: {
                    [leg: string]: number;
                  };
                  low: {
                    [leg: string]: number;
                  };
                  close: {
                    [leg: string]: number;
                  };
                  volume: {
                    [leg: string]: number;
                  };
                } = {
                  open: {},
                  high: {},
                  low: {},
                  close: {},
                  volume: {},
                };

                if (lastLegKline.size === _symbolInfo.legs.length) {
                  for (const leg of _symbolInfo.legs) {
                    if (lastLegKline.has(leg)) {
                      params.open[leg] = Number(lastLegKline.get(leg)?.open);
                      params.high[leg] = Number(lastLegKline.get(leg)?.high);
                      params.low[leg] = Number(lastLegKline.get(leg)?.low);
                      params.close[leg] = Number(lastLegKline.get(leg)?.close);
                      params.volume[leg] =
                        Number(lastLegKline.get(leg)?.volume) +
                        Number(lastLegKline.get(leg)?.quoteAssetVolume);
                    }
                  }
                  const close = formula.evaluate(params.close) as number;
                  const open = formula.evaluate(params.open) as number;
                  const high = formula.evaluate(params.high) as number;
                  const low = formula.evaluate(params.low) as number;
                  const volume = formula.evaluate(params.volume) as number;

                  const bar: Bar = {
                    time: kline.openTime,
                    close,
                    open,
                    high,
                    low,
                    volume,
                  };
                  onRealtimeCallback(bar);
                }
              }
            },
            uuidv4(),
          ),
        ),
      ).then((unsubs) => {
        unSubscriptions[subscriberUID] = () => {
          unsubs.forEach((unsub) => unsub());
        };
      });

      return;
    }
    default:
      console.error("Unknown exchange", symbolInfo.exchange);
      return;
  }
};

export const unsubscribeFromStream = (subscriberUID: string) => {
  const subscription = subscriptionsTopics[subscriberUID];
  if (subscription) {
    delete subscriptionsTopics[subscriberUID];
  }
  // } else {
  //   console.error("unsubscribeFromStream", subscriberUID);
  // }
  unSubscriptions[subscriberUID]?.();
  delete unSubscriptions[subscriberUID];
};
