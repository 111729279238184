import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Button } from "@/components/ui/button.tsx";
import { useGrid } from "@/hooks/useGrid.ts";
import { memo, MouseEvent, useEffect, useMemo, useState } from "react";
import { Input } from "@/components/ui/input.tsx";
import { ResolutionString } from "@tradingView/charting_library";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { FaCheck } from "react-icons/fa6";
import { EFilterType, EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { IFilterOption } from "@/domain/interfaces/dashboard.interface.ts";
import { cn } from "@/lib/utils.ts";
import { Toggle } from "@/components/ui/toggle";
import { useDrawSignal } from "@/lib/store";

export function PaneSubHeader() {
  return (
    <div className="h-6 w-full bg-stone-900 flex items-center gap-2">
      <TimeFrameDropdown />
      <Filters />
    </div>
  );
}

const timeFrameLabels: Record<string, string> = {
  "1": "1 Minute",
  "3": "3 Minutes",
  "5": "5 Minutes",
  "15": "15 Minutes",
  "30": "30 Minutes",
  "60": "1 Hour",
  "120": "2 Hours",
  "240": "4 Hours",
  "360": "6 Hours",
  "480": "8 Hours",
  "720": "12 Hours",
  "1D": "1 Day",
  "3D": "3 Days",
  "1W": "1 Week",
  "1M": "1 Month",
};

function TimeFrameDropdown() {
  const nodeId = usePaneContext();
  const { node, getNodeConfig, updateTabConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const [isOpen, setIsOpen] = useState(false);
  const timeframesOptions = useMemo(
    () =>
      config.availableIntervals?.map(
        (interval) =>
          ({
            label: timeFrameLabels[interval as string],
            value: interval,
          }) as { label: string; value: ResolutionString },
      ) ?? [],
    [config.availableIntervals],
  );

  if (!config.availableIntervals?.length) {
    return null;
  }

  const handleIntervalChange = (value: string) => {
    updateTabConfig({
      interval: value as ResolutionString,
    });
  };

  return node ? (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            size="xxs"
            className="p-2"
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen((prev) => !prev);
            }}
          >
            {BINANCE_RESOLUTION_MAP[config?.interval]}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-40 bg-black">
          <DropdownMenuLabel>Select TimeFrame</DropdownMenuLabel>
          <DropdownMenuSeparator />

          {timeframesOptions.map((tf) => (
            <DropdownMenuItem
              key={tf.value}
              onMouseDown={(e) => {
                e.stopPropagation();
                handleIntervalChange(tf.value);
              }}
            >
              <div className="flex gap-2">
                <div className="w-4">
                  {config?.interval === tf.value && <FaCheck />}
                </div>
                <div>{tf.label}</div>
              </div>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  ) : null;
}

const FilterInput = memo(function FilterInput({
  filter,
}: {
  filter: IFilterOption;
}) {
  const nodeId = usePaneContext();
  const { node } = useGrid(nodeId);
  const { updateTabConfig, getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const filterValue =
    config?.filterValues?.[filter.filterByField] || filter.defaultValue || "";
  const [value, setValue] = useState<any>(
    filterValue || filter.defaultValue || "",
  );

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const widgetType = node?.getComponent() as EWidgetType;
  if (
    widgetType === EWidgetType.chart ||
    widgetType === EWidgetType.controlPanel
  ) {
    return null;
  }

  const handleFilterChange = (value: any) => {
    setValue(value);
    const newFilterValues = {
      ...config?.filterValues,
      [filter.filterByField]: value,
    };
    updateTabConfig({ filterValues: newFilterValues });
  };

  const resetFilter = (filterName: string, e?: MouseEvent<any>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setValue(filter.defaultValue || "");
    const newFilterValues = { ...config?.filterValues };
    delete newFilterValues[filterName];
    updateTabConfig({ filterValues: newFilterValues });
  };

  // const hasModifiedFilters = !!Object.values(config?.filterValues ?? {}).length;

  const isModified = config?.filterValues?.[filter.filterByField] !== undefined;
  switch (filter.type) {
    case EFilterType.select:
    case EFilterType.multiSelect:
      return (
        <div className="flex items-center space-x-2 relative">
          <Select
            value={value}
            onValueChange={(value) => handleFilterChange(value)}
          >
            <SelectTrigger
              className="w-full h-6 text-xs pr-6"
              chevronSide="left"
            >
              <span className={cn(!filterValue && "!text-muted-foreground")}>
                <SelectValue placeholder={filter.title} />
              </span>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="text-xs">{filter.title}</SelectLabel>
                {filter.options?.map((option) => (
                  <SelectItem
                    className="text-xs"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {isModified && (
            <Button
              size="xxs"
              variant="ghost"
              className="absolute top-1/2 right-0 transform -translate-y-1/2"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          )}
          {isModified && (
            <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
          )}
        </div>
      );
    case EFilterType.range:
      return (
        <div className="flex space-x-2 items-center relative">
          <Input
            type="number"
            placeholder="Min"
            value={filterValue?.min || filter.min}
            onChange={(e) =>
              handleFilterChange({
                ...filterValue,
                min: parseInt(e.target.value),
              })
            }
            className="w-full"
          />
          <Input
            type="number"
            placeholder="Max"
            value={filterValue?.max || filter.max}
            onChange={(e) =>
              handleFilterChange({
                ...filterValue,
                max: parseInt(e.target.value),
              })
            }
            className="w-full"
          />
          {isModified && (
            <Button
              size="xs"
              variant="ghost"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          )}
          {isModified && (
            <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
          )}
        </div>
      );
    // case EFilterType.object:
    //   return (
    //     <div className="space-y-2 relative">
    //       {filter.options?.map((option) => (
    //         <div key={option} className="flex items-center space-x-2">
    //           <Label>{option}</Label>
    //           <Input
    //             type="checkbox"
    //             checked={filterValue?.[option] || false}
    //             onChange={(e) =>
    //               handleFilterChange(filter.filterByField, {
    //                 ...filterValue,
    //                 [option]: e.target.checked,
    //               })
    //             }
    //           />
    //         </div>
    //       ))}
    //       <Button
    //         size="xs"
    //         variant="ghost"
    //         onClick={() => resetFilter(filter.filterByField)}
    //       >
    //         <i className="fa-solid fa-times"></i>
    //       </Button>
    //       {isModified && (
    //         <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
    //       )}
    //     </div>
    //   );
    case EFilterType.number:
      return (
        <div className="relative">
          <Input
            type="number"
            placeholder={filter.title}
            value={value}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="w-full text-xs rounded-md h-6"
          />
          {isModified && (
            <Button
              size="xxs"
              variant="ghost"
              className="absolute top-1/2 right-0 transform -translate-y-1/2"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          )}
          {isModified && (
            <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
          )}
        </div>
      );
    case EFilterType.text:
      return (
        <div className="relative">
          <Input
            type="text"
            placeholder={filter.title}
            value={value}
            onChange={(e) => handleFilterChange(e.target.value)}
            className="w-full text-xs rounded-md h-6"
          />
          {isModified && (
            <Button
              size="xxs"
              variant="ghost"
              className="absolute top-1/2 right-0 transform -translate-y-1/2"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
          )}
          {isModified && (
            <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
          )}
        </div>
      );
    default:
      return null;
  }
});

function Filters() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { setSignalToDraw } = useDrawSignal();
  const [isPressed, setIsPressed] = useState(false);

  return (
    <div className="flex gap-2 items-center justify-between w-full">
      <div className="flex gap-2">
        {config?.filters
          ?.filter((item) => !item.global)
          .map((filter) => (
            <FilterInput key={filter.type + filter.title} filter={filter} />
          ))}
      </div>
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          onMouseDown={() => {
            setIsPressed(true);
          }}
          onMouseUp={() => {
            setIsPressed(false);
            if (config?.indicatorTitle) {
              setSignalToDraw(config.indicatorTitle, config.color);
            } else {
              console.warn("No indicator title found");
            }
          }}
        >
          <i
            className={cn(
              "fa-solid fa-chart-mixed",
              isPressed && "text-green-500",
            )}
          ></i>
        </Button>
      </div>
    </div>
  );
}
