import axios from "axios";
import { useQuery } from "@tanstack/react-query";

export interface IExchangeSymbol {
  symbol: string;
  status: string;
  baseAsset: string;
  baseAssetPrecision: number;
  quoteAsset: string;
  quoteAssetPrecision: number;
  filters: string[];
}

export interface IExchangeInfo {
  symbols: IExchangeSymbol[];
}

const baseUrl = "https://fapi.binance.com";
const req = axios.get<IExchangeInfo>(`${baseUrl}/fapi/v1/exchangeInfo`);

export function useBinanceSymbols() {
  return useQuery<IExchangeSymbol[]>({
    queryKey: ["exchangeInfo"],
    queryFn: async () => {
      const response = await req;
      return response.data.symbols.filter(
        (symbol: IExchangeSymbol) =>
          symbol.status === "TRADING" &&
          symbol.symbol.includes("USDT") &&
          !symbol.symbol.includes("_"),
      );
    },
  });
}
