import { extendTailwindMerge } from "tailwind-merge";

export const SFTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": [
        {
          text: ["xxs"],
        },
      ],
      "text-color": [{ text: ["primary"] }],
    },
  },
});
