import { useDashboard } from "@/lib/store.ts";
import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { FaRegFolderClosed, FaRegFolderOpen } from "react-icons/fa6";

export function ToggleExpandAll() {
  const { updateFavorites, favorites } = useDashboard((state) => ({
    updateFavorites: state.updateFavorites,
    favorites: state.favorites,
  }));

  const [foldersExpanded, setFoldersExpanded] = useState<boolean>(
    favorites.some((f) => f.expanded),
  );

  useEffect(() => {
    setFoldersExpanded(favorites.some((f) => f.expanded));
  }, [favorites]);

  function toggleAllHandler() {
    setFoldersExpanded((prev) => !prev);
    updateFavorites((state) => {
      state.forEach((f) => {
        f.expanded = !foldersExpanded;
      });
    });
  }

  return (
    <Button
      onClick={toggleAllHandler}
      size="xs"
      className="flex gap-2 items-center"
    >
      {foldersExpanded ? (
        <>
          <FaRegFolderOpen /> Expand All
        </>
      ) : (
        <>
          <FaRegFolderClosed /> Collapse All
        </>
      )}
    </Button>
  );
}
