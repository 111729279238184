import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { merge } from "lodash";
import { useCallback } from "react";
import { environment } from "@/lib/constants.ts";

export interface IManipulation {
  corr: number;
  corrV: number;
}

interface IManipulationMonitorResponse {
  timestamp: number;
  data: Record<string, IManipulation>;
}

export function useManipulationMonitor() {
  const handler = useCallback(
    (
      oldData: Record<string, IManipulation>,
      newData: IManipulationMonitorResponse,
    ) => merge(oldData, newData.data),
    [],
  );
  return useGenericSocket<
    Record<string, IManipulation>,
    IManipulationMonitorResponse
  >(SocketTopics.dictManipulationMonitors, handler);
}
