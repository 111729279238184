import { queryClient } from "@/lib/queryClient.ts";
import {
  connect,
  NatsConnection,
  StringCodec,
  Subscription as natsSubscription,
} from "nats.ws";
import hash from "object-hash";

export const baseDomain = "fra1.prod.do.spreadfighter.cloud";
export const socketUrl = `wss://wss.fra1.prod.do.spreadfighter.cloud`;

type ISubscriptionCB<T> = (data: T) => void;
type IConnectionCounter = { [topic: string]: number };

const sc = StringCodec();
const connectionCounter: IConnectionCounter = {};
export const subscriptions: Record<
  string,
  Record<string, ISubscriptionCB<any>>
> = {};
const natsSubscriptions: Record<string, natsSubscription> = {};
const hashFunction = (fn: ISubscriptionCB<any>): string => hash(fn.toString());

let natsConnection: NatsConnection | null = null;
let connectPromise: Promise<void> | null = null;

const connectNATS = async () => {
  if (!connectPromise) {
    connectPromise = (async () => {
      try {
        natsConnection = await connect({
          servers: [socketUrl],
          user: "crypto",
          pass: "password",
        });
        console.log("Connected to NATS");
        setInterval(async () => {
          if (natsConnection) {
            const rtt = await natsConnection.rtt();
            queryClient.setQueryData(["natsRTT"], rtt);
          }
        }, 1000);

        natsConnection.closed().then(() => {
          console.log("NATS connection closed");
          Object.keys(subscriptions).forEach((topic) => {
            Object.keys(subscriptions[topic]).forEach((subscriberUID) => {
              unsubscribe(topic, subscriberUID);
            });
          });
        });
      } catch (error) {
        console.error("Error connecting to NATS", error);
        natsConnection = null;
        connectPromise = null;
        console.log("Reconnecting in 5 seconds");
        setTimeout(connectNATS, 5000);
      }
    })();
  }
  return connectPromise;
};

const subscribe = async <T = any>(
  topic: string,
  cb: ISubscriptionCB<T>,
  cbHash = hashFunction(cb),
) => {
  await connectNATS();
  if (!natsConnection) {
    throw new Error("NATS connection not established");
  }

  if (!subscriptions[topic]) {
    subscriptions[topic] = {};
  }

  connectionCounter[topic] = (connectionCounter[topic] || 0) + 1;

  if (!subscriptions[topic][cbHash]) {
    subscriptions[topic][cbHash] = cb;
    if (connectionCounter[topic] === 1) {
      natsSubscriptions[topic] = natsConnection.subscribe(topic, {
        callback: (err, msg) => {
          if (err) {
            console.error("NATS subscription error", err);
            return;
          }
          const parsedData = JSON.parse(sc.decode(msg.data)) as T;
          Object.values(subscriptions[topic]).forEach((subscription) =>
            subscription(parsedData),
          );
        },
      });
    }
  }

  return () => unsubscribe(topic, cbHash);
};

const unsubscribe = (topic: string, cbHash: string) => {
  if (!subscriptions[topic]) {
    return;
  }

  connectionCounter[topic]--;
  if (connectionCounter[topic] <= 0) {
    delete subscriptions[topic][cbHash];
    natsSubscriptions[topic].unsubscribe();
    delete natsSubscriptions[topic];
    delete subscriptions[topic];
  }
};

connectNATS();

export const socketConnection = {
  subscribe,
  unsubscribe,
};

export enum SocketTopics {
  strategies = "sf.core.scripts.screener.strategySpike.all",
  altusherBB = "sf.core.scripts.screener.altusherBb.1.*.*.storage",
  altusherGaps = "sf.core.scripts.screener.altusherGap.1.*.*.storage",
  cmStrategyV1 = "sf.core.scripts.screener.strategy.cmStrategy.1.*.*.storage",
  cmStrategyV2 = "sf.core.scripts.screener.strategy.cmStrategy.2.*.*.storage",
  directionalIndexWithBollingerConfirmation = "sf.core.scripts.screener.strategy.directionalIndexWithBollingerConfirmation.1.*.*.storage",
  dualEmaBollinger = "sf.core.scripts.screener.strategy.dualEmaBollinger.1.*.*.storage",
  multiPeriodVolatilityFilter = "sf.core.scripts.screener.strategy.multiPeriodVolatilityFilter.1.*.*.storage",
  relativeHighLowOscillator = "sf.core.scripts.screener.strategy.relativeHighLowOscillator.1.*.*.storage",
  triplePriceDeviationFilter = "sf.core.scripts.screener.strategy.triplePriceDeviationFilter.1.*.*.storage",
  volatilityBasedEma = "sf.core.scripts.screener.strategy.volatilityBasedEma.1.*.*.storage",
  stopKillers = "sf.core.scripts.screener.stopKillerPro.all",
  pickUp = "sf.core.scripts.screener.pickUp.all",
  structMapAvgs = "sf.core.scripts.screener.structureMapAvg.*.*",
  dictMapAvgs = "sf.core.scripts.screener.marketRatio.dict",
  marketRatio = "sf.core.scripts.screener.marketRatio.sort.top",
  marketRatioArr = "sf.core.scripts.screener.marketRatio.all.all.arr",
  manipulationMonitors = "sf.core.scripts.screener.manipulationMonitor.*.all",
  dictManipulationMonitors = "sf.core.scripts.screener.manipulationMonitor.1m.all.dict",
  cryptoPanicNews = "info.news.cryptoPanic.all",
  openInterests = "openInterest.binance.futures.stream.*.openInterest",
  liquidations = "binance.futures.liquidation",
  hiLowScanners = "sf.core.scripts.screener.hiLowScanner.nearLevelWindow.>", // todo: switch to dict
  dictHiLowScanners = "sf.core.scripts.screener.hiLowScanner.dict.nearLevelWindow",
  dog = "sf.core.scripts.screener.dog",
  spotFuturesSpread = "sf.core.scripts.screener.spotFutureSpread.1m.all.dict",
  spotFuturesSpreadCum = "sf.core.scripts.screener.spotFutureSpread.1m.all.cum.dict",
  openInterestNetSpike = "sf.core.scripts.screener.openInterestNetSpike",
  tickIndexes = "sf.core.scripts.screener.tickIndex.*",
  dictOnlyPrices = "kline.binance.futures.price.dict.all",
  dict24Prices = "binance.futures.marketTicker.dict.all",
  sfNews = "sf.news.updates.all",
  marginLevel = "sf.core.scripts.screener.marginLevel",
  candlePatterns = "sf.core.scripts.screener.candlePattern.>",
  topGainers = "sf.core.scripts.screener.topGainer.all",
  topGainersBuySell = "sf.core.scripts.screener.topGainer.buySell",
  topGainersOiPrc = "sf.core.scripts.screener.topGainer.oiPrc",
  topGainersPricePrc = "sf.core.scripts.screener.topGainer.pricePrc",
  topGainersVolumePrc = "sf.core.scripts.screener.topGainer.volumePrc",
  topGainersFundingPrc = "sf.core.scripts.screener.topGainer.fundingPrc",
  funding = "binance.futures.funding.dict.all",
  formations = "sf.core.scripts.screener.formationZigZag.all.all.top",
  divergence = "sf.core.scripts.screener.divergence.all",
  kline = "binance.futures.kline.*.*",
  altTimer = "sf.core.scripts.screener.altTimer.all",
  bigTrades = "sf.core.scripts.screener.powerTrades.all",
  marginLevelWindow = "sf.core.scripts.screener.marginLevel.window",
  levels = "sf.core.scripts.screener.cascadeLevel.*",
  speedRushAdts = "sf.core.scripts.screener.speedRush.adts",
  speedRushAdtv = "sf.core.scripts.screener.speedRush.adtv",
  hftActivity = "sf.core.scripts.screener.hftActivity.>",
  prepumpDetector = "sf.core.scripts.screener.pumpDetector.1m",
  tradePerSecond = "sf.core.scripts.screener.tradePerSecond.top",
  aggTrade = "binance.futures.aggTrade",
  depth = "binance.futures.depth",
  marketPower = "sf.core.scripts.screener.marketPower.1m.all.dict",
  marketPowerScreener = "sf.core.scripts.screener.marketPower.1m.all.window",
  retailPower = "sf.core.scripts.screener.retailPower.1m",
  retailPowerScreener = "sf.core.scripts.screener.retailPower.all",
  pumpTrend = "sf.core.scripts.screener.pumpTrend.1m",
  ideas = "sf.core.scripts.screener.ideas.1m.all.dict",
  matrixSpread = "sf.core.scripts.screener.matrixSpread.1m.all.dict",
  matrixSpreadCum = "sf.core.scripts.screener.matrixSpread.1m.all.cum.dict",
  timeDomination = "sf.core.scripts.screener.timeDomination.1m.all.cum.arr",
  videoStreamSymbols = "video.stream.symbols",
  sizeScreener = "sf.core.scripts.screener.spoofLayer.1m.*.toggle",
  sizesSnapshot = "sf.core.scripts.screener.spoofLayer.1m.all.rt.arr",
  technicalDesk = "sf.core.scripts.screener.technicalDesk.1m.all.arr",
  pairScreenerBase = "sf.spreadscreener.signal",
  powerDelta = "sf.core.scripts.screener.powerCvd.1m.all.arr",
  speedRushAll = "sf.core.scripts.screener.speedRush.all",
  activityDetector = "sf.core.scripts.screener.activityDetector.1m.all.arr",
  cryptoDrift = "sf.core.scripts.screener.cryptoDrift.1m.all.arr",
  powerTrades = "sf.core.scripts.screener.powerTrades",
  openInterestNet = "sf.core.scripts.screener.openInterestNet",
  trendAssessment = "sf.core.scripts.screener.assessmentTrend.1m.all.arr",
}

export function getNetOIDeltaTopic(symbol: string, timeFrame = "1m") {
  return `${SocketTopics.openInterestNet}.${timeFrame.toLowerCase()}.${symbol.toLowerCase()}` as SocketTopics;
}

export function getPowerTradesTopic(symbol: string, timeFrame = "1m") {
  return `${SocketTopics.powerTrades}.${timeFrame.toLowerCase()}.${symbol.toLowerCase()}` as SocketTopics;
}

export function getPairScreenerTopic(timeFrame = "1m") {
  return `${SocketTopics.pairScreenerBase}.${timeFrame.toLowerCase()}` as SocketTopics;
}

export function getLevelsTopic(symbol: string, timeFrame = "1m") {
  return `sf.core.scripts.screener.cascadeLevel.${symbol.toLowerCase()}.${timeFrame.toLowerCase()}` as SocketTopics;
}

export function getAggregatedConstructorTopic(symbol: string) {
  return `sf.core.scripts.screener.aggregatedConstructor.all.${symbol.toLowerCase()}` as SocketTopics;
}

export function getMarketRatioTopic(symbol: string) {
  return `sf.core.scripts.screener.marketRatio.all.${symbol.toLowerCase()}` as SocketTopics;
}

export function getAltTimerTopic(timeFrame = "1m") {
  return `sf.core.scripts.screener.altTimer.${timeFrame.toLowerCase()}` as SocketTopics;
}

export function getCascadeLevelScreenersTopic(timeFrame = "1m") {
  return `sf.core.scripts.screener.cascadeLevel.${timeFrame.toLowerCase()}.window` as SocketTopics;
}

export function getPatternPredictTopic(timeFrame = "1m") {
  return `sf.core.scripts.screener.patternPredict.${timeFrame.toLowerCase()}` as SocketTopics;
}

export function getKlineTopic(symbol: string, interval: string) {
  return `binance.futures.kline.${symbol.toLowerCase()}.${interval}` as SocketTopics;
}

export function getBybitKlineTopic(symbol: string, interval: string) {
  return `bybit.futures.kline.${symbol.toLowerCase()}.${interval}` as SocketTopics;
}

export function getOITopic(symbol: string) {
  return `binance.futures.oi.${symbol.toLowerCase()}` as SocketTopics;
}

export function getNetOISpikeTopic(
  symbol: string,
  timeFrame = "1m",
  side = "long",
) {
  return `${SocketTopics.openInterestNetSpike}.${timeFrame}.${symbol.toLowerCase()}.${side}` as SocketTopics;
}

export function getDOMTopic(symbol: string, timeFrame = "1m", weight = "all") {
  return `${
    SocketTopics.marginLevel
  }.${symbol.toLowerCase()}.${timeFrame.toLowerCase()}.${weight.toLowerCase()}` as SocketTopics;
}

export function getTradesTapeTopic(symbol: string) {
  return `${SocketTopics.aggTrade}.${symbol.toLowerCase()}` as SocketTopics;
}

export function getDepthSummaryTopic(symbol: string) {
  return `${SocketTopics.depth}.${symbol.toLowerCase()}.sum` as SocketTopics;
}

export function getDepthSpreadTopic(symbol: string) {
  return `${SocketTopics.depth}.${symbol.toLowerCase()}.spread` as SocketTopics;
}

export function getDepthHighSizeTopic(symbol: string) {
  return `${SocketTopics.depth}.${symbol.toLowerCase()}.highSize` as SocketTopics;
}

export function getDepthChangeTopic(symbol: string, timeFrame = "1m") {
  return `${SocketTopics.depth}.${symbol.toLowerCase()}.change.${timeFrame.toLowerCase()}` as SocketTopics;
}

export function getLiquidationTopic(symbol: string) {
  return `${SocketTopics.liquidations}.${symbol.toLowerCase()}` as SocketTopics;
}
