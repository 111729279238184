//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const beta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Beta",
    metainfo: {
      _metainfoVersion: 51,
      id: "beta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Beta",
      shortDescription: "[SF] β",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTuckerInput: "BINANCE:BTCUSDT",
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "β",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "benchmarkTuckerInput",
          name: "Benchmark tiker",
          defval: "BINANCE:BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 3,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const benchmarkSymbolInput: symbol = this._input(0);
        this._context.new_sym(
          benchmarkSymbolInput,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const lengthInput: number = this._input(1);

        //Market data parsing
        const hlc3Series = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(1);
        const benchmarkTimeArray = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmarkArray = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmark: number = benchmarkArray.adopt(
          benchmarkTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        const benchmarkSeries = this._context.new_var(0);
        benchmarkSeries.set(benchmark);

        //Calculation
        let sum: number = 0.0;
        let sum2Pow: number = 0.0;
        const hlc3Avg: number =
          PineJS.Std.sum(hlc3Series, lengthInput, this._context) / lengthInput;
        const becnhmarkAvg: number =
          PineJS.Std.sum(benchmarkSeries, lengthInput, this._context) /
          lengthInput;
        for (let i = 0; i < lengthInput; i++) {
          const value1 = hlc3Series.get(i);
          const value2 = benchmarkSeries.get(i);
          if (!isNaN(value1) && !isNaN(value2)) {
            sum += (value1 - hlc3Avg) * (value2 - becnhmarkAvg);
            sum2Pow += PineJS.Std.pow(value2 - becnhmarkAvg, 2);
          }
        }
        const beta: number = sum / sum2Pow;

        //Returns
        return [0, beta];
      };
    },
  };
};
