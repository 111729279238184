//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

export const depthOfMarket = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Depth of market",
    metainfo: {
      _metainfoVersion: 51,
      id: "depth-of-market@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Depth of market",
      shortDescription: "[SF] DOM",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "ask10DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask9DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask8DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask7DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask6DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask5DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask4DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask3DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask2DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "ask1DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid10DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid9DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid8DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid7DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid6DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid5DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid4DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid3DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid2DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "bid1DepthPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          ask10DepthPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#211CFF",
            transparency: 1,
          },
          ask9DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#1871FF",
            transparency: 1,
          },
          ask8DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18BAFF",
            transparency: 1,
          },
          ask7DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FFB5",
            transparency: 1,
          },
          ask6DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FF42",
            transparency: 1,
          },
          ask5DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#63FF18",
            transparency: 1,
          },
          ask4DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#D6FF18",
            transparency: 1,
          },
          ask3DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FFCF18",
            transparency: 1,
          },
          ask2DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF7118",
            transparency: 1,
          },
          ask1DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF2821",
            transparency: 1,
          },
          bid10DepthPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#211CFF",
            transparency: 1,
          },
          bid9DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#1871FF",
            transparency: 1,
          },
          bid8DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18BAFF",
            transparency: 1,
          },
          bid7DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FFB5",
            transparency: 1,
          },
          bid6DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#18FF42",
            transparency: 1,
          },
          bid5DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#63FF18",
            transparency: 1,
          },
          bid4DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#D6FF18",
            transparency: 1,
          },
          bid3DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FFCF18",
            transparency: 1,
          },
          bid2DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF7118",
            transparency: 1,
          },
          bid1DepthPlot: {
            visible: true,
            display: 3,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "#FF2821",
            transparency: 1,
          },
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
        },
      },
      styles: {
        ask10DepthPlot: {
          title: "Ask depth at 10%",
          histogramBase: 0,
        },
        ask9DepthPlot: {
          title: "Ask depth at 9%",
          histogramBase: 0,
        },
        ask8DepthPlot: {
          title: "Ask depth at 8%",
          histogramBase: 0,
        },
        ask7DepthPlot: {
          title: "Ask depth at 7%",
          histogramBase: 0,
        },
        ask6DepthPlot: {
          title: "Ask depth at 6%",
          histogramBase: 0,
        },
        ask5DepthPlot: {
          title: "Ask depth at 5%",
          histogramBase: 0,
        },
        ask4DepthPlot: {
          title: "Ask depth at 4%",
          histogramBase: 0,
        },
        ask3DepthPlot: {
          title: "Ask depth at 3%",
          histogramBase: 0,
        },
        ask2DepthPlot: {
          title: "Ask depth at 2%",
          histogramBase: 0,
        },
        ask1DepthPlot: {
          title: "Ask depth at 1%",
          histogramBase: 0,
        },
        bid10DepthPlot: {
          title: "Bid depth at 10%",
          histogramBase: 0,
        },
        bid9DepthPlot: {
          title: "Bid depth at 9%",
          histogramBase: 0,
        },
        bid8DepthPlot: {
          title: "Bid depth at 8%",
          histogramBase: 0,
        },
        bid7DepthPlot: {
          title: "Bid depth at 7%",
          histogramBase: 0,
        },
        bid6DepthPlot: {
          title: "Bid depth at 6%",
          histogramBase: 0,
        },
        bid5DepthPlot: {
          title: "Bid depth at 5%",
          histogramBase: 0,
        },
        bid4DepthPlot: {
          title: "Bid depth at 4%",
          histogramBase: 0,
        },
        bid3DepthPlot: {
          title: "Bid depth at 3%",
          histogramBase: 0,
        },
        bid2DepthPlot: {
          title: "Bid depth at 2%",
          histogramBase: 0,
        },
        bid1DepthPlot: {
          title: "Bid depth at 1%",
          histogramBase: 0,
        },
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const askDepthSymbol1_5: string = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM1_5`;
        this._context.new_sym(
          askDepthSymbol1_5,
          PineJS.Std.period(this._context),
        );

        const askDepthSymbol6_10: string = `${this._context.symbol.info.name.split("USDT")[0]}#ASKORDERBOOKSUM6_10`;
        this._context.new_sym(
          askDepthSymbol6_10,
          PineJS.Std.period(this._context),
        );

        const bidDepthSymbol1_5: string = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM1_5`;
        this._context.new_sym(
          bidDepthSymbol1_5,
          PineJS.Std.period(this._context),
        );

        const bidDepthSymbol6_10: string = `${this._context.symbol.info.name.split("USDT")[0]}#BIDORDERBOOKSUM6_10`;
        this._context.new_sym(
          bidDepthSymbol6_10,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Input
        const measureInput: string = this._input(0);

        //Market data parsing
        const close: number = PineJS.Std.close(this._context);
        this._context.select_sym(1);
        const ask_1_5_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const ask_1_5_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const ask_1_5_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const ask_1_5_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const ask_1_5_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const ask_1_5_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(2);
        const ask_6_10_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const ask_6_10_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const ask_6_10_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const ask_6_10_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const ask_6_10_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const ask_6_10_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(3);
        const bid_1_5_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const bid_1_5_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const bid_1_5_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const bid_1_5_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const bid_1_5_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const bid_1_5_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(4);
        const bid_6_10_TimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const bid_6_10_Open_SeriesArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const bid_6_10_High_SeriesArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const bid_6_10_Low_SeriesArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const bid_6_10_Close_SeriesArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const bid_6_10_Volume_SeriesArray: any = this._context.new_var(
          PineJS.Std.volume(this._context),
        );

        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let ask1Depth: number = ask_1_5_Open_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask2Depth: number = ask_1_5_High_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask3Depth: number = ask_1_5_Low_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask4Depth: number = ask_1_5_Close_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask5Depth: number = ask_1_5_Volume_SeriesArray.adopt(
          ask_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        let ask6Depth: number = ask_6_10_Open_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask7Depth: number = ask_6_10_High_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask8Depth: number = ask_6_10_Low_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask9Depth: number = ask_6_10_Close_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let ask10Depth: number = ask_6_10_Volume_SeriesArray.adopt(
          ask_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        let bid1Depth: number = -bid_1_5_Open_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid2Depth: number = -bid_1_5_High_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid3Depth: number = -bid_1_5_Low_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid4Depth: number = -bid_1_5_Close_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid5Depth: number = -bid_1_5_Volume_SeriesArray.adopt(
          bid_1_5_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        let bid6Depth: number = -bid_6_10_Open_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid7Depth: number = -bid_6_10_High_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid8Depth: number = -bid_6_10_Low_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid9Depth: number = -bid_6_10_Close_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );
        let bid10Depth: number = -bid_6_10_Volume_SeriesArray.adopt(
          bid_6_10_TimeArray,
          mainSymbolTimeSeries,
          1,
        );

        //Calculations
        if (measureInput === "USD") {
          ask10Depth *= close;
          ask9Depth *= close;
          ask8Depth *= close;
          ask7Depth *= close;
          ask6Depth *= close;
          ask5Depth *= close;
          ask4Depth *= close;
          ask3Depth *= close;
          ask2Depth *= close;
          ask1Depth *= close;
          bid10Depth *= close;
          bid9Depth *= close;
          bid8Depth *= close;
          bid7Depth *= close;
          bid6Depth *= close;
          bid5Depth *= close;
          bid4Depth *= close;
          bid3Depth *= close;
          bid2Depth *= close;
          bid1Depth *= close;
        }

        //Returns
        return [
          ask10Depth,
          ask9Depth,
          ask8Depth,
          ask7Depth,
          ask6Depth,
          ask5Depth,
          ask4Depth,
          ask3Depth,
          ask2Depth,
          ask1Depth,
          bid10Depth,
          bid9Depth,
          bid8Depth,
          bid7Depth,
          bid6Depth,
          bid5Depth,
          bid4Depth,
          bid3Depth,
          bid2Depth,
          bid1Depth,
          0,
        ];
      };
    },
  };
};
