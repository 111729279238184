import React, { useEffect, useRef } from "react";

interface RatioProps {
  leftValue: number;
  rightValue: number;
  width?: number;
  height?: number;
}

export const Ratio: React.FC<RatioProps> = ({
  leftValue,
  rightValue,
  width = 100,
  height = 20,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return;
    }

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      const total = leftValue + rightValue;
      const leftWidth = (leftValue / total) * width;
      const rightWidth = (rightValue / total) * width;

      // Draw left side
      ctx.fillStyle = "#16a34a";
      ctx.fillRect(0, 0, leftWidth, height);

      // Draw right side
      ctx.fillStyle = "#dc2626";
      ctx.fillRect(leftWidth, 0, rightWidth, height);

      // Draw divider
      ctx.fillStyle = "white";
      ctx.fillRect(leftWidth - 1, 0, 1, height);

      // Draw left text if greater than 30%
      if (leftValue / total > 0.3) {
        ctx.fillStyle = "white";
        ctx.font = "11px Arial";
        ctx.fillText(
          leftValue.toFixed(0) + "%",
          leftWidth / 2 - 10,
          height / 2 + 3,
        );
      }

      // Draw right text if greater than 30%
      if (rightValue / total > 0.3) {
        ctx.fillStyle = "white";
        ctx.font = "11px Arial";
        ctx.fillText(
          rightValue.toFixed(0) + "%",
          leftWidth + rightWidth / 2 - 10,
          height / 2 + 3,
        );
      }
    };

    draw();
  }, [leftValue, rightValue, width, height]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};
