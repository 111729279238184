import { SfChartWithoutInterface } from "@/components/core/charts/sfChartWithoutInterface.tsx";
import { SfTable } from "@/components/core/sfTable.tsx";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable.tsx";
import { ESFTableType, EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import {
  IColumn,
  IRow,
  ISfTable,
} from "@/domain/interfaces/sfTable.interface.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useDashboard } from "@/lib/store.ts";
import { SortingState } from "@tanstack/react-table";
import { debounce } from "lodash";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";

interface IPaneBody {
  data: IRow[];
  tableColumns: IColumn[];
  rowClassCb?: ISfTable<IRow<any>>["rowClassCb"];
  className?: string;
}

function sortData(data: IRow[], sorting: SortingState) {
  return [...data].sort((a, b) => {
    for (let i = 0; i < sorting.length; i++) {
      const { id, desc } = sorting[i];
      if (a[id] < b[id]) {
        return desc ? 1 : -1;
      }
      if (a[id] > b[id]) {
        return desc ? -1 : 1;
      }
    }
    return 0;
  });
}

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).catch((err) => {
    console.error("Failed to copy text: ", err);
  });
};

export function PaneBody({
  data: __data,
  tableColumns,
  rowClassCb,
  className,
}: IPaneBody) {
  const nodeId = usePaneContext();
  const { node, getNodeConfig, updateTabConfig } = useGrid(nodeId);
  const widgetType = node?.getComponent() as EWidgetType;
  const [data, setData] = useState(__data);
  const isPaused = getNodeConfig().paused;
  useEffect(() => {
    if (!isPaused) {
      setData(__data);
    }
  }, [__data, isPaused]);

  const filterByFavorites = getNodeConfig()?.filterByFavorites;
  const columnSizes = useMemo(() => {
    return getNodeConfig()?.columnSizes ?? {};
  }, [getNodeConfig]);

  const { pinned: pinState } = useDashboard((state) => ({
    updatePinned: state.updatePinned,
    pinned:
      state.pinned.find((e) => e.widgetType === widgetType)?.symbols ?? [],
  }));

  const sortingState = useMemo(() => {
    return (getNodeConfig()?.sortingState ?? []) as SortingState;
  }, [getNodeConfig]);

  const updatedData = useMemo(() => {
    const pinned: IRow[] = [];
    const unpinned: IRow[] = [];

    const pinnedSymbolsSet = new Set(pinState);
    const filteredData = data.filter(
      (row) =>
        !filterByFavorites ||
        !filterByFavorites.length ||
        filterByFavorites.includes(row.symbol),
    );

    filteredData.forEach((row) => {
      if (pinnedSymbolsSet.has(row.symbol as string)) {
        pinned.push(row);
        pinnedSymbolsSet.delete(row.symbol as string);
      } else {
        unpinned.push(row);
      }
    });

    pinnedSymbolsSet.forEach((symbol) => {
      pinned.push({ symbol, value: 0 });
    });

    const sortedPinned = sortData(pinned, sortingState);
    const sortedUnpinned = sortData(unpinned, sortingState);

    const combinedData = [...sortedPinned, ...sortedUnpinned];

    const numberOfCharts =
      getNodeConfig()?.numberOfCharts ?? combinedData.length;
    const limitedCharts = combinedData.slice(0, numberOfCharts);

    return {
      pinned: sortedPinned,
      unpinned: sortedUnpinned,
      combinedData: limitedCharts,
    };
  }, [data, pinState, filterByFavorites, sortingState, getNodeConfig]);

  const updatedTableColumns = useMemo(() => {
    return tableColumns.map((column) => {
      if (columnSizes) {
        const size = columnSizes[column?.accessorKey];
        if (size) {
          return { ...column, size };
        }
      }
      return column;
    });
  }, [columnSizes, tableColumns]);

  const style = {
    height: `${100 / updatedData.combinedData.length}%`,
  };

  const onLayout = (sizes: number[]) => {
    updateTabConfig({ resizeArray: sizes });
  };

  const debouncedOnLayout = debounce(onLayout, 300);
  const config = getNodeConfig();
  const tableSize = config?.resizeArray?.[0] ?? 50;
  const chartsSize = config?.resizeArray?.[1] ?? 50;

  const MIN_SIZE_IN_PIXELS = 22;

  const [minSize, setMinSize] = useState(1);

  const { setSymbolForColor } = useDashboard((state) => ({
    setSymbolForColor: state.setSymbolForColor,
  }));

  useLayoutEffect(() => {
    if (!node) {
      return;
    }
    const panelGroup = document.querySelector(
      `[data-panel-group-id="${node?.getId()}"]`,
    );

    const observer = new ResizeObserver(() => {
      const totalHeight = panelGroup
        ? (panelGroup as HTMLElement).offsetHeight
        : 0;

      if (totalHeight > 0) {
        const minSizePercentage = (MIN_SIZE_IN_PIXELS / totalHeight) * 100;
        setMinSize(Math.max(minSizePercentage, 1));
      }
    });

    if (panelGroup) {
      observer.observe(panelGroup);
    }

    return () => {
      observer.disconnect();
    };
  }, [node]);

  if (!node) {
    return null;
  }

  return (
    <ResizablePanelGroup
      direction="vertical"
      onLayout={debouncedOnLayout}
      id={node.getId()}
      className={className}
    >
      {/*table*/}
      <ResizablePanel defaultSize={tableSize} minSize={minSize}>
        <SfTable
          data={{ pinned: updatedData.pinned, unpinned: updatedData.unpinned }}
          columns={updatedTableColumns}
          tableType={[]}
          rowClassCb={rowClassCb}
          onRowClick={(row) => {
            copyToClipboard(row.symbol as string);
            setSymbolForColor(getNodeConfig().color, row.symbol as string);
          }}
        />
      </ResizablePanel>

      {/*charts*/}
      <ResizableHandle
        className="min-h-4 bg-stone-950 hover:bg-stone-600/40 transition duration-250"
        withHandle={true}
      />

      <ResizablePanel defaultSize={chartsSize}>
        {updatedData.combinedData
          .slice(0, updatedData.pinned.length)
          .map((item, i) => (
            <div key={i} style={style} className="relative">
              <div className="text-xs w-full">
                <SfTable
                  data={{ unpinned: [], pinned: [item] }}
                  withHeader={false}
                  tableType={[ESFTableType.chart]}
                  columns={updatedTableColumns}
                  className="pr-2 w-[calc(100%-4px)]"
                  onRowClick={(row) => {
                    copyToClipboard(row.symbol as string);
                    setSymbolForColor(
                      getNodeConfig().color,
                      row.symbol as string,
                    );
                  }}
                />
              </div>
              <div className="h-[calc(100%-16px)] relative">
                <SfChartWithoutInterface symbol={item.symbol as string} />
                <div className="absolute top-0 left-0 w-full h-full" />
              </div>
            </div>
          ))}
        {updatedData.combinedData
          .slice(updatedData.pinned.length)
          .map((item, i) => (
            <div key={i} style={style} className="relative">
              <div className="text-xs w-full">
                <SfTable
                  data={{ unpinned: [item], pinned: [] }}
                  withHeader={false}
                  tableType={[ESFTableType.chart]}
                  columns={updatedTableColumns}
                  className="pr-2 w-[calc(100%-4px)]"
                  onRowClick={(row) => {
                    copyToClipboard(row.symbol as string);
                    setSymbolForColor(
                      getNodeConfig().color,
                      row.symbol as string,
                    );
                  }}
                />
              </div>
              <div className="h-[calc(100%-16px)] relative">
                <SfChartWithoutInterface
                  symbol={item.symbol as string}
                  interval={config?.interval}
                />
                <div className="absolute top-0 left-0 w-full h-full" />
              </div>
            </div>
          ))}
      </ResizablePanel>
    </ResizablePanelGroup>
  );
}
