//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const zScore = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Z-score",
    metainfo: {
      _metainfoVersion: 51,
      id: "z-score@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Z-score",
      shortDescription: "[SF] Z-score",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "zScore",
          type: StudyPlotType.Line,
        },
        {
          id: "upperStDev",
          type: StudyPlotType.Line,
        },
        {
          id: "lowerStDev",
          type: StudyPlotType.Line,
        },
        {
          id: "zScoreColorer",
          type: StudyPlotType.Colorer,
          target: "zScore",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "upperStDev",
          objBId: "lowerStDev",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling Z-Score" },
            1: { name: "Growing Z-Score" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(255, 255, 255, 0.05)",
            visible: true,
            transparency: 5,
          },
        },
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          zScore: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.AreaWithBreaks,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.75)",
          },
          upperStDev: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgba(255, 255, 255, 0.5)",
          },
          lowerStDev: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          length: 240,
          stdDevs: 3.0,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        zScore: {
          title: "Z-Score",
          histogramBase: 0,
        },
        upperStDev: {
          title: "Positive sigma",
          histogramBase: 0,
        },
        lowerStDev: {
          title: "Negative sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "length",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "stdDevs",
          name: "Sigma multiplier",
          defval: 3.0,
          min: 0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const maTypeInput: string = this._input(1);
        const lengthInput: number = this._input(2);
        const stDevNumberInput: number = this._input(3);

        //Market data parsing
        let source: number = 0.0;
        switch (sourceInput) {
          case "open":
            source = PineJS.Std.open(this._context);
            break;
          case "high":
            source = PineJS.Std.high(this._context);
            break;
          case "low":
            source = PineJS.Std.low(this._context);
            break;
          case "close":
            source = PineJS.Std.close(this._context);
            break;
          case "hl2":
            source = PineJS.Std.hl2(this._context);
            break;
          case "hlc3":
            source = PineJS.Std.hlc3(this._context);
            break;
          case "ohlc4":
            source = PineJS.Std.ohlc4(this._context);
            break;
          default:
            source = PineJS.Std.close(this._context);
        }
        const sourceSeries: any = this._context.new_var(source);

        //Calculations
        let ma: number = 0.0;
        switch (maTypeInput) {
          case "SMA":
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
            break;
          case "EMA":
            ma = PineJS.Std.ema(sourceSeries, lengthInput, this._context);
            break;
          case "WMA":
            ma = PineJS.Std.wma(sourceSeries, lengthInput, this._context);
            break;
          case "VWMA":
            ma = PineJS.Std.vwma(sourceSeries, lengthInput, this._context);
            break;
          case "LSMA":
            ma = PineJS.Std.linreg(sourceSeries, lengthInput, 0);
            break;
          default:
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
        }

        const zScore: number =
          (source - ma) /
          PineJS.Std.stdev(sourceSeries, lengthInput, this._context);
        const color: number = zScore > 0 ? 1 : 0;

        //Returns
        return [0, zScore, stDevNumberInput, -stDevNumberInput, color];
      };
    },
  };
};
