//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const alpha = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Alpha",
    metainfo: {
      _metainfoVersion: 51,
      id: "alpha@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Alpha",
      shortDescription: "[SF] α",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTickerInput: "BINANCE:BTCUSDT",
          riskFreeRateInput: 10.95,
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Alpha",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "benchmarkTickerInput",
          name: "Benchmark tiker",
          defval: "BINANCE:BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "riskFreeRateInput",
          name: "Risk free rate",
          defval: 10.95,
          min: 0,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const benchmarkSymbolInput: symbol = this._input(0);
        this._context.new_sym(
          benchmarkSymbolInput,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const riskFreeRateInput: number = this._input(1);
        const lengthInput: number = this._input(2);

        //Market data parsing
        const hlc3Series = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(1);
        const benchmarkTime = this._context.new_var(this._context.symbol.time);
        const benchmarkHlc3Array = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmarkHlc3: number = benchmarkHlc3Array.adopt(
          benchmarkTime,
          mainSymbolTimeSeries,
          0,
        );
        const benchmarkHlc3Series = this._context.new_var(benchmarkHlc3);

        //Risk-free rate calculations
        const time: number = PineJS.Std.time(this._context);
        const timeSeries = this._context.new_var(time);
        const timePeriod: number =
          (time - timeSeries.get(lengthInput)) / 31536000000;
        const riskFreeRate: number = (timePeriod * riskFreeRateInput) / 100;

        //Calculation
        let sum: number = 0.0;
        let sum2Pow: number = 0.0;
        const hlc3Avg: number =
          PineJS.Std.sum(hlc3Series, lengthInput, this._context) / lengthInput;
        const becnhmarkAvg: number =
          PineJS.Std.sum(benchmarkHlc3Series, lengthInput, this._context) /
          lengthInput;
        for (let i = 0; i < lengthInput; i++) {
          const value1 = hlc3Series.get(i);
          const value2 = benchmarkHlc3Series.get(i);
          if (!isNaN(value1) && !isNaN(value2)) {
            sum += (value1 - hlc3Avg) * (value2 - becnhmarkAvg);
            sum2Pow += PineJS.Std.pow(value2 - becnhmarkAvg, 2);
          }
        }
        const beta: number = sum / sum2Pow;
        const portfolioreturn: number =
          ((hlc3Series.get(0) - hlc3Series.get(lengthInput)) /
            hlc3Series.get(lengthInput)) *
          100;
        const benchmarkReturn: number =
          ((benchmarkHlc3Series.get(0) - benchmarkHlc3Series.get(lengthInput)) /
            benchmarkHlc3Series.get(lengthInput)) *
            100 -
          100;
        const alpha: number =
          portfolioreturn -
          (riskFreeRate + beta * (benchmarkReturn - riskFreeRate));
        const alphaColor: number = alpha >= 0 ? 1 : 0;

        //Returns
        return [0, alpha, alphaColor];
      };
    },
  };
};
