import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";
import { merge } from "lodash";

export interface IFundingResponse {
  timestamp: number;
  data: IFunding;
}

export type IFunding = Record<string, number>;

export function useFunding() {
  const handler = useCallback(
    (oldData: IFunding, newData: IFundingResponse) => {
      return merge(oldData, newData.data);
    },
    [],
  );

  return useGenericSocket<IFunding, IFundingResponse>(
    SocketTopics.funding,
    handler,
  );
}
