// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";
import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const openInterest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Open interest",
    metainfo: {
      _metainfoVersion: 51,
      id: "open-interest@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Open interest",
      shortDescription: "[SF] OI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "openInterestOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "openInterestOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "openInterestOhlcPlotcandle",
        },
        {
          id: "oiMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "openInterestUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "openInterestLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling open interest" },
            1: { name: "Growing open interest" },
          },
        },
      },
      ohlcPlots: {
        openInterestOhlcPlotcandle: {
          title: "Open interest plot candle",
        },
      },
      defaults: {
        styles: {
          oiMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          openInterestUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          openInterestLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          openInterestOhlcPlotcandle: {
            borderColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "rgb(149, 152, 161)",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          depthInput: 0,
          metricsBoolInput: false,
        },
      },
      styles: {
        oiMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        openInterestUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        openInterestLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Extremum channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "depthInput",
          name: "Depth",
          defval: 0,
          min: 0,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: true,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string =
          this._input(0) === ``
            ? this._context.symbol.info.name
            : this._input(0);
        const substringToRemove: string = '={"session":"regular","symbol":"';
        const oiSymbol: string = `${symbol.replace(substringToRemove, "").replace('"}', "").split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const chanelTypeInput: string = this._input(4);
        const depthInput: number = this._input(5);
        const metricsBoolInput = this._input(6);

        //Market data parsing
        this._context.select_sym(1);
        const oiTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const oiHighArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const oiLowArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const oiCloseArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );

        let oiOpen: number = NaN;
        let oiHigh: number = NaN;
        let oiLow: number = NaN;
        let oiClose: number = NaN;
        switch (measureInput) {
          case "Coins":
            oiHigh = oiHighArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            oiLow = oiLowArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            oiClose = oiCloseArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            break;
          case "USD":
            oiHigh =
              oiHighArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0) *
              PineJS.Std.high(this._context);
            oiLow =
              oiLowArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0) *
              PineJS.Std.low(this._context);
            oiClose =
              oiCloseArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0) *
              PineJS.Std.close(this._context);
            break;
          default:
            oiHigh = oiHighArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            oiLow = oiLowArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            oiClose = oiCloseArray.adopt(oiTimeArray, mainSymbolTimeSeries, 0);
            break;
        }
        const oiCloseSeries: any = this._context.new_var(oiClose);
        oiOpen = oiCloseSeries.get(1);
        const oiColor: number = oiClose > oiOpen ? 1 : 0;

        if (oiOpen == 0) {
          oiOpen = NaN;
        }
        if (oiHigh == 0) {
          oiHigh = NaN;
        }
        if (oiLow == 0) {
          oiLow = NaN;
        }
        if (oiClose == 0) {
          oiClose = NaN;
        }

        //Derived metrics
        let oiStdev: number = NaN;
        let oiMa: number = NaN;
        let oiUpperBand: number = NaN;
        let oiLowerBand: number = NaN;
        if (metricsBoolInput) {
          const oiHighSeries: any = this._context.new_var(oiHigh);
          const oiLowSeries: any = this._context.new_var(oiLow);
          switch (maTypeInput) {
            case "SMA":
              oiMa = PineJS.Std.sma(oiCloseSeries, lengthInput, this._context);
              break;
            case "EMA":
              oiMa = PineJS.Std.ema(oiCloseSeries, lengthInput, this._context);
              break;
            case "WMA":
              oiMa = PineJS.Std.wma(oiCloseSeries, lengthInput, this._context);
              break;
            case "VWMA":
              oiMa = PineJS.Std.vwma(oiCloseSeries, lengthInput, this._context);
              break;
            case "LSMA":
              oiMa = PineJS.Std.linreg(oiCloseSeries, lengthInput, 0);
              break;
            default:
              oiMa = PineJS.Std.sma(oiCloseSeries, lengthInput, this._context);
              break;
          }
          switch (chanelTypeInput) {
            case "Extremum channel":
              oiUpperBand = PineJS.Std.highest(
                oiHighSeries,
                lengthInput,
                this._context,
              );
              oiLowerBand = PineJS.Std.lowest(
                oiLowSeries,
                lengthInput,
                this._context,
              );
              if (oiUpperBand == 0) {
                oiUpperBand = NaN;
              }
              if (oiLowerBand == 0) {
                oiLowerBand = NaN;
              }
              break;
            case "Bollinger channel":
              oiStdev = PineJS.Std.stdev(
                oiCloseSeries,
                lengthInput,
                this._context,
              );
              oiUpperBand = oiMa + oiStdev;
              oiLowerBand = oiMa - oiStdev;
              break;
            default:
              oiStdev = PineJS.Std.stdev(
                oiCloseSeries,
                lengthInput,
                this._context,
              );
              oiUpperBand = oiMa + oiStdev;
              oiLowerBand = oiMa - oiStdev;
              break;
          }
        }

        if (depthInput != 0) {
          if (
            Date.now() - PineJS.Std.time(this._context) >
            depthInput * 1000 * 60
          ) {
            return [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN];
          }
        }

        //Returns
        return [
          oiOpen,
          oiHigh,
          oiLow,
          oiClose,
          oiColor,
          oiColor,
          oiColor,
          oiMa,
          oiUpperBand,
          oiLowerBand,
        ];
      };
    },
  };
};
