import { colors, widgets } from "@/domain/constants.ts";
import {
  IDashboardStore,
  ILayout,
  IDashboard,
} from "@/domain/interfaces/dashboard.interface.ts";
import { db } from "@/lib/firebase.ts";
import { queryClient } from "@/lib/queryClient.ts";
import { User } from "@firebase/auth";
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
} from "firebase/firestore";
import { cloneDeep, last } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { GLOBAL_FILTERS } from "@/domain/constants";

interface DrawSignalState {
  signalToDraw: { signal: string | null; color: string | null };
  setSignalToDraw: (signal: string | null, color: string | null) => void;
  clearSignal: () => void;
}

export const useDrawSignal = create<DrawSignalState>((set) => ({
  signalToDraw: { signal: null, color: null },
  setSignalToDraw: (signal, color) => set({ signalToDraw: { signal, color } }),
  clearSignal: () => set({ signalToDraw: { signal: null, color: null } }),
}));

const getDashboardDocRef = () => {
  const user = queryClient.getQueryData<User>(["user"]);
  if (user?.uid) {
    return doc(
      db,
      "dashboards2",
      user.uid,
    ) as DocumentReference<IDashboardStore>;
  } else {
    return null;
  }
};

const getLayoutDocRef = (id: string) => {
  const dashboardRef = getDashboardDocRef();
  if (dashboardRef) {
    return doc(dashboardRef, "layouts", id) as DocumentReference<ILayout>;
  } else {
    return null;
  }
};

const getLayoutsCollectionRef = () => {
  const dashboardRef = getDashboardDocRef();
  if (dashboardRef) {
    return collection(dashboardRef, "layouts") as CollectionReference<ILayout>;
  } else {
    return null;
  }
};

const removeFromFirestoreLayout = async (id: string) => {
  const ref = getLayoutDocRef(id);
  if (ref) {
    await deleteDoc(ref).then(() => {
      console.log("active layout removed", id);
    });
  }
};

const emptyLayout: ILayout = {
  name: "New Layout",
  id: "",
  createdAt: Date.now(),
  json: {
    type: "row",
    weight: 100,
    children: [],
  },
};

const initialLayouts: ILayout[] = [
  {
    name: "Initial Layout",
    id: "initial-layout",
    createdAt: Date.now(),
    json: {
      type: "row",
      id: "#de7dbc64-a10f-408b-a02b-a7d61fe39ed1",
      children: [
        {
          type: "tabset",
          id: "#d0f53544-0cc9-4d9c-a069-a966fdcbe8e3",
          weight: 25,
          children: [
            {
              type: "tab",
              id: "#initSFS",
              name: "spotFuturesSpread",
              component: "spotFuturesSpread",
              config: widgets.spotFuturesSpread.config,
            },
          ],
        },
        {
          type: "tabset",
          id: "#8ebadcbc-c602-4b51-a547-a87eb9899c52",
          weight: 25,
          children: [
            {
              type: "tab",
              id: "#initStopKiller",
              name: "stopKiller",
              component: "stopKiller",
              config: widgets.stopKiller.config,
            },
          ],
          active: true,
        },
        {
          type: "tabset",
          id: "#b259a8a6-0de0-45ca-8345-ea9df49b926f",
          weight: 25,
          children: [
            {
              type: "tab",
              id: "#initStraregies",
              name: "strategies",
              component: "strategiesV2",
              config: widgets.strategiesV2.config,
            },
          ],
        },
        {
          type: "tabset",
          id: "#initControlPanel",
          weight: 25,
          children: [
            {
              type: "tab",
              id: "#82a608ea-c381-4608-a418-df0fd312a35c",
              name: "ControlPanel",
              component: "controlPanel",
              config: widgets.controlPanel.config,
            },
          ],
        },
      ],
    },
  },
];

export const useDashboard = create<IDashboardStore>()(
  // TODO move to useDashboard.ts hook
  subscribeWithSelector(
    persist(
      devtools(
        immer((set, get) => ({
          layouts: initialLayouts,
          layoutsOrder: ["initial-layout"],
          activeLayoutId: "initial-layout",
          colorsToSymbolMap: {
            [colors[0]]: "BTCUSDT",
          },
          indicatorsToColorMap: {},
          settings: {},
          favorites: [],
          globalFilters: GLOBAL_FILTERS,
          blacklist: [],
          favoritesSettings: {
            activeColumns: [],
          },
          pinned: [],
          setActiveLayoutId: (layoutId) => {
            const layout = get().layouts.find((l) => l.id === layoutId);
            if (!layout) {
              console.error("Layout not found", layoutId);
              return;
            }
            set((state) => {
              state.activeLayoutId = layoutId;
            });
          },
          addLayout: async (_layout = emptyLayout) => {
            const layout = cloneDeep(_layout);
            if (!layout.id.length) {
              layout.id = uuidv4();
            }
            layout.createdAt = Date.now();

            set((state) => {
              state.layouts.push(layout);
              state.activeLayoutId = layout.id;
              state.layoutsOrder = [...state.layoutsOrder, layout.id];
            });
          },
          removeActiveLayout: async () => {
            console.log("remove layout");
            const oldState = get();
            const idToRemove = oldState.activeLayoutId;

            if (!idToRemove || oldState.layouts.length <= 1) {
              console.log("Can't remove the last layout");
              return;
            }

            const filteredLayoutsOrder = oldState.layoutsOrder.filter(
              (id) => id !== idToRemove,
            );
            const newActiveLayoutId = last(filteredLayoutsOrder);

            set((state) => {
              if (newActiveLayoutId) {
                const activeLayout = state.layouts.find(
                  (layout) => layout.id === newActiveLayoutId,
                ) as ILayout;
                if (!activeLayout) {
                  console.error("Active layout not found", newActiveLayoutId);
                  return;
                }
                state.activeLayoutId = newActiveLayoutId;

                state.layoutsOrder = filteredLayoutsOrder;
              }
              state.layouts = oldState.layouts.filter(
                (layout) => layout.id !== idToRemove,
              );
            });
          },
          updateActiveLayout: async (cb) => {
            set((state) => {
              const activeLayout = state.layouts.find(
                (layout) => layout.id === state.activeLayoutId,
              );
              if (!activeLayout) {
                console.error("Active layout not found", state.activeLayoutId);
                return;
              }
              cb(activeLayout);
            });
          },
          updateDashboardState: async (cb) => {
            console.log("update dashboard state");
            set(cb);
          },
          updatePinned: (cb) => {
            set((state) => {
              cb(state.pinned);
            });
          },
          addFavoriteFolder: (folder) => {
            set((state) => {
              state.favorites.push({
                id: uuidv4(),
                ...folder,
                expanded: true,
                symbols: folder.symbols ? [...folder.symbols] : [],
              });
            });
          },
          removeFavoriteFolder: (folderId) => {
            set((state) => {
              state.favorites = state.favorites.filter(
                (f) => f.id !== folderId,
              );
            });
          },
          addSymbolToBlacklist: (symbol) => {
            set((state) => {
              state.blacklist.push(symbol);
            });
          },
          removeSymbolFromBlacklist: (symbol) => {
            set((state) => {
              state.blacklist = state.blacklist.filter((s) => s !== symbol);
            });
          },
          updateFavorites: (cb) => {
            set((state) => {
              cb(state.favorites);
            });
          },
          updateFavoritesSettings: (cb) => {
            set((state) => {
              cb(state.favoritesSettings);
            });
          },
          addSymbolsToFavorites: (folderId, symbolNames) => {
            set((state) => {
              const folder = state.favorites.find((f) => f.id === folderId);
              if (!folder) {
                // create new folder
                state.favorites.push({
                  id: uuidv4(),
                  name: "New Folder",
                  color: "",
                  expanded: true,
                  symbols: symbolNames,
                });
              } else {
                folder.symbols = [...folder.symbols, ...symbolNames];
              }
            });
          },
          removeSymbolFromFavorites: (folderId, symbolId) => {
            set((state) => {
              const folder = state.favorites.find((f) => f.id === folderId);
              if (!folder) {
                return;
              }
              folder.symbols = folder.symbols.filter((s) => s !== symbolId);
            });
          },
          setSymbolForColor: (color, symbol) => {
            set((state) => {
              state.colorsToSymbolMap[color] = symbol;
            });
          },
          addIndicatorForColor: (color, indicator) => {
            set((state) => {
              state.indicatorsToColorMap[color] = [
                ...(state.indicatorsToColorMap[color] || []),
                indicator,
              ];
            });
          },
          updateGlobalFilter: (index: number, value: string) =>
            set((state) => ({
              globalFilters: state.globalFilters.map((filter, i) =>
                i === index ? { ...filter, value } : filter,
              ),
            })),
        })),
      ),
      {
        version: 1,
        name: "sf-state",
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

//   function dashboardStateMiddlewares(
//   newState: IDashboardStore,
//   oldState: IDashboardStore,
// ) {
//   console.log("dashboardStateMiddlewares", newState, oldState);
//   return newState;
// }

// function activeLayoutStateMiddlewares(
//   newState: ILayout | undefined,
//   oldState: ILayout | undefined,
// ) {}
//
// export function useStoreMiddlewares() {
//   useEffect(() => {
//     //     const unsubscribeDashboard = useDashboard.subscribe(
//     // (state) => state.activeLayoutId,
//     //       dashboardStateMiddlewares,
//     //     );
//
//     const unsubscribeLayout = useDashboard.subscribe(
//       (state) =>
//         state.layouts.find((layout) => layout.id === state.activeLayoutId),
//       activeLayoutStateMiddlewares,
//       { equalityFn: isEqual },
//     );
//
//     return () => {
//       unsubscribeDashboard();
//       unsubscribeLayout();
//     };
//   }, []);
// }
