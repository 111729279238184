//eslint-disable-next-line
//@ts-nocheck
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const liquidations = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Liquidations",
    metainfo: {
      _metainfoVersion: 51,
      id: "liquidations@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Liquidations",
      shortDescription: "[SF] Liquidations",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "longLiquidationsPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "shortLiquidationsPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          longLiquidationsPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          shortLiquidationsPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        longLiquidationsPlot: {
          title: "Long liquidations",
          histogramBase: 0,
        },
        shortLiquidationsPlot: {
          title: "Short liquidations",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_LIQUIDATIONS`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Input
        const measureInput: string = this._input(0);

        //Market data parsing
        let buyLiquidationsArray: any = this._context.new_var(0.0);
        let sellLiquidationsArray: any = this._context.new_var(0.0);
        this._context.select_sym(1);
        const liquidationsTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        if (measureInput === "Coin") {
          buyLiquidationsArray = this._context.new_var(
            PineJS.Std.open(this._context),
          );
          sellLiquidationsArray = this._context.new_var(
            PineJS.Std.high(this._context),
          );
        } else {
          buyLiquidationsArray = this._context.new_var(
            PineJS.Std.low(this._context),
          );
          sellLiquidationsArray = this._context.new_var(
            PineJS.Std.close(this._context),
          );
        }
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const buyLiquidations: number = buyLiquidationsArray.adopt(
          liquidationsTime,
          mainSymbolTime,
          1,
        );
        const sellLiquidations: number = -sellLiquidationsArray.adopt(
          liquidationsTime,
          mainSymbolTime,
          1,
        );

        //Returns
        return [0, sellLiquidations, buyLiquidations];
      };
    },
  };
};
