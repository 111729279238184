//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const cumulativeStopKillerDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Cumulative stop killer delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "cumulative-stop-killer-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Cumulative stop killer delta",
      shortDescription: "[SF] CSKD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "cskdOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "cskdOhlcPlotcandle",
        },
        {
          id: "cskdMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cskdUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "cskdLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling CSKD" },
            1: { name: "Growing CSKD" },
          },
        },
      },
      ohlcPlots: {
        cskdOhlcPlotcandle: {
          title: "CSKD plot candle",
        },
      },
      defaults: {
        styles: {
          cskdMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cskdUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          cskdLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          cskdOhlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
          // sessionBreakInput: false
        },
      },
      styles: {
        cskdMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        cskdUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        cskdLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
        // {
        //   id: 'sessionBreakInput',
        //   name: 'Intraday calculations?',
        //   defval: false,
        //   type: StudyInputType.Bool
        // }
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const stopkillerSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#STOPKILLER`;
        this._context.new_sym(
          stopkillerSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const measureInput: string = this._input(0);
        const maType: string = this._input(1);
        const length: number = this._input(2);
        const chanelType: string = this._input(3);
        const metricsBoolInput: boolean = this._input(4);
        //const sessionBreakInput: boolean = this._input(5);

        //Market data parsing
        this._context.select_sym(1);
        const stopkillerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShortArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const significantLongArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const criticalShortArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const criticalLongArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_var(this._context.symbol.time);
        const significantShort: number =
          significantShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const significantLong: number =
          significantLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalShort: number =
          criticalShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalLong: number =
          criticalLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;

        //Calculations
        let buyStoploss: number = significantShort + criticalShort;
        let sellStoploss: number = significantLong + criticalLong;
        if (measureInput === "Coins") {
          const hlc3: number = PineJS.Std.hlc3(this._context);
          buyStoploss /= hlc3;
          sellStoploss /= hlc3;
        }
        const stoplossDelta: number = buyStoploss + sellStoploss || 0;
        const cskdClose: number = PineJS.Std.cum(stoplossDelta, this._context);
        // if (sessionBreakInput === false){
        //   cskdClose = PineJS.Std.cum(stoplossDelta, this._context);
        // } else {
        //   const volumeDeltaSeries = this._context.new_var(this._context.symbol.time);
        //   volumeDeltaSeries.set(stoplossDelta);
        //   let barsSinceStartOfDay: number = 1;
        //   const dayOfWeek: number = PineJS.Std.dayofweek(this._context);
        //   const dayOfWeekSeries = this._context.new_var(0);
        //   dayOfWeekSeries.set(dayOfWeek);
        //   if (PineJS.Std.change(dayOfWeekSeries) != 0) {
        //     barsSinceStartOfDay = 1;
        //     cskdClose = NaN
        //   } else {
        //     barsSinceStartOfDay ++;
        //     //cskdClose = PineJS.Std.sum(volumeDeltaSeries, barsSinceStartOfDay, this._context);
        //   }
        //   cskdClose = PineJS.Std.sum(volumeDeltaSeries, barsSinceStartOfDay, this._context);
        // }

        const cskdCloseSeries: any = this._context.new_var(cskdClose);
        const cskdOpen: number = cskdCloseSeries.get(1);
        const cskdHigh: number = PineJS.Std.max(cskdOpen, cskdClose);
        const cskdHighSeries = this._context.new_var(cskdHigh);
        const cskdLow: number = PineJS.Std.min(cskdOpen, cskdClose);
        const cskdLowSeries: any = this._context.new_var(cskdLow);
        const cskdColor: number = cskdClose > cskdOpen ? 1 : 0;

        //Derivative metrics
        let cskdStdev: number = NaN;
        let cskdMa: number = NaN;
        let cskdUpperBand: number = NaN;
        let cskdLowerBand: number = NaN;
        if (metricsBoolInput) {
          switch (maType) {
            case "SMA":
              cskdMa = PineJS.Std.sma(cskdCloseSeries, length, this._context);
              break;
            case "EMA":
              cskdMa = PineJS.Std.ema(cskdCloseSeries, length, this._context);
              break;
            case "WMA":
              cskdMa = PineJS.Std.wma(cskdCloseSeries, length, this._context);
              break;
            case "VWMA":
              cskdMa = PineJS.Std.vwma(cskdCloseSeries, length, this._context);
              break;
            case "LSMA":
              cskdMa = PineJS.Std.linreg(cskdCloseSeries, length, 0);
              break;
            default:
              cskdMa = PineJS.Std.sma(cskdCloseSeries, length, this._context);
              break;
          }
          switch (chanelType) {
            case "Extremum channel":
              cskdUpperBand = PineJS.Std.highest(
                cskdHighSeries,
                length,
                this._context,
              );
              cskdLowerBand = PineJS.Std.lowest(
                cskdLowSeries,
                length,
                this._context,
              );
              break;
            case "Bollinger channel":
              cskdStdev = PineJS.Std.stdev(
                cskdCloseSeries,
                length,
                this._context,
              );
              cskdUpperBand = cskdMa + cskdStdev;
              cskdLowerBand = cskdMa - cskdStdev;
              break;
            default:
              cskdStdev = PineJS.Std.stdev(
                cskdCloseSeries,
                length,
                this._context,
              );
              cskdUpperBand = cskdMa + cskdStdev;
              cskdLowerBand = cskdMa - cskdStdev;
          }
        }

        //Returns
        return [
          cskdOpen,
          cskdHigh,
          cskdLow,
          cskdClose,
          cskdColor,
          cskdColor,
          cskdColor,
          cskdMa,
          cskdUpperBand,
          cskdLowerBand,
        ];
      };
    },
  };
};
