//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import { group } from "console";

export const fundingRate = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Funding rate",
    metainfo: {
      _metainfoVersion: 51,
      id: "funding-rate@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Funding rate",
      shortDescription: "[SF] Funding",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: "percent",
        precision: 2,
      },
      plots: [
        {
          id: "fundingRatePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "fundingRateColorer",
          type: StudyPlotType.Colorer,
          target: "fundingRatePlot",
          palette: "fundingRatePalette",
        },
        {
          id: "ohlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "ohlcPlotcandle",
        },
        {
          id: "ohlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "ohlcPlotcandle",
        },
        {
          id: "predictFundingRatePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "spreadPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "defaultValuePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "upperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "lowerBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      ohlcPlots: {
        ohlcPlotcandle: {
          title: "Funding rate candle",
        },
      },
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Background of the default range",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        fundingRatePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(120, 123, 134, 0.05)",
            visible: true,
            transparency: 5,
          },
        },
        palettes: {
          fundingRatePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          fundingRatePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          fundingRateColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          predictFundingRatePlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgba(255, 160, 40, 0.5)",
          },
          spreadPlot: {
            linestyle: 0,
            visible: false,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgba(13, 157, 219, 0.5)",
          },
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          defaultValuePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          upperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          lowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
        },
        ohlcPlots: {
          ohlcPlotcandle: {
            borderColor: "#737375",
            color: "#737375",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "#737375",
          },
        },
        precision: 2,
        inputs: {
          symbolInput: "",
          fundingRateMaxValueInput: 2.5,
          payoutFrequencyInput: 8,
          defaultValueInput: 0.01,
          upperValueInput: 0.06,
          lowerValueInput: -0.04,
        },
      },
      styles: {
        fundingRatePlot: {
          title: "Funding rate",
          histogramBase: 0,
        },
        predictFundingRatePlot: {
          title: "Predict funding rate",
          histogramBase: 0,
        },

        spreadPlot: {
          title: "Spot perp spread",
          histogramBase: 0,
        },
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        defaultValuePlot: {
          title: "Default value",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        backgroundColorer: {
          title: "Background highlighter",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "symbolInput",
          name: "Symbol",
          defval: "",
          type: StudyInputType.Symbol,
          group: "Main settings",
        },
        {
          id: "fundingRateMaxValueInput",
          name: "Max funding rate value",
          defval: 2.5,
          min: 0,
          type: StudyInputType.Float,
          group: "Main settings",
        },
        {
          id: "payoutFrequencyInput",
          name: "Payout frequency",
          defval: 8,
          min: 1,
          max: 24,
          type: StudyInputType.Integer,
          group: "Main settings",
        },
        {
          id: "defaultValueInput",
          name: "Default funding rate value",
          defval: 0.01,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
        {
          id: "upperValueInput",
          name: "Upper band value",
          defval: 0.06,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
        {
          id: "lowerValueInput",
          name: "Lower band value",
          defval: -0.04,
          type: StudyInputType.Float,
          group: "Additional settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        const symbol: string =
          this._input(0) === ``
            ? this._context.symbol.info.name
            : this._input(0);
        const substringToRemove: string = '={"session":"regular","symbol":"';

        const fundingRateSymbol: string = `${symbol.replace(substringToRemove, "").split("USDT")[0]}#FUNDINGRATE`;
        this._context.new_sym(
          fundingRateSymbol,
          PineJS.Std.period(this._context),
        );

        const spotFutureSpreadSymbol: string = `${symbol.replace(substringToRemove, "").split("USDT")[0]}#SF_SPREAD`;
        this._context.new_sym(
          spotFutureSpreadSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const fundingRateMaxValueInput: number = this._input(1);
        const payoutFrequencyInput: number = this._input(2);
        const defaultValueInput: number = this._input(3);
        const upperValueInput: number = this._input(4);
        const lowerValueInput: number = this._input(5);

        // Function imporfunction
        function Clamp(value: number, min: number, max: number): number {
          let result: number = value;
          if (value < min) {
            result = min;
          } else if (result > max) {
            result = max;
          }
          return result;
        }

        function Iwma(source: number, length: number): number {
          const sourceSeries: any = context.new_var(source);
          let cum: number = 0.0;

          for (let i: number = 1; i < length; i++) {
            cum += sourceSeries.get(length - 1) * i;
          }
          cum = cum / (((length + 1) * length) / 2);

          return cum;
        }

        //Market data parsing
        this._context.select_sym(1);
        const fundingRateTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const fundingRateArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );

        this._context.select_sym(2);
        const spotFuturesSpreadTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const spotFuturesSpreadArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );

        const fundingRate: number =
          fundingRateArray.adopt(fundingRateTime, mainSymbolTime, 0) * 100;
        const fundingRateSeries: any = this._context.new_var(fundingRate);

        const spotFuturesSpread: number = -spotFuturesSpreadArray.adopt(
          spotFuturesSpreadTime,
          mainSymbolTime,
          0,
        );

        const spotFuturesSpreadSeries: any =
          this._context.new_var(spotFuturesSpread);

        // Calculations
        const fundingRateOpen: number = fundingRateSeries.get(1);
        const fundingRateHigh: number = Math.max(fundingRateOpen, fundingRate);
        const fundingRateLow: number = Math.min(fundingRateOpen, fundingRate);
        const fundingRateOhlcColor: number =
          fundingRate >= fundingRateOpen ? 1 : 0;

        const syntheticPiOpen: number = spotFuturesSpreadSeries.get(1);
        const syntheticPiClose: number = spotFuturesSpread;

        const payoutFrequency: number = payoutFrequencyInput / 8;
        const length: number =
          (480 / Number(PineJS.Std.period(this._context))) * payoutFrequency;

        const piOpenAvg: number = Iwma(syntheticPiOpen, length);
        const piCloseAvg: number = Iwma(syntheticPiClose, length);

        const piAvg: number = (piOpenAvg + piCloseAvg) / 2;
        let predictFundingRate: number =
          piAvg + Clamp(0.01 - piAvg, -0.05, 0.05);

        predictFundingRate =
          Clamp(
            predictFundingRate,
            -fundingRateMaxValueInput,
            fundingRateMaxValueInput,
          ) * payoutFrequency;

        const fundingRateColor: number = fundingRate > 0 ? 1 : 0;

        let backgroundColor: number = NaN;
        if (fundingRate > upperValueInput) {
          backgroundColor = 1;
        } else if (fundingRate < lowerValueInput) {
          backgroundColor = 0;
        }

        //Returns
        return [
          fundingRate,
          fundingRateColor,
          fundingRateOpen,
          fundingRateHigh,
          fundingRateOpen,
          fundingRate,
          fundingRateOhlcColor,
          fundingRateOhlcColor,
          fundingRateOhlcColor,
          predictFundingRate,
          spotFuturesSpread,
          0,
          defaultValueInput,
          upperValueInput,
          lowerValueInput,
          backgroundColor,
        ];
      };
    },
  };
};
