export enum FilledAreaType {
  TypePlots = "plot_plot",
  TypeHlines = "hline_hline",
}

export enum LineStudyPlotStyle {
  /**
   * Line plot style.
   */
  Line = 0,
  /**
   * Histogram plot style.
   */
  Histogram = 1,
  /**
   * Cross plot style.
   */
  Cross = 3,
  /**
   * Area plot style.
   */
  Area = 4,
  /**
   * Column plot style.
   */
  Columns = 5,
  /**
   * Circles plot style.
   */
  Circles = 6,
  /**
   * Line with breaks plot style.
   */
  LineWithBreaks = 7,
  /**
   * Area with breaks plot style.
   */
  AreaWithBreaks = 8,
  /**
   * Step line plot style.
   */
  StepLine = 9,
  /**
   * Step line with diamonds plot style.
   */
  StepLineWithDiamonds = 10,
  /**
   * Step line with breaks, like LineWithBreaks
   */
  StepLineWithBreaks = 11,
}
export enum LineStyle {
  /**
   * Solid line style.
   */
  Solid = 0,
  /**
   * Dotted line style.
   */
  Dotted = 1,
  /**
   * Dashed line style.
   */
  Dashed = 2,
}


export const enum OhlcStudyPlotStyle {
  OhlcBars = "ohlc_bars",
  OhlcCandles = "ohlc_candles",
}

export const enum StudyInputType {
  Integer = "integer",
  Float = "float",
  Price = "price",
  Bool = "bool",
  Text = "text",
  Symbol = "symbol",
  Session = "session",
  Source = "source",
  Resolution = "resolution",
  Time = "time",
  BarTime = "bar_time",
  Color = "color",
  Textarea = "text_area",
}

export enum StudyPlotType {
  Line = "line",
  Colorer = "colorer",
  BarColorer = "bar_colorer",
  BgColorer = "bg_colorer",
  TextColorer = "text_colorer",
  OhlcColorer = "ohlc_colorer",
  CandleWickColorer = "wick_colorer",
  CandleBorderColorer = "border_colorer",
  UpColorer = "up_colorer",
  DownColorer = "down_colorer",
  Shapes = "shapes",
  Chars = "chars",
  Arrows = "arrows",
  Data = "data",
  DataOffset = "dataoffset",
  OhlcOpen = "ohlc_open",
  OhlcHigh = "ohlc_high",
  OhlcLow = "ohlc_low",
  OhlcClose = "ohlc_close",
}

export enum StudyTargetPriceScale {
  Right = 0,
  Left = 1,
  NoScale = 2,
}
