import React, { useMemo } from "react";
import { useSpotFuturesSpread } from "@/hooks/useSpotFuturesSpread.ts";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn } from "@/domain/interfaces/sfTable.interface.ts";
import { ISpotFuturesSpread } from "@/domain/interfaces/general.interface.ts";
import { IFunding, useFunding } from "@/hooks/useFunding.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { useFilterStream } from "@/hooks/useFilterStream.ts";
import { applyFilters } from "@/lib/utils.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

type t = ReturnType<typeof prepareData>;

const columnHelper = createColumnHelper<t>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),
  columnHelper.accessor("spread", {
    header: "Spread",
    cell: (row) => {
      const value = row.getValue() as string;
      // @ts-expect-error - Access the sf value from the row data
      const sfValue = row.row.original.sf; // Access the sf value from the row data

      switch (sfValue) {
        case "F":
          return <div className="font-semibold text-[#0d9ddb]">{value}</div>;
        case "S":
          return <div className="font-semibold text-[#FFA028]">{value}</div>;
        default:
          return <div className="font-semibold">{value}</div>;
      }
    },
    size: 50,
    minSize: 20,
  }),
  columnHelper.accessor("funding", {
    header: "F %",
    cell: (row) => {
      const value = row.getValue() as number;
      return <div>{value ? value.toFixed(3) + "%" : "--"}</div>;
    },
    size: 60,
    minSize: 20,
  }),
];

const prepareData = (
  spreadData: ISpotFuturesSpread[],
  fundingData: IFunding,
  adtv15m: Record<string, number>,
  adtv30m: Record<string, number>,
  adtv1h: Record<string, number>,
  adtv4h: Record<string, number>,
  adtv1d: Record<string, number>,
  adtv1w: Record<string, number>,
  adtv1M: Record<string, number>,
  volume15m: Record<string, number>,
  volume30m: Record<string, number>,
  volume1h: Record<string, number>,
  volume4h: Record<string, number>,
  volume1d: Record<string, number>,
  volume1w: Record<string, number>,
  volume1M: Record<string, number>,
) => {
  return spreadData.map((item) => {
    let spread = item.value.toFixed(2);
    if (spread.includes("e")) {
      const m = spread.split("e")[1];
      spread = item.value.toFixed(Math.abs(parseInt(m)));
    }
    let sf = item.value > item.sma ? "S" : "F";
    if (item.sma === item.value) {
      sf = "=";
    }
    return {
      symbol: item.symbol,
      funding: fundingData[item.symbol] * 100 || 0,
      sf,
      spread,
      rawSpread: item.value,
      adtv15m: adtv15m[item.symbol] || 0,
      adtv30m: adtv30m[item.symbol] || 0,
      adtv1h: adtv1h[item.symbol] || 0,
      adtv4h: adtv4h[item.symbol] || 0,
      adtv1d: adtv1d[item.symbol] || 0,
      adtv1w: adtv1w[item.symbol] || 0,
      adtv1M: adtv1M[item.symbol] || 0,
      volume15m: volume15m[item.symbol] || 0,
      volume30m: volume30m[item.symbol] || 0,
      volume1h: volume1h[item.symbol] || 0,
      volume4h: volume4h[item.symbol] || 0,
      volume1d: volume1d[item.symbol] || 0,
      volume1w: volume1w[item.symbol] || 0,
      volume1M: volume1M[item.symbol] || 0,
    };
  });
};

export function SpotFuturesSpreadPaneBody() {
  const { data: spreadData, isLoading: spotFuturesLoading } =
    useSpotFuturesSpread();
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");
  const { data: fundingData, isLoading: fundingLoading } = useFunding();
  const { blacklist } = useDashboard();
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const isLoading = spotFuturesLoading || fundingLoading;

  const preparedData = useMemo(() => {
    if (
      !spreadData ||
      !fundingData ||
      !adtv15m ||
      !adtv30m ||
      !adtv1h ||
      !adtv4h ||
      !adtv1d ||
      !adtv1w ||
      !adtv1M ||
      !volume15m ||
      !volume30m ||
      !volume1h ||
      !volume4h ||
      !volume1d ||
      !volume1w ||
      !volume1M
    ) {
      return [];
    }
    let data = prepareData(
      spreadData,
      fundingData,
      adtv15m,
      adtv30m,
      adtv1h,
      adtv4h,
      adtv1d,
      adtv1w,
      adtv1M,
      volume15m,
      volume30m,
      volume1h,
      volume4h,
      volume1d,
      volume1w,
      volume1M,
    );

    if (blacklist.length > 0) {
      data = data.filter((item) => !blacklist.includes(item.symbol));
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<any>(data, config.filters, config.filterValues);
    }

    return data;
  }, [
    spreadData,
    fundingData,
    adtv15m,
    adtv30m,
    adtv1h,
    adtv4h,
    adtv1d,
    adtv1w,
    adtv1M,
    volume15m,
    volume30m,
    volume1h,
    volume4h,
    volume1d,
    volume1w,
    volume1M,
    blacklist,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
