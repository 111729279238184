//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const valueAtRisk = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Value at risk",
    metainfo: {
      _metainfoVersion: 51,
      id: "value-at-risk@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Value at risk",
      shortDescription: "[SF] VaR",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLineWithBreaks,
            trackPrice: true,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTuckerInput: "BINANCE:BTCUSDT",
          riskFreeRateInput: 10.95,
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "VaR",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "benchmarkTuckerInput",
          name: "Benchmark tiker",
          defval: "BINANCE:BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "riskFreeRateInput",
          name: "Risk free rate",
          defval: 10.95,
          min: 0,
          type: StudyInputType.Float,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const benchmarkSymbolInput: symbol = this._input(0);
        this._context.new_sym(
          benchmarkSymbolInput,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const riskFreeRateInput: number = this._input(1);
        const lengthInput: number = this._input(2);

        //Market data parsing
        const hlc3: number = PineJS.Std.hlc3(this._context);
        const hlc3Series: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(1);
        const benchmarkTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmarkArray: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmark: number = benchmarkArray.adopt(
          benchmarkTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        const benchmarkSeries: any = this._context.new_var(benchmark);

        //Risk-free rate calculations
        const time: number = PineJS.Std.time(this._context);
        const timeSeries: any = this._context.new_var(time);
        const timePeriod: number =
          (time - timeSeries.get(lengthInput)) / 31536000000;
        const riskFreeRate: number = (timePeriod * riskFreeRateInput) / 100;

        //Risk premium calculation
        const hlc3Avg: number =
          PineJS.Std.sum(hlc3Series, lengthInput, this._context) / lengthInput;
        const deviation: number = (hlc3 - hlc3Avg) / hlc3;
        const deviationSeries: any = this._context.new_var(deviation);
        const portfolioReturn: number =
          deviation - deviationSeries.get(lengthInput);
        const becnhmarkAvg: number =
          PineJS.Std.sum(benchmarkSeries, lengthInput, this._context) /
          lengthInput;
        const deviationBenchmark: number =
          (benchmark - becnhmarkAvg) / benchmark;
        const deviationBenchmarkSeries: any =
          this._context.new_var(deviationBenchmark);
        const benchmarkReturn: number =
          deviationBenchmark - deviationBenchmarkSeries.get(lengthInput);
        const valueAtRisk: number =
          (portfolioReturn - benchmarkReturn) * 100 - riskFreeRate;
        const valueAtRiskColor: number = valueAtRisk >= 0 ? 1 : 0;

        //Returns
        return [0, valueAtRisk, valueAtRiskColor];
      };
    },
  };
};
