//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const stopKiller = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Stop killer",
    metainfo: {
      _metainfoVersion: 51,
      id: "stop-killer@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Stop killer",
      shortDescription: "[SF] Stop killer ",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "volume",
        precision: 0,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "sellVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyVolumePlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          sellVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          buyVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
        },
        precision: 2,
        inputs: {
          measureInput: "USD",
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        sellVolumePlot: {
          title: "Long stoplosses",
          histogramBase: 0,
        },
        buyVolumePlot: {
          title: "Short stoplosses",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const stopkillerSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#STOPKILLER`;
        this._context.new_sym(
          stopkillerSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const measureInput: string = this._input(0);

        //Market data parsing
        this._context.select_sym(1);
        const stopkillerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShortArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const significantLongArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const criticalShortArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        const criticalLongArray: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const significantShort: number =
          significantShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const significantLong: number =
          significantLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalShort: number =
          criticalShortArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;
        const criticalLong: number =
          criticalLongArray.adopt(stopkillerTime, mainSymbolTime, 1) || 0;

        //Calculations
        let buyStoploss: number = significantShort + criticalShort;
        let sellStoploss: number = significantLong + criticalLong;
        if (measureInput === "Coins") {
          const hlc3: number = PineJS.Std.hlc3(this._context);
          buyStoploss /= hlc3;
          sellStoploss /= hlc3;
        }
        if (buyStoploss === 0) {
          buyStoploss = NaN;
        }
        if (sellStoploss === 0) {
          sellStoploss = NaN;
        }

        //Returns
        return [0, sellStoploss, buyStoploss];
      };
    },
  };
};
