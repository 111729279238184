//eslint-disable-next-line
//@ts-nocheck

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const priceChangeCost = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Price change cost",
    metainfo: {
      _metainfoVersion: 51,
      id: "price-change-cost@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Price change cost",
      shortDescription: "[SF] Price change cost",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 8,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "sellCostPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyCostPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "deltaColorer",
          type: StudyPlotType.Colorer,
          target: "deltaPlot",
          palette: "deltaPalette",
        },
      ],
      palettes: {
        deltaPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          deltaPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.5)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.5)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          sellCostPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          buyCostPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          deltaPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          deltaColorer: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {
          maTypeInput: "AVG",
          lengthInput: 6,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        sellCostPlot: {
          title: "Negative cost",
          histogramBase: 0,
        },
        buyCostPlot: {
          title: "Positive cost",
          histogramBase: 0,
        },
        deltaPlot: {
          title: "Cost delta",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "AVG",
          options: ["AVG", "SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 6,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const buyVolumeSymbol = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const maTypeInput: string = this._input(0);
        const lengthInput: number = this._input(1);

        //Market data parsing
        const close: number = PineJS.Std.close(this._context);
        const volume: number = PineJS.Std.volume(this._context);
        this._context.select_sym(1);
        const buyVolumeSymbolTimeArray = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolumeSeriesArray = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_var(
          this._context.symbol.time,
        );
        const buyVolume: number = buyVolumeSeriesArray.adopt(
          buyVolumeSymbolTimeArray,
          mainSymbolTimeSeries,
          1,
        );

        //Calculations
        const sellVolume: number = buyVolume - volume;
        const buyRatio: number = close / buyVolume;
        const sellRatio: number = close / sellVolume;
        const ratioDelta: number = buyRatio + sellRatio;
        const ratioDeltaSeries = this._context.new_var(ratioDelta);
        let ratioDeltaAvg;
        switch (maTypeInput) {
          case "AVG":
            ratioDeltaAvg =
              PineJS.Std.sum(ratioDeltaSeries, lengthInput, this._context) /
              lengthInput;
            break;
          case "SMA":
            ratioDeltaAvg = PineJS.Std.sma(
              ratioDeltaSeries,
              lengthInput,
              this._context,
            );
            break;
          case "EMA":
            ratioDeltaAvg = PineJS.Std.ema(
              ratioDeltaSeries,
              lengthInput,
              this._context,
            );
            break;
          case "WMA":
            ratioDeltaAvg = PineJS.Std.wma(
              ratioDeltaSeries,
              lengthInput,
              this._context,
            );
            break;
          case "VWMA":
            ratioDeltaAvg = PineJS.Std.vwma(
              ratioDeltaSeries,
              lengthInput,
              this._context,
            );
            break;
          case "LSMA":
            ratioDeltaAvg = PineJS.Std.linreg(ratioDeltaSeries, lengthInput, 0);
            break;
        }
        const ratioDeltaAvgColor: number = ratioDeltaAvg > 0 ? 1 : 0;

        //Returns
        return [0, sellRatio, buyRatio, ratioDeltaAvg, ratioDeltaAvgColor];
      };
    },
  };
};
