export const firebase = {
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  storageBucket: "spreadfighter.appspot.com",
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: "spreadfighter.firebaseapp.com",
  messagingSenderId: "1003234821057",
  measurementId: "G-NW87KTBTFK",
  functionsURL: "https://us-central1-spreadfighter.cloudfunctions.net",
  getNonceToSign: "https://getnoncetosign-mm6nybwt3a-ey.a.run.app",
  verifySignedMessage: "https://verifysignedmessage-mm6nybwt3a-ey.a.run.app",
  updateCustomer: "https://updatecustomer-mm6nybwt3a-ey.a.run.app",
  createCustomer: "https://createcustomer-mm6nybwt3a-ey.a.run.app",
};
