//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const standardizedActiveReturnMeasure = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Standardized active return measure",
    metainfo: {
      _metainfoVersion: 51,
      id: "standardized-active-return-measure@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Standardized active return measure",
      shortDescription: "[SF] SARM",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [
        { id: "zeroline", type: StudyPlotType.Line },
        { id: "metricPlot", type: StudyPlotType.Line },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            display: 3,
            color: "rgb(149, 152, 161)",
            transparency: 40,
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {
          benchmarkTuckerInput: "BINANCE:BTCUSDT",
          lengthInput: 10,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "SARM",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "benchmarkTuckerInput",
          name: "Benchmark tiker",
          defval: "BINANCE:BTCUSDT",
          type: StudyInputType.Symbol,
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 10,
          min: 2,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const benchmarkSymbolInput: symbol = this._input(0);
        this._context.new_sym(
          benchmarkSymbolInput,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const lengthInput: number = this._input(1);

        //Market data parsing
        const hlc3: number = PineJS.Std.hlc3(this._context);
        const hlc3Series: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(1);
        const benchmarkTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmarkArray: any = this._context.new_var(
          PineJS.Std.hlc3(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const benchmark: number = benchmarkArray.adopt(
          benchmarkTimeArray,
          mainSymbolTimeSeries,
          0,
        );
        const benchmarkSeries: any = this._context.new_var(benchmark);

        //CARM calculation
        const hlc3Avg: number =
          PineJS.Std.sum(hlc3Series, lengthInput, this._context) / lengthInput;
        const deviation: number = (hlc3 - hlc3Avg) / hlc3;
        const deviationSeries: any = this._context.new_var(deviation);
        const portfolioReturn: number =
          (deviation - deviationSeries.get(lengthInput)) /
          deviationSeries.get(lengthInput);
        const becnhmarkAvg: number =
          PineJS.Std.sum(benchmarkSeries, lengthInput, this._context) /
          lengthInput;
        const deviationBenchmark: number =
          (benchmark - becnhmarkAvg) / benchmark;
        const deviationBenchmarkSeries: any =
          this._context.new_var(deviationBenchmark);
        const benchmarkReturn: number =
          (deviationBenchmark - deviationBenchmarkSeries.get(lengthInput)) /
          deviationBenchmarkSeries.get(lengthInput);
        const activePortfolioReturn: number = portfolioReturn - benchmarkReturn;
        const activePortfolioReturnSeries: any = this._context.new_var(
          activePortfolioReturn,
        );
        const activePortfolioRisk: number = PineJS.Std.stdev(
          activePortfolioReturnSeries,
          lengthInput,
          this._context,
        );
        const sarm: number = activePortfolioReturn / activePortfolioRisk;

        //Returns
        return [0, sarm];
      };
    },
  };
};
