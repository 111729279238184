// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  FilledAreaType,
  LineStudyPlotStyle,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const movingAverage = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Moving average",
    metainfo: {
      _metainfoVersion: 51,
      id: "moving-average@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Moving average",
      shortDescription: "[SF] MA",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "fastMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "fastMaColorer",
          type: StudyPlotType.Colorer,
          target: "fastMaPlot",
          palette: "linePalette",
        },
        {
          id: "slowMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "slowMaColorer",
          type: StudyPlotType.Colorer,
          target: "slowMaPlot",
          palette: "linePalette",
        },
        {
          id: "backgroundColorer",
          type: StudyPlotType.Colorer,
          target: "backgroundPlot",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "backgroundPlot",
          objAId: "fastMaPlot",
          objBId: "slowMaPlot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        linePalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Bearish" },
            1: { name: "Bullish" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Bearish" },
            1: { name: "Bullish" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          backgroundPlot: {
            color: "rgba(255, 255, 255, 0)",
            visible: true,
            transparency: 100,
          },
        },
        palettes: {
          linePalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.1)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.1)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          fastMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          fastMaColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
          },
          slowMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          slowMaColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
          },
          backgroundPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 1)",
            transparency: 100,
          },
          backgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(243, 60, 48, 1)",
          },
        },
        precision: 2,
        inputs: {
          fastSourceInput: "close",
          fastLengthInput: 20,
          fastMaTypeInput: "SMA",
          slowSourceInput: "close",
          slowLengthInput: 240,
          slowMaTypeInput: "SMA",
        },
      },
      styles: {
        fastMaPlot: {
          title: "Fast MA",
          histogramBase: 0,
        },
        slowMaPlot: {
          title: "Slow MA",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "fastSourceInput",
          name: "Fast MA source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "fastLengthInput",
          name: "Fast MA length",
          defval: 20,
          type: StudyInputType.Integer,
        },
        {
          id: "fastMaTypeInput",
          name: "Type of fast MA",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "slowSourceInput",
          name: "Slow MA source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "slowLengthInput",
          name: "Slow MA length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "slowMaTypeInput",
          name: "Type of slow MA",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_OPEN_INTEREST`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const fastSourceInput: string = this._input(0);
        const fastLengthInput: number = this._input(1);
        const fastMaTypeInput: string = this._input(2);

        const slowSourceInput: string = this._input(3);
        const slowLengthInput: number = this._input(4);
        const slowMaTypeInput: string = this._input(5);

        //Market data parsing
        let fastSource: any;
        switch (fastSourceInput) {
          case "open":
            fastSource = this._context.new_var(PineJS.Std.open(this._context));
            break;
          case "high":
            fastSource = this._context.new_var(PineJS.Std.high(this._context));
            break;
          case "low":
            fastSource = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            fastSource = this._context.new_var(PineJS.Std.close(this._context));
            break;
          case "hl2":
            fastSource = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            fastSource = this._context.new_var(PineJS.Std.hlc3(this._context));
            break;
          case "ohlc4":
            fastSource = this._context.new_var(PineJS.Std.ohlc4(this._context));
            break;
          default:
            fastSource = this._context.new_var(PineJS.Std.close(this._context));
            break;
        }

        let slowSource;
        switch (slowSourceInput) {
          case "open":
            slowSource = this._context.new_var(PineJS.Std.open(this._context));
            break;
          case "high":
            slowSource = this._context.new_var(PineJS.Std.high(this._context));
            break;
          case "low":
            slowSource = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            slowSource = this._context.new_var(PineJS.Std.close(this._context));
            break;
          case "hl2":
            slowSource = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            slowSource = this._context.new_var(PineJS.Std.hlc3(this._context));
            break;
          case "ohlc4":
            slowSource = this._context.new_var(PineJS.Std.ohlc4(this._context));
            break;
          default:
            slowSource = this._context.new_var(PineJS.Std.close(this._context));
            break;
        }

        //Calculations
        let fastMa: number = fastSource.get(0);
        let oiTime: any,
          oiArray: any,
          mainSymbolTime: any,
          oi: any,
          oiSeries: any,
          fastSource_x_oi: any,
          fastSource_x_oiSeries: any,
          fastSource_x_oiAvg: any,
          oiAvg: any;
        switch (fastMaTypeInput) {
          case "SMA":
            fastMa = PineJS.Std.sma(fastSource, fastLengthInput, this._context);
            break;
          case "EMA":
            fastMa = PineJS.Std.ema(fastSource, fastLengthInput, this._context);
            break;
          case "WMA":
            fastMa = PineJS.Std.wma(fastSource, fastLengthInput, this._context);
            break;
          case "VWMA":
            fastMa = PineJS.Std.vwma(
              fastSource,
              fastLengthInput,
              this._context,
            );
            break;
          case "OIWMA":
            this._context.select_sym(1);
            oiTime = this._context.new_var(this._context.symbol.time);
            oiArray = this._context.new_var(PineJS.Std.close(this._context));
            this._context.select_sym(0);
            mainSymbolTime = this._context.new_var(this._context.symbol.time);
            oi = oiArray.adopt(oiTime, mainSymbolTime, 0);
            oiSeries = this._context.new_var(oi);
            fastSource_x_oi = fastSource.get(0) * oi;
            fastSource_x_oiSeries = this._context.new_var(fastSource_x_oi);
            fastSource_x_oiAvg =
              PineJS.Std.sum(
                fastSource_x_oiSeries,
                fastLengthInput,
                this._context,
              ) / fastLengthInput;
            oiAvg =
              PineJS.Std.sum(oiSeries, fastLengthInput, this._context) /
              fastLengthInput;
            fastMa = fastSource_x_oiAvg / oiAvg;
            break;
          case "LSMA":
            fastMa = PineJS.Std.linreg(fastSource, fastLengthInput, 0);
            break;
          default:
            fastMa = PineJS.Std.sma(fastSource, fastLengthInput, this._context);
            break;
        }

        let slowMa: number = slowSource.get(0);
        let slowSource_x_oi: any,
          slowSource_x_oiSeries: any,
          slowSource_x_oiAvg: any;
        switch (slowMaTypeInput) {
          case "SMA":
            slowMa = PineJS.Std.sma(slowSource, slowLengthInput, this._context);
            break;
          case "EMA":
            slowMa = PineJS.Std.ema(slowSource, slowLengthInput, this._context);
            break;
          case "WMA":
            slowMa = PineJS.Std.wma(slowSource, slowLengthInput, this._context);
            break;
          case "VWMA":
            slowMa = PineJS.Std.vwma(
              slowSource,
              slowLengthInput,
              this._context,
            );
            break;
          case "OIWMA":
            this._context.select_sym(1);
            oiTime = this._context.new_var(this._context.symbol.time);
            oiArray = this._context.new_var(PineJS.Std.close(this._context));
            this._context.select_sym(0);
            mainSymbolTime = this._context.new_var(this._context.symbol.time);
            oi = oiArray.adopt(oiTime, mainSymbolTime, 0);
            oiSeries = this._context.new_var(oi);
            slowSource_x_oi = slowSource.get(0) * oi;
            slowSource_x_oiSeries = this._context.new_var(slowSource_x_oi);
            slowSource_x_oiAvg =
              PineJS.Std.sum(
                slowSource_x_oiSeries,
                slowLengthInput,
                this._context,
              ) / slowLengthInput;
            oiAvg =
              PineJS.Std.sum(oiSeries, slowLengthInput, this._context) /
              slowLengthInput;
            fastMa = slowSource_x_oiAvg / oiAvg;
            break;
          case "LSMA":
            slowMa = PineJS.Std.linreg(slowSource, slowLengthInput, 0);
            break;
          default:
            slowMa = PineJS.Std.sma(fastSource, fastLengthInput, this._context);
            break;
        }

        const color: number = fastMa > slowMa ? 1 : 0;

        //Return
        return [fastMa, color, slowMa, color, color];
      };
    },
  };
};
