import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { environment } from "@/lib/constants.ts";

dayjs.extend(relativeTime);
dayjs.extend(duration);

export interface ICascadeLevelDetails {
  timestamp: number;
  daysFromNow: string;
  side: string;
  price: number;
  fcSum: number;
  logicFc: boolean;
  decLevel: number;
  toFcPrc: number;
  symbol: string;
}

export interface IUpdatedCascadeLevelDetails extends ICascadeLevelDetails {
  symbol: string;
}

export interface ICascadeLevelsResponse {
  timestamp: number;
  timeFrame: string;
  levels: {
    [p: string]: ICascadeLevelDetails[];
  };
}

export function useCascadeLevelsWindow(timeFrameFilter: string) {
  const handler = useCallback(
    (_: IUpdatedCascadeLevelDetails[], newData: ICascadeLevelsResponse) => {
      if (!newData.levels) {
        return [];
      }

      const parsedLevels: IUpdatedCascadeLevelDetails[] = Object.keys(
        newData.levels,
      ).reduce((acc, symbolKey) => {
        const levels = newData.levels[symbolKey].map((level) => ({
          ...level,
          symbol: symbolKey,
          daysFromNow: dayjs(level.timestamp)
            .fromNow()
            .replace(/\ban hour\b/, "1h")
            .replace(/\ba day\b/, "1d")
            .replace(/\ban minute\b/, "1m")
            .replace(/\ba minute\b/, "1m")
            .replace(/\ban second\b/, "1s")
            .replace(/\ba second\b/, "1s")
            .replace(/\byears?\b/, "y")
            .replace(/\bmonths?\b/, "mo")
            .replace(/\bdays?\b/, "d")
            .replace(/\bhours?\b/, "h")
            .replace(/\bminutes?\b/, "m")
            .replace(/\bseconds?\b/, "s")
            .replace(/\b ago\b/, ""),
        }));

        return [...acc, ...levels];
      }, [] as IUpdatedCascadeLevelDetails[]);

      return parsedLevels;
    },
    [],
  );

  const topic =
    `sf.core.scripts.screener.cascadeLevel.${timeFrameFilter.toLowerCase()}.window` as SocketTopics;

  return useGenericSocket<
    IUpdatedCascadeLevelDetails[],
    ICascadeLevelsResponse
  >(topic, handler);
}
