import {
  IStrategy,
  IStrategyV2Generic,
} from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { environment } from "@/lib/constants.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import axios from "axios";
import { useCallback } from "react";

export function useMultiPeriodVolatilityFilterV1() {
  const fetchInitialData = async () => {
    try {
      const response = await axios.get<IStrategyV2Generic[]>(
        `${environment.multiPeriodVolatilityFilterV1LastApiURL}`,
      );
      return response.data.sort((a, b) => b.timestamp - a.timestamp);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      return [];
    }
  };

  const handler = useCallback(
    (oldData: IStrategyV2Generic[] = [], newData: IStrategyV2Generic) => {
      return [newData, ...oldData];
    },
    [],
  );

  return useGenericSocket<IStrategyV2Generic[], IStrategyV2Generic>(
    SocketTopics.multiPeriodVolatilityFilter,
    handler,
    {
      fetchInitialData,
      apiUrl: environment.multiPeriodVolatilityFilterV1LastApiURL,
    },
  );
}
