//eslint-disable-next-line
//@ts-nocheck
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import { StudyPlotType } from "@/lib/datafeed/tvTypes.ts";

export const dayHighAndLow = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Day high and low",
    metainfo: {
      _metainfoVersion: 51,
      id: "day-high-and-low@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Day high & low",
      shortDescription: "[SF] Day high & low",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "upperBandPlot", type: StudyPlotType.Line },
        { id: "lowerBandPlot", type: StudyPlotType.Line },
      ],
      filledAreas: [],
      defaults: {
        filledAreasStyle: {
          Background: {},
        },
        styles: {
          upperBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            display: 3,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
          lowerBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            display: 3,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: false,
            color: "rgb(255, 255, 255)",
          },
        },
        precision: 2,
        inputs: {},
      },
      styles: {
        upperBandPlot: {
          title: "Day high",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Day low",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Market data parsing
        const highSeries: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const lowSeries: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );

        //Calculaions
        let dayHigh: number = highSeries.get(0);
        let dayLow: number = lowSeries.get(0);
        if (PineJS.Std.isintraday(this._context) === true) {
          const length: number =
            1440 / parseInt(PineJS.Std.period(this._context));
          dayHigh = PineJS.Std.highest(highSeries, length, this._context);
          dayLow = PineJS.Std.lowest(lowSeries, length, this._context);
        }

        //Returns
        return [dayHigh, dayLow];
      };
    },
  };
};
