import { useEffect, useRef } from "react";
import { QueryFunction, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";
import axios from "axios";
import { socketConnection } from "@/lib/natsConnection.ts";

type UpdateQueryDataFunction<TData, TSocketMessage = TData> = (
  currentData: TData,
  socketMessage: TSocketMessage,
) => TData;

interface UseGenericSocketOptions<TData> {
  apiUrl?: string;
  requestHeaders?: Record<string, string>;
  fetchInitialData?: QueryFunction<TData>;
  selector?: (state: any) => TData;
  processInitialDataFunction?: (apiResponse: any) => TData;
}

export function useGenericSocket<TData, TSocketMessage = TData>(
  topic: string,
  updateQueryData: UpdateQueryDataFunction<TData, TSocketMessage> = (
    _,
    newData,
  ) => newData as unknown as TData,
  options?: UseGenericSocketOptions<TData>,
) {
  const queryClient = useQueryClient();
  const unsubscribeRef = useRef<() => void>();

  useEffect(() => {
    let isMounted = true;
    let unsubscribe: (() => void) | undefined;

    const subscribeToTopic = async () => {
      if (isMounted && !unsubscribe) {
        unsubscribe = await socketConnection.subscribe(
          topic,
          (newData: TSocketMessage) => {
            queryClient.setQueryData([topic], (currentData: TData) =>
              updateQueryData(cloneDeep(currentData), newData),
            );
          },
        );
        if (isMounted) {
          unsubscribeRef.current = unsubscribe;
        } else {
          unsubscribe?.();
        }
      } else {
        console.error("useGenericSocket hook is not mounted");
      }
    };

    subscribeToTopic();

    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
        unsubscribeRef.current = undefined;
      }
    };
  }, [topic, updateQueryData, queryClient]);

  const fetchInitialData = async () => {
    if (!options?.apiUrl) {
      return null as TData;
    }
    console.log(options.apiUrl);
    const response = await axios.get<TData>(options.apiUrl, {
      headers: options.requestHeaders,
    });
    if (options.processInitialDataFunction) {
      return options.processInitialDataFunction(response.data);
    }
    return response.data as TData;
  };

  const query = useQuery<TData>({
    queryKey: [topic],
    queryFn: options?.fetchInitialData ?? fetchInitialData,
    enabled: !!options?.fetchInitialData || !!options?.apiUrl,
    select: options?.selector ?? ((state) => state),
  });

  useEffect(() => {
    const loadInitialData = async () => {
      const { data } = await query.refetch();
      queryClient.setQueryData([topic], data);
    };

    if (!queryClient.getQueryData([topic])) {
      loadInitialData();
    }
  }, [topic, queryClient, query]);

  return query;
}
