import { IPaneConfig } from "@/domain/interfaces/dashboard.interface.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Actions, Layout, Model, TabNode } from "flexlayout-react";
import { useCallback, useMemo } from "react";

const key = ["grid"];

interface IGridData {
  grid: Layout | null;
  model: Model | null;
}

interface IGridActions {
  setGridRef: (grid: Layout) => void;
  setModelRef: (model: Model) => void;
  updateTabConfig: (config: Partial<IPaneConfig>, nodeId?: string) => void;
}

type IGridResult = IGridData & IGridActions;

type IGridResultWithNode = IGridResult & {
  node: TabNode | null;
  getNodeConfig: () => IPaneConfig;
};

export function useGrid(nodeId: string): IGridResultWithNode;

export function useGrid(): IGridResult;

export function useGrid(nodeId?: string): IGridResultWithNode {
  const queryClient = useQueryClient();
  const { data } = useQuery<IGridData>({
    queryKey: key,
    enabled: false,
    initialData: {
      grid: null,
      model: null,
    },
  });

  const setGridRef = useCallback(
    (grid: Layout) => {
      queryClient.setQueryData<IGridData>(key, (data) => {
        return {
          ...(data as IGridData),
          grid,
        };
      });
    },
    [queryClient],
  );

  const setModelRef = useCallback(
    (model: Model) => {
      queryClient.setQueryData<IGridData>(key, (data) => {
        return {
          ...(data as IGridData),
          model,
        };
      });
    },
    [queryClient],
  );

  const node = useMemo(
    () => (nodeId ? (data.model?.getNodeById(nodeId) as TabNode) : null),
    [data.model, nodeId],
  );

  const getNodeConfig = useCallback(
    () => (node ? node.getConfig() : {}) as IPaneConfig,
    [node],
  );

  const updateTabConfig = useCallback(
    (config: Partial<IPaneConfig>, nodeId = node?.getId()) => {
      if (!nodeId) {
        console.warn("Node ID is not defined.");
        return;
      }

      const currentConfig = getNodeConfig();
      // const state = get
      if (!currentConfig) {
        console.warn("Current node config is not defined.");
        return;
      }

      const newConfig = { ...currentConfig, ...config };
      data.model?.doAction(
        Actions.updateNodeAttributes(nodeId, { config: newConfig }),
      );
    },
    [data.model, getNodeConfig, node],
  );

  return {
    ...data,
    setGridRef,
    setModelRef,
    updateTabConfig,
    node,
    getNodeConfig,
  };
}
