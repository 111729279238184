import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import dayjs from "dayjs";
import { IPairScreenerItem, usePairs } from "@/hooks/usePairs.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { Loader } from "@/components/shared/loader.tsx";

const columnHelper = createColumnHelper<IPairScreenerItem>();

const columns = [
  columnHelper.accessor("baseName", {
    header: "Symbol",
    enableSorting: false,
    cell: (info) => {
      const originalRow = info.row.original;
      return originalRow.baseName + "/" + originalRow.quoteName;
    },
  }),
  columnHelper.accessor("sigma", {
    header: "Sigma",
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn("font-bold block", rowClassCb(info.row.original))}
          decimal={2}
        />
      );
    },
    size: 50,
    minSize: 50,
  }),
  columnHelper.accessor("zScore", {
    header: "zScore",
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn("font-bold block", rowClassCb(info.row.original))}
          decimal={2}
        />
      );
    },
    size: 50,
    minSize: 50,
  }),
  columnHelper.accessor("maDev1", {
    header: "MA",
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn("font-bold")}
          decimal={2}
        />
      );
    },
    size: 50,
    minSize: 50,
  }),
  columnHelper.accessor("updatedAt", {
    header: "Time",
    cell: (row) => {
      const value = row.getValue();
      return dayjs(value).utc().format("HH:mm:ss");
    },
    size: 50,
    minSize: 50,
  }),
];

function rowClassCb(row: IRow<IPairScreenerItem>) {
  return cn(
    row.description === "BUY" ? "bg-red-800" : "bg-green-800",
    dayjs().valueOf() - dayjs(row.updatedAt).valueOf() < 1000 &&
      "animate-pulse",
  );
}

export function PairsScannerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { data: pairs, isLoading } = usePairs(config.interval);

  const preparedData = useMemo(() => {
    if (!pairs) {
      return [];
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IPairScreenerItem>(
        pairs,
        config.filters,
        config.filterValues,
      );
    }

    return pairs;
  }, [config, pairs]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
