//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const tickIndex = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Tick Index",
    metainfo: {
      _metainfoVersion: 51,
      id: "tick-index@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Tick Index",
      shortDescription: "[SF] Tick Index",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "tickIndexPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "tickIndexColorer",
          type: StudyPlotType.Colorer,
          target: "tickIndexPlot",
          palette: "tickIndexPalette",
        },
      ],
      palettes: {
        tickIndexPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          tickIndexPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          tickIndexPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 4,
        inputs: {},
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        tickIndexPlot: {
          title: "Tick index",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_TICK_INDEX`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Market data parsing
        this._context.select_sym(1);
        const rtIndexTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const rtIndexArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const rtIndex: number = rtIndexArray.adopt(
          rtIndexTime,
          mainSymbolTime,
          1,
        );

        // Calculations
        const rtIndexColor: number = rtIndex > 0 ? 1 : 0;

        return [0, rtIndex, rtIndexColor];
      };
    },
  };
};
