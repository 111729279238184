import { useEffect, useRef } from "react";
import {
  getAggregatedConstructorTopic,
  socketConnection,
} from "@/lib/natsConnection.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export type IValuesByTime = {
  "1h": number;
  "1d": number;
  "1w": number;
  "1M": number;
};

export interface IAggregatedConstructor {
  timestamp: number;
  symbol: string;
  price: IValuesByTime;
  openInterest: IValuesByTime;
  volume: IValuesByTime;
  buySellRatio: IValuesByTime;
  funding: number;
  volumeQuoteDay: number;
  spotFutureSpread: {
    sma: number;
    value: number;
  };
  powerCvd: {
    value: number;
    isShort: boolean;
    isLong: boolean;
  };
}

export type IAggregatedData = Record<string, IAggregatedConstructor>;

const key = ["aggregatedData"];

export function useAggregatedConstructor(symbols: string[]) {
  const unsubscribeRef = useRef<Record<string, () => void>>({});
  const queryClient = useQueryClient();

  const oldSymbols = useRef<string[]>([]);

  useEffect(() => {
    // sub to new symbols
    symbols
      .filter((s) => !oldSymbols.current.includes(s))
      .forEach((symbol) => {
        const topic = getAggregatedConstructorTopic(symbol);
        socketConnection
          .subscribe(topic, (newData: IAggregatedConstructor) => {
            queryClient.setQueryData<IAggregatedData>(
              key,
              (currentData: Record<string, IAggregatedConstructor> = {}) => {
                return { ...currentData, [newData.symbol]: newData };
              },
            );
          })
          .then((unsub) => {
            unsubscribeRef.current[symbol] = unsub;
          });
      });

    //unsub from unused symbols
    oldSymbols.current
      .filter((s) => !symbols.includes(s))
      .forEach((symbol) => {
        unsubscribeRef.current[symbol]();
        delete unsubscribeRef.current[symbol];
      });

    oldSymbols.current = symbols;
  }, [queryClient, symbols]);

  useEffect(() => {
    return () => {
      // unsub from everything on unmount
      Object.keys(unsubscribeRef.current).forEach((symbol) => {
        unsubscribeRef.current[symbol]();
      });
    };
  }, []);

  return useQuery<IAggregatedData>({
    queryKey: key,
    enabled: false,
  });
}
