import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<ITechDesk>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("price", {
    header: "Pr Ac",
    cell: (info) => {
      const value = info.getValue();
      return (
        <HighlightedValueChange
          value={value}
          highlightNewTextColor={false}
          highlightPosNeg={false}
          className={cn("font-bold")}
          decimal={2}
        />
      );
    },
    size: 110,
    minSize: 110,
  }),
];

const oldValues: IRow[] = [];
const highlightTimestamps: number[] = [];

function rowClassCb(
  row: ITechDesk,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  if (idx > 4) {
    return "";
  }

  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const oldRow = oldValues[idx];
  const isPositionChanged = oldRow?.symbol !== row.symbol;
  const now = Date.now();
  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";

  if (isPositionChanged) {
    oldValues[idx] = row;
    highlightTimestamps[idx] = now;
    return cn(color, "animate-pulse");
  } else if (
    highlightTimestamps[idx] &&
    now - highlightTimestamps[idx] < 1000
  ) {
    return cn(color, "animate-pulse");
  } else {
    return "";
  }
}

export function PriceScreenerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist } = useDashboard();

  const { data: techDesk, isLoading } = useTechnicalDesk();

  const preparedData = useMemo(() => {
    if (!techDesk?.data) {
      return [];
    }

    if (blacklist.length > 0) {
      techDesk.data = techDesk.data.filter(
        (item) => !blacklist.includes(item.symbol),
      );
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ITechDesk>(
        techDesk.data,
        config.filters,
        config.filterValues,
      );
    }

    return techDesk.data;
  }, [config, techDesk?.data, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
        rowClassCb={(...args) => rowClassCb(...args, config.sortingState)}
      />
    </>
  );
}
