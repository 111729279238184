import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

// {"timestamp":1729020599019,"timeFrame":"all","data":[{"symbol":"CFXUSDT","data":{"1M":{"min":-8.702,"value":8.552,"max":45.871},"1d":{"min":-6.291,"value":-3.883,"max":2.652},"1w":{"min":-23.527,"value":-12.671,"max":14.036}}},{"symbol":"ALPACAUSDT","data":{"1M":{"min":0,"value":0,"max":0},"1d":{"min":-6.102,"value":-3.355,"max":-1.045},"1w":{"min":-10.365,"value":-7.52,"max":11.038}}},{"symbol":"APTUSDT","data":{"1M":{"min":-9.676,"value":58.974,"max":59.744},"1d":{"min":-9.998,"value":6.061,"max":8.236},"1w":{"min":-12.661,"value":5.074,"max":24.344}}},{"symbol":"BICOUSDT","data":{"1M":{"min":-21.142,"value":-15.775,"max":-9.111},"1d":{"min":-1.766,"value":0.657,"max":3.184},"1w":{"min":-9.382,"value":-2.675,"max":-1.997}}}

export interface IMarketRatioScreener {
  timeFrame: string;
  data: IMarketRatioScreenerSymbol[];
  timestamp: number;
}

export interface IMarketRatioScreenerData {
  symbol: string;
  monthValue: number;
  monthMin: number;
  monthMax: number;
  dayValue: number;
  dayMin: number;
  dayMax: number;
  weekValue: number;
  weekMin: number;
  weekMax: number;
  month: {
    min: number;
    value: number;
    max: number;
  };
  day: {
    min: number;
    value: number;
    max: number;
  };
  week: {
    min: number;
    value: number;
    max: number;
  };
}

export interface IMarketRatioScreenerSymbol {
  symbol: string;
  data: {
    [k: string]: {
      min: number;
      value: number;
      max: number;
    };
  };
}

export function useMarketRatioScreener() {
  const handler = useCallback(
    (_: IMarketRatioScreener, newData: IMarketRatioScreener) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<IMarketRatioScreener, IMarketRatioScreener>(
    SocketTopics.marketRatioArr,
    handler,
  );
}
