import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Label } from "@/components/ui/label.tsx";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@/components/ui/popover.tsx";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { MAX_CHARTS } from "@/domain/constants.ts";
import { EFilterType, EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { IFilterOption } from "@/domain/interfaces/dashboard.interface.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { ResolutionString } from "@tradingView/charting_library";
import { Actions, TabNode } from "flexlayout-react";
import { memo, useState } from "react";
import { FaSliders } from "react-icons/fa6";

const timeframesOptions = [
  { label: "1 Minute", value: "1" },
  { label: "3 Minutes", value: "3" },
  { label: "5 Minutes", value: "5" },
  { label: "15 Minutes", value: "15" },
  { label: "30 Minutes", value: "30" },
  { label: "1 Hour", value: "60" },
  { label: "2 Hours", value: "120" },
  { label: "4 Hours", value: "240" },
  { label: "Daily", value: "D" },
  { label: "Weekly", value: "W" },
  { label: "Monthly", value: "M" },
] as { label: string; value: ResolutionString }[];

export const TabSettingsDropdown = memo(function TabSettingsDropdown({
  node,
}: {
  node: TabNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { updateTabConfig, getNodeConfig, model } = useGrid(node.getId());
  const config = getNodeConfig();
  const handleIntervalChange = (value: string) => {
    updateTabConfig({
      interval: value as ResolutionString,
    });
  };

  const widgetType = node.getComponent() as EWidgetType;
  if (
    widgetType === EWidgetType.chart ||
    widgetType === EWidgetType.controlPanel
  ) {
    return null;
  }

  const handleFilterChange = (filterName: string, value: any) => {
    const newFilterValues = { ...config?.filterValues, [filterName]: value };
    const currentNodeId = node.getId();
    model?.visitNodes((node) => {
      const _node = node as TabNode;
      if (node.getType() === "tab" && node.getId() !== currentNodeId) {
        const currentConfig = _node.getConfig();
        model?.doAction(
          Actions.updateNodeAttributes(_node.getId(), {
            config: { ...currentConfig, filterValues: newFilterValues },
          }),
        );
      }
    });
    updateTabConfig({ filterValues: newFilterValues });
  };

  const resetFilter = (filterName: string) => {
    const newFilterValues = { ...config?.filterValues };
    delete newFilterValues[filterName];
    const currentNodeId = node.getId();
    model?.visitNodes((node) => {
      const _node = node as TabNode;
      if (node.getType() === "tab" && node.getId() !== currentNodeId) {
        const currentConfig = _node.getConfig();
        model?.doAction(
          Actions.updateNodeAttributes(_node.getId(), {
            config: { ...currentConfig, filterValues: newFilterValues },
          }),
        );
      }
    });
    updateTabConfig({ filterValues: newFilterValues });
  };

  const renderFilterInput = (filter: IFilterOption) => {
    const filterValue = config?.filterValues?.[filter.filterByField];
    const isModified = filterValue !== undefined;
    switch (filter.type) {
      case EFilterType.select:
      case EFilterType.multiSelect:
        return (
          <div className="flex items-center space-x-2 relative">
            <Select
              value={filterValue || filter.defaultValue}
              onValueChange={(value) =>
                handleFilterChange(filter.filterByField, value)
              }
            >
              <SelectTrigger className="w-full h-8">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{filter.title}</SelectLabel>
                  {filter.options?.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Button
              size="xs"
              variant="ghost"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
            {isModified && (
              <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
            )}
          </div>
        );
      case EFilterType.range:
        return (
          <div className="flex space-x-2 items-center relative">
            <Input
              type="number"
              placeholder="Min"
              value={filterValue?.min || filter.min}
              onChange={(e) =>
                handleFilterChange(filter.filterByField, {
                  ...filterValue,
                  min: parseInt(e.target.value),
                })
              }
              className="w-full"
            />
            <Input
              type="number"
              placeholder="Max"
              value={filterValue?.max || filter.max}
              onChange={(e) =>
                handleFilterChange(filter.filterByField, {
                  ...filterValue,
                  max: parseInt(e.target.value),
                })
              }
              className="w-full"
            />
            <Button
              size="xs"
              variant="ghost"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
            {isModified && (
              <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
            )}
          </div>
        );
      case EFilterType.number:
        return (
          <div className="flex items-center space-x-2 relative">
            <Input
              type="number"
              placeholder={filter.title}
              value={filterValue || filter.defaultValue || ""}
              onChange={(e) =>
                handleFilterChange(
                  filter.filterByField,
                  parseInt(e.target.value),
                )
              }
              className="w-full text-xs"
            />
            <Button
              size="xs"
              variant="ghost"
              onClick={() => resetFilter(filter.filterByField)}
            >
              <i className="fa-solid fa-times"></i>
            </Button>
            {isModified && (
              <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full" />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const hasModifiedFilters = !!Object.values(config?.filterValues ?? {}).length;
  return (
    <>
      <Button
        size="xxs"
        className="relative"
        variant="ghost"
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        {hasModifiedFilters && (
          <div className="absolute -top-0.5 -right-0.5 w-2 h-2 bg-red-500 rounded-full" />
        )}
        <FaSliders />
      </Button>
      <Popover open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <PopoverAnchor />
        <PopoverContent
          align="start"
          alignOffset={-30}
          className="w-[600px]"
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="grid grid-col-2 gap-4">
            <div className="space-y-2">
              <h4 className="font-medium leading-none">
                {node.getName()} settings
              </h4>
              <p className="text-sm text-muted-foreground">
                Set the parameters for the widget.
              </p>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="grid grid-cols-3 items-center gap-4">
                <Label htmlFor="charts">Number of charts</Label>
                <Input
                  onMouseDown={(e) => e.stopPropagation()}
                  id="charts"
                  value={config?.numberOfCharts}
                  className="col-span-2 h-8"
                  type="number"
                  max={10}
                  onChange={(e) => {
                    updateTabConfig({
                      numberOfCharts: Math.min(
                        parseInt(e.target.value),
                        MAX_CHARTS,
                      ),
                    });
                  }}
                />
              </div>
              <div className="grid grid-cols-3 items-center gap-4">
                <Label htmlFor="interval">Interval</Label>
                <div className="col-span-2">
                  <Select
                    value={config?.interval}
                    onValueChange={handleIntervalChange}
                  >
                    <SelectTrigger className="w-full h-8">
                      <SelectValue placeholder="Select interval" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Intervals</SelectLabel>
                        {timeframesOptions.map((tf) => (
                          <SelectItem key={tf.value} value={tf.value}>
                            {tf.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              {config?.filters && config.filters.length > 0 && (
                <hr className="m-2 col-span-2" />
              )}
              {config?.filters?.map((filter) => (
                <div
                  key={filter.filterByField}
                  className="grid grid-cols-3 items-center gap-4"
                >
                  <Label htmlFor={filter.filterByField}>{filter.title}</Label>
                  <div className="col-span-2 flex items-center space-x-2">
                    {renderFilterInput(filter)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
});
