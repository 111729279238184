import React, { useMemo } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useGrid } from "@/hooks/useGrid";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useBinanceSymbols } from "@/hooks/useBinanceSymbols";
import { AltSelect } from "@/components/shared/altSelect";
import { useDashboard } from "@/lib/store";

export function Blacklist() {
  const blacklistItems = useDashboard((state) => state.blacklist);
  const [newItem, setNewItem] = React.useState("");
  const nodeId = usePaneContext();
  const { node } = useGrid(nodeId);
  const { addSymbolToBlacklist, removeSymbolFromBlacklist } = useDashboard(
    (state) => ({
      addSymbolToBlacklist: state.addSymbolToBlacklist,
      removeSymbolFromBlacklist: state.removeSymbolFromBlacklist,
    }),
  );

  const height = node?.getRect()?.height ?? 0;

  const addItem = (e: string) => {
    if (e.trim() !== "") {
      addSymbolToBlacklist(e.trim());
      setNewItem("");
    }
  };
  const { data: symbols } = useBinanceSymbols();

  const handleAddFavorite = (e: string) => {
    if (e) {
      addItem(e);
    }
  };

  const options = useMemo(
    () =>
      symbols
        ?.filter((e) => {
          return !blacklistItems.includes(e.symbol);
        })
        .sort((a, b) => a.symbol.localeCompare(b.symbol))
        .map((e) => {
          return { label: e.symbol, value: e.symbol };
        }),
    [blacklistItems, symbols],
  );

  const removeItem = (symbol: string) => {
    removeSymbolFromBlacklist(symbol);
  };

  return (
    <Card className="bg-background p-2 pt-4" style={{ height: height - 50 }}>
      <CardContent className="p-0">
        <div className="flex space-x-2 mb-4">
          <AltSelect
            onChange={handleAddFavorite}
            options={options ?? []}
            trigger={
              <Button size="xs" variant="ghost" className="w-4 h-4">
                <i className="fa-regular fa-file-plus" />
              </Button>
            }
            emptyText="This folder is full"
            searchText="Search symbol..."
          />
        </div>
        <ul className="space-y-2 px-2">
          {blacklistItems.map((item, index) => (
            <li
              key={index}
              className="flex justify-between items-center text-xxs"
            >
              <span>{item}</span>
              <Button
                variant="destructive"
                size="xs"
                className="text-xxs"
                onClick={() => removeItem(item)}
              >
                Remove
              </Button>
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}
