//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const powerDeltaVolume = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Power delta volume",
    metainfo: {
      _metainfoVersion: 51,
      id: "power-delta-volume@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Power delta volume",
      shortDescription: "[SF] PVD",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "#FF5B5B",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Power delta volume",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#POWERDELTA`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const cvdPowerTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const valueArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const isShortArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        const isLongArray: any = this._context.new_var(
          PineJS.Std.low(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_var(this._context.symbol.time);
        const value: number = valueArray.adopt(cvdPowerTime, mainSymbolTime, 0);
        const isShort: boolean = isShortArray.adopt(
          cvdPowerTime,
          mainSymbolTime,
          0,
        );
        const isLong: boolean = isLongArray.adopt(
          cvdPowerTime,
          mainSymbolTime,
          0,
        );

        // Calcualtions
        const color: number = value >= 0 ? 1 : 0;
        let bgcolor: number = NaN;

        switch (true) {
          case isShort === true:
            bgcolor = 0;
            break;
          case isLong === true:
            bgcolor = 1;
            break;
          default:
            bgcolor = NaN;
        }

        //Return
        return [0, value, color, bgcolor];
      };
    },
  };
};
