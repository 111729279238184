import { TabNode } from "flexlayout-react";
import { useState } from "react";
import { useGrid } from "@/hooks/useGrid.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@/components/ui/popover.tsx";
import { colors } from "@/domain/constants.ts";
import { useDashboard } from "@/lib/store.ts";
import { cn } from "@/lib/utils.ts";

export function ColorPicker({ node }: { node: TabNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const { updateTabConfig, getNodeConfig } = useGrid(node.getId());
  const config = getNodeConfig();
  const color = config.color;
  const { colorsToSymbolMap, setSymbolForColor, symbol } = useDashboard(
    (state) => ({
      colorsToSymbolMap: state.colorsToSymbolMap,
      setSymbolForColor: state.setSymbolForColor,
      symbol: color ? state.colorsToSymbolMap[color] : null,
    }),
  );

  const handleColorClick = (color: string) => {
    if (symbol) {
      setSymbolForColor(color, symbol);
      updateTabConfig({
        color,
      });
    }

    setIsOpen(false);
  };

  return (
    <>
      <Button
        size="xxs"
        variant="ghost"
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(true);
        }}
        style={{
          backgroundColor: getNodeConfig()?.color ?? "transparent",
        }}
      ></Button>
      <Popover open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <PopoverAnchor />
        <PopoverContent
          className="p-1 w-auto"
          onMouseDown={(e) => e.stopPropagation()}
        >
          {colors.map((color, i) => (
            <div
              key={color}
              onMouseDown={() => handleColorClick(color)}
              style={{
                backgroundColor: color,
              }}
            >
              <div className="py-2 px-4 cursor-pointer hover:bg-slate-900/20">
                <span
                  className={cn(
                    "text-xs mr-1 rounded-full",
                    !colorsToSymbolMap[color] && "text-muted-foreground",
                  )}
                >
                  {i + 1}
                </span>
                <span
                  className={cn(
                    "text-sm",
                    !colorsToSymbolMap[color] && "text-muted-foreground",
                  )}
                >
                  {colorsToSymbolMap[color] ?? "No symbol"}
                </span>
              </div>
            </div>
          ))}
        </PopoverContent>
      </Popover>
    </>
  );
}
