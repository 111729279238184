import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";
import { ICryptoDrift } from "@/hooks/useCryptoDrift.ts";
import { useFilterStream } from "@/hooks/useFilterStream.ts";

export interface ITechDesk {
  symbol: string;
  price: number;
  openInterest: number;
  volume: number;
  buyDelta: number;
  sellDelta: number;
}

export interface ITechDeskResponse {
  data: ITechDesk[];
  timeFrame: string;
  timestamp: number;
}

interface ITechDeskWithFilters extends ITechDesk {
  adtv15m: number;
  adtv30m: number;
  adtv1h: number;
  adtv4h: number;
  adtv1d: number;
  adtv1w: number;
  adtv1M: number;
  vol15m: number;
  vol30m: number;
  vol1h: number;
  vol4h: number;
  vol1d: number;
  vol1w: number;
  vol1M: number;
}

export function useTechnicalDesk() {
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");
  const handler = useCallback(
    (_: ITechDeskResponse, newData: ITechDeskResponse) => {
      if (
        !newData.data ||
        !adtv15m ||
        !adtv30m ||
        !adtv1h ||
        !adtv4h ||
        !adtv1d ||
        !adtv1w ||
        !adtv1M ||
        !volume15m ||
        !volume30m ||
        !volume1h ||
        !volume4h ||
        !volume1d ||
        !volume1w ||
        !volume1M
      ) {
        return newData;
      }
      newData.data = newData.data.map((techDesk) => {
        return {
          ...techDesk,
          adtv15m: adtv15m[techDesk.symbol],
          adtv30m: adtv30m[techDesk.symbol],
          adtv1h: adtv1h[techDesk.symbol],
          adtv4h: adtv4h[techDesk.symbol],
          adtv1d: adtv1d[techDesk.symbol],
          adtv1w: adtv1w[techDesk.symbol],
          adtv1M: adtv1M[techDesk.symbol],
          vol15m: volume15m[techDesk.symbol],
          vol30m: volume30m[techDesk.symbol],
          vol1h: volume1h[techDesk.symbol],
          vol4h: volume4h[techDesk.symbol],
          vol1d: volume1d[techDesk.symbol],
          vol1w: volume1w[techDesk.symbol],
          vol1M: volume1M[techDesk.symbol],
        };
      });

      return newData;
    },
    [
      adtv15m,
      adtv1M,
      adtv1d,
      adtv1h,
      adtv1w,
      adtv30m,
      adtv4h,
      volume15m,
      volume1M,
      volume1d,
      volume1h,
      volume1w,
      volume30m,
      volume4h,
    ],
  );

  return useGenericSocket<ITechDeskResponse>(
    SocketTopics.technicalDesk,
    handler,
  );
}
