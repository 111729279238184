import { findLegsName } from "@/lib/datafeed/helpers.ts";
import {
  getActivityDetectorIndicatorSymbols,
  getAltTimersSymbols,
  getAsk_1_5_Symbols,
  getAsk_6_10_Symbols,
  getBid_1_5_Symbols,
  getBid_6_10_Symbols,
  getBidAskQuotedSpreadSymbols,
  getBinanceSymbols,
  getByBitSymbols,
  getCryptoDriftIndicatorSymbols,
  getDogSymbols,
  getFundingRateSymbols,
  getHFTActivitySymbols,
  getHighestSizeOrderSymbols,
  getIdeasSymbols,
  getLayeringSymbols,
  getLiquidationsIndicatorSymbols,
  getManipulationIndicatorSymbols,
  getMatrixSpreadSymbols,
  getNetOIDeltaSymbols,
  getOINetTakerLongSpikeSymbols,
  getOINetTakerShortSpikeSymbols,
  getOISpikeSymbols,
  getOpenInterestIndicatorSymbols,
  getOrderBookChangesSymbols,
  getPowerDeltaIndicatorSymbols,
  getPowerTradesSymbols,
  getPumpTrendIndicatorSymbols,
  getRealizedBidAskSpreadSymbols,
  getRetailPowerIndicatorSymbols,
  getSentimentsSymbols,
  getSpoofLayerDownSymbols,
  getSpoofLayerUpSymbols,
  getSpotFutureSpreadSymbols,
  getSpreadIndicatorSymbols,
  getStopKillerIndicatorSymbols,
  getStrategyIndicatorSymbols,
  getTickIndexSymbols,
  getTrendAssessmentIndicatorSymbols,
  getVolumeIndicatorSymbols,
  IFilter,
} from "@/lib/datafeed/utils.ts";
import {
  IDatafeedChartApi,
  LibrarySymbolInfo,
  SearchSymbolResultItem,
  VisiblePlotsSet,
} from "@tradingView/charting_library";
import { CHART_CONFIG, EXCHANGES } from "./config.ts";

type IExtendSearchSymbolResultItem = SearchSymbolResultItem & {
  full_name: string;
  filters?: IFilter[];
  price_filter?: {
    tick_size: string;
  };
  pricescale?: number;
};

export async function getAllSymbols(): Promise<
  IExtendSearchSymbolResultItem[]
> {
  const symbols: IExtendSearchSymbolResultItem[] = [];
  const bybitSymbols = await getByBitSymbols();

  const binanceSymbols = await getBinanceSymbols();
  const sentimentsSymbols = await getSentimentsSymbols(binanceSymbols);
  const strategiesSymbols = await getStrategyIndicatorSymbols(binanceSymbols);
  const activityDetectorSymbols =
    await getActivityDetectorIndicatorSymbols(binanceSymbols);
  const cryptoDriftSymbols =
    await getCryptoDriftIndicatorSymbols(binanceSymbols);
  const manipulationIndicatorSymbols =
    await getManipulationIndicatorSymbols(binanceSymbols);

  const retailPowerIndicatorSymbols =
    await getRetailPowerIndicatorSymbols(binanceSymbols);
  const powerTradesSymbols = await getPowerTradesSymbols(binanceSymbols);

  const pumpTrendIndicatorSymbols =
    await getPumpTrendIndicatorSymbols(binanceSymbols);

  const stopKillerIndicatorSymbols =
    await getStopKillerIndicatorSymbols(binanceSymbols);

  const openInterestIndicatorSymbols =
    await getOpenInterestIndicatorSymbols(binanceSymbols);

  const liquidationsIndicatorSymbols =
    await getLiquidationsIndicatorSymbols(binanceSymbols);

  const volumeIndicatorSymbols =
    await getVolumeIndicatorSymbols(binanceSymbols);

  const spreadIndicatorSymbols =
    await getSpreadIndicatorSymbols(binanceSymbols);

  const askSymbols_1_5 = await getAsk_1_5_Symbols(binanceSymbols);

  const askSymbols_6_10 = await getAsk_6_10_Symbols(binanceSymbols);

  const bidSymbols_1_5 = await getBid_1_5_Symbols(binanceSymbols);

  const bidSymbols_6_10 = await getBid_6_10_Symbols(binanceSymbols);

  const bidAskQuotedSpread = await getBidAskQuotedSpreadSymbols(binanceSymbols);

  const highestSizeOrder = await getHighestSizeOrderSymbols(binanceSymbols);

  const orderBookChanges = await getOrderBookChangesSymbols(binanceSymbols);

  const realizedBidAskSpread =
    await getRealizedBidAskSpreadSymbols(binanceSymbols);

  const matrixSpread = await getMatrixSpreadSymbols(binanceSymbols);

  const spotFutureSpread = await getSpotFutureSpreadSymbols(binanceSymbols);

  const ideaSymbols = await getIdeasSymbols(binanceSymbols);

  const spoofLayerUpSymbols = await getSpoofLayerUpSymbols(binanceSymbols);

  const spoofLayerDownSymbols = await getSpoofLayerDownSymbols(binanceSymbols);

  const powerCVDsIndicatorSymbols =
    await getPowerDeltaIndicatorSymbols(binanceSymbols);

  const hftActivitySymbols = await getHFTActivitySymbols(binanceSymbols);

  const oiSpikeSymbols = await getOISpikeSymbols(binanceSymbols);

  const oiNetTakerLongSpikeSymbols =
    await getOINetTakerLongSpikeSymbols(binanceSymbols);

  const oiNetTakerShortSpikeSymbols =
    await getOINetTakerShortSpikeSymbols(binanceSymbols);

  const netOIDeltaSymbols = await getNetOIDeltaSymbols(binanceSymbols);

  const altTimersSymbols = await getAltTimersSymbols(binanceSymbols);

  const dogSymbols = await getDogSymbols(binanceSymbols);

  const tickIndexSymbols = await getTickIndexSymbols(binanceSymbols);

  const layeringSymbols = await getLayeringSymbols(binanceSymbols);

  const fundingRateSymbols = await getFundingRateSymbols(binanceSymbols);

  const trendAssessmentIndicatorSymbols =
    await getTrendAssessmentIndicatorSymbols(binanceSymbols);

  trendAssessmentIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.TREND_ASSESSMENT}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.TREND_ASSESSMENT}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.TREND_ASSESSMENT}`,
      type: "crypto",
    });
  });

  fundingRateSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.FUNDING_RATE}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.FUNDING_RATE}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.FUNDING_RATE}`,
      type: "crypto",
    });
  });

  oiSpikeSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.OISPIKE}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.OISPIKE}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.OISPIKE}`,
      type: "crypto",
    });
  });

  oiNetTakerLongSpikeSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.OINETLONGSPIKE}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.OINETLONGSPIKE}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.OINETLONGSPIKE}`,
      type: "crypto",
    });
  });

  oiNetTakerShortSpikeSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.OINETSHORTSPIKE}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.OINETSHORTSPIKE}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.OINETSHORTSPIKE}`,
      type: "crypto",
    });
  });

  netOIDeltaSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.OINETDELTA}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.OINETDELTA}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.OINETDELTA}`,
      type: "crypto",
    });
  });

  hftActivitySymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.HFT_ACTIVITY}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.HFT_ACTIVITY}:${ss.symbol}`,
      description: ss.symbol,
      pricescale: 1,
      exchange: `${EXCHANGES.HFT_ACTIVITY}`,
      type: "crypto",
    });
  });

  dogSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.DOG}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.DOG}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.DOG}`,
      type: "crypto",
    });
  });

  tickIndexSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.TICK_INDEX}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.TICK_INDEX}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.TICK_INDEX}`,
      type: "crypto",
    });
  });

  altTimersSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.ALT_TIMERS}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.ALT_TIMERS}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.ALT_TIMERS}`,
      type: "crypto",
    });
  });

  layeringSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.LAYERING}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.LAYERING}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.LAYERING}`,
      type: "crypto",
    });
  });

  powerCVDsIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.POWER_DELTA}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.POWER_DELTA}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.POWER_DELTA}`,
      type: "crypto",
    });
  });

  powerTradesSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.POWER_TRADES}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.POWER_TRADES}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.POWER_TRADES}`,
      type: "crypto",
    });
  });

  binanceSymbols.forEach((bs) => {
    symbols.push({
      ticker: `${EXCHANGES.BINANCE}:${bs.symbol}`,
      symbol: bs.symbol,
      full_name: `${EXCHANGES.BINANCE}:${bs.symbol}`,
      description: bs.symbol,
      exchange: `${EXCHANGES.BINANCE}`,
      type: "crypto",
      filters: bs.filters,
    });
  });

  bybitSymbols.forEach((bs) => {
    symbols.push({
      ticker: `${EXCHANGES.BYBIT}:${bs.name}`,
      symbol: bs.name,
      full_name: `${EXCHANGES.BYBIT}:${bs.name}`,
      description: bs.name,
      exchange: `${EXCHANGES.BYBIT}`,
      price_filter: {
        tick_size: bs.price_filter?.tick_size,
      },
      type: "crypto",
    });
  });

  strategiesSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.STRATEGY}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.STRATEGY}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.STRATEGY}`,
      type: "crypto",
    });
  });

  sentimentsSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.SANTIMENT}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.SANTIMENT}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.SANTIMENT}`,
      type: "crypto",
    });
  });

  stopKillerIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.STOP_KILLER}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.STOP_KILLER}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.STOP_KILLER}`,
      type: "crypto",
    });
  });

  manipulationIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.MANIPULATIONS}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.MANIPULATIONS}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.MANIPULATIONS}`,
      type: "crypto",
    });
  });

  retailPowerIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.RETAIL_POWER}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.RETAIL_POWER}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.RETAIL_POWER}`,
      type: "crypto",
    });
  });

  pumpTrendIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.PUMP_TREND}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.PUMP_TREND}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.PUMP_TREND}`,
      type: "crypto",
    });
  });

  retailPowerIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.MARKET_POWER}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.MARKET_POWER}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.MARKET_POWER}`,
      type: "crypto",
    });
  });

  openInterestIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.OPEN_INTEREST}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.OPEN_INTEREST}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.OPEN_INTEREST}`,
      type: "crypto",
    });
  });

  liquidationsIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.LIQUIDATIONS}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.LIQUIDATIONS}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.LIQUIDATIONS}`,
      type: "crypto",
    });
  });

  volumeIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.VOLUME}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.VOLUME}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.VOLUME}`,
      type: "crypto",
    });
  });

  spreadIndicatorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.SPREAD}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.SPREAD}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.SPREAD}`,
      type: "crypto",
    });
  });

  askSymbols_1_5.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.ASKORDERBOOKSUM1_5}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.ASKORDERBOOKSUM1_5}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.ASKORDERBOOKSUM1_5}`,
      type: "crypto",
    });
  });
  askSymbols_6_10.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.ASKORDERBOOKSUM6_10}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.ASKORDERBOOKSUM6_10}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.ASKORDERBOOKSUM6_10}`,
      type: "crypto",
    });
  });
  bidSymbols_1_5.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.BIDORDERBOOKSUM1_5}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.BIDORDERBOOKSUM1_5}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.BIDORDERBOOKSUM1_5}`,
      type: "crypto",
    });
  });
  bidSymbols_6_10.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.BIDORDERBOOKSUM6_10}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.BIDORDERBOOKSUM6_10}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.BIDORDERBOOKSUM6_10}`,
      type: "crypto",
    });
  });

  bidAskQuotedSpread.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.BIDASKQUOTEDSPREAD}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.BIDASKQUOTEDSPREAD}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.BIDASKQUOTEDSPREAD}`,
      type: "crypto",
    });
  });

  highestSizeOrder.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.HIGHESTSIZEORDER}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.HIGHESTSIZEORDER}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.HIGHESTSIZEORDER}`,
      type: "crypto",
    });
  });

  orderBookChanges.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.ORDERBOOKCHANGES}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.ORDERBOOKCHANGES}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.ORDERBOOKCHANGES}`,
      type: "crypto",
    });
  });

  realizedBidAskSpread.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.REALIZEDBIDASKSPREAD}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.REALIZEDBIDASKSPREAD}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.REALIZEDBIDASKSPREAD}`,
      type: "crypto",
    });
  });

  matrixSpread.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.MATRIXSPREAD}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.MATRIXSPREAD}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.MATRIXSPREAD}`,
      type: "crypto",
    });
  });

  spotFutureSpread.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.SPOTFUTURESPREAD}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.SPOTFUTURESPREAD}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.SPOTFUTURESPREAD}`,
      type: "crypto",
    });
  });

  ideaSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.IDEAS}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.IDEAS}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.IDEAS}`,
      type: "crypto",
    });
  });

  spoofLayerUpSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.SPOOF_LAYER_UP}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.SPOOF_LAYER_UP}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.SPOOF_LAYER_UP}`,
      type: "crypto",
    });
  });

  spoofLayerDownSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.SPOOF_LAYER_DOWN}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.SPOOF_LAYER_DOWN}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.SPOOF_LAYER_DOWN}`,
      type: "crypto",
    });
  });

  activityDetectorSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.ACTIVITY_DETECTOR}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.ACTIVITY_DETECTOR}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.ACTIVITY_DETECTOR}`,
      type: "crypto",
    });
  });

  cryptoDriftSymbols.forEach((ss) => {
    symbols.push({
      ticker: `${EXCHANGES.CRYPTO_DRIFT}:${ss.symbol}`,
      symbol: ss.symbol,
      full_name: `${EXCHANGES.CRYPTO_DRIFT}:${ss.symbol}`,
      description: ss.symbol,
      exchange: `${EXCHANGES.CRYPTO_DRIFT}`,
      type: "crypto",
    });
  });

  return symbols;
}

export interface IExtendedLibrarySymbolInfo extends LibrarySymbolInfo {
  legs: string[];
  allLegsSymbols: IExtendSearchSymbolResultItem[];
}

export const resolveSymbol: IDatafeedChartApi["resolveSymbol"] = async (
  fullName,
  onSymbolResolvedCallback,
  onResolveErrorCallback,
) => {
  const exchange = fullName.split(":")[0];
  const baseResolveSymbol: IExtendedLibrarySymbolInfo = {
    allLegsSymbols: [],
    legs: [],
    listed_exchange: exchange,
    exchange: exchange,
    ticker: fullName,
    full_name: fullName,
    format: "price",
    name: fullName,
    description: fullName.replaceAll("USDT", "").replaceAll("BUSD", ""),
    type: "crypto",
    session: "24x7",
    timezone: "Etc/UTC",
    minmov: 1,
    pricescale: 100,
    has_intraday: true,
    has_weekly_and_monthly: true,
    supported_resolutions: CHART_CONFIG.supported_resolutions || [],
    volume_precision: 1,
    data_status: "streaming",
  };

  let symbol = {} as IExtendedLibrarySymbolInfo;
  switch (true) {
    case fullName.includes("#SF_OPEN_INTEREST"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.OPEN_INTEREST,
        listed_exchange: EXCHANGES.OPEN_INTEREST,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_ACTIVITY_DETECTOR"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 1000000,
        has_ticks: false,
        exchange: EXCHANGES.ACTIVITY_DETECTOR,
        listed_exchange: EXCHANGES.ACTIVITY_DETECTOR,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#TREND_ASSESSMENT"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.TREND_ASSESSMENT,
        listed_exchange: EXCHANGES.TREND_ASSESSMENT,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_CRYPTO_DRIFT"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 1000000,
        has_ticks: false,
        exchange: EXCHANGES.CRYPTO_DRIFT,
        listed_exchange: EXCHANGES.CRYPTO_DRIFT,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#FUNDINGRATE"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.FUNDING_RATE,
        listed_exchange: EXCHANGES.FUNDING_RATE,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_LIQUIDATIONS"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.LIQUIDATIONS,
        listed_exchange: EXCHANGES.LIQUIDATIONS,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_ALT_TIMERS"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.ALT_TIMERS,
        listed_exchange: EXCHANGES.ALT_TIMERS,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_DOG"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.DOG,
        listed_exchange: EXCHANGES.DOG,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_TICK_INDEX"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.TICK_INDEX,
        listed_exchange: EXCHANGES.TICK_INDEX,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#LAYERING"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: false,
        exchange: EXCHANGES.LAYERING,
        listed_exchange: EXCHANGES.LAYERING,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_MANIPULATION_MONITOR"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.MANIPULATIONS,
        listed_exchange: EXCHANGES.MANIPULATIONS,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_RETAIL_POWER"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.RETAIL_POWER,
        listed_exchange: EXCHANGES.RETAIL_POWER,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_MARKET_POWER"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.MARKET_POWER,
        listed_exchange: EXCHANGES.MARKET_POWER,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#STOPKILLER"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.STOP_KILLER,
        listed_exchange: EXCHANGES.STOP_KILLER,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#STRATEGY"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.STRATEGY,
        listed_exchange: EXCHANGES.STRATEGY,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_VOLUME"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.VOLUME,
        listed_exchange: EXCHANGES.VOLUME,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_SPREAD"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.SPREAD,
        listed_exchange: EXCHANGES.SPREAD,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#ASKORDERBOOKSUM1_5"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.ASKORDERBOOKSUM1_5,
        listed_exchange: EXCHANGES.ASKORDERBOOKSUM1_5,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#ASKORDERBOOKSUM6_10"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.ASKORDERBOOKSUM6_10,
        listed_exchange: EXCHANGES.ASKORDERBOOKSUM6_10,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#BIDORDERBOOKSUM1_5"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.BIDORDERBOOKSUM1_5,
        listed_exchange: EXCHANGES.BIDORDERBOOKSUM1_5,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#BIDORDERBOOKSUM6_10"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.BIDORDERBOOKSUM6_10,
        listed_exchange: EXCHANGES.BIDORDERBOOKSUM6_10,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;

    case fullName.includes("#BIDASKQUOTEDSPREAD"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.BIDASKQUOTEDSPREAD,
        listed_exchange: EXCHANGES.BIDASKQUOTEDSPREAD,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#HIGHESTSIZEORDER"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.HIGHESTSIZEORDER,
        listed_exchange: EXCHANGES.HIGHESTSIZEORDER,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#ORDERBOOKCHANGES"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.ORDERBOOKCHANGES,
        listed_exchange: EXCHANGES.ORDERBOOKCHANGES,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#REALIZEDBIDASKSPREAD"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.REALIZEDBIDASKSPREAD,
        listed_exchange: EXCHANGES.REALIZEDBIDASKSPREAD,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#NETTAKERLONGSPIKE"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.OINETLONGSPIKE,
        listed_exchange: EXCHANGES.OINETLONGSPIKE,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#POWERTRADES"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.POWER_TRADES,
        listed_exchange: EXCHANGES.POWER_TRADES,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#POWERDELTA"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.POWER_DELTA,
        listed_exchange: EXCHANGES.POWER_DELTA,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#NETTAKERSHORTSPIKE"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.OINETSHORTSPIKE,
        listed_exchange: EXCHANGES.OINETSHORTSPIKE,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#OISPIKE"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.OISPIKE,
        listed_exchange: EXCHANGES.OISPIKE,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#MATRIXSPREAD"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.MATRIXSPREAD,
        listed_exchange: EXCHANGES.MATRIXSPREAD,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SF_IDEAS"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        has_ticks: true,
        exchange: EXCHANGES.IDEAS,
        listed_exchange: EXCHANGES.IDEAS,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    case fullName.includes("#SANTIMENTS_"):
      symbol = {
        ...baseResolveSymbol,
        visible_plots_set: "c" as VisiblePlotsSet,
        pricescale: 100,
        exchange: EXCHANGES.SANTIMENT,
        listed_exchange: EXCHANGES.SANTIMENT,
      };
      setTimeout(() => {
        onSymbolResolvedCallback(symbol);
      }, 0);
      break;
    default: {
      const symbols = await getAllSymbols();
      Object.values(EXCHANGES).forEach((exchange) => {
        fullName = fullName
          .toUpperCase()
          .replaceAll(`${exchange.toUpperCase()}:`, "");
      });
      // get all legs from fullname
      const legs = findLegsName(fullName, true);

      const priceScaleSymbols = (symbol: string, exchange: string): number => {
        const legs = findLegsName(symbol, true);
        const params: { [leg: string]: number } = {};
        const ar: number[] = [];
        for (const leg of legs) {
          for (const symbol of symbols) {
            if (symbol.symbol === leg && symbol.filters) {
              for (const filter of symbol.filters) {
                if (filter.filterType === "PRICE_FILTER" && filter.tickSize) {
                  params[leg] = parseFloat(filter.tickSize);
                  ar.push(parseFloat(filter.tickSize));
                }
              }
            } else {
              if (symbol.price_filter?.tick_size) {
                if (symbol.exchange === exchange && symbol.symbol === leg) {
                  ar.push(parseFloat(symbol?.price_filter?.tick_size));
                }
              }
            }
          }
        }
        return Math.round(1 / Math.min(...ar));
      };

      if (legs.length) {
        const allLegsSymbols = symbols.filter(({ full_name }) =>
          legs.some((leg) =>
            full_name.toUpperCase().endsWith(leg.toUpperCase()),
          ),
        );

        if (!allLegsSymbols.length) {
          onResolveErrorCallback("cannot resolve symbol");
          return;
        }

        const priceScale = priceScaleSymbols(
          fullName,
          allLegsSymbols[0].exchange,
        );
        const symbolInfo: IExtendedLibrarySymbolInfo = {
          ticker: fullName,
          full_name: fullName,
          format: "price",
          listed_exchange: allLegsSymbols[0].exchange,
          name: fullName,
          description: fullName.replaceAll("USDT", "").replaceAll("BUSD", ""),
          type: allLegsSymbols[0].type,
          session: "24x7",
          timezone: "Etc/UTC",
          exchange: allLegsSymbols[0].exchange,
          minmov: 1,
          pricescale: priceScale,
          has_intraday: true,
          has_weekly_and_monthly: true,
          supported_resolutions: CHART_CONFIG.supported_resolutions || [],
          volume_precision: 1,
          data_status: "streaming",
          legs: legs,
          allLegsSymbols: allLegsSymbols,
        };

        onSymbolResolvedCallback(symbolInfo);
      }
    }
  }
};
