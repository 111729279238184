import { IStrategy } from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { environment } from "@/lib/constants.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import axios from "axios";
import { useCallback } from "react";

export function useStrategies() {
  const fetchInitialData = async () => {
    try {
      const response = await axios.get<IStrategy[]>(
        `${environment.strategyApiURL}/top`,
      );
      return response.data.sort((a, b) => b.timestamp - a.timestamp);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      return [];
    }
  };

  const handler = useCallback(
    (oldData: IStrategy[] = [], newData: IStrategy) => [newData, ...oldData],
    [],
  );

  return useGenericSocket<IStrategy[], IStrategy>(
    SocketTopics.strategies,
    handler,
    { fetchInitialData, apiUrl: environment.strategyApiURL },
  );
}
