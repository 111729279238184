//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const rsiCannel = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "RSI channel",
    metainfo: {
      _metainfoVersion: 51,
      id: "rsi-channel@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] RSI channel",
      shortDescription: "[SF] RSI channel",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "sourcePlot", type: StudyPlotType.Line },
        { id: "maPlot", type: StudyPlotType.Line },
        { id: "upperBandPlot", type: StudyPlotType.Line },
        { id: "lowerBandPlot", type: StudyPlotType.Line },
        {
          id: "insideBackgroundColorer",
          type: StudyPlotType.Colorer,
          target: "insideBackground",
          palette: "backgroundPalette",
        },
        { id: "outsideBandPlot", type: StudyPlotType.Line },
        {
          id: "outsideBackgroundColorer",
          type: StudyPlotType.Colorer,
          target: "outsideBackground",
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [
        {
          id: "insideBackground",
          objAId: "upperBandPlot",
          objBId: "lowerBandPlot",
          title: "Inside background",
          type: FilledAreaType.TypePlots,
        },
        {
          id: "outsideBackground",
          objAId: "sourcePlot",
          objBId: "outsideBandPlot",
          title: "Outside background",
          type: FilledAreaType.TypePlots,
        },
      ],
      palettes: {
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Uptrend" },
            1: { name: "Downtrend" },
          },
        },
      },
      defaults: {
        filledAreasStyle: {
          insideBackground: {
            color: "rgba(255, 255, 255, 0.25)",
            visible: true,
            transparency: 25,
          },
          outsideBackground: {
            color: "rgba(255, 255, 255, 0.25)",
            visible: true,
            transparency: 25,
          },
        },
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          sourcePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          maPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 40,
          },
          upperBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          lowerBandPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          insideBackgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgb(149, 152, 161)",
          },
          outsideBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgba(255, 255, 255, 0)",
          },
          outsideBackgroundColorer: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            trackPrice: false,
            color: "rgba(149, 152, 161, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          sourceInput: "close",
          maTypeInput: "SMA",
          length: 240,
          stdDevs: 2.0,
        },
      },
      styles: {
        sourcePlot: {
          histogramBase: 0,
          joinPoints: false,
          title: "Source",
          isHidden: true,
          minHeight: 0,
          showLast: 0,
          zorder: 0,
        },
        maPlot: {
          title: "Moving average",
          histogramBase: 0,
        },
        upperBandPlot: {
          title: "Upper band",
          histogramBase: 0,
        },
        lowerBandPlot: {
          title: "Lower band",
          histogramBase: 0,
        },
        outsideBandPlot: {
          histogramBase: 0,
          joinPoints: false,
          title: "Outside band",
          isHidden: true,
          minHeight: 0,
          showLast: 0,
          zorder: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
        {
          id: "maTypeInput",
          name: "MA type",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "OIWMA", "LSMA"],
          type: StudyInputType.Text,
        },
        {
          id: "length",
          name: "Length",
          defval: 240,
          type: StudyInputType.Integer,
        },
        {
          id: "stdDevs",
          name: "Sigma multiplier",
          defval: 3.0,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const sourceInput: string = this._input(0);
        const maTypeInput: string = this._input(1);
        const lengthInput: number = this._input(2);
        const stDevNumberInput: number = this._input(3);

        //Market data parsing
        let sourceSeries: any;
        let source: number = NaN;
        switch (sourceInput) {
          case "open":
            source = PineJS.Std.open(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            break;
          case "high":
            source = PineJS.Std.high(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.high(this._context),
            );
            break;
          case "low":
            source = PineJS.Std.low(this._context);
            sourceSeries = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            source = PineJS.Std.close(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
          case "hl2":
            source = PineJS.Std.hl2(this._context);
            sourceSeries = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            source = PineJS.Std.hlc3(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.hlc3(this._context),
            );
            break;
          case "ohlc4":
            source = PineJS.Std.ohlc4(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.ohlc4(this._context),
            );
            break;
          default:
            source = PineJS.Std.close(this._context);
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
        }

        //Calculaions
        let ma: number = NaN;
        let oiTime: any,
          oiArray: any,
          mainSymbolTime: any,
          oi: any,
          oiSeries: any,
          source_x_oi: any,
          source_x_oiSeries: any,
          source_x_oiAvg: any,
          oiAvg: any;
        switch (maTypeInput) {
          case "SMA":
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
            break;
          case "EMA":
            ma = PineJS.Std.ema(sourceSeries, lengthInput, this._context);
            break;
          case "WMA":
            ma = PineJS.Std.wma(sourceSeries, lengthInput, this._context);
            break;
          case "VWMA":
            ma = PineJS.Std.vwma(sourceSeries, lengthInput, this._context);
            break;
          case "OIWMA":
            this._context.select_sym(1);
            oiTime = this._context.new_var(this._context.symbol.time);
            oiArray = this._context.new_var(PineJS.Std.close(this._context));
            this._context.select_sym(0);
            mainSymbolTime = this._context.new_var(this._context.symbol.time);
            oi = oiArray.adopt(oiTime, mainSymbolTime, 0);
            oiSeries = this._context.new_var(oi);
            source_x_oi = sourceSeries.get(0) * oi;
            source_x_oiSeries = this._context.new_var(source_x_oi);
            source_x_oiAvg =
              PineJS.Std.sum(source_x_oiSeries, lengthInput, this._context) /
              lengthInput;
            oiAvg =
              PineJS.Std.sum(oiSeries, lengthInput, this._context) /
              lengthInput;
            ma = source_x_oiAvg / oiAvg;
            break;
          case "LSMA":
            ma = PineJS.Std.linreg(sourceSeries, lengthInput, 0);
            break;
          default:
            ma = PineJS.Std.sma(sourceSeries, lengthInput, this._context);
            break;
        }
        const stDev: number = PineJS.Std.stdev(
          sourceSeries,
          lengthInput,
          this._context,
        );

        const upperBand: number = ma + stDevNumberInput * stDev;
        const lowerBand: number = ma - stDevNumberInput * stDev;

        const rsi: number = PineJS.Std.stoch(
          sourceSeries,
          sourceSeries,
          sourceSeries,
          lengthInput,
          this._context,
        );
        const insideBackgroundColor: number = rsi > 50 ? 0 : 1;
        let outsideBand: number = NaN;
        switch (true) {
          case source > upperBand:
            outsideBand = upperBand;
            break;
          case source < lowerBand:
            outsideBand = lowerBand;
            break;
          default:
            outsideBand = NaN;
            break;
        }
        let outsideBackgroundColor: number = NaN;
        switch (true) {
          case source > upperBand:
            outsideBackgroundColor = 1;
            break;
          case source < lowerBand:
            outsideBackgroundColor = 0;
            break;
          default:
            outsideBackgroundColor = NaN;
            break;
        }

        //Returns
        return [
          source,
          ma,
          upperBand,
          lowerBand,
          insideBackgroundColor,
          outsideBand,
          outsideBackgroundColor,
        ];
      };
    },
  };
};
