import React, { ReactNode } from "react";
import {
  Menubar,
  MenubarContent,
  MenubarGroup,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarShortcut,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { widgets } from "@/domain/constants.ts";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { IWidgetOptions } from "@/domain/interfaces/dashboard.interface.ts";
import { useAuth } from "@/hooks/useAuth.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useRTT } from "@/hooks/useRTT.ts";
import { cn } from "@/lib/utils.ts";
import { Actions, TabNode } from "flexlayout-react";
import { isUndefined } from "lodash";
import { Logo } from "@/components/shared/logo.tsx";

function RTT() {
  const { data: rtt } = useRTT();
  if (isUndefined(rtt)) {
    return null;
  }

  const getRTTColor = (rtt: number | null) => {
    if (!rtt) {
      return "text-gray-600 border-gray-600";
    }

    switch (true) {
      case rtt < 150:
        return "text-[#3CA64B] border-[#3CA64B]";
      case rtt < 250:
        return "text-[#FFA028] border-[#FFA028]";
      case rtt < 350:
        return "text-[#B2182C] border-[#B2182C]";
      default:
        return "text-[#B2182C] border-[#B2182C]";
    }
  };

  const rttColor = getRTTColor(rtt);

  return (
    <div
      className={cn(
        "px-1.5 h-10 flex items-center justify-center text-xxs rounded-md border mx-2 w-14 text-center",
        rttColor,
      )}
    >
      {rtt} ms
    </div>
  );
}

interface MenuItem {
  label: string;
  widgetType?: EWidgetType;
  onClick?: (model: any) => void;
  className?: string;
  shortcut?: ReactNode;
  items?: MenuItem[];
}

const menuItems: MenuItem[] = [
  {
    label: "Chart",
    widgetType: EWidgetType.chart,
  },
  {
    label: "ControlPanel",
    widgetType: EWidgetType.controlPanel,
  },
  {
    label: "Screeners",
    items: [
      {
        label: "Microstructure",
        items: [
          {
            label: "Manipulations",
            widgetType: EWidgetType.manipulationMonitor,
          },
          {
            label: "Spread",
            widgetType: EWidgetType.spotFuturesSpread,
          },
          {
            label: "Spread Domination",
            widgetType: EWidgetType.spreadDominationScreener,
          },
          {
            label: "Predict Liquidation",
            widgetType: EWidgetType.predictLiquidation,
          },
          {
            label: "Cascade Levels",
            widgetType: EWidgetType.cascadeLevels,
          },
          {
            label: "ADTV",
            widgetType: EWidgetType.adtvScreener,
          },
          {
            label: "Activity Detector",
            widgetType: EWidgetType.activityDetectorScreener,
          },
          {
            label: "Crypto Drift",
            widgetType: EWidgetType.cryptoDriftScreener,
          },
        ],
      },
      {
        label: "Technical",
        items: [
          {
            label: "Price",
            widgetType: EWidgetType.priceScreener,
          },
          {
            label: "Open Interest",
            widgetType: EWidgetType.oiScreener,
          },
          {
            label: "Volume",
            widgetType: EWidgetType.volumeScreener,
          },
          {
            label: "Delta",
            widgetType: EWidgetType.deltaScreener,
          },
        ],
      },
      {
        label: "Sentiment",
        items: [],
      },
      {
        label: "Correlation",
        items: [
          {
            label: "Market Ratio",
            widgetType: EWidgetType.marketRatioScreener,
          },
        ],
      },
      {
        label: "Predict",
        items: [],
      },
    ],
  },
  {
    label: "Scanners",
    items: [
      {
        label: "Liquidations",
        widgetType: EWidgetType.liquidationsScanner,
      },
      {
        label: "Pairs",
        widgetType: EWidgetType.pairsScanner,
      },
      {
        label: "Stop Killer",
        widgetType: EWidgetType.stopKiller,
      },
    ],
  },
  // {
  //   label: "Craft Signals",
  // },
  {
    label: "Strategies",
    items: [
      {
        label: "Strategies (legacy)",
        widgetType: EWidgetType.strategies,
      },
      {
        label: "Strategies (new)",
        widgetType: EWidgetType.strategiesV2,
      },
    ],
  },
  // {
  //   label: "BlockChain",
  // },
  // {
  //   label: "News",
  // },
  // {
  //   label: "F. Calendar",
  // },
  {
    label: "Settings",
    items: [
      {
        label: "clear layout",
        onClick: (model) => {
          const ids: string[] = [];
          model?.visitNodes((node: TabNode) => {
            if (node.getType() === "tabset") {
              ids.push(node.getId());
            }
          });
          ids.forEach((id) => {
            model?.doAction(Actions.deleteTabset(id));
          });
        },
        shortcut: <i className="fa-regular fa-broom-wide" />,
      },
      {
        label: "clear storage",
        onClick: () => {
          window.localStorage.removeItem("sf-state");
          window.location.reload();
        },
        className: "text-red-900",
        shortcut: <i className="fa-regular fa-refresh" />,
      },
    ],
  },
  {
    label: "Marketplace",
    className: "text-[#FFA028]",
    onClick: () => {
      window.open("https://marketplace.spreadfighter.com", "_blank");
    },
  },
];

export function DashboardHeader() {
  const { user } = useAuth();

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <Logo className="w-8 h-8" />
        <HeaderMenu />
        <RTT />
      </div>
      <div className="p-2">Hi {user?.displayName}!</div>
    </div>
  );
}

function HeaderMenu() {
  const { grid, model } = useGrid();
  const addWidget = (widgetOptions: IWidgetOptions) => {
    grid?.addTabWithDragAndDrop("Drag me! Esc for cancel", widgetOptions, () =>
      console.log("added"),
    );
  };

  const onClick = (menu: MenuItem) => {
    if (menu.onClick) {
      menu.onClick(model);
    } else if (menu.widgetType) {
      addWidget(widgets[menu.widgetType]);
    } else {
      console.error("No action or widgetType provided");
    }
  };

  const renderMenuItems = (items: MenuItem[]) => {
    return items.map((item, index) => {
      const isInactive =
        !item.items?.length && !item.widgetType && !item.onClick;
      return (
        <MenubarItem
          key={index}
          onClick={() => onClick(item)}
          disabled={isInactive}
          className={cn(item.className)}
        >
          {item.label}
          {item.shortcut && <MenubarShortcut>{item.shortcut}</MenubarShortcut>}
        </MenubarItem>
      );
    });
  };

  return (
    <Menubar>
      {menuItems.map((menu, index) => {
        const isInactive =
          !menu.items?.length && !menu.widgetType && !menu.onClick;
        return (
          <MenubarMenu key={index}>
            {menu.items ? (
              <>
                <MenubarTrigger disabled={isInactive}>
                  {menu.label}
                </MenubarTrigger>
                <MenubarContent
                  className={cn(
                    menu.items.length && menu.items[0].items && "flex gap-2",
                  )}
                >
                  {menu.items.map((group, groupIndex) =>
                    group.items ? (
                      <MenubarGroup key={groupIndex}>
                        <MenubarLabel>{group.label}</MenubarLabel>
                        {renderMenuItems(group.items)}
                      </MenubarGroup>
                    ) : (
                      renderMenuItems([group])
                    ),
                  )}
                </MenubarContent>
              </>
            ) : (
              <MenubarTrigger
                disabled={isInactive}
                className={cn(menu.className)}
                onClick={() => onClick(menu)}
              >
                {menu.label}
              </MenubarTrigger>
            )}
          </MenubarMenu>
        );
      })}
    </Menubar>
  );
}
