import { useEffect, useRef } from "react";
import { getMarketRatioTopic, socketConnection } from "@/lib/natsConnection";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export interface IMarketRatioData {
  min: number;
  value: number;
  max: number;
}

export interface IMarketRatio {
  symbol: string;
  timestamp: number;
  data: {
    "1M": IMarketRatioData;
    "1d": IMarketRatioData;
    "1w": IMarketRatioData;
  };
}

export type IMarketRatioAllData = Record<string, IMarketRatio>;

const key = ["marketRatioAllData"];

export function useMarketRatioAll(symbols: string[]) {
  const unsubscribeRef = useRef<Record<string, () => void>>({});
  const queryClient = useQueryClient();

  const oldSymbols = useRef<string[]>([]);

  useEffect(() => {
    // Подписка на новые символы
    symbols
      .filter((s) => !oldSymbols.current.includes(s))
      .forEach((symbol) => {
        const topic = getMarketRatioTopic(symbol);
        socketConnection
          .subscribe(topic, (newData: IMarketRatio) => {
            queryClient.setQueryData<IMarketRatioAllData>(
              key,
              (currentData: Record<string, IMarketRatio> = {}) => {
                return { ...currentData, [newData.symbol]: newData };
              },
            );
          })
          .then((unsub) => {
            unsubscribeRef.current[symbol] = unsub;
          });
      });

    // Отписка от неиспользуемых символов
    oldSymbols.current
      .filter((s) => !symbols.includes(s))
      .forEach((symbol) => {
        unsubscribeRef.current[symbol]();
        delete unsubscribeRef.current[symbol];
      });

    oldSymbols.current = symbols;
  }, [queryClient, symbols]);

  useEffect(() => {
    return () => {
      // Отписка от всех символов при размонтировании компонента
      Object.keys(unsubscribeRef.current).forEach((symbol) => {
        unsubscribeRef.current[symbol]();
      });
    };
  }, []);

  return useQuery<IMarketRatioAllData>({
    queryKey: key,
    enabled: false,
  });
}
