import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import { useCallback } from "react";

export interface IPredictLiquidationMessage {
  timestamp: number;
  chanel: string;
  data: IPredictLiquidation[];
}

export interface IPredictLiquidation {
  timestamp: number;
  side: string;
  price: number;
  quoteQuantity: number;
  quoteQuantityCum: number;
  index: number;
  prc: number;
  symbol: string;
}

export function usePredictLiquidation() {
  const handler = useCallback(
    (_: IPredictLiquidation[], newData: IPredictLiquidationMessage) => {
      return newData.data;
    },
    [],
  );

  return useGenericSocket<IPredictLiquidation[], IPredictLiquidationMessage>(
    SocketTopics.marginLevelWindow,
    handler,
  );
}
