import { TabNode } from "flexlayout-react";
import { useMemo, useState } from "react";
import { Button } from "@/components/ui/button.tsx";
import { useDashboard } from "@/lib/store.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { IFavoriteFolder } from "@/domain/interfaces/dashboard.interface.ts";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { FaRegStar, FaSquare, FaSquareCheck, FaStar } from "react-icons/fa6";

export function FilterByFavorites({ node }: { node: TabNode }) {
  //todo wrap to memo
  const { favorites } = useDashboard((state) => ({
    favorites: state.favorites,
  }));
  const [isOpen, setIsOpen] = useState(false);
  const { updateTabConfig, getNodeConfig } = useGrid(node.getId());
  const filterByFavorites = getNodeConfig()?.filterByFavorites;

  const isFolderInFilter = useMemo(() => {
    return (folder: IFavoriteFolder) =>
      filterByFavorites?.some((symbol) => folder?.symbols.includes(symbol));
  }, [filterByFavorites]);

  const isSymbolInFilter = useMemo(() => {
    return (symbol: string) => filterByFavorites?.includes(symbol);
  }, [filterByFavorites]);

  const widgetType = node.getComponent() as EWidgetType;
  if (
    widgetType === EWidgetType.chart ||
    widgetType === EWidgetType.controlPanel
  ) {
    return null;
  }

  const resetFilter = () => {
    updateTabConfig({
      filterByFavorites: null,
    });
    setIsOpen(false);
  };

  const toggleFilterByFavoritesSymbol = (symbol: string) => {
    if (filterByFavorites?.includes(symbol)) {
      updateTabConfig({
        filterByFavorites: filterByFavorites?.filter((s) => s !== symbol),
      });
    } else {
      updateTabConfig({
        filterByFavorites: [...(filterByFavorites ?? []), symbol],
      });
    }
    setIsOpen(false);
  };

  const toggleFilterByFavoritesFolder = (folder: IFavoriteFolder) => {
    if (isFolderInFilter(folder)) {
      updateTabConfig({
        filterByFavorites: filterByFavorites?.filter(
          (symbol) => !folder?.symbols.includes(symbol),
        ),
      });
    } else {
      updateTabConfig({
        filterByFavorites: Array.from(
          new Set([...(filterByFavorites ?? []), ...(folder?.symbols ?? [])]),
        ),
      });
    }
    setIsOpen(false);
  };

  return (
    <>
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            className="text-white"
            size="xxs"
            variant="ghost"
            // style={{
            //   backgroundColor: filterByFavoriteFolder?.color,
            // }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen((prev) => !prev);
            }}
          >
            {filterByFavorites?.length ? <FaStar /> : <FaRegStar />}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" className="w-56 bg-black">
          <DropdownMenuLabel>Filter By Favorites</DropdownMenuLabel>
          <DropdownMenuSeparator />

          <DropdownMenuItem
            onMouseDown={(e) => {
              e.stopPropagation();
              resetFilter();
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
            <span>Reset filter</span>
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          {favorites.map((folder) =>
            folder.symbols.length ? (
              <DropdownMenuSub key={folder.id}>
                <DropdownMenuSubTrigger
                  style={{
                    backgroundColor: folder.color,
                  }}
                  className="cursor-pointer"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    toggleFilterByFavoritesFolder(folder);
                  }}
                >
                  {isFolderInFilter(folder) ? (
                    <i className="fa-solid fa-square-check" />
                  ) : (
                    <i className="fa-regular fa-square" />
                  )}
                  <span>{folder.name}</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    {folder.symbols.map((symbol) => (
                      <DropdownMenuItem
                        key={symbol}
                        onClick={() => toggleFilterByFavoritesSymbol(symbol)}
                      >
                        {isSymbolInFilter(symbol) ? (
                          <i className="fa-solid fa-square-check" />
                        ) : (
                          <i className="fa-regular fa-square" />
                        )}
                        {symbol}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>
            ) : (
              <DropdownMenuItem
                key={folder.id}
                className="cursor-pointer"
                style={{
                  backgroundColor: folder.color,
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  toggleFilterByFavoritesFolder(folder);
                }}
              >
                {isFolderInFilter(folder) ? <FaSquareCheck /> : <FaSquare />}
                {folder.name}
              </DropdownMenuItem>
            ),
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
