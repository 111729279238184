// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const who = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Who",
    metainfo: {
      _metainfoVersion: 51,
      id: "who@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Spot futures who",
      shortDescription: "[SF] Who",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "whoPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "whoColorer",
          type: StudyPlotType.Colorer,
          target: "whoPlot",
          palette: "whoPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      filledAreas: [],
      palettes: {
        whoPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Futures" },
            1: { name: "Spot" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Futures" },
            1: { name: "Spot" },
          },
        },
      },
      defaults: {
        palettes: {
          whoPalette: {
            colors: {
              0: { color: "rgba(13, 157, 219, 0.5)", width: 3, style: 0 },
              1: { color: "rgba(255, 160, 40, 0.5)", width: 3, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(13, 157, 219, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(255, 160, 40, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          whoPlot: {
            visible: true,
            linewidth: 3,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
          whoColorer: {
            visible: true,
            linewidth: 3,
            plottype: LineStudyPlotStyle.Circles,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
        precision: 2,
        inputs: {},
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        whoPlot: {
          title: "Who",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#MATRIXSPREAD`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Market data parsing
        this._context.select_sym(1);
        const whoSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const whoArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTime: any = this._context.new_var(
          this._context.symbol.time,
        );
        const who: any = whoArray.adopt(whoSymbolTime, mainSymbolTime, 1);

        //Calculations
        let value: number = NaN;

        switch (who) {
          case 1:
            value = 1;
            break;
          case 0:
            value = -1;
            break;
          default:
            value = NaN;
        }

        //Return
        return [0, value, who, who];
      };
    },
  };
};
