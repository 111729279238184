import { useDashboard } from "@/lib/store.ts";
import { useMemo } from "react";
import { colors } from "@/domain/constants.ts";

export function useFavoriteFolders() {
  const {
    folders,
    addFavoriteFolder,
    addSymbolsToFavorites,
    removeSymbolFromFavorites,
  } = useDashboard((state) => ({
    folders: state.favorites,
    addFavoriteFolder: state.addFavoriteFolder,
    addSymbolsToFavorites: state.addSymbolsToFavorites,
    removeSymbolFromFavorites: state.removeSymbolFromFavorites,
  }));

  const pickedColors = useMemo(() => folders.map((f) => f.color), [folders]);

  const availableColors = useMemo(
    () => colors.filter((c) => !pickedColors.includes(c)),
    [pickedColors],
  );

  const canAddMoreFolders = useMemo(
    () => availableColors.length > 0,
    [availableColors],
  );

  return {
    folders,
    addFavoriteFolder,
    addSymbolsToFavorites,
    removeSymbolFromFavorites,
    availableColors,
    canAddMoreFolders,
  };
}
