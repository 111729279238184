import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { merge } from "lodash";
import { useCallback } from "react";

export interface IFilterStreamResponse {
  [symbol: string]: number;
}

export function useFilterStream(timeframe: string, type: string) {
  const handler = useCallback(
    (oldData: Record<string, number>, newData: IFilterStreamResponse) =>
      merge(oldData, newData),
    [],
  );
  return useGenericSocket<Record<string, number>, IFilterStreamResponse>(
    `sf.core.filter.${timeframe}.all.${type}`,
    handler,
  );
}
