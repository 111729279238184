//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const manipulationMonitorHighlighter = (
  PineJS: PineJS,
): CustomIndicator => {
  return {
    name: "Manipulation monitor highlighter",
    metainfo: {
      _metainfoVersion: 51,
      id: "manipulation-monitor-highlighter@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFM] Manipulation monitor highlighter",
      shortDescription: "[SF] MMH",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 0,
      },
      plots: [
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          colors: [
            { name: "low activity" },
            { name: "medium activity" },
            { name: "high activity" },
            { name: "culmination activity" },
          ],
          valToIndex: {
            100: 0,
            200: 1,
            300: 2,
            400: 3,
          },
        },
      },
      filledAreas: [],
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: [
              { color: "rgba(0, 165, 207, 0.2)" },
              { color: "rgba(18, 213, 18, 0.2)" },
              { color: "rgba(245, 187, 0, 0.2)" },
              { color: "rgba(186, 45, 11, 0.2)" },
            ],
          },
        },
        filledAreasStyle: {},
        styles: {},
        precision: 2,
        inputs: {
          sourceInput: "Price activity",
          minvalInput: 0,
          filterInput: false,
        },
      },
      styles: {
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "sourceInput",
          name: "Source",
          defval: "Price activity",
          options: ["Price activity", "Volume activity"],
          type: StudyInputType.Text,
        },
        {
          id: "minvalInput",
          name: "Minimal value",
          defval: 0,
          min: 0,
          max: 100,
          type: StudyInputType.Float,
        },
        {
          id: "filterInput",
          name: "Filter",
          defval: false,
          type: StudyInputType.Bool,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const manipulationMonitorSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_MANIPULATION_MONITOR`;
        this._context.new_sym(
          manipulationMonitorSymbol,
          PineJS.Std.period(this._context),
        );
        const filterSymbol: string = `DEFI#SF_MANIPULATION_MONITOR`;
        this._context.new_sym(filterSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const sourceInput: string = this._input(0);
        const minvalInput: number = this._input(1);
        const filter: boolean = this._input(2);

        //Market data parsing
        this._context.select_sym(1);
        const marketDataTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const volumeActivitySeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        const priceActivitySeries: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let volumeActivity: number = volumeActivitySeries.adopt(
          marketDataTimeSeries,
          mainSymbolTimeSeries,
          0,
        );
        let priceActivity: number = priceActivitySeries.adopt(
          marketDataTimeSeries,
          mainSymbolTimeSeries,
          0,
        );

        //Filter noize
        if (filter === true) {
          this._context.select_sym(2);
          const filterTimeSeries: any = this._context.new_var(
            this._context.symbol.time,
          );
          const filterPriceSeries: any = this._context.new_var(
            PineJS.Std.open(this._context),
          );
          const filterVolumeSeries: any = this._context.new_var(
            PineJS.Std.close(this._context),
          );
          this._context.select_sym(0);
          const filterPriceResult: number = filterPriceSeries.adopt(
            filterTimeSeries,
            mainSymbolTimeSeries,
            0,
          );
          const filterVolumeResult: number = filterVolumeSeries.adopt(
            filterTimeSeries,
            mainSymbolTimeSeries,
            0,
          );
          priceActivity = filterPriceResult > 0.1 ? 0 : priceActivity;
          volumeActivity = filterVolumeResult > 1 ? 0 : volumeActivity;
        }

        //Calculations
        const source: number =
          sourceInput === "Price activity" ? priceActivity : volumeActivity;
        let color: number = NaN;
        if (source > minvalInput) {
          switch (true) {
            case source < 0.5:
              color = NaN;
              break;
            case source > 0.5 && source <= 5:
              color = 100;
              break;
            case source > 5 && source <= 50:
              color = 200;
              break;
            case source > 50 && source <= 95:
              color = 300;
              break;
            case source > 95:
              color = 400;
              break;
          }
        }

        //Return
        return [color];
      };
    },
  };
};
