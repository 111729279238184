import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";
import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { ICryptoDrift, useCryptoDrift } from "@/hooks/useCryptoDrift.ts";
import { useFilterStream } from "@/hooks/useFilterStream.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import {
  IMarketRatioScreenerData,
  useMarketRatioScreener,
} from "@/hooks/useMarketRatioScreener";
import { MarketRatio } from "../shared/marketRatio";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<IMarketRatioScreenerData>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),
  columnHelper.accessor("dayValue", {
    header: "Day",
    cell: (info) => {
      const data = info.row.original.day;
      return <MarketRatio data={data} height={17} width={60} />;
    },
    size: 70,
    minSize: 30,
  }),
  columnHelper.accessor("weekValue", {
    header: "Week",
    cell: (info) => {
      const data = info.row.original.week;
      return <MarketRatio data={data} height={17} width={60} />;
    },
    size: 70,
    minSize: 30,
  }),
  columnHelper.accessor("monthValue", {
    header: "Month",
    cell: (info) => {
      const data = info.row.original.month;

      return <MarketRatio data={data} height={17} width={60} />;
    },
    size: 70,
    minSize: 30,
  }),
];

export function MarketRatioScreenerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { globalFilters, blacklist } = useDashboard();
  const { data: marketRatioScreenerData, isLoading } = useMarketRatioScreener();
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");

  const preparedData = useMemo(() => {
    if (!marketRatioScreenerData?.data) {
      return [];
    }

    if (
      !adtv15m ||
      !adtv30m ||
      !adtv1h ||
      !adtv4h ||
      !adtv1d ||
      !adtv1w ||
      !adtv1M ||
      !volume15m ||
      !volume30m ||
      !volume1h ||
      !volume4h ||
      !volume1d ||
      !volume1w ||
      !volume1M
    ) {
      return [];
    }

    let preparedData = marketRatioScreenerData.data.map((item) => ({
      ...item,
      adtv15m: adtv15m[item.symbol],
      adtv30m: adtv30m[item.symbol],
      adtv1h: adtv1h[item.symbol],
      adtv4h: adtv4h[item.symbol],
      adtv1d: adtv1d[item.symbol],
      adtv1w: adtv1w[item.symbol],
      adtv1M: adtv1M[item.symbol],
      volume15m: volume15m[item.symbol],
      volume30m: volume30m[item.symbol],
      volume1h: volume1h[item.symbol],
      volume4h: volume4h[item.symbol],
      volume1d: volume1d[item.symbol],
      volume1w: volume1w[item.symbol],
      volume1M: volume1M[item.symbol],
      symbol: item.symbol,
      monthValue: item.data["1M"]?.value,
      monthMin: item.data["1M"]?.min,
      monthMax: item.data["1M"]?.max,
      dayValue: item.data["1d"]?.value,
      dayMin: item.data["1d"]?.min,
      dayMax: item.data["1d"]?.max,
      weekValue: item.data["1w"]?.value,
      weekMin: item.data["1w"]?.min,
      weekMax: item.data["1w"]?.max,
      month: {
        value: item.data["1M"]?.value,
        min: item.data["1M"]?.min,
        max: item.data["1M"]?.max,
      },
      day: {
        value: item.data["1d"]?.value,
        min: item.data["1d"]?.min,
        max: item.data["1d"]?.max,
      },
      week: {
        value: item.data["1w"]?.value,
        min: item.data["1w"]?.min,
        max: item.data["1w"]?.max,
      },
    }));

    const globalFilterValues = globalFilters.reduce(
      (acc, filter) => {
        acc[filter.filterByField] = filter.value;
        return acc;
      },
      {} as Record<string, string>,
    );

    preparedData = applyFilters(
      preparedData,
      globalFilters,
      globalFilterValues,
    );

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        preparedData = preparedData.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters(preparedData, config.filters, config.filterValues);
    }

    return preparedData;
  }, [config, marketRatioScreenerData?.data, blacklist]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
