// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  FilledAreaType,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";
import {
  CustomIndicator,
  RawStudyMetaInfoId,
  PineJS,
  LibraryPineStudy,
  IPineStudyResult
} from "@tradingView/charting_library";

export const volumeChange = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Volume change",
    metainfo: {
      _metainfoVersion: 51,
      id: "volume-change@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Volume change",
      shortDescription: "[SF] Volume change",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "percent",
        precision: 0,
      },
      plots: [
        {
          id: "zerolinePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "metricColorer",
          type: StudyPlotType.Colorer,
          target: "metricPlot",
          palette: "metricPalette",
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        metricPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Negative" },
            1: { name: "Positive" },
          },
        },
      },
      defaults: {
        palettes: {
          metricPalette: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          zerolinePlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: true,
            color: "#FF5B5B",
            transparency: 0,
          },
        },
        inputs: {
          lengthInput: 60
        },
      },
      styles: {
        zerolinePlot: {
          title: "Zeroline",
          histogramBase: 0,
        },
        metricPlot: {
          title: "Volume change prc %",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "lengthInput",
          name: "Length",
          defval: 60,
          type: StudyInputType.Integer,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User input
        const lengthInput: number = this._input(0);

        //Data parsing
        const volumeSeries: any = this._context.new_var(
          PineJS.Std.volume(this._context)
        );
        const volumeSum: number = PineJS.Std.sum(volumeSeries, lengthInput, this._context);
        const volumeSumSeries: any = this._context.new_var(volumeSum);
        const volumeChange: number = volumeSumSeries.get(0) / volumeSumSeries.get(lengthInput-1) * 100 - 100;

        let color;
        switch (true) {
          case volumeChange < 0:
            color = 0;
            break;
          case volumeChange > 0:
            color = 1;
            break;
          default:
            color = NaN;
        }

        //Return
        return [0, volumeChange, color, color];
      };
    },
  };
};
