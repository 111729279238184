import { bybitKlineApiV2Domain, klineApiV2Domain } from "@/lib/constants.ts";
import {
  IBinanceKlineArray,
  IBybitKlineArray,
  IBybitKlineResponse,
} from "@/lib/datafeed/binanceTypes.ts";
import { BINANCE_RESOLUTION_MAP, EXCHANGES } from "@/lib/datafeed/config.ts";
import { findLegsName, prepareFormula } from "@/lib/datafeed/helpers.ts";
import {
  fetchActivityDetector,
  fetchAltTimersBars,
  fetchBidAsk,
  fetchBidAskSpread,
  fetchCryptoDrift,
  fetchDogBars,
  fetchFundingRate,
  fetchHFTActivity,
  fetchHighestSizeOrder,
  fetchIdeasBars,
  fetchLayering,
  fetchLiquidationsBars,
  fetchManipulationBars,
  fetchMarketPowerBars,
  fetchMatrixSpread,
  fetchNetOiDelta,
  fetchOiNetSpike,
  fetchOiSpike,
  fetchOpenInterestBars,
  fetchOrderBookChanges,
  fetchPowerDelta,
  fetchPowerTradesBars,
  fetchPumpTrendBars,
  fetchRealizedBidAskSpread,
  fetchRetailPowerBars,
  fetchSantimentBars,
  fetchSpoofLayer,
  fetchSpotFutureSpread,
  fetchSpreadBars,
  fetchStopKillerBars,
  fetchStrategyBars,
  fetchTickIndexBars,
  fetchTrendAssessment,
  fetchVolumeBars,
  recursiveApiCall,
} from "@/lib/datafeed/utils.ts";
import { Bar, IDatafeedChartApi } from "@tradingView/charting_library";
import Formula from "fparser";

export const getBars: IDatafeedChartApi["getBars"] = async (
  symbolInfo,
  resolution,
  periodParams,
  onHistoryCallback,
  onErrorCallback,
) => {
  const { from, to, countBack } = periodParams;
  // console.log('getBars', symbolInfo, resolution, periodParams)
  try {
    let bars: Bar[] = [];
    switch (symbolInfo.exchange) {
      case EXCHANGES.BYBIT:
        bars = (await bybitKlines(symbolInfo.name, from, to, resolution)).map(
          (kline) => {
            return {
              time: kline.time,
              open: kline.open,
              high: kline.high,
              low: kline.low,
              close: kline.close,
              volume: kline.volume,
            } as Bar;
          },
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.BINANCE:
        bars = (
          await binanceKlines(
            symbolInfo.name,
            from,
            to,
            resolution,
            1500,
            countBack,
          )
        ).map(
          (kline) =>
            ({
              time: kline[0],
              open: parseFloat(kline[1]),
              high: parseFloat(kline[2]),
              low: parseFloat(kline[3]),
              close: parseFloat(kline[4]),
              volume: parseFloat(kline[5]),
            }) as Bar,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.HFT_ACTIVITY:
        bars = await fetchHFTActivity(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.OINETLONGSPIKE:
        bars = await fetchOiNetSpike(
          symbolInfo.name,
          periodParams,
          resolution,
          "LONG",
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.TREND_ASSESSMENT:
        bars = await fetchTrendAssessment(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.ALT_TIMERS:
        bars = await fetchAltTimersBars(
          symbolInfo.name,
          periodParams,
          // resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.DOG:
        bars = await fetchDogBars(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.TICK_INDEX:
        bars = await fetchTickIndexBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.LAYERING:
        bars = await fetchLayering(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.OINETSHORTSPIKE:
        bars = await fetchOiNetSpike(
          symbolInfo.name,
          periodParams,
          resolution,
          "SHORT",
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.OISPIKE:
        bars = await fetchOiSpike(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;

      case EXCHANGES.OINETDELTA:
        bars = await fetchNetOiDelta(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.RETAIL_POWER:
        bars = await fetchRetailPowerBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.POWER_DELTA:
        bars = await fetchPowerDelta(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.MARKET_POWER:
        bars = await fetchMarketPowerBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.IDEAS:
        bars = await fetchIdeasBars(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.PUMP_TREND:
        bars = await fetchPumpTrendBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.POWER_TRADES:
        bars = await fetchPowerTradesBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.SANTIMENT:
        bars = await fetchSantimentBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.FUNDING_RATE:
        bars = await fetchFundingRate(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.STRATEGY:
        bars = await fetchStrategyBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.SPREAD:
        bars = await fetchSpreadBars(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.MANIPULATIONS:
        if (symbolInfo.name == "BTCUSD") {
          onHistoryCallback([], { noData: true });
        }
        bars = await fetchManipulationBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.STOP_KILLER:
        bars = await fetchStopKillerBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.OPEN_INTEREST:
        bars = await fetchOpenInterestBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.LIQUIDATIONS:
        bars = await fetchLiquidationsBars(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.BIDASKQUOTEDSPREAD:
        bars = await fetchBidAskSpread(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.HIGHESTSIZEORDER:
        bars = await fetchHighestSizeOrder(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.SPOOF_LAYER_UP:
        bars = await fetchSpoofLayer(
          symbolInfo.name,
          periodParams,
          resolution,
          "UP",
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.SPOOF_LAYER_DOWN:
        bars = await fetchSpoofLayer(
          symbolInfo.name,
          periodParams,
          resolution,
          "DOWN",
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.ORDERBOOKCHANGES:
        bars = await fetchOrderBookChanges(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.REALIZEDBIDASKSPREAD:
        bars = await fetchRealizedBidAskSpread(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.MATRIXSPREAD:
        bars = await fetchMatrixSpread(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.SPOTFUTURESPREAD:
        bars = await fetchSpotFutureSpread(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.VOLUME:
        bars = await fetchVolumeBars(symbolInfo.name, periodParams, resolution);
        onHistoryCallback(bars, { noData: false });
        return;

      case EXCHANGES.ASKORDERBOOKSUM1_5:
        bars = await fetchBidAsk(
          symbolInfo.name,
          periodParams,
          resolution,
          EXCHANGES.ASKORDERBOOKSUM1_5,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.ASKORDERBOOKSUM6_10:
        bars = await fetchBidAsk(
          symbolInfo.name,
          periodParams,
          resolution,
          EXCHANGES.ASKORDERBOOKSUM6_10,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.BIDORDERBOOKSUM1_5:
        bars = await fetchBidAsk(
          symbolInfo.name,
          periodParams,
          resolution,
          EXCHANGES.BIDORDERBOOKSUM1_5,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.ACTIVITY_DETECTOR:
        bars = await fetchActivityDetector(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.CRYPTO_DRIFT:
        bars = await fetchCryptoDrift(
          symbolInfo.name,
          periodParams,
          resolution,
        );
        onHistoryCallback(bars, { noData: false });
        return;
      case EXCHANGES.BIDORDERBOOKSUM6_10:
        bars = await fetchBidAsk(
          symbolInfo.name,
          periodParams,
          resolution,
          EXCHANGES.BIDORDERBOOKSUM6_10,
        );
        onHistoryCallback(bars, { noData: false });
        return;
    }

    onHistoryCallback(bars, { noData: false });
  } catch (error) {
    console.error(error);
    onErrorCallback("Error getting bars");
  }
};

export const lastBarsCache = new Map<string, Bar>();

export interface IParam {
  [s: string]: number;
}

async function bybitKlines(
  symbol: string,
  startTime: number,
  endTime: number,
  resolution: string,
  limit = 1000,
): Promise<Bar[]> {
  const urlWithParams = `https://${bybitKlineApiV2Domain}/v5/market/kline?category=linear&symbol=${symbol}&interval=${resolution}&start=${startTime * 1000}&end=${endTime * 1000}&limit=${limit}`;
  const data = await fetch(urlWithParams).then<IBybitKlineResponse>(
    (res: Response) => (res.ok ? res.json() : Promise.resolve()),
  );

  if (data && data.result.list.length) {
    return data.result.list.map((d: IBybitKlineArray) => {
      return {
        time: Number(d[0]),
        open: Number(d[1]),
        high: Number(d[2]),
        low: Number(d[3]),
        close: Number(d[4]),
        volume: Number(d[5]),
      };
    });
  } else {
    return [];
  }
}

async function binanceKlines(
  symbol: string,
  startTime: number,
  endTime: number,
  resolution: string,
  limit = 1500,
  count: number,
): Promise<IBinanceKlineArray[]> {
  const legs = findLegsName(symbol, true);
  const results = new Map<string, IBinanceKlineArray[]>();
  const interval = BINANCE_RESOLUTION_MAP[resolution];
  const promises = legs.map(async (leg) => {
    return recursiveApiCall<IBinanceKlineArray>(
      `https://${klineApiV2Domain}/fapi/v1/klines`,
      leg,
      interval,
      startTime * 1000,
      endTime * 1000,
      limit,
      count,
    )
      .then((kline: IBinanceKlineArray[]) => {
        results.set(leg, kline);
      })
      .catch((err) => console.error(err));
  });

  await Promise.all(promises);

  const calculator = new Formula(prepareFormula(symbol));

  const kData = [] as IBinanceKlineArray[];

  let minLength = Infinity;
  results.forEach((c) => {
    minLength = Math.min(c.length, minLength);
  });
  results.forEach((value, key) => {
    value = value.slice(-minLength);
    results.set(key, value);
  });

  const entry = results.get(legs[0]);

  if (minLength > 0 && entry) {
    for (let i = 0; i < entry.length; i++) {
      const paramsOpen: IParam = {};
      const paramsHigh: IParam = {};
      const paramsLow: IParam = {};
      const paramsClose: IParam = {};
      let volume = 0;
      for (const symbol of legs) {
        const kl = results.get(symbol);
        if (kl) {
          paramsOpen[symbol] = Number(kl[i][1]);
          paramsHigh[symbol] = Number(kl[i][2]);
          paramsLow[symbol] = Number(kl[i][3]);
          paramsClose[symbol] = Number(kl[i][4]);
          if (legs.length == 1) {
            volume += Number(kl[i][5]);
          } else {
            volume += (Number(kl[i][5]) + Number(kl[i][7])) / 2;
          }
        }
      }
      const time = entry[i][0];
      const open = calculator.evaluate(paramsOpen) as number;
      const high = calculator.evaluate(paramsHigh) as number;
      const low = calculator.evaluate(paramsLow) as number;
      const close = calculator.evaluate(paramsClose) as number;

      kData.push([
        time,
        String(open),
        String(open >= close ? Math.max(high, open) : Math.max(high, close)),
        String(open >= close ? Math.min(low, close) : Math.min(low, open)),
        String(close),
        String(volume),
      ]);
    }
  }

  return kData;
}
