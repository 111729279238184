import { IStopKiller } from "@/domain/interfaces/general.interface.ts";
import { useGenericSocket } from "@/hooks/useGenericSocket.ts";
import { environment } from "@/lib/constants.ts";
import { SocketTopics } from "@/lib/natsConnection.ts";
import axios from "axios";
import { useCallback } from "react";

export function useStopKiller() {
  const fetchInitialData = async () => {
    try {
      const response = await axios.get<IStopKiller[]>(
        environment.stopKillerRawApiURL,
        { params: { limit: 70 } },
      );

      return response.data.sort((a, b) => b.timestamp - a.timestamp);
    } catch (error) {
      console.error("Error fetching initial data:", error);
      return [];
    }
  };

  const handler = useCallback(
    (oldData: IStopKiller[] = [], newData: IStopKiller) => [
      newData,
      ...oldData,
    ],
    [],
  );

  return useGenericSocket<IStopKiller[], IStopKiller>(
    SocketTopics.stopKillers,
    handler,
    { fetchInitialData, apiUrl: environment.stopKillerRawApiURL },
  );
}
