import { useDashboard } from "@/lib/store.ts";
import { Button } from "@/components/ui/button.tsx";
import React from "react";
import { FaRegTrashCan } from "react-icons/fa6";

export function RemoveAllFavorites() {
  const { updateFavorites } = useDashboard((state) => ({
    updateFavorites: state.updateFavorites,
  }));
  const handleRemoveAll = () => {
    updateFavorites((state) => {
      state.splice(0, state.length);
    });
  };
  return (
    <Button
      size="xs"
      variant="destructive"
      onClick={handleRemoveAll}
      className="flex gap-2"
    >
      <FaRegTrashCan /> Remove All
    </Button>
  );
}
