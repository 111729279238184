import { useDashboard } from "@/lib/store.ts";
import React, { useCallback } from "react";
import { Button } from "@/components/ui/button.tsx";

export function RemoveFolder({ folderId }: { folderId: string }) {
  const { removeFolder } = useDashboard((state) => ({
    removeFolder: state.removeFavoriteFolder,
  }));
  const handleRemoveFolder = useCallback(() => {
    removeFolder(folderId);
  }, [removeFolder, folderId]);
  return (
    <Button
      size="xs"
      variant="ghost"
      onClick={handleRemoveFolder}
      className="w-4 h-4"
    >
      <i className="fa-regular fa-trash" />
    </Button>
  );
}
