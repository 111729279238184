import { useGenericSocket } from "@/hooks/useGenericSocket";
import { SocketTopics } from "@/lib/natsConnection";
import { useCallback } from "react";

export interface ISpeedRushResponse {
  data: ISpeedRush[];
  timestamp: number;
}

export interface ISpeedRush {
  symbol: string;
  adtv: number;
  adts: number;
}

export function useSpeedRush() {
  const handler = useCallback(
    (_: ISpeedRushResponse, newData: ISpeedRushResponse) => {
      return newData;
    },
    [],
  );

  return useGenericSocket<ISpeedRushResponse>(
    SocketTopics.speedRushAll,
    handler,
  );
}
