import { useBinanceSymbols } from "@/hooks/useBinanceSymbols.ts";
import { useDashboard } from "@/lib/store.ts";
import React, { useMemo } from "react";
import { AltSelect } from "@/components/shared/altSelect.tsx";
import { Button } from "@/components/ui/button.tsx";

export function AddFavoriteAlt({ folderId }: { folderId: string }) {
  const { data: symbols } = useBinanceSymbols();

  const { addFavorite, folder } = useDashboard((state) => ({
    addFavorite: state.addSymbolsToFavorites,
    folder: state.favorites.find((f) => f.id === folderId),
  }));

  const handleAddFavorite = (e: string) => {
    if (e) {
      addFavorite(folderId, [e]);
    }
  };

  const options = useMemo(
    () =>
      symbols
        ?.filter((e) => {
          return !folder?.symbols?.includes(e.symbol);
        })
        .sort((a, b) => a.symbol.localeCompare(b.symbol))
        .map((e) => {
          return { label: e.symbol, value: e.symbol };
        }),
    [folder?.symbols, symbols],
  );
  return (
    <AltSelect
      onChange={handleAddFavorite}
      options={options ?? []}
      trigger={
        <Button size="xs" variant="ghost" className="w-4 h-4">
          <i className="fa-regular fa-file-plus" />
        </Button>
      }
      emptyText="This folder is full"
      searchText="Search symbol..."
    />
  );
}
