import { ColorPicker } from "@/components/core/grid/colorPicker.tsx";
import { FilterByFavorites } from "@/components/core/grid/filterByFavorites.tsx";
import { TabSetPlaceholder } from "@/components/core/grid/tabSetPlaceholder.tsx";
import { TabSettingsDropdown } from "@/components/core/grid/tabSettingsDropdown.tsx";
import { Pane } from "@/components/pane/pane.tsx";
import { Button } from "@/components/ui/button.tsx";
import { MAX_CHARTS } from "@/domain/constants.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { useDashboard } from "@/lib/store.ts";
import { IJsonModel, Layout, Model, TabNode } from "flexlayout-react";
import "flexlayout-react/style/dark.css";
import { IGlobalAttributes } from "flexlayout-react/src/model/IJsonModel.ts";
import { ITabRenderValues } from "flexlayout-react/src/view/Layout.tsx";
import { debounce, isEqual, isNil } from "lodash";
import {
  memo,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { EWidgetType } from "@/domain/enums/dashboard.enum.ts";
import { FaMinus, FaPause, FaPlay, FaPlus } from "react-icons/fa6";
import { FavoriteStarInTabHeader } from "@/components/core/grid/favoriteStarInTabHeader.tsx";

const globalAttributes: IGlobalAttributes = {
  splitterSize: 8,
  tabSetEnableClose: true,
  tabEnableClose: true,
  tabEnableRename: false,
  tabSetEnableMaximize: true,
  tabSetMinWidth: 200,
};

enum EIncreaseDecreaseCharts {
  increase,
  decrease,
}
const IncreaseDecreaseCharts = memo(function IncreaseDecreaseCharts({
  node,
}: {
  node: TabNode;
}) {
  const { updateTabConfig, getNodeConfig } = useGrid(node.getId());
  const config = getNodeConfig();
  if (isNil(config.numberOfCharts)) {
    return null;
  }
  const handleIncreaseDecreaseCharts = (
    e: MouseEvent,
    type: EIncreaseDecreaseCharts,
  ) => {
    e.stopPropagation();
    e.preventDefault();

    if (type === EIncreaseDecreaseCharts.increase) {
      updateTabConfig({
        numberOfCharts: Math.min(config.numberOfCharts + 1, MAX_CHARTS),
      });
    } else {
      updateTabConfig({
        numberOfCharts: Math.max(config.numberOfCharts - 1, 0),
      });
    }
  };

  return (
    <div className="flex items-center justify-center">
      <Button
        size="xxs"
        variant="ghost"
        disabled={getNodeConfig()?.numberOfCharts === 0}
        onMouseDown={(e) =>
          handleIncreaseDecreaseCharts(e, EIncreaseDecreaseCharts.decrease)
        }
      >
        <FaMinus />
      </Button>
      <p className="text-xxs">{getNodeConfig()?.numberOfCharts}</p>
      <Button
        size="xxs"
        variant="ghost"
        onMouseDown={(e) =>
          handleIncreaseDecreaseCharts(e, EIncreaseDecreaseCharts.increase)
        }
      >
        <FaPlus />
      </Button>
    </div>
  );
});

const PauseButton = memo(function PauseButton({ node }: { node: TabNode }) {
  const { updateTabConfig, getNodeConfig } = useGrid(node.getId());
  const isPaused = getNodeConfig()?.paused;
  if (node.getComponent() === EWidgetType.chart) {
    return null;
  }
  const togglePause = () => {
    updateTabConfig({ paused: !isPaused });
  };
  return (
    <Button size="xxs" variant="ghost" onMouseDown={togglePause}>
      {isPaused && <FaPlay className={"text-red-700"} />}
      {!isPaused && <FaPause />}
    </Button>
  );
});

const WidgetTitle = memo(function WidgetTitle({ node }: { node: TabNode }) {
  const { getNodeConfig } = useGrid(node.getId());
  const config = getNodeConfig();
  const color = config.color;
  const widgetType = node.getComponent() as EWidgetType;

  const { symbol } = useDashboard((state) => ({
    symbol: color ? state.colorsToSymbolMap[color] : null,
  }));

  const widgetTitle =
    widgetType === EWidgetType.chart ? symbol : node.getName();

  return <div>{widgetTitle}</div>;
});

function onRenderTab(node: TabNode, renderValues: ITabRenderValues) {
  renderValues.leading = (
    <div className="flex text-white">
      <TabSettingsDropdown node={node} />
      <PauseButton node={node} />
      <IncreaseDecreaseCharts node={node} />
      <FavoriteStarInTabHeader node={node} />
    </div>
  );
  renderValues.content = <WidgetTitle node={node} />;
  renderValues.buttons.push(
    <FilterByFavorites node={node} key={"filterByFavorites"} />,
    <ColorPicker node={node} key={"color"} />,
  );
}

// function onRenderTabSet(
//   node: TabSetNode | BorderNode,
//   renderValues: ITabSetRenderValues,
// ) {
//   if (node instanceof TabSetNode) {
//     if (node.getChildren().length === 0) {
//       //
//     } else {
//       renderValues.buttons.push(
//         <Button
//           key="close"
//           size="xs"
//           variant="ghost"
//           onClick={() => {
//             node.getModel().doAction(Actions.deleteTabset(node.getId()));
//           }}
//         >
//           <i className="fa-regular fa-xmark" />
//         </Button>,
//       );
//     }
//   }
// }

function onTabSetPlaceHolder() {
  return <TabSetPlaceholder />;
}

// function syncGlobalFilters(jsonModel: IJsonModel) {
//   jsonModel.layout.children.map((tabset) => {
//     if (tabset.type === "tabset") {
//       tabset.children.map((tab) => {
//         tab.config.
//       });
//     }
//   });
// }

export function Grid() {
  const { activeLayout: layout, updateActiveLayout } = useDashboard(
    (state) => ({
      activeLayout: state.layouts.find((l) => l.id === state.activeLayoutId),
      activeLayoutId: state.activeLayoutId,
      updateActiveLayout: state.updateActiveLayout,
    }),
  );

  const model = useMemo(
    () =>
      layout
        ? Model.fromJson({
            global: globalAttributes,
            borders: [],
            layout: layout.json,
          })
        : null,
    [layout],
  );

  const layoutRef = useRef<Layout | null>(null);

  const { setGridRef, setModelRef } = useGrid();

  useEffect(() => {
    if (layoutRef.current) {
      setGridRef(layoutRef.current);
    }
    if (model) {
      setModelRef(model);
    }
  }, [model, setGridRef, setModelRef]);

  const factory = useCallback((node: TabNode) => {
    return <Pane nodeId={node.getId()} />;
  }, []);

  const onModelChange = useMemo(
    () =>
      debounce((model: Model) => {
        const jsonModel = model.toJson();

        // syncGlobalFilters(jsonModel);

        jsonModel.layout.children = jsonModel.layout.children.map((tabset) => {
          if (tabset.type === "tabset") {
            if (
              tabset.children.some(
                (tab) =>
                  tab.component === EWidgetType.chart ||
                  tab.component === EWidgetType.controlPanel,
              )
            ) {
              tabset.enableDrop = true;
            } else {
              tabset.enableDrop = false;
            }
          }
          return tabset;
        });

        updateActiveLayout((state) => {
          if (!isEqual(state.json, jsonModel.layout)) {
            state.json = jsonModel.layout;
          }
        });
      }, 100),
    [updateActiveLayout],
  );

  const classNameMapper = useCallback((defaultClassName: string) => {
    if (defaultClassName === "flexlayout__tabset_tabbar_outer") {
      return "flexlayout__tabset_tabbar_outer !bg-none";
    }
    if (defaultClassName === "flexlayout__splitter") {
      return "flexlayout__splitter !bg-stone-950 !hover:bg-stone-600/40 rounded-sm";
    }
    if (defaultClassName === "flexlayout__splitter_drag") {
      return "!bg-stone-950 !hover:bg-stone-600/40 rounded-sm";
    }
    if (defaultClassName === "flexlayout__tab") {
      return "flexlayout__tab overflow-x-hidden";
    }
    return defaultClassName;
  }, []);

  return (
    <div className="flex flex-col flex-grow h-full">
      <div className="relative flex-grow">
        {layout && model ? (
          <Layout
            model={model}
            factory={factory}
            ref={layoutRef}
            onModelChange={onModelChange}
            // onRenderTabSet={onRenderTabSet}
            onRenderTab={onRenderTab}
            classNameMapper={classNameMapper}
            realtimeResize={true}
            onTabSetPlaceHolder={onTabSetPlaceHolder}
          />
        ) : (
          <div className="animate-pulse">Loading...</div>
        )}
      </div>
    </div>
  );
}
