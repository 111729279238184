import React, { useMemo } from "react";
import { createColumnHelper, SortingState, Table } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn, IRow } from "@/domain/interfaces/sfTable.interface.ts";

import { applyFilters, cn } from "@/lib/utils.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { ITechDesk, useTechnicalDesk } from "@/hooks/useTechnicalDesk.ts";
import { HighlightedValueChange } from "@/components/shared/highlightedValueChange.tsx";
import { ESFTableType } from "@/domain/enums/dashboard.enum.ts";
import { Loader } from "@/components/shared/loader.tsx";
import { ISpeedRush, useSpeedRush } from "@/hooks/useSpeedRush.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useFilterStream } from "@/hooks/useFilterStream.ts";
import { useDashboard } from "@/lib/store";

const columnHelper = createColumnHelper<ISpeedRush>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),

  columnHelper.accessor("adtv", {
    header: "ADTV",
    cell: (info) => {
      const value = info.getValue();
      return <div className="font-semibold">{value.toFixed(0)}</div>;
    },
    size: 50,
    minSize: 20,
  }),
  columnHelper.accessor("adts", {
    header: "ADTS",
    cell: (info) => {
      const value = info.getValue();
      return <div className="font-semibold">{value.toFixed(0)}</div>;
    },
    size: 50,
    minSize: 20,
  }),
];

const oldValues: IRow[] = [];
const highlightTimestamps: number[] = [];

function rowClassCb(
  row: ISpeedRush,
  idx: number,
  table: Table<IRow>,
  sortingState: SortingState,
) {
  if (idx > 4) {
    return "";
  }

  const meta = table.options.meta as { tableType: ESFTableType };
  const isHighlightable =
    meta?.tableType.includes(ESFTableType.unpinned) &&
    !meta?.tableType.includes(ESFTableType.chart);

  if (!isHighlightable) {
    return "";
  }

  const oldRow = oldValues[idx];
  const isPositionChanged = oldRow?.symbol !== row.symbol;
  const now = Date.now();
  const isSortedDesc = sortingState.some((sort) => sort.desc);
  const color = isSortedDesc ? "bg-green-600/20" : "bg-red-600/20";

  if (isPositionChanged) {
    oldValues[idx] = row;
    highlightTimestamps[idx] = now;
    return cn(color, "animate-pulse");
  } else if (
    highlightTimestamps[idx] &&
    now - highlightTimestamps[idx] < 1000
  ) {
    return cn(color, "animate-pulse");
  } else {
    return "";
  }
}

export function AdtvScreenerPaneBody() {
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const { blacklist, globalFilters } = useDashboard();
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");
  const { data: speedRush, isLoading } = useSpeedRush();

  const preparedData = useMemo(() => {
    if (
      !speedRush?.data ||
      !adtv15m ||
      !adtv30m ||
      !adtv1h ||
      !adtv4h ||
      !adtv1d ||
      !adtv1w ||
      !adtv1M ||
      !volume15m ||
      !volume30m ||
      !volume1h ||
      !volume4h ||
      !volume1d ||
      !volume1w ||
      !volume1M
    ) {
      return [];
    }
    let data = speedRush.data?.reduce(
      (acc, speed) => {
        const symbol = speed.symbol;
        acc.push({
          symbol: symbol,
          adtv: speed.adtv,
          adts: speed.adts,
          adtv15m: adtv15m[symbol],
          adtv30m: adtv30m[symbol],
          adtv1h: adtv1h[symbol],
          adtv4h: adtv4h[symbol],
          adtv1d: adtv1d[symbol],
          adtv1w: adtv1w[symbol],
          adtv1M: adtv1M[symbol],
          vol15m: volume15m[symbol],
          vol30m: volume30m[symbol],
          vol1h: volume1h[symbol],
          vol4h: volume4h[symbol],
          vol1d: volume1d[symbol],
          vol1w: volume1w[symbol],
          vol1M: volume1M[symbol],
        });
        return acc;
      },
      [] as Array<{
        symbol: string;
        adtv: number;
        adts: number;
        adtv15m: number;
        adtv30m: number;
        adtv1h: number;
        adtv4h: number;
        adtv1d: number;
        adtv1w: number;
        adtv1M: number;
        vol15m: number;
        vol30m: number;
        vol1h: number;
        vol4h: number;
        vol1d: number;
        vol1w: number;
        vol1M: number;
      }>,
    );

    const globalFilterValues = globalFilters.reduce(
      (acc, filter) => {
        acc[filter.filterByField] = filter.value;
        return acc;
      },
      {} as Record<string, string>,
    );

    data = applyFilters(data, globalFilters, globalFilterValues);

    if (blacklist.length > 0) {
      blacklist.forEach((symbol) => {
        data = data.filter((item) => item.symbol !== symbol);
      });
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<ISpeedRush>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [
    adtv15m,
    adtv1M,
    adtv1d,
    adtv1h,
    adtv1w,
    adtv30m,
    adtv4h,
    config,
    speedRush?.data,
    volume15m,
    volume1M,
    volume1d,
    volume1h,
    volume1w,
    volume30m,
    volume4h,
    blacklist,
    globalFilters,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
