import { cn } from "@/lib/utils.ts";

export function Logo({ className }: { className?: string }) {
  return (
    <img
      className={cn("m-2 w-10 h-10", className)}
      src="./logo/logo_white.svg"
      alt="Spreadfighter"
    />
  );
}
