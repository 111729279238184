import {
  IManipulation,
  useManipulationMonitor,
} from "@/hooks/useManipulationMonitor.ts";
import { createColumnHelper } from "@tanstack/react-table";
import { PaneBody } from "@/components/pane/paneBody.tsx";
import { IColumn } from "@/domain/interfaces/sfTable.interface.ts";
import { isNil } from "lodash";
import { Loader } from "@/components/shared/loader.tsx";
import React, { useMemo } from "react";
import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import { applyFilters } from "@/lib/utils.ts";
import { useFilterStream } from "@/hooks/useFilterStream.ts";
import { PaneSubHeader } from "@/components/pane/paneSubHeader.tsx";
import { useDashboard } from "@/lib/store";

type IUpdatedManipulation = IManipulation & { symbol: string };
const columnHelper = createColumnHelper<IUpdatedManipulation>();

const columns = [
  columnHelper.accessor("symbol", {
    header: "Symbol",
    enableSorting: false,
  }),
  columnHelper.accessor("corr", {
    header: "P",
    cell: (row) => {
      const value = row.getValue();
      const bgClass = getBgClass(value);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <div className={bgClass + " font-semibold"}>{value.toFixed(1)}%</div>
      );
    },
    size: 50,
    minSize: 20,
  }),
  columnHelper.accessor("corrV", {
    header: "V",
    cell: (row) => {
      const value = row.getValue();
      const bgClass = getBgClass(value);
      if (isNil(value)) {
        return <div>-</div>;
      }
      return (
        <div className={bgClass + " font-semibold"}>{value.toFixed(1)}%</div>
      );
    },
    size: 50,
    minSize: 20,
  }),
];

const getBgClass = (val: number) => {
  if (val > 0.99) {
    return "bg-[#f91a36]";
  }
  if (val > 0.75) {
    return "bg-[#b2182c]";
  }
  if (val > 0.5) {
    return "bg-[#600b17]";
  }
  if (val > 0.25) {
    return "bg-[#1a5824]";
  }
  if (val > 0.01) {
    return "bg-[#3ea54b]";
  }
};

export function ManipulationMonitorPaneBody() {
  const { data: _raw, isLoading } = useManipulationMonitor();
  const { data: adtv15m } = useFilterStream("15m", "adtv");
  const { data: adtv30m } = useFilterStream("30m", "adtv");
  const { data: adtv1h } = useFilterStream("1h", "adtv");
  const { data: adtv4h } = useFilterStream("4h", "adtv");
  const { data: adtv1d } = useFilterStream("1d", "adtv");
  const { data: adtv1w } = useFilterStream("1w", "adtv");
  const { data: adtv1M } = useFilterStream("1M", "adtv");
  const { data: volume15m } = useFilterStream("15m", "volume");
  const { data: volume30m } = useFilterStream("30m", "volume");
  const { data: volume1h } = useFilterStream("1h", "volume");
  const { data: volume4h } = useFilterStream("4h", "volume");
  const { data: volume1d } = useFilterStream("1d", "volume");
  const { data: volume1w } = useFilterStream("1w", "volume");
  const { data: volume1M } = useFilterStream("1M", "volume");
  const { blacklist } = useDashboard();
  const nodeId = usePaneContext();
  const { getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const preparedData = useMemo(() => {
    if (
      !_raw ||
      !adtv15m ||
      !adtv30m ||
      !adtv1h ||
      !adtv4h ||
      !adtv1d ||
      !adtv1w ||
      !adtv1M ||
      !volume15m ||
      !volume30m ||
      !volume1h ||
      !volume4h ||
      !volume1d ||
      !volume1w ||
      !volume1M
    ) {
      return [];
    }

    let data = Object.entries(_raw ?? {}).reduce(
      (acc, [symbol, { corr, corrV }]) => {
        if (corr < 0.99 || corrV < 0.99) {
          const corrPrc = Number(Number(-(corr - 1) * 50));
          const corrVPrc = Number(Number(-(corrV - 1) * 50));

          acc.push({
            symbol,
            corr: corrPrc,
            corrV: corrVPrc,
            adtv15m: adtv15m[symbol],
            adtv30m: adtv30m[symbol],
            adtv1h: adtv1h[symbol],
            adtv4h: adtv4h[symbol],
            adtv1d: adtv1d[symbol],
            adtv1w: adtv1w[symbol],
            adtv1M: adtv1M[symbol],
            vol15m: volume15m[symbol],
            vol30m: volume30m[symbol],
            vol1h: volume1h[symbol],
            vol4h: volume4h[symbol],
            vol1d: volume1d[symbol],
            vol1w: volume1w[symbol],
            vol1M: volume1M[symbol],
          });
        }
        return acc;
      },
      [] as Array<{
        symbol: string;
        corr: number;
        corrV: number;
        adtv15m: number;
        adtv30m: number;
        adtv1h: number;
        adtv4h: number;
        adtv1d: number;
        adtv1w: number;
        adtv1M: number;
        vol15m: number;
        vol30m: number;
        vol1h: number;
        vol4h: number;
        vol1d: number;
        vol1w: number;
        vol1M: number;
      }>,
    );

    if (blacklist.length > 0) {
      data = data.filter((item) => !blacklist.includes(item.symbol));
    }

    if (config && config.filters && config.filterValues) {
      return applyFilters<IUpdatedManipulation>(
        data,
        config.filters,
        config.filterValues,
      );
    }

    return data;
  }, [
    config,
    _raw,
    adtv15m,
    adtv1M,
    adtv1d,
    adtv1h,
    adtv1w,
    adtv30m,
    adtv4h,
    volume15m,
    volume1M,
    volume1d,
    volume1h,
    volume1w,
    volume30m,
    volume4h,
    blacklist,
  ]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <PaneSubHeader />
      <PaneBody
        className="!h-[calc(100%-24px)]"
        tableColumns={columns as IColumn[]}
        data={preparedData}
      />
    </>
  );
}
