// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";
import { StudyInputType, StudyPlotType } from "@/lib/datafeed/tvTypes.ts";
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

export const volumeDelta = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Volume delta",
    metainfo: {
      _metainfoVersion: 51,
      id: "volume-delta@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Volume delta",
      shortDescription: "[SF] Volume delta",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "zeroline",
          type: StudyPlotType.Line,
        },
        {
          id: "sellVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyVolumePlot",
          type: StudyPlotType.Line,
        },
        {
          id: "sellVolumeMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "buyVolumeMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "volumeDeltaStDevPlot",
          type: StudyPlotType.Line,
        },
      ],
      defaults: {
        styles: {
          zeroline: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            display: 3,
            color: "rgb(149, 152, 161)",
          },
          sellVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(178, 24, 44)",
          },
          buyVolumePlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgb(60, 166, 75)",
          },
          sellVolumeMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(249, 26, 54)",
          },
          buyVolumeMaPlot: {
            linestyle: 0,
            visible: true,
            plottype: LineStudyPlotStyle.LineWithBreaks,
            linewidth: 1,
            trackPrice: false,
            color: "rgb(82, 237, 106)",
          },
          volumeDeltaStDevPlot: {
            visible: true,
            plottype: LineStudyPlotStyle.Columns,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.25)",
          },
        },
        precision: 2,
        inputs: {
          displayMode: "Delta",
          measureInput: "USD",
          maTypeInput: "SMA",
          lengthInput: 20,
          metricsBoolInput: false,
        },
      },
      styles: {
        zeroline: {
          title: "Zeroline",
          histogramBase: 0,
        },
        buyVolumePlot: {
          title: "Buy taker volume",
          histogramBase: 0,
        },
        sellVolumePlot: {
          title: "Sell taker volume",
          histogramBase: 0,
        },
        buyVolumeMaPlot: {
          title: "Buy taker volume MA",
          histogramBase: 0,
        },
        sellVolumeMaPlot: {
          title: "Sell taker volume MA",
          histogramBase: 0,
        },
        volumeDeltaStDevPlot: {
          title: "Volume delta sigma",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "displayMode",
          name: "Display mode",
          defval: "Delta",
          options: ["Delta", "Default"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "USD",
          options: ["Coin", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: false,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const buyVolumeSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SF_VOLUME`;
        this._context.new_sym(
          buyVolumeSymbol,
          PineJS.Std.period(this._context),
        );
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const displayModeInput: string = this._input(0);
        const measureInput: string = this._input(1);
        const maTypeInput: string = this._input(2);
        const lengthInput: number = this._input(3);
        const metricsBoolInput: boolean = this._input(4);

        //Market data parsing
        const volume: number =
          measureInput === "Coin"
            ? PineJS.Std.volume(this._context)
            : PineJS.Std.volume(this._context) *
              PineJS.Std.close(this._context);
        this._context.select_sym(1);
        const buyVolumeSymbolTimeArray: any = this._context.new_var(
          this._context.symbol.time,
        );
        let buyVolumeSeriesArray;
        switch (measureInput) {
          case "Coin":
            buyVolumeSeriesArray = this._context.new_var(
              PineJS.Std.low(this._context),
            );
            break;
          case "USD":
            buyVolumeSeriesArray = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
        }
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let buyVolume: number = buyVolumeSeriesArray.adopt(
          buyVolumeSymbolTimeArray,
          mainSymbolTimeSeries,
          1,
        );

        //Calculations
        let sellVolume: number = buyVolume - volume;
        if (sellVolume === volume) {
          sellVolume = NaN;
        }
        const volumeDelta: number = buyVolume + sellVolume;

        let volumeDeltaStdev: number = NaN;
        let buyVolumeMa: number = NaN;
        let sellVolumeMa: number = NaN;
        if (metricsBoolInput) {
          const buyVolumeSeries: any = context.new_var(buyVolume);
          const sellVolumeSeries: any = this._context.new_var(sellVolume);
          const volumeDeltaSeries: any = this._context.new_var(volumeDelta);
          switch (maTypeInput) {
            case "SMA":
              buyVolumeMa = PineJS.Std.sma(
                buyVolumeSeries,
                lengthInput,
                this._context,
              );
              sellVolumeMa = PineJS.Std.sma(
                sellVolumeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              buyVolumeMa = PineJS.Std.ema(
                buyVolumeSeries,
                lengthInput,
                this._context,
              );
              sellVolumeMa = PineJS.Std.ema(
                sellVolumeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              buyVolumeMa = PineJS.Std.wma(
                buyVolumeSeries,
                lengthInput,
                this._context,
              );
              sellVolumeMa = PineJS.Std.wma(
                sellVolumeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              buyVolumeMa = PineJS.Std.vwma(
                buyVolumeSeries,
                lengthInput,
                this._context,
              );
              sellVolumeMa = PineJS.Std.vwma(
                sellVolumeSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              buyVolumeMa = PineJS.Std.linreg(buyVolumeSeries, lengthInput, 0);
              sellVolumeMa = PineJS.Std.linreg(
                sellVolumeSeries,
                lengthInput,
                0,
              );
              break;
            default:
              buyVolumeMa = PineJS.Std.sma(
                buyVolumeSeries,
                lengthInput,
                this._context,
              );
              sellVolumeMa = PineJS.Std.sma(
                sellVolumeSeries,
                lengthInput,
                this._context,
              );
          }
          volumeDeltaStdev = PineJS.Std.stdev(
            volumeDeltaSeries,
            lengthInput,
            this._context,
          );
        }

        if (displayModeInput == "Delta") {
          buyVolume = volumeDelta > 0 ? volumeDelta : 0;
          sellVolume = volumeDelta < 0 ? volumeDelta : 0;
        }

        //Returns
        return [
          0,
          sellVolume,
          buyVolume,
          sellVolumeMa,
          buyVolumeMa,
          volumeDeltaStdev,
        ];
      };
    },
  };
};
