import { useDashboard } from "@/lib/store.ts";
import React, { useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils.ts";
import AutosizeInput from "react-input-autosize";

export function FolderName({ folderId }: { folderId: string }) {
  const { folder, updateFavorites } = useDashboard((state) => ({
    folder: state.favorites.find((folder) => folder.id === folderId),
    updateFavorites: state.updateFavorites,
  }));

  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(folder?.name ?? "");
  }, [folder]);

  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  function renameFolder(name: string) {
    updateFavorites((state) => {
      const folderIndex = state.findIndex((f) => f.id === folderId);
      state[folderIndex].name = name;
    });
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function handleOnFocus() {
    setFocused(true);
  }

  function handleOnBlur() {
    setFocused(false);
    renameFolder(value ?? "");
  }

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === "Enter") {
        inputRef.current?.blur();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    folder && (
      <div className="flex gap-1 items-center">
        <AutosizeInput
          placeholder="Folder name"
          className={cn(
            "border bg-transparent h-full p-1 rounded-sm",
            focused ? "border-gray-300" : "border-transparent",
          )}
          inputStyle={{
            backgroundColor: "transparent",
            transition: "border-color 0.2s, outline 0.2s",
          }}
          value={value}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      </div>
    )
  );
}
