//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  LineStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const asymmetricVolatility = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Asymmetric volatility",
    metainfo: {
      _metainfoVersion: 51,
      id: "asymmetric-volatility@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFRM] Asymmetric volatility",
      shortDescription: "[SF] Asymmetric volatility",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 0,
      },
      plots: [
        {
          id: "downVolatilityPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "upVolatilityPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "backgroundPlot",
          type: StudyPlotType.BgColorer,
          palette: "backgroundPalette",
        },
      ],
      palettes: {
        backgroundPalette: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Downward volatility" },
            1: { name: "Upward volatility" },
          },
        },
      },
      defaults: {
        palettes: {
          backgroundPalette: {
            colors: {
              0: { color: "rgba(178, 24, 44, 0.25)", width: 1, style: 0 },
              1: { color: "rgba(60, 166, 75, 0.25)", width: 1, style: 0 },
            },
          },
        },
        styles: {
          downVolatilityPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(178, 24, 44)",
            transparency: 0,
          },
          upVolatilityPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(60, 166, 75)",
            transparency: 0,
          },
        },
        inputs: {
          lengthInput: 20,
          sourceInput: "close",
        },
      },
      styles: {
        downVolatilityPlot: {
          title: "Downward volatility",
          histogramBase: 0,
        },
        upVolatilityPlot: {
          title: "Upward volatility",
          histogramBase: 0,
        },
        backgroundPlot: {
          title: "Background",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "lengthInput",
          name: "Length",
          defval: 20,
          minval: 1,
          type: StudyInputType.Integer,
        },
        {
          id: "sourceInput",
          name: "Price source",
          defval: "close",
          options: ["open", "high", "low", "close", "hl2", "hlc3", "ohlc4"],
          type: StudyInputType.Text,
        },
      ],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // User inputs
        const lengthInput: number = this._input(0);
        const sourceInput: number = this._input(1);

        //Data parsing
        let sourceSeries: any;
        switch (sourceInput) {
          case "open":
            sourceSeries = this._context.new_var(
              PineJS.Std.open(this._context),
            );
            break;
          case "high":
            sourceSeries = this._context.new_var(
              PineJS.Std.high(this._context),
            );
            break;
          case "low":
            sourceSeries = this._context.new_var(PineJS.Std.low(this._context));
            break;
          case "close":
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
          case "hl2":
            sourceSeries = this._context.new_var(PineJS.Std.hl2(this._context));
            break;
          case "hlc3":
            sourceSeries = this._context.new_var(
              PineJS.Std.hlc3(this._context),
            );
            break;
          case "ohlc4":
            sourceSeries = this._context.new_var(
              PineJS.Std.ohlc4(this._context),
            );
            break;
          default:
            sourceSeries = this._context.new_var(
              PineJS.Std.close(this._context),
            );
            break;
        }

        // Calculations
        const upMoves: number = Math.max(
          sourceSeries.get(0) - sourceSeries.get(1),
          0,
        );
        const upMovesSeries: any = this._context.new_var(upMoves);
        const downMoves: number = Math.max(
          sourceSeries.get(1) - sourceSeries.get(0),
          0,
        );
        const downMovesSeries: any = this._context.new_var(downMoves);

        const upVolatility: number = PineJS.Std.sum(
          upMovesSeries,
          lengthInput,
          this._context,
        );
        const downVolatility: number = PineJS.Std.sum(
          downMovesSeries,
          lengthInput,
          this._context,
        );

        const color: number = downVolatility > upVolatility ? 0 : 1;

        //Return
        return [downVolatility, upVolatility, color];
      };
    },
  };
};
