// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  FilledAreaType,
  StudyInputType,
  LineStudyPlotStyle,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const altucherBollingerBands = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Altucher Bollinger bands",
    metainfo: {
      _metainfoVersion: 51,
      id: "altucher-bollinger-bands@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFTA] Altucher Bollinger bands",
      shortDescription: "[SF] Altucher BB",
      is_hidden_study: false,
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: "price",
        precision: 2,
      },
      plots: [
        { id: "fastSmaPlot", type: StudyPlotType.Line },
        { id: "slowSmaPlot", type: StudyPlotType.Line },
        { id: "conditional1Plot", type: StudyPlotType.Line },
        { id: "conditional2Plot", type: StudyPlotType.Line },
      ],
      filledAreas: [
        {
          id: "Background",
          objAId: "conditional1Plot",
          objBId: "conditional2Plot",
          title: "Background",
          type: FilledAreaType.TypePlots,
        },
      ],
      defaults: {
        filledAreasStyle: {
          Background: {
            color: "rgba(255, 160, 40, 0.5)",
            visible: true,
            transparency: 50,
          },
        },
        styles: {
          fastSmaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(255, 160, 40)",
          },
          slowSmaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.Line,
            color: "rgb(13, 157, 219)",
          },
          conditional1Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Circles,
            color: "rgb(255, 160, 40)",
          },
          conditional2Plot: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: LineStudyPlotStyle.Circles,
            color: "rgb(255, 160, 40)",
          },
        },
        inputs: {
          useCustomSettingsInput: false,
          bbLengthInput: 450,
          bbMultInput: 1.5,
          fastSmaLengthInput: 235,
          slowSmaLengthInput: 345,
          levelInput: -25,
        },
      },
      styles: {
        fastSmaPlot: {
          title: "Fast SMA",
          histogramBase: 0,
        },
        slowSmaPlot: {
          title: "Slow SMA",
          histogramBase: 0,
        },
        conditional1Plot: {
          title: "Upper band",
          histogramBase: 0,
        },
        conditional2Plot: {
          title: "Lower band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "useCustomSettingsInput",
          name: "Use custom settings",
          defval: false,
          type: StudyInputType.Bool,
        },
        {
          id: "bbLengthInput",
          name: "BB Length",
          defval: 450,
          type: StudyInputType.Integer,
        },
        {
          id: "bbMultInput",
          name: "BB Multiplier",
          defval: 1.5,
          type: StudyInputType.Float,
        },
        {
          id: "fastSmaLengthInput",
          name: "Fast SMA length",
          defval: 235,
          type: StudyInputType.Integer,
        },
        {
          id: "slowSmaLengthInput",
          name: "Slow SMA length",
          defval: 345,
          type: StudyInputType.Integer,
        },
        {
          id: "levelInput",
          name: "Level",
          defval: -25,
          type: StudyInputType.Float,
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        // Inputs
        const useCustomSettingsInput: boolean = this._input(0);
        const bbLengthInput: number = this._input(1);
        const bbMultInput: number = this._input(2);
        const fastSmaLengthInput: number = this._input(3);
        const slowSmaLengthInput: number = this._input(4);
        const levelInput: number = this._input(5);

        // Market data parsing
        const close: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );

        // Calculations
        let bbLength: number;
        let bbMult: number;
        let fastSmaLength: number;
        let slowSmaLength: number;
        let level: number;

        if (useCustomSettingsInput) {
          bbLength = bbLengthInput;
          bbMult = bbMultInput;
          fastSmaLength = fastSmaLengthInput;
          slowSmaLength = slowSmaLengthInput;
          level = levelInput;
        } else {
          const lengthMlt: number = Number(PineJS.Std.period(this._context));
          bbLength = Math.round(450 / lengthMlt);
          bbMult = bbMultInput;
          fastSmaLength = Math.round(235 / lengthMlt);
          slowSmaLength = Math.round(345 / lengthMlt);
          level = levelInput;
        }

        const bbBasis: number = PineJS.Std.sma(close, bbLength, this._context);
        const sigma: number = PineJS.Std.stdev(close, bbLength, this._context);
        const bbUpper: number = bbBasis + sigma * bbMult;
        const bbLower: number = bbBasis - sigma * bbMult;

        const formula: number =
          ((close.get(0) - bbLower) / (bbUpper - bbLower)) * 100;

        const booleanExpress: boolean =
          close.get(0) < bbLower && formula < level;

        const fastSma: number = PineJS.Std.sma(
          close,
          fastSmaLength,
          this._context,
        );
        const slowSma: number = PineJS.Std.sma(
          close,
          slowSmaLength,
          this._context,
        );

        const conditional1: number = booleanExpress
          ? close.get(0) - (close.get(0) / 100) * 3.5
          : NaN;
        const conditional2: number = booleanExpress
          ? close.get(0) - (close.get(0) / 100) * 4.5
          : NaN;

        // Return values
        return [fastSma, slowSma, conditional1, conditional2];
      };
    },
  };
};
