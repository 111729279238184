//eslint-disable-next-line
//@ts-nocheck
import {
  CustomIndicator,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";

import {
  OhlcStudyPlotStyle,
  StudyInputType,
  StudyPlotType,
} from "@/lib/datafeed/tvTypes.ts";

export const netOpenInterest = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Net open interest",
    metainfo: {
      _metainfoVersion: 51,
      id: "net-open-interest@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFT] Net open interest",
      shortDescription: "[SF] Net OI",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: true,
      format: {
        type: "volume",
        precision: 2,
      },
      plots: [
        {
          id: "netOiOhlcOpenPlot",
          type: StudyPlotType.OhlcOpen,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcHighPlot",
          type: StudyPlotType.OhlcHigh,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcLowPlot",
          type: StudyPlotType.OhlcLow,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcClosePlot",
          type: StudyPlotType.OhlcClose,
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcBarColorer",
          type: StudyPlotType.OhlcColorer,
          palette: "ohlcPaletteBar",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcWickColorer",
          type: StudyPlotType.CandleWickColorer,
          palette: "ohlcPaletteWick",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiOhlcBorderColorer",
          type: StudyPlotType.CandleBorderColorer,
          palette: "ohlcPaletteBorder",
          target: "netOiOhlcPlotcandle",
        },
        {
          id: "netOiMaPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "netOiUpperBandPlot",
          type: StudyPlotType.Line,
        },
        {
          id: "netOiLowerBandPlot",
          type: StudyPlotType.Line,
        },
      ],
      palettes: {
        ohlcPaletteBar: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
        ohlcPaletteWick: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
        ohlcPaletteBorder: {
          valToIndex: {
            0: 0,
            1: 1,
          },
          colors: {
            0: { name: "Falling net OI" },
            1: { name: "Growing net OI" },
          },
        },
      },
      ohlcPlots: {
        netOiOhlcPlotcandle: {
          title: "Net OI plot candle",
        },
      },
      defaults: {
        styles: {
          netOiMaPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          netOiUpperBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
          netOiLowerBandPlot: {
            linestyle: 2,
            visible: true,
            linewidth: 1,
            trackPrice: false,
            color: "rgba(255, 255, 255, 0.5)",
            transparency: 100,
          },
        },
        ohlcPlots: {
          netOiOhlcPlotcandle: {
            borderColor: "rgb(149, 152, 161)",
            color: "rgb(149, 152, 161)",
            drawBorder: true,
            drawWick: true,
            plottype: OhlcStudyPlotStyle.OhlcCandles,
            visible: true,
            wickColor: "rgb(149, 152, 161)",
          },
        },
        palettes: {
          ohlcPaletteBar: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteWick: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
          ohlcPaletteBorder: {
            colors: {
              0: { color: "rgb(178, 24, 44)", width: 1, style: 0 },
              1: { color: "rgb(60, 166, 75)", width: 1, style: 0 },
            },
          },
        },
        precision: 2,
        inputs: {
          displayModeInput: "Taker net long",
          calculationMethodInput: "Cum",
          measureInput: "Coins",
          maTypeInput: "SMA",
          lengthInput: 60,
          channelTypeInput: "Bollinger channel",
          metricsBoolInput: false,
        },
      },
      styles: {
        netOiMaPlot: {
          isHidden: false,
          title: "Moving average",
          histogramBase: 0,
        },
        netOiUpperBandPlot: {
          isHidden: false,
          title: "Upper Band",
          histogramBase: 0,
        },
        netOiLowerBandPlot: {
          isHidden: false,
          title: "Lower Band",
          histogramBase: 0,
        },
      },
      inputs: [
        {
          id: "displayModeInput",
          name: "Display mode",
          defval: "Taker net long",
          options: [
            "Taker net long",
            "Taker net short",
            "Maker net long",
            "Maker net short",
          ],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "calculationMethodInput",
          name: "Calculation method",
          defval: "Cum",
          options: ["Cum", "Sum"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "measureInput",
          name: "Measure",
          defval: "Coins",
          options: ["Coins", "USD"],
          type: StudyInputType.Text,
          group: "Main settings",
        },
        {
          id: "maTypeInput",
          name: "Moving average typeline",
          defval: "SMA",
          options: ["SMA", "EMA", "WMA", "VWMA", "LSMA"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "lengthInput",
          name: "Length",
          defval: 240,
          min: 2,
          max: 1500,
          type: StudyInputType.Integer,
          group: "Metrics settings",
        },
        {
          id: "channelTypeInput",
          name: "Channel type",
          defval: "Bollinger channel",
          options: ["Extremum channel", "Bollinger channel"],
          type: StudyInputType.Text,
          group: "Metrics settings",
        },
        {
          id: "metricsBoolInput",
          name: "Show metrics?",
          defval: true,
          type: StudyInputType.Bool,
          group: "Metrics settings",
        },
      ],
    },

    constructor: function () {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const oiSymbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#NETOIDELTA`;
        this._context.new_sym(oiSymbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //User input
        const displayModeInput: string = this._input(0);
        const calculationMethodInput: string = this._input(1);
        const measureInput: string = this._input(2);
        const maTypeInput: string = this._input(3);
        const lengthInput: number = this._input(4);
        const chanelTypeInput: string = this._input(5);
        const metricsBoolInput: boolean = this._input(6);

        //Market data parsing
        this._context.select_sym(1);
        const oiTime: any = this._context.new_var(this._context.symbol.time);
        const takerLongDeltaArray: any = this._context.new_var(
          PineJS.Std.open(this._context),
        );
        const takerShortDeltaArray: any = this._context.new_var(
          PineJS.Std.high(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const takerLongDelta: number = takerLongDeltaArray.adopt(
          oiTime,
          mainSymbolTimeSeries,
          1,
        );
        const takerShortDelta: number = takerShortDeltaArray.adopt(
          oiTime,
          mainSymbolTimeSeries,
          1,
        );

        //Calculations
        let netOiDelta: number =
          displayModeInput === "Taker net long" ||
          displayModeInput === "Maker net short"
            ? takerLongDelta
            : takerShortDelta;

        if (Number.isNaN(netOiDelta)) {
          netOiDelta = 0;
        }

        if (measureInput === "USD") {
          const close: number = PineJS.Std.close(this._context);
          netOiDelta *= close;
        }

        let netOiClose: number = NaN;
        if (calculationMethodInput === "Sum") {
          const length: number =
            1440 / Number(PineJS.Std.period(this._context));
          const netOiDeltaSeries: any = this._context.new_var(netOiDelta);
          netOiClose = PineJS.Std.sum(netOiDeltaSeries, length, this._context);
        } else {
          netOiClose = PineJS.Std.cum(netOiDelta, this._context);
        }

        const netOiCloseSeries: any = this._context.new_var(netOiClose);
        const netOiOpen: number = netOiCloseSeries.get(1);
        const netOiHigh: number = PineJS.Std.max(netOiOpen, netOiClose);
        const netOiLow: number = PineJS.Std.min(netOiOpen, netOiClose);
        const netOiColor: number = netOiClose > netOiOpen ? 1 : 0;

        let netOiStdev: number = NaN;
        let netOiMa: number = NaN;
        let netOiUpperBand: number = NaN;
        let netOiLowerBand: number = NaN;
        if (metricsBoolInput) {
          const netOiHighSeries: any = this._context.new_var(netOiHigh);
          const netOiLowSeries: any = this._context.new_var(netOiLow);
          switch (maTypeInput) {
            case "SMA":
              netOiMa = PineJS.Std.sma(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              break;
            case "EMA":
              netOiMa = PineJS.Std.ema(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              break;
            case "WMA":
              netOiMa = PineJS.Std.wma(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              break;
            case "VWMA":
              netOiMa = PineJS.Std.vwma(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              break;
            case "LSMA":
              netOiMa = PineJS.Std.linreg(netOiCloseSeries, lengthInput, 0);
              break;
            default:
              netOiMa = PineJS.Std.sma(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              break;
          }
          switch (chanelTypeInput) {
            case "Extremum channel":
              netOiUpperBand = PineJS.Std.highest(
                netOiHighSeries,
                lengthInput,
                this._context,
              );
              netOiLowerBand = PineJS.Std.lowest(
                netOiLowSeries,
                lengthInput,
                this._context,
              );
              if (netOiUpperBand == 0) {
                netOiUpperBand = NaN;
              }
              if (netOiLowerBand == 0) {
                netOiLowerBand = NaN;
              }
              break;
            case "Bollinger channel":
              netOiStdev = PineJS.Std.stdev(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              netOiUpperBand = netOiMa + netOiStdev;
              netOiLowerBand = netOiMa - netOiStdev;
              break;
            default:
              netOiStdev = PineJS.Std.stdev(
                netOiCloseSeries,
                lengthInput,
                this._context,
              );
              netOiUpperBand = netOiMa + netOiStdev;
              netOiLowerBand = netOiMa - netOiStdev;
              break;
          }
        }

        //Returns
        return [
          netOiOpen,
          netOiHigh,
          netOiLow,
          netOiClose,
          netOiColor,
          netOiColor,
          netOiColor,
          netOiMa,
          netOiUpperBand,
          netOiLowerBand,
        ];
      };
    },
  };
};
