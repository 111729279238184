import { BINANCE_RESOLUTION_MAP_REVERSE } from "@/lib/datafeed/config.ts";
import { ResolutionString } from "@tradingView/charting_library";
import {
  EFilterType,
  ENumberComparisonType,
  EWidgetType,
} from "@/domain/enums/dashboard.enum.ts";
import {
  IPaneConfig,
  IWidgetOptions,
} from "@/domain/interfaces/dashboard.interface.ts";

export const colors = [
  "#B2182C",
  "#FFA028",
  "#3CA64B",
  "#047857",
  "#0d9ddb",
  "#4338ca",
  "#6d28d9",
  "#a21caf",
  "#be185d",
];

export const MAX_CHARTS = 10;

export const GLOBAL_FILTERS = [
  {
    type: EFilterType.number,
    title: "ADTV 15m",
    global: true,
    filterByField: "adtv15m",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 15m",
    global: true,
    filterByField: "vol15m",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 30m",
    global: true,
    filterByField: "adtv30m",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 30m",
    global: true,
    filterByField: "vol30m",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 1h",
    global: true,
    filterByField: "adtv1h",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 1h",
    global: true,
    filterByField: "vol1h",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 4h",
    global: true,
    filterByField: "adtv4h",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 4h",
    global: true,
    filterByField: "vol4h",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 1d",
    global: true,
    filterByField: "adtv1d",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 1d",
    global: true,
    filterByField: "vol1d",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 1w",
    global: true,
    filterByField: "adtv1w",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 1w",
    global: true,
    filterByField: "vol1w",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "ADTV 1M",
    global: true,
    filterByField: "adtv1M",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Volume Quote 1M",
    global: true,
    filterByField: "vol1M",
    numberComparisonType: ENumberComparisonType.greater,
  },
  {
    type: EFilterType.number,
    title: "Activity Detector",
    global: true,
    filterByField: "value",
    numberComparisonType: ENumberComparisonType.mixed,
  },
  {
    type: EFilterType.number,
    title: "Crypto Drift",
    global: true,
    filterByField: "value",
    numberComparisonType: ENumberComparisonType.mixed,
  },
];

export const dynamicColumns: Record<
  string,
  {
    title: string;
    availableTimeFrames?: string[];
    timeFrameTitles?: string[];
  }
> = {
  priceChg: {
    title: "Price",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  openInterest: {
    title: "Open Interest",
  },
  manipulationMonitor: {
    title: "Manipulation Monitor",
    availableTimeFrames: ["corr", "corrV"],
    timeFrameTitles: ["Price", "Volume"],
  },
  spotFuturesSpread: {
    title: "Spot Futures Spread",
    availableTimeFrames: ["funding", "spread"],
    timeFrameTitles: ["Funding", "Spread"],
  },
  openInterestChg: {
    title: "Open Interest Chg",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  volumeChg: {
    title: "Volume",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour %CHG", "Day %CHG", "Week %CHG", "Month %CHG"],
  },
  buySellRatioChg: {
    title: "Buy/Sell Ratio",
    availableTimeFrames: ["1h", "1d", "1w", "1M"],
    timeFrameTitles: ["Hour", "Day", "Week", "Month"],
  },
  marketRatio: {
    title: "Market Ratio",
    availableTimeFrames: ["1M", "1d", "1w"],
    timeFrameTitles: ["Month", "Day", "Week"],
  },
  funding: {
    title: "Funding",
  },
};

const defaultConfig: Pick<
  IPaneConfig,
  "interval" | "color" | "numberOfCharts" | "availableIntervals"
> = {
  color: colors[0],
  interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
  availableIntervals: [
    BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["3m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["30m"] as ResolutionString,
    BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
  ],

  numberOfCharts: 3,
};

export const widgets: Record<EWidgetType, IWidgetOptions> = {
  [EWidgetType.chart]: {
    component: EWidgetType.chart,
    name: "Chart",
    config: {
      symbol: "BTCUSDT",
      color: colors[0],
    },
  },
  [EWidgetType.spotFuturesSpread]: {
    component: EWidgetType.spotFuturesSpread,
    name: "S/F Spread",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Spot futures dominance",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "Spread",
          filterByField: "spread",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Funding",
          filterByField: "funding",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "funding",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.spreadDominationScreener]: {
    component: EWidgetType.spreadDominationScreener,
    name: "Spread Domination",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Spot futures time domination",
      indicatorMergedWithChart: true,
      filters: [
        {
          type: EFilterType.number,
          title: "D.CUM",
          filterByField: "dCum",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "M.CUM",
          filterByField: "mCum",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.strategies]: {
    component: EWidgetType.strategies,
    name: "Strategies",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Spot futures spread",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.range,
          title: "WinRate",
          filterByField: "probability",
          min: 0,
          max: 100,
        },
      ],
    },
  },
  [EWidgetType.strategiesV2]: {
    component: EWidgetType.strategiesV2,
    name: "Strategies (new)",
    config: {
      ...defaultConfig,
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "direction",
          options: [
            {
              label: "Buy",
              value: "BUY",
            },
            {
              label: "Sell",
              value: "SELL",
            },
          ],
        },
        {
          type: EFilterType.multiSelect,
          title: "Strategy",
          filterByField: "strategy",
          options: [
            {
              label: "Altusher BB",
              value: "SCREENER_STRATEGY_ALTUSHER_BB1",
            },
            {
              label: "Altusher Gaps",
              value: "SCREENER_STRATEGY_ALTUSHER_GAPS1",
            },
            {
              label: "CM V1",
              value: "SCREENER_STRATEGY_CM_STRATEGY1",
            },
            {
              label: "CM V2",
              value: "SCREENER_STRATEGY_CM_STRATEGY2",
            },
            {
              label: "Dual EMA Bollinger",
              value: "SCREENER_STRATEGY_DUAL_EMA_BOLLINGER1",
            },
            {
              label: "Multi Period Volatility Filter",
              value: "SCREENER_STRATEGY_MULTI_PERIOD_VOLATILITY_FILTER1",
            },
            {
              label: "Relative High Low Oscillator",
              value: "SCREENER_STRATEGY_RELATIVE_HIGH_LOW_OSCILLATOR1",
            },
            {
              label: "Directional Index with Bollinger Confirmation",
              value:
                "SCREENER_STRATEGY_DIRECTIONAL_INDEX_WITH_BOLINGER_CONFIRMATION1",
            },
            {
              label: "Triple Price Deviation Filter",
              value: "SCREENER_STRATEGY_TRIPLE_PRICE_DEVIATION_FILTER1",
            },
            {
              label: "Volatility Based EMA",
              value: "SCREENER_STRATEGY_VOLATILITY_BASED_EMA1",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.stopKiller]: {
    component: EWidgetType.stopKiller,
    name: "Stop Killer",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Stop killer",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "kill",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.manipulationMonitor]: {
    component: EWidgetType.manipulationMonitor,
    name: "MM",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Manipulation monitor",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "corr",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "M.M.Price",
          filterByField: "corr",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "M.M.Volume",
          filterByField: "corrV",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.priceScreener]: {
    component: EWidgetType.priceScreener,
    name: "Pr Ac",
    config: {
      ...defaultConfig,
      sortingState: [
        {
          id: "price",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Price",
          filterByField: "price",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.adtvScreener]: {
    component: EWidgetType.adtvScreener,
    name: "ADTV",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFT] Average day trade volume",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "adtv",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "ADTV",
          filterByField: "adtv",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "ADTS",
          filterByField: "adts",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.activityDetectorScreener]: {
    component: EWidgetType.activityDetectorScreener,
    name: "Activity Detector",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Activity detector",
      indicatorMergedWithChart: true,
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.marketRatioScreener]: {
    component: EWidgetType.marketRatioScreener,
    name: "Market Ratio",

    config: {
      ...defaultConfig,
      filters: [
        {
          type: EFilterType.number,
          title: "Day Value",
          filterByField: "dayValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Week Value",
          filterByField: "weekValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
        {
          type: EFilterType.number,
          title: "Month Value",
          filterByField: "monthValue",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
      sortingState: [
        {
          id: "monthValue",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.cryptoDriftScreener]: {
    component: EWidgetType.cryptoDriftScreener,
    name: "Crypto Drift",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Crypto drift",
      indicatorMergedWithChart: true,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "value",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Value",
          filterByField: "value",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.oiScreener]: {
    component: EWidgetType.oiScreener,
    name: "Oi Ac",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFT] Open interest",
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "openInterest",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Open Interest",
          filterByField: "openInterest",
          numberComparisonType: ENumberComparisonType.mixed,
        },
      ],
    },
  },
  [EWidgetType.volumeScreener]: {
    component: EWidgetType.volumeScreener,
    name: "Vol Ac",
    config: {
      ...defaultConfig,
      indicatorMergedWithChart: true,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "volume",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "volume",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.deltaScreener]: {
    component: EWidgetType.deltaScreener,
    name: "B/S D Ac",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFT] Volume delta",
      interval: BINANCE_RESOLUTION_MAP_REVERSE["1m"] as ResolutionString,
      sortingState: [
        {
          id: "buyDelta",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Buy Delta",
          filterByField: "buyDelta",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.number,
          title: "Sell Delta",
          filterByField: "sellDelta",
          numberComparisonType: ENumberComparisonType.greater,
        },
      ],
    },
  },
  [EWidgetType.liquidationsScanner]: {
    component: EWidgetType.liquidationsScanner,
    name: "Liq",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFM] Liquidations",
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.pairsScanner]: {
    component: EWidgetType.pairsScanner,
    name: "Pairs",
    config: {
      ...defaultConfig,
      indicatorTitle: "[SFT] Bollinger bands",
      sortingState: [
        {
          id: "timestamp",
          desc: true,
        },
      ],
    },
  },
  [EWidgetType.cascadeLevels]: {
    component: EWidgetType.cascadeLevels,
    name: "Cascade Levels",
    config: {
      ...defaultConfig,
      interval: BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
      availableIntervals: [
        BINANCE_RESOLUTION_MAP_REVERSE["5m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["15m"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["1h"] as ResolutionString,
        BINANCE_RESOLUTION_MAP_REVERSE["4h"] as ResolutionString,
      ],
      sortingState: [
        {
          id: "toFcPrc",
          desc: true,
        },
      ],
      filters: [
        {
          type: EFilterType.number,
          title: "Percents",
          filterByField: "toFcPrc",
          numberComparisonType: ENumberComparisonType.less,
        },
        {
          type: EFilterType.select,
          title: "Cells",
          filterByField: "fcSum",
          options: [
            {
              label: "1",
              value: "1",
            },
            {
              label: "2",
              value: "2",
            },
            {
              label: "3",
              value: "3",
            },
            {
              label: "4",
              value: "4",
            },
          ],
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },
  [EWidgetType.predictLiquidation]: {
    component: EWidgetType.predictLiquidation,
    name: "Predict Liquidation",
    config: {
      ...defaultConfig,
      sortingState: [
        {
          id: "prc",
          desc: false,
        },
      ],
      filters: [
        {
          type: EFilterType.text,
          title: "Symbol",
          filterByField: "symbol",
        },
        {
          type: EFilterType.number,
          title: "Percents",
          filterByField: "prc",
          numberComparisonType: ENumberComparisonType.less,
        },
        {
          type: EFilterType.number,
          title: "Volume",
          filterByField: "quoteQuantity",
          numberComparisonType: ENumberComparisonType.greater,
        },
        {
          type: EFilterType.select,
          title: "Side",
          filterByField: "side",
          options: [
            {
              label: "Buy",
              value: "UP",
            },
            {
              label: "Sell",
              value: "DOWN",
            },
          ],
        },
      ],
    },
  },

  [EWidgetType.controlPanel]: {
    component: EWidgetType.controlPanel,
    name: "Control Panel",
    config: {
      color: colors[0],
    },
  },
};

export enum EScreenerStrategy {
  SCREENER_STRATEGY_CM_STRATEGY1 = "CM v.1",
  SCREENER_STRATEGY_CM_STRATEGY2 = "CM v.2",
  SCREENER_STRATEGY_DUAL_EMA_BOLLINGER1 = "Dual EMA Bollinger",
  SCREENER_STRATEGY_ALTUSHER_BB1 = "Altusher BB",
  SCREENER_STRATEGY_ALTUSHER_GAPS1 = "Altusher Gaps",
  SCREENER_STRATEGY_RELATIVE_HIGH_LOW_OSCILLATOR1 = "Relative High Low Oscillator",
  SCREENER_STRATEGY_MULTI_PERIOD_VOLATILITY_FILTER1 = "Multi Period Volatility Filter",
  SCREENER_STRATEGY_DIRECTIONAL_INDEX_WITH_BOLINGER_CONFIRMATION1 = "Directional Index with Bollinger Confirmation",
  SCREENER_STRATEGY_TRIPLE_PRICE_DEVIATION_FILTER1 = "Triple Price Deviation Filter",
  SCREENER_STRATEGY_VOLATILITY_BASED_EMA1 = "Volatility Based EMA",
}
