import { usePaneContext } from "@/components/pane/paneContext.ts";
import { useAuth } from "@/hooks/useAuth.ts";
import {
  ICascadeLevelDetails,
  useCascadeLevelsBySymbol,
} from "@/hooks/useCascadeLevelsBySymbol.ts";
import { useGrid } from "@/hooks/useGrid.ts";
import {
  IMarginLevelDetails,
  useMarginLevelsBySymbol,
} from "@/hooks/useMarginLevelsBySymbol.ts";
import { BINANCE_RESOLUTION_MAP } from "@/lib/datafeed/config.ts";
import { datafeed } from "@/lib/datafeed/df.ts";

import { accumulationDistribution } from "@/lib/indicators/accumulation-distribution.ts";
import { activeAddresses } from "@/lib/indicators/active-addresses.ts";
import { activityDetector } from "@/lib/indicators/activity-detector.ts";
import { activityDetectorHighlighter } from "@/lib/indicators/activity-detector-highlighter.ts";
import { agarchVolatilityEstimation } from "@/lib/indicators/agarch-volatility-estimation.ts";
import { alpha } from "@/lib/indicators/alpha";
import { altMeter } from "@/lib/indicators/alt-meter";
import { altucherBollingerBands } from "@/lib/indicators/altucher-bollinger-bands.ts";
import { apgarchVolatilityEstimation } from "@/lib/indicators/apgarch-volatility-estimation.ts";
import { asymmetricVolatility } from "@/lib/indicators/asymmetric-volatility.ts";
import { augmentedDickeyFullerTest } from "@/lib/indicators/augmented-dickey-fuller-test.ts";
import { averageDayTradeSpeed } from "@/lib/indicators/average-day-trade-speed.ts";
import { averageDayTradeVolume } from "@/lib/indicators/average-day-trade-volume.ts";
import { averageTradesSize } from "@/lib/indicators/average-trade-size.ts";
import { beta } from "@/lib/indicators/beta.ts";
import { bestAskAndBidSize } from "@/lib/indicators/best-ask-and-bid-size.ts";
import { bidAskDelta } from "@/lib/indicators/bid-ask-delta.ts";
import { bidAskImbalance } from "@/lib/indicators/bid-ask-imbalance.ts";
import { bollingerBands } from "@/lib/indicators/bollinger-bands.ts";
import { cexBalance } from "@/lib/indicators/cex-balance.ts";
import { commonActiveReturnMeasure } from "@/lib/indicators/common-active-return-measure.ts";
import { cryptoDrift } from "@/lib/indicators/crypto-drift.ts";
import { cumulativeLiquidationsDelta } from "@/lib/indicators/cumulative-liquidations-delta.ts";
import { cumulativeStopKillerDelta } from "@/lib/indicators/cumulative-stop-killer-delta.ts";
import { cumulativeVolumeDelta } from "@/lib/indicators/cumulative-volume-delta.ts";
import { dayHighAndLow } from "@/lib/indicators/day-high-and-low.ts";
import { depthOfMarket } from "@/lib/indicators/depth-of-market.ts";
import { developersActivity } from "@/lib/indicators/developers-activity.ts";
import { exchangeInflow } from "@/lib/indicators/exchange-inflow.ts";
import { exchangeNetflow } from "@/lib/indicators/exchange-netflow.ts";
import { exchangeOutflow } from "@/lib/indicators/exchange-outflow.ts";
import { exchangeUsdInflow } from "@/lib/indicators/exchange-usd-inflow.ts";
import { exchangeUsdOutflow } from "@/lib/indicators/exchange-usd-outflow.ts";
import { fundingRate } from "@/lib/indicators/funding-rate.ts";
import { garchmVolatilityEstimation } from "@/lib/indicators/garch-m-volatility-estimation.ts";
import { githubActivity } from "@/lib/indicators/github-activity.ts";
import { hftActivity } from "@/lib/indicators/hft-activity.ts";
import { hullWhiteVolatilityModel } from "@/lib/indicators/hull-white-volatility-model.ts";
import { ideas } from "@/lib/indicators/ideas.ts";
import { keltnerChannel } from "@/lib/indicators/keltner-channel";
import { layeringHighlighter } from "@/lib/indicators/layering-highlighter";
import { liquidations } from "@/lib/indicators/liquidations.ts";
import { liquidationsHighlighter } from "@/lib/indicators/liquidations-highlighter.ts";
import { liveStrategies } from "@/lib/indicators/live-strategies";
import { localVolalility } from "@/lib/indicators/local-volalility";
import { manipulationMonitor } from "@/lib/indicators/manipulation-monitor.ts";
import { manipulationMonitorHighlighter } from "@/lib/indicators/manipulation-monitor-highlighter.ts";
import { marketCapitalization } from "@/lib/indicators/market-capitalization.ts";
import { marketFacilitationIndex } from "@/lib/indicators/market-facilitation-index.ts";
import { marketPower } from "@/lib/indicators/market-power.ts";
import { marketPowerHighlighter } from "@/lib/indicators/market-power-highlighter.ts";
import { marketRatio } from "@/lib/indicators/market-ratio.ts";
import { marketValueToRealisedValue } from "@/lib/indicators/market-value-to-realised-value.ts";
import { moneyFlowIndex } from "@/lib/indicators/money-flow-index.ts";
import { movingAverage } from "@/lib/indicators/moving-average.ts";
import { natrVolalility } from "@/lib/indicators/natr-volalility";
import { negativeMarketdataIndex } from "@/lib/indicators/negative-market-data-index.ts";
import { netOpenInterest } from "@/lib/indicators/net-open-interest.ts";
import { netOpenInterestDelta } from "@/lib/indicators/net-open-interest-delta.ts";
import { netOpenInterestSpike } from "@/lib/indicators/net-open-interest-spike.ts";
import { networkProfitAndLoss } from "@/lib/indicators/network-profit-and-loss.ts";
import { networkValueToTransactionsRatio } from "@/lib/indicators/network-value-to-transactions-ratio.ts";
import { openInterest } from "@/lib/indicators/open-interest.ts";
import { openInterestChange } from "@/lib/indicators/open-interest-change.ts";
import { openInterestDelta } from "@/lib/indicators/open-interest-delta.ts";
import { openInterestSpike } from "@/lib/indicators/open-interest-spike.ts";
import { orderFlowIntesity } from "@/lib/indicators/order-flow-intesity.ts";
import { positiveMarketdataIndex } from "@/lib/indicators/positive-market-data-index.ts";
import { powerDeltaVolume } from "@/lib/indicators/power-delta-volume";
import { powerTrades } from "@/lib/indicators/power-trades.ts";
import { priceChange } from "@/lib/indicators/price-change";
import { priceChangeCost } from "@/lib/indicators/price-change-cost.ts";
import { pumpTrend } from "@/lib/indicators/pump-trend.ts";
import { pumpTrendHighlighter } from "@/lib/indicators/pump-trend-highlighter.ts";
import { quotedSpread } from "@/lib/indicators/quoted-spread.ts";
import { rateOfChange } from "@/lib/indicators/rate-of-change.ts";
import { realizedVolalility } from "@/lib/indicators/realized-volalility.ts";
import { retailPower } from "@/lib/indicators/retail-power.ts";
import { rsiCannel } from "@/lib/indicators/rsi-channel.ts";
import { rtIndex } from "@/lib/indicators/rt-index";
import { sentimentBalanceTotal } from "@/lib/indicators/sentiment-balance-total.ts";
import { sentimentNegativeTotal } from "@/lib/indicators/sentiment-negative-total.ts";
import { sentimentPositiveTotal } from "@/lib/indicators/sentiment-positive-total.ts";
import { sharpeRatio } from "@/lib/indicators/sharpe-ratio";
import { simpleCevVolatilityApproximation } from "@/lib/indicators/simple-cev-volatility-approximation";
import { socialActiveUsers } from "@/lib/indicators/social-active-users";
import { socialDominanceAiTotal } from "@/lib/indicators/social-dominance-ai-total.ts";
import { socialDominanceTotal } from "@/lib/indicators/social-dominance-total.ts";
import { socialVolumeTotal } from "@/lib/indicators/social-volume-total.ts";
import { spoofingHighlighter } from "@/lib/indicators/spoofing-highlighter.ts";
import { spotFututresAugmentedDickeyFullerTest } from "@/lib/indicators/spot-fututres-augmented-dickey-fuller-test";
import { spotFuturesDominance } from "@/lib/indicators/spot-futures-dominance.ts";
import { spotFuturesDominanceHighlighter } from "@/lib/indicators/spot-futures-dominance-highlighter.ts";
import { spotFuturesSpread } from "@/lib/indicators/spot-futures-spread.ts";
import { standardizedActiveReturnMeasure } from "@/lib/indicators/standardized-active-return-measure.ts";
import { stopKiller } from "@/lib/indicators/stop-killer.ts";
import { stopKillerHighlighter } from "@/lib/indicators/stop-killer-highlighter.ts";
import { takeProfitAndStopLoss } from "@/lib/indicators/take-profit-and-stop-loss.ts";
import { tape } from "@/lib/indicators/tape.ts";
import { tickIndex } from "@/lib/indicators/tick-index";
import { timeDomination } from "@/lib/indicators/time-domination.ts";
import { totalTradeVolumeByDex } from "@/lib/indicators/total-trade-volume-by-dex.ts";
import { transactionVolume } from "@/lib/indicators/transaction-volume.ts";
import { trendAssessment } from "@/lib/indicators/trend-assessment";
import { twitterFollowers } from "@/lib/indicators/twitter-followers.ts";
import { valueAtRisk } from "@/lib/indicators/value-at-risk";
import { volalility } from "@/lib/indicators/volalility.ts";
import { volumeChange } from "@/lib/indicators/volume-change.ts";
import { volumeDelta } from "@/lib/indicators/volume-delta.ts";
import { whale1KTransactionCount } from "@/lib/indicators/whale-1-k-transaction-count";
import { whale1MlnTransactionCount } from "@/lib/indicators/whale-1-mln-transaction-count";
import { who } from "@/lib/indicators/who.ts";
import { withdrawalBalance } from "@/lib/indicators/withdrawal-balance.ts";
import { zScore } from "@/lib/indicators/z-score.ts";

import { useDashboard } from "@/lib/store.ts";
import { cn } from "@/lib/utils.ts";
import {
  ChartingLibraryWidgetOptions,
  CustomIndicator,
  IChartingLibraryWidget,
  IDropdownApi,
  PineJS,
  ResolutionString,
  SaveLoadChartRecord,
  widget as Widget,
} from "@tradingView/charting_library";
import { merge } from "lodash";
import { powerVolumeDelta } from "@/lib/indicators/power-volume-delta.ts";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useDrawSignal } from "@/lib/store";

function getSFIndicators(PineJS: PineJS) {
  let sfIndicators = [
    accumulationDistribution(PineJS),
    activeAddresses(PineJS),
    activityDetector(PineJS),
    activityDetectorHighlighter(PineJS),
    agarchVolatilityEstimation(PineJS),
    alpha(PineJS),
    altMeter(PineJS),
    altucherBollingerBands(PineJS),
    apgarchVolatilityEstimation(PineJS),
    asymmetricVolatility(PineJS),
    augmentedDickeyFullerTest(PineJS),
    averageDayTradeSpeed(PineJS),
    averageDayTradeVolume(PineJS),
    averageTradesSize(PineJS),
    beta(PineJS),
    bidAskDelta(PineJS),
    bidAskImbalance(PineJS),
    bestAskAndBidSize(PineJS),
    bollingerBands(PineJS),
    cexBalance(PineJS),
    commonActiveReturnMeasure(PineJS),
    cryptoDrift(PineJS),
    cumulativeLiquidationsDelta(PineJS),
    cumulativeStopKillerDelta(PineJS),
    cumulativeVolumeDelta(PineJS),
    dayHighAndLow(PineJS),
    developersActivity(PineJS),
    depthOfMarket(PineJS),
    exchangeInflow(PineJS),
    exchangeNetflow(PineJS),
    exchangeOutflow(PineJS),
    exchangeUsdInflow(PineJS),
    exchangeUsdOutflow(PineJS),
    fundingRate(PineJS),
    garchmVolatilityEstimation(PineJS),
    githubActivity(PineJS),
    hftActivity(PineJS),
    hullWhiteVolatilityModel(PineJS),
    ideas(PineJS),
    keltnerChannel(PineJS),
    layeringHighlighter(PineJS),
    liquidations(PineJS),
    liquidationsHighlighter(PineJS),
    liveStrategies(PineJS),
    localVolalility(PineJS),
    manipulationMonitor(PineJS),
    manipulationMonitorHighlighter(PineJS),
    marketCapitalization(PineJS),
    marketFacilitationIndex(PineJS),
    marketPower(PineJS),
    marketPowerHighlighter(PineJS),
    marketRatio(PineJS),
    marketValueToRealisedValue(PineJS),
    moneyFlowIndex(PineJS),
    movingAverage(PineJS),
    natrVolalility(PineJS),
    negativeMarketdataIndex(PineJS),
    netOpenInterest(PineJS),
    netOpenInterestDelta(PineJS),
    netOpenInterestSpike(PineJS),
    networkProfitAndLoss(PineJS),
    networkValueToTransactionsRatio(PineJS),
    openInterest(PineJS),
    openInterestChange(PineJS),
    openInterestDelta(PineJS),
    openInterestSpike(PineJS),
    orderFlowIntesity(PineJS),
    positiveMarketdataIndex(PineJS),
    powerDeltaVolume(PineJS),
    powerTrades(PineJS),
    powerVolumeDelta(PineJS),
    priceChange(PineJS),
    priceChangeCost(PineJS),
    quotedSpread(PineJS),
    rateOfChange(PineJS),
    realizedVolalility(PineJS),
    rsiCannel(PineJS),
    rtIndex(PineJS),
    sentimentBalanceTotal(PineJS),
    sentimentNegativeTotal(PineJS),
    sentimentPositiveTotal(PineJS),
    sharpeRatio(PineJS),
    simpleCevVolatilityApproximation(PineJS),
    socialActiveUsers(PineJS),
    socialDominanceAiTotal(PineJS),
    socialDominanceTotal(PineJS),
    socialVolumeTotal(PineJS),
    spotFututresAugmentedDickeyFullerTest(PineJS),
    spotFuturesDominance(PineJS),
    spotFuturesDominanceHighlighter(PineJS),
    spotFuturesSpread(PineJS),
    spoofingHighlighter(PineJS),
    standardizedActiveReturnMeasure(PineJS),
    stopKiller(PineJS),
    stopKillerHighlighter(PineJS),
    takeProfitAndStopLoss(PineJS),
    tape(PineJS),
    tickIndex(PineJS),
    timeDomination(PineJS),
    totalTradeVolumeByDex(PineJS),
    transactionVolume(PineJS),
    trendAssessment(PineJS),
    twitterFollowers(PineJS),
    valueAtRisk(PineJS),
    volalility(PineJS),
    volumeChange(PineJS),
    volumeDelta(PineJS),
    whale1KTransactionCount(PineJS),
    whale1MlnTransactionCount(PineJS),
    who(PineJS),
    withdrawalBalance(PineJS),
    zScore(PineJS),
  ];

  const sfTraderIndicators: CustomIndicator[] = [];
  //
  const sfProIndicators: CustomIndicator[] = [
    bidAskDelta(PineJS),
    bidAskImbalance(PineJS),
    timeDomination(PineJS),
    ideas(PineJS),
  ];

  //
  // if (this.isPro) {
  //   sfIndicators = sfIndicators.concat(
  //     sfIndicators,
  //     sfTraderIndicators,
  //     sfProIndicators,
  //   );
  // } else if (this.isTrader) {
  //   sfIndicators = sfIndicators.concat(sfIndicators, sfTraderIndicators);
  // } else {
  //   sfIndicators = sfIndicators.concat(sfIndicators, [
  //     hftActivityDemo(PineJS),
  //     manipulationMonitorDemo(PineJS),
  //     averageDayTradeSpeedDemo(PineJS),
  //     netOpenInterestSpikeDemo(PineJS),
  //     openInterestSpikeDemo(PineJS),
  //     spotFuturesDominanceDemo(PineJS),
  //   ]);
  // }
  //
  // if (environment.development) {
  //   sfIndicators = sfIndicators.concat(sfIndicators, [
  //     //testData(PineJS),
  //     who(PineJS),
  //   ]);
  // }
  //
  // // if (this.propDesk) {
  // //   console.log("Loading Prop Desk Indicators");
  sfIndicators = sfIndicators.concat(sfIndicators, [
    marketPowerHighlighter(PineJS),
    marketPower(PineJS),
    retailPower(PineJS),
    pumpTrend(PineJS),
    pumpTrendHighlighter(PineJS),
  ]);

  return sfIndicators.concat(sfTraderIndicators, sfProIndicators);
}

export interface ISfChartProps {
  symbol?: string;
  interval?: ResolutionString;
  options?: ChartingLibraryWidgetOptions;
  syncTabConfigWithChart?: boolean;
  indicatorTitle?: string;
  indicatorChart?: boolean;
}

export const SfChartFullFunctional = memo(function SfChartFullFunctional({
  symbol,
  interval: _interval,
  options,
  syncTabConfigWithChart = true,
  indicatorTitle,
  indicatorChart = false,
}: ISfChartProps) {
  const nodeId = usePaneContext();
  const { updateTabConfig, getNodeConfig } = useGrid(nodeId);
  const config = getNodeConfig();
  const color = config.color;
  const { user } = useAuth();

  const interval = _interval ?? config.interval ?? ("5" as ResolutionString);
  const {
    colorsToSymbolMap,
    setSymbolForColor,
    indicatorsToColorMap,
    addIndicatorForColor,
  } = useDashboard((state) => ({
    colorsToSymbolMap: state.colorsToSymbolMap,
    setSymbolForColor: state.setSymbolForColor,
    indicatorsToColorMap: state.indicatorsToColorMap,
    addIndicatorForColor: state.addIndicatorForColor,
  }));

  const nodeSymbol = colorsToSymbolMap[color];

  const cascadeLevels = useCascadeLevelsBySymbol(
    BINANCE_RESOLUTION_MAP[interval],
    nodeSymbol,
  );

  const marginLevels = useMarginLevelsBySymbol(
    BINANCE_RESOLUTION_MAP[interval],
    nodeSymbol,
  );

  const id = useMemo(
    () => `chartContainer_${Math.random().toString(36).substring(7)}`,
    [],
  );
  // const chartIsReady = useRef(false);
  const indicatorAdded = useRef(false);

  const initialOptions: ChartingLibraryWidgetOptions = useMemo(
    () => ({
      container: id,
      locale: "en",
      library_path: "static/charting_library/charting_library/",
      charts_storage_url: "https://save-load.cf.spreadfighter.cloud",
      charts_storage_api_version: "1.1",
      auto_save_delay: 0.5, // возможно лучше сделать 1сек
      datafeed: datafeed,
      symbol: symbol || nodeSymbol || "BTCUSDT",
      interval: interval,
      autosize: true,
      custom_indicators_getter: (
        PineJS: PineJS,
      ): Promise<CustomIndicator[]> => {
        return Promise.resolve(getSFIndicators(PineJS));
      },
      disabled_features: [
        "popup_hints",
        "header_saveload",
        "display_market_status",
      ],
      enabled_features: [
        "study_templates",
        "hide_left_toolbar_by_default",
        "show_spread_operators",
        "hide_main_series_symbol_from_indicator_legend",
        "hide_price_scale_global_last_bar_value",
        "study_overlay_compare_legend_option",
        "use_localstorage_for_settings",
        "two_character_bar_marks_labels",
      ],

      client_id: "sf-front-v2",
      time_scale: {},
      theme: "dark",
      overrides: {
        // Background
        "paneProperties.background": "#0f0f0f",
        "paneProperties.backgroundType": "solid",
        // Candles
        "mainSeriesProperties.barStyle.upColor": "#3CA64B",
        "mainSeriesProperties.barStyle.downColor": "#B2182C",
        "mainSeriesProperties.candleStyle.upColor": "#3CA64B",
        "mainSeriesProperties.candleStyle.downColor": "#B2182C",
        "mainSeriesProperties.candleStyle.borderUpColor": "#3CA64B",
        "mainSeriesProperties.candleStyle.borderDownColor": "#B2182C",
        "mainSeriesProperties.candleStyle.wickUpColor": "#3CA64B",
        "mainSeriesProperties.candleStyle.wickDownColor": "#B2182C",
        "mainSeriesProperties.hollowCandleStyle.borderUpColor": "#3CA64B",
        "mainSeriesProperties.hollowCandleStyle.borderDownColor": "#B2182C",
        "mainSeriesProperties.hollowCandleStyle.upColor": "#3CA64B",
        "mainSeriesProperties.hollowCandleStyle.downColor": "#B2182C",
        "mainSeriesProperties.hollowCandleStyle.wickUpColor": "#3CA64B",
        "mainSeriesProperties.hollowCandleStyle.wickDownColor": "#B2182C",
        // Lines
        "mainSeriesProperties.lineStyle.color": "#ffffff",
        "mainSeriesProperties.lineWithMarkersStyle.color": "#ffffff",
        "mainSeriesProperties.steplineStyle.color": "#ffffff",
        // Areas
        "mainSeriesProperties.areaStyle.color1": "rgba(13, 157, 219, 0.25)",
        "mainSeriesProperties.areaStyle.color2": "rgba(13, 157, 219, 0.05)",
        "mainSeriesProperties.areaStyle.linecolor": "#ffffff",
        "mainSeriesProperties.hlcAreaStyle.closeLowFillColor":
          "rgba(75, 192, 91, 0.2)",
        "mainSeriesProperties.hlcAreaStyle.highCloseFillColor":
          "rgba(204, 43, 43, 0.2)",
        "mainSeriesProperties.hlcAreaStyle.highLineColor": "#B2182C",
        "mainSeriesProperties.hlcAreaStyle.lowLineColor": "#3CA64B",
        "mainSeriesProperties.baselineStyle.topFillColor1":
          "rgba(75, 192, 91, 0.25)",
        "mainSeriesProperties.baselineStyle.topFillColor2":
          "rgba(75, 192, 91, 0.05)",
        "mainSeriesProperties.baselineStyle.topLineColor": "#3CA64B",
        "mainSeriesProperties.baselineStyle.bottomFillColor1":
          "rgba(204, 43, 43, 0.05)",
        "mainSeriesProperties.baselineStyle.bottomFillColor2":
          "rgba(204, 43, 43, 0.25)",
        "mainSeriesProperties.baselineStyle.bottomLineColor": "#B2182C",
        // Exotic charts
        "mainSeriesProperties.columnStyle.upColor": "rgba(75, 192, 91, 0.5)",
        "mainSeriesProperties.columnStyle.downColor": "rgba(204, 43, 43, 0.5)",
        "mainSeriesProperties.hiloStyle.borderColor": "#3CA64B",
        "mainSeriesProperties.hiloStyle.color": "#3CA64B",
        "mainSeriesProperties.hiloStyle.labelColor": "#3CA64B",
        "mainSeriesProperties.haStyle.borderUpColor": "#3CA64B",
        "mainSeriesProperties.haStyle.borderDownColor": "#B2182C",
        "mainSeriesProperties.haStyle.upColor": "#3CA64B",
        "mainSeriesProperties.haStyle.downColor": "#B2182C",
        "mainSeriesProperties.haStyle.wickUpColor": "#3CA64B",
        "mainSeriesProperties.haStyle.wickDownColor": "#B2182C",
        "mainSeriesProperties.kagiStyle.upColor": "#3CA64B",
        "mainSeriesProperties.kagiStyle.upColorProjection": "#3CA64B",
        "mainSeriesProperties.kagiStyle.downColor": "#B2182C",
        "mainSeriesProperties.kagiStyle.downColorProjection": "#B2182C",
        "mainSeriesProperties.pbStyle.borderUpColor": "#3CA64B",
        "mainSeriesProperties.pbStyle.borderUpColorProjection": "#3CA64B",
        "mainSeriesProperties.pbStyle.borderDownColor": "#B2182C",
        "mainSeriesProperties.pbStyle.borderDownColorProjection": "#B2182C",
        "mainSeriesProperties.pbStyle.upColor": "#3CA64B",
        "mainSeriesProperties.pbStyle.upColorProjection": "#3CA64B",
        "mainSeriesProperties.pbStyle.downColor": "#B2182C",
        "mainSeriesProperties.pbStyle.downColorProjection": "#B2182C",
        "mainSeriesProperties.pnfStyle.upColor": "#3CA64B",
        "mainSeriesProperties.pnfStyle.upColorProjection": "#3CA64B",
        "mainSeriesProperties.pnfStyle.downColor": "#B2182C",
        "mainSeriesProperties.pnfStyle.downColorProjection": "#B2182C",
        "mainSeriesProperties.renkoStyle.borderUpColor": "#3CA64B",
        "mainSeriesProperties.renkoStyle.borderUpColorProjection": "#3CA64B",
        "mainSeriesProperties.renkoStyle.borderDownColor": "#B2182C",
        "mainSeriesProperties.renkoStyle.borderDownColorProjection": "#B2182C",
        "mainSeriesProperties.renkoStyle.upColor": "#3CA64B",
        "mainSeriesProperties.renkoStyle.upColorProjection": "#3CA64B",
        "mainSeriesProperties.renkoStyle.downColor": "#B2182C",
        "mainSeriesProperties.renkoStyle.downColorProjection": "#B2182C",
        "mainSeriesProperties.renkoStyle.wickUpColor": "#3CA64B",
        "mainSeriesProperties.renkoStyle.wickDownColor": "#B2182C",
      },
      studies_overrides: {
        "volume.volume.color.0": "rgba(178, 24, 44, 0.5)",
        "volume.volume.color.1": "rgba(60, 166, 75, 0.5)",
        "volume.volume ma.color": "rgb(255, 255, 255)",
        "volume.volume ma.linewidth": 1,
        "volume.volume ma.visible": true,
        // 'volume profile visible range.histBars2.color.0': 'rgba(178, 24, 44, 0.5)',
        // 'volume profile visible range.histBarsVA.color.1': 'rgba(60, 166, 75, 0.5)',
        // 'volume profile visible range.pocLines.color': 'rgb(255, 255, 255)',
        "volume profile visible range.developing poc.color":
          "rgb(255, 255, 255)",
        "volume profile visible range.developing va high.color":
          "rgb(255, 255, 255)",
        "volume profile visible range.developing va high.linestyle": 2,
        "volume profile visible range.developing va low.color":
          "rgb(255, 255, 255)",
        "volume profile visible range.developing va low.linestyle": 2,
      },
      user_id: user!.uid,
      // custom_css_url: "/static/charting_library/charting_library/custom.css",
      custom_css_url: "/chart.css",

      debug: false,
    }),
    [id, symbol, nodeSymbol, interval, user],
  );
  const widgetRef = useRef<IChartingLibraryWidget | null>(null);

  useEffect(() => {
    localStorage.removeItem("tradingview.current_theme.name");
    if (!widgetRef.current) {
      widgetRef.current = new Widget(merge({}, initialOptions, options));
    }
  }, [initialOptions, options]);

  useEffect(() => {
    if (syncTabConfigWithChart && widgetRef.current && nodeId) {
      widgetRef.current?.onChartReady(() => {
        getSaveChartDetails(nodeId).then((chartFromServer) => {
          if (chartFromServer) {
            widgetRef.current?.loadChartFromServer(chartFromServer);
          }
          widgetRef.current?.subscribe("onAutoSaveNeeded", () => {
            widgetRef.current?.save(async () => {
              widgetRef.current?.saveChartToServer(
                () => {},
                () => {
                  console.log("saveChartToServer error");
                },
                { chartName: nodeId },
              );
            });
          });
        });
      });
    }
  }, [nodeId, syncTabConfigWithChart]);

  async function getSaveChartDetails(
    id: string,
  ): Promise<SaveLoadChartRecord | undefined> {
    return new Promise((r) => {
      widgetRef.current?.getSavedCharts((e: SaveLoadChartRecord[]) => {
        const chart = e.find((e) => e.name === id);
        return r(chart);
      });
    });
  }

  useEffect(() => {
    if (widgetRef.current && symbol) {
      widgetRef.current.onChartReady(async () => {
        widgetRef.current?.chart().setSymbol(symbol, () => {});
      });
    }
  }, [color, symbol, id]);

  useEffect(() => {
    if (widgetRef.current && !symbol) {
      widgetRef.current.onChartReady(() => {
        const symbol = nodeSymbol ?? "BTCUSDT";
        widgetRef.current?.chart().setSymbol(symbol, () => {});
        // setSymbolForColor(color, symbol);
      });
    }
  }, [color, nodeSymbol, symbol]);

  useEffect(() => {
    widgetRef.current?.onChartReady(() => {
      widgetRef.current?.applyOverrides(initialOptions?.overrides ?? {});
    });
  }, [initialOptions?.overrides]);

  useEffect(() => {
    if (widgetRef.current && interval) {
      widgetRef.current.onChartReady(() => {
        if (widgetRef.current?.chart) {
          widgetRef.current?.chart().setResolution(interval, () => {});
        }
        if (interval === "60") {
          widgetRef.current?.applyOverrides({
            "mainSeriesProperties.style": 3,
          });
        } else {
          widgetRef.current?.applyOverrides({
            "mainSeriesProperties.style": 1,
          });
        }
      });
    }
  }, [indicatorTitle, interval]);

  useEffect(() => {
    if (widgetRef.current && indicatorTitle && !indicatorAdded.current) {
      indicatorAdded.current = true;
      widgetRef.current.onChartReady(() => {
        widgetRef.current
          ?.chart()
          .createStudy(indicatorTitle)
          .then((e) => {
            if (e && config.indicatorMergedWithChart) {
              widgetRef.current?.chart().getStudyById(e).mergeUp();
            }
          });
      });
    }
  }, [config.indicatorMergedWithChart, indicatorTitle]);

  useEffect(() => {
    if (syncTabConfigWithChart) {
      if (widgetRef.current) {
        widgetRef.current?.onChartReady(() => {
          widgetRef.current?.chart().onSymbolChanged().unsubscribeAll(null);
          widgetRef.current
            ?.chart()
            .onSymbolChanged()
            .subscribe(null, () => {
              let symbol = widgetRef.current?.chart().symbol();
              if (symbol?.indexOf("BINANCE:") === 0) {
                symbol = symbol.replace("BINANCE:", "");
              }
              if (symbol) {
                setSymbolForColor(color, symbol);
              }
            });
          widgetRef.current
            ?.chart()
            .onIntervalChanged()
            .subscribe(null, (interval) => {
              updateTabConfig({ interval });
            });
        });
      }
    }
  }, [color, setSymbolForColor, syncTabConfigWithChart, updateTabConfig]);

  const cascadeLevelsRef = useRef<ICascadeLevelDetails[] | null>(null);
  const marginLevelsRef = useRef<IMarginLevelDetails[] | null>(null);

  useEffect(() => {
    if (cascadeLevels.data) {
      cascadeLevelsRef.current = cascadeLevels.data;
    }
    if (marginLevels.data) {
      marginLevelsRef.current = marginLevels.data;
    }
  }, [cascadeLevels.data, marginLevels.data]);

  const drawCascadeLevels = useCallback(() => {
    if (!cascadeLevelsRef.current) {
      console.log("No cascade levels");
      return;
    }
    cascadeLevelsRef.current?.map((level) => {
      widgetRef.current
        ?.chart()
        .createMultipointShape(
          [{ time: level.timestamp / 1000, price: level.price }],
          {
            shape: "horizontal_ray",
            text: level.logicFc
              ? `⇛ ${level.fcSum.toString()}`
              : level.fcSum.toString(),
            lock: false,
            overrides: {
              fontsize: level.logicFc ? 20 : 12,
              linewidth: 2,
              linecolor: level.side === "UP" ? "#3CA64B" : "#B2182C",
              linestyle: 0,
              showLabel: true,
              textcolor: "#ffffff",
              bold: false,
              vertLabelsAlign: "bottom",
              horzLabelsAlign: "right",
              showPrice: true,
            },
          },
        );
    });
  }, [cascadeLevelsRef]);

  const drawMarginLevels = useCallback(() => {
    if (!marginLevelsRef.current) {
      console.log("No cascade levels");
      return;
    }
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    marginLevelsRef.current?.map((level) => {
      widgetRef.current
        ?.chart()
        .createMultipointShape(
          [{ time: level.timestamp / 1000, price: level.price }],
          {
            shape: "horizontal_ray",
            lock: false,
            text: formatter.format(level.quoteQuantity),
            overrides: {
              fontsize: 12,
              linewidth: 2,
              linecolor: level.side === "UP" ? "#3CA64B" : "#B2182C",
              linestyle: 0,
              showLabel: true,
              textcolor: "#ffffff",
              bold: false,
              vertLabelsAlign: "bottom",
              horzLabelsAlign: "right",
              showPrice: true,
            },
          },
        );
    });
  }, [marginLevelsRef]);

  const dropDownAdded = useRef(false);

  useEffect(() => {
    let dropdownApi: Promise<IDropdownApi> | undefined;
    if (widgetRef.current && !dropDownAdded.current) {
      dropDownAdded.current = true;
      widgetRef.current.headerReady().then(async () => {
        dropdownApi = widgetRef.current?.createDropdown({
          title: "Custom Indicators",
          items: [
            {
              title: "Cascade Levels",
              onSelect: () => {
                drawCascadeLevels();
              },
            },
            {
              title: "Margin Levels",
              onSelect: () => {
                drawMarginLevels();
              },
            },
          ],
        });
      });

      return () => {
        // Cleanup logic if necessary
        dropdownApi?.then((api) => {
          api.remove();
        });
      };
    }
  }, [drawCascadeLevels, drawMarginLevels]);

  const { signalToDraw, clearSignal } = useDrawSignal();

  // Listen for signals
  useEffect(() => {
    if (!signalToDraw.signal || !signalToDraw.color) {
      return;
    }
    if (signalToDraw && widgetRef.current) {
      addIndicator(signalToDraw.signal, signalToDraw.color);
      clearSignal(); // Clear the signal after drawing
    }
  }, [signalToDraw, clearSignal]);

  const addIndicator = (indicator: string | null, color: string | null) => {
    if (!indicator || !color) {
      return;
    }

    if (color != config.color) {
      return;
    }
    widgetRef.current
      ?.chart()
      .createStudy(indicator)
      .then((e) => {});
  };

  return <div className={cn("h-full")} id={id} />;
});
