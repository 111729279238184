//eslint-disable-next-line
//@ts-nocheck

import {
  CustomIndicator,
  IPineStudyResult,
  LibraryPineStudy,
  PineJS,
  RawStudyMetaInfoId,
} from "@tradingView/charting_library";
import { LineStudyPlotStyle } from "@/lib/datafeed/utils.ts";

import {
  StudyPlotType,
  StudyTargetPriceScale,
} from "@/lib/datafeed/tvTypes.ts";

export const marketValueToRealisedValue = (PineJS: PineJS): CustomIndicator => {
  return {
    name: "Market value to realised value",
    metainfo: {
      _metainfoVersion: 51,
      id: "market-value-to-realised-value@tv-basicstudies-1" as RawStudyMetaInfoId,
      description: "[SFS] Market value to realised value",
      shortDescription: "[SF] MVRV",
      is_hidden_study: false,
      is_price_study: false,
      isCustomIndicator: true,
      linkedToSeries: false,
      priceScale: StudyTargetPriceScale.NoScale,
      format: {
        type: "price",
        precision: 3,
      },
      plots: [{ id: "metricPlot", type: StudyPlotType.Line }],
      defaults: {
        styles: {
          metricPlot: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: LineStudyPlotStyle.StepLine,
            trackPrice: true,
            color: "rgb(255, 255, 255)",
            transparency: 0,
          },
        },
        inputs: {},
      },
      styles: {
        metricPlot: {
          title: "MVRV",
          histogramBase: 0,
        },
      },
      inputs: [],
    },

    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
        const symbol: string = `${this._context.symbol.info.name.split("USDT")[0]}#SANTIMENTS_MVRV_USD_INTRADAY`;
        this._context.new_sym(symbol, PineJS.Std.period(this._context));
      };

      this.main = function (context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        //Data parsing
        this._context.select_sym(1);
        const firstSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        const firstSeriesCloseSeries: any = this._context.new_var(
          PineJS.Std.close(this._context),
        );
        this._context.select_sym(0);
        const mainSymbolTimeSeries: any = this._context.new_var(
          this._context.symbol.time,
        );
        let metric: number = firstSeriesCloseSeries.adopt(
          firstSymbolTimeSeries,
          mainSymbolTimeSeries,
          1,
        );
        if (metric === 0) {
          metric = NaN;
        }

        //Return
        return [metric];
      };
    },
  };
};
